import {Col, Row} from 'antd';
import {SorterResult} from 'antd/es/table/interface';
import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {TableList} from '../table/Table';
import {getAdminVehiclesColumns, VehicleRecord} from '../table/Vehicles';
import {useStateFn} from '../useStateFn';

const defaultSorter: SorterResult<any> = {
  columnKey: 'updateDate',
  order: 'descend',
};

/** for admins */
export const OwnerVehicles: FC<{ownerId: string}> = (props) => {
  const {ownerId} = props;

  const {t, lang} = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshDate, setRefreshDate] = useState<number>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState<number>();

  const columns = useStateFn(() => getAdminVehiclesColumns(t, lang), [t, lang]);
  const forcedFilter = useStateFn(() => ({ownerId: [ownerId]}), [ownerId]);

  return (
    <div style={{margin: '2rem 0'}}>
      <Row justify="space-between" align="middle">
        <Col>
          <AppTitle level={3} style={{marginTop: 0}}>
            {t('vehicles')}
          </AppTitle>
        </Col>
      </Row>

      <TableList<VehicleRecord>
        singularRoute={'/vehicle'}
        pluralRoute={'/vehicles'}
        columns={columns}
        defaultSorter={defaultSorter}
        refreshDate={refreshDate}
        setEditEntity={(v) => navigate(`/admin/vehicle/${v.id}`)}
        onTotalChange={setTotal}
        forcedFilter={forcedFilter}
        actionButtonProps={{
          delete: {disabled: true},
        }}
      />
    </div>
  );
};
