import {CheckCircleTwoTone} from '@ant-design/icons';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {TranslateFn, useTranslation} from '../../translation';
import {TimestampToDayjsDate} from '../../util/date';
import {ApartmentCodeSelect} from '../input/ApartmentCodeSelect';
import {OwnerSelect} from '../input/OwnerSelect';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {ArticleType, ArticleTypeLabel} from './Article';
import {OrderRecord, renderOrderNumber} from './Order';
import {
  booleanFilters,
  getDefaultSorter,
  TableColumnsProp,
  TableList,
} from './Table';

interface GoldCardRecord
  extends Pick<
    OrderRecord,
    | 'id'
    | 'createDate'
    | 'updateDate'
    | 'orderNumber'
    | 'season'
    | 'owner'
    | 'recipientName'
    | 'apartment'
  > {
  nbGoldCards: number;
  articleName: string;
}

export const getGoldCardColumns = (
  t: TranslateFn,
): TableColumnsProp<GoldCardRecord> => [
  {
    title: t('orders.createDate.short'),
    key: 'createDate',
    dataIndex: 'createDate',
    render: (createDate) => TimestampToDayjsDate(createDate).format('L'),
    sorter: true,
  },
  {
    title: t('orders.orderNumber'),
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    searchInput: true,
    sorter: true,
    render: (_, order) => renderOrderNumber(order),
  },
  {
    title: t('orders.season'),
    key: 'seasonId',
    dataIndex: 'seasonId',
    sorter: true,
    render: (_, {season}: GoldCardRecord) => season?.code,
  },
  {
    title: t('orders.owner'),
    key: 'ownerId',
    dataIndex: 'ownerId',
    sorter: true,
    render: (_, {owner}) =>
      owner ? (
        owner?.user ? (
          <Link to={`/admin/owner/${owner.id}`}>
            {owner?.user?.lastName} {owner?.user?.firstName}
          </Link>
        ) : (
          <Link to={`/admin/owner/${owner.id}`}>{owner.id}</Link>
        )
      ) : null,
    searchInput: true,
    searchSelect: (props) => <OwnerSelect {...props} />,
  },
  {
    title: t('orders.recipient'),
    key: 'recipientName',
    dataIndex: 'recipientName',
    sorter: true,
    render: (_, entity) => entity?.recipientName,
    searchInput: true,
  },
  {
    title: t('orders.apartment'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    sorter: true,
    render: (_, {apartment}) => apartment?.code,
    searchInput: true,
    searchSelect: (props) => <ApartmentCodeSelect {...props} />,
  },
  {
    title: t('orders.nbGoldCards'),
    key: 'nbGoldCards',
    dataIndex: 'nbGoldCards',
    // sorter: true,
    render: (_, entity) => entity?.nbGoldCards,
    // searchInput: true,
  },
  // {
  //   title: t('orders.article'),
  //   key: 'articleId',
  //   dataIndex: 'articleId',
  //   sorter: true,
  //   render: (_, entity) => entity?.articleName,
  //   searchInput: true,
  // },
  {
    title: t('orders.articleType'),
    key: 'articleType',
    dataIndex: 'articleType',
    sorter: true,
    render: (articleType: ArticleType) => t(ArticleTypeLabel[articleType]),
    // searchInput: true,
    // searchSelect: (props) => (
    //   <Select<string>
    //     {...props}
    //     options={Object.entries(ArticleTypeLabel)
    //       .filter(([key, value]) =>
    //         [ArticleType.POOL_GOLD, ArticleType.POOL_GOLD_ADDITIONAL].includes(
    //           Number(key),
    //         ),
    //       )
    //       .map(([type, label]) => ({
    //         value: type,
    //         label: t(label),
    //       }))}
    //     showSearch
    //   />
    // ),
  },
  {
    title: t('orders.isRenewal.short'),
    key: 'isRenewal',
    dataIndex: 'isRenewal',
    render: (isRenewal) =>
      isRenewal ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null,
    filters: booleanFilters(t),
  },
  {
    title: t('orders.renewalCardNo.short'),
    key: 'renewalCardNo',
    dataIndex: 'renewalCardNo',
    render: (renewalCardNo) => renewalCardNo,
    searchInput: true,
  },
];

export const AdminGoldCards: FC = () => {
  const {t} = useTranslation();

  // const [total, setTotal] = useState<number>();

  const columns = useStateFn(() => getGoldCardColumns(t), [t]);
  const defaultSorter = useStateFn(
    () => getDefaultSorter(columns, 'orderNumber'),
    [columns],
  );

  return (
    <ContentDiv
      // title={total ? `${t('cardsGold.title')}: ${total}` : t('cardsGold.title')}
      title={t('cardsGold.title')}
    >
      <TableList<GoldCardRecord>
        singularRoute={'#'}
        pluralRoute={'/admin/gold-cards'}
        columns={columns}
        // refreshDate={refreshDate}
        // setEditEntity={setEditEntity}
        defaultSorter={defaultSorter}
        hideActionColumn
        // onTotalChange={setTotal}
        // onFilterChange={setExportFilter}
      />
    </ContentDiv>
  );
};
