import {FC} from 'react';
import {COLORS, MAX_WIDTH} from '../../design';

interface AppContentProps {
  children?: React.ReactNode;
}

export const AppContent: FC<AppContentProps> = ({children}) => {
  return (
    <div style={{flex: '1 1 auto'}}>
      <div
        style={{
          maxWidth: MAX_WIDTH,
          margin: '24px auto',
        }}
      >
        <div
          style={{
            padding: 24,
            border: `2px solid ${COLORS.PRIVATE_COLOR}`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
