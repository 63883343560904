{
  "general.login": "Connexion",
  "general.logout": "Déconnexion",
  "general.updateTitle": "Modification",
  "general.update": "Modifier",
  "general.addTitle": "Ajout",
  "general.add": "Ajouter",
  "general.yes": "Oui",
  "general.no": "Non",
  "general.close": "Fermer",
  "general.cancel": "Annuler",
  "general.delete": "Supprimer",
  "general.submit": "Soumettre",
  "general.notConnected": "Pas connecté",
  "general.uploadError": "Erreur de mise en ligne",
  "general.form.messages.required": "Le champ \"${label}\" est obligatoire",
  "general.form.messages.types_email": "Le champ \"${label}\" n'est pas une adresse email valide",
  "general.form.messages.types_number": "Le champ \"${label}\" n'est pas un nombre valide",
  "general.form.messages.number_range": "Le champ \"${label}\" doit être entre ${min} et ${max}",
  "general.connectionError": "Erreur de connexion",
  "general.timeIntervalAgo": "Il y a ${interval}",
  "general.loginAction": "J'ai déjà un compte",
  "general.registerAction": "Je crée mon compte",
  "general.upload": "Mettre en ligne",
  "general.invalidUploadFileType": "Type de fichier invalide",
  "general.clearFilters": "Enlever filtres",
  "general.clearSorters": "Enlever tri",
  "general.reset": "Réinitialiser",
  "general.search": "Rechercher...",
  "general.searchSomething": "Rechercher ${label}...",
  "general.ok": "Ok",
  "general.goBackHome": "Retour à l'accueil",
  "general.notFoundErrorMessage": "Page introuvable",
  "general.starMarksMandatory": "Les champs * sont obligatoires",
  "general.export": "Exporter",
  "general.continue": "Continuer",
  "general.createDate": "Date de création",
  "general.updateDate": "Date de modification",

  "general.overstaturationError.message1": "Pour cause de réseau saturé, les commandes des cartes piscines blanches sont momentanément suspendues.",
  "general.overstaturationError.message2": "Nous nous excusons pour la gêne occasionnée et vous invitons à renouveler l'opération sous 24/48h.",

  "general.sumupCheckError.message1": "Le règlement des commandes de cartes blanches en CB est actuellement suspendu sur cette application pour des problèmes techniques.",
  "general.sumupCheckError.message2": "Vous pouvez passer vos commandes en effectuant un virement. Pour cela enregistrez votre commande avec le mode de paiement Virement. Vous pouvez également nous adresser un chèque :",
  "general.sumupCheckError.message3": "ASCAPE - Résidence du Belvédère - Boite N° 6 - 99 rue des Calanques - 83530 SAINT RAPHAEL",
  "general.sumupCheckError.message4": "Les cartes seront réalisées après réception du paiement.",
  "general.sumupCheckError.message5": "Des instructions de tolérance ont été données aux plagistes à l'entrée de la piscine du Grand Bleu afin de permettre l'accès avec les anciennes cartes aux propriétaires et locataires durant cette période.",
  "general.sumupCheckError.message6": "Nous vous prions de nous excuser pour la gêne occasionnée.",

  "general.duration.days": "Jours",
  "general.duration.weeks": "Semaines",
  "general.duration.months": "Mois",
  "general.duration.years": "Années",

  "general.error.negativeDuration": "La date de début doit démarrer avant la date de fin",

  "general.ownersArea": "Espace propriétaires",
  "footer.theVillage": "Le site",
  "footer.theVillage.visit": "Visiter",
  "footer.theVillage.news": "Actualités",
  "footer.theVillage.findAccomodation": "Trouver un logement",
  "footer.theVillage.shopsAndServices": "Commerces & Services",
  "footer.theArea": "Les alentours",
  "footer.theArea.events": "Évènements",
  "footer.theArea.ourSelection": "CAP ESTEREL et ses alentours",
  "footer.theArea.infoAndResources": "Informations et ressources",
  "footer.ownersArea": "Espace propriétaires",
  "footer.ownersArea.swimmingPoolOrParkingCards": "Cartes de piscine ou de parking",
  "footer.ownersArea.administrativeDocuments": "Documents administratifs",
  "footer.ownersArea.makeAStatement": "Faire une déclaration",
  "footer.ownersArea.news": "Actualités",
  "footer.ourNewsletter": "Notre newsletter",
  "footer.yourEmail": "Votre email",
  "footer.newsletterSignup": "S'abonner",
  "footer.newsletterConfidentialyText": "J'accepte la politique de confidentialité.",
  "footer.legalNotice": "Mentions légales",
  "footer.cookiePolicy": "Politique de cookies",
  "footer.privacyPolicy": "Politique de confidentialité",
  "footer.termsOfSales": "Conditions générales de vente",

  "header.theVillage": "Le village",
  "header.activities": "Activités",
  "header.tourism": "Tourisme",
  "header.news": "Actualités",
  "header.contact": "Contact",
  "header.owner.documents": "Documents",
  "header.owner.info": "Informations",
  "header.owner.news": "Actualités",
  "header.owner.orderTracking": "Suivi commandes",
  "header.owner.myAccount": "Mon compte",
  "header.owner.myVehicles": "Mes véhicules",
  "header.owner.myOccupants": "Mes locataires",
  "header.admin.settings": "Paramètres",
  "header.admin.apartments": "Appartements",
  "header.admin.owners": "Propriétaires",
  "header.admin.orders": "Commandes",
  "header.admin.invoices": "Factures",
  "header.admin.payments": "Règlements",
  "header.admin.entries": "Écritures",
  "header.admin.stats": "Statistiques",

  "home.welcome": "Bienvenue",
  "home.user": "Utilisateur",
  "home.notConnected": "Pas connecté",
  "home.users": "Utilisateurs",
  "home.owners": "Propriétaires",
  "home.ownerRequests": "Comptes en attente",
  "home.apartments": "Appartements",
  "home.hosts": "Hébergeurs",
  "home.articles": "Articles",

  "home.ordersUnavailable": "Le module de saisie des commandes de cartes de piscine et de parking est en phase de test, il sera prochainement ouvert.\nMerci de cotre compréhension",

  "home.settings": "Paramètres",
  "home.stats": "Statistiques",
  "home.orders": "Commandes",
  "home.invoices": "Factures",
  "home.payments": "Règlements",
  "home.accountingEntries": "Écritures",
  "home.apartmentRequests": "Appartements en attente",
  "home.cardsAwaitingProduction": "Cartes à fabriquer",
  "home.cardsAwaitingDelivery": "Cartes à livrer",
  "home.cardsGold": "Cartes Or",
  "home.vehicles": "Véhicules",
  "home.occupants": "Locataires",
  "home.superAdmin": "Super admin",

  "home.unconfirmed.title": "Confirmation compte",
  "home.unconfirmed.message1": "Merci de valider votre adresse email en cliquant sur le lien dans le mail que vous avez reçu.",
  "home.unconfirmed.message2": "À bientôt",

  "home.unvalidated.title": "Validation nouveau compte",
  "home.unvalidated.message1": "Nous avons bien reçu votre demande de création de compte.",
  "home.unvalidated.message2": "Elle est en cours de validation par nos services.",
  "home.unvalidated.message3": "Un mail de confirmation vous sera prochainement envoyé.",
  "home.unvalidated.message4": "",
  "home.unvalidated.message5": "",
  "home.unvalidated.message6": "À bientôt",

  "login.title": "Connexion",
  "login.loginActionButton": "Se connecter",
  "login.error.pleaseInputUsername": "Veuillez saisir votre nom d'utilisateur",
  "login.username": "Nom d'utilisateur",
  "login.error.pleaseInputPassword": "Veuillez saisir votre mot de passe",
  "login.password": "Mot de passe",
  "login.forgotPassword": "Mot de passe oublié",
  "login.home": "Accueil",
  "login.newPasswordTitle": "Nouveau mot de passe",
  "login.newPasswordText": "Veuillez saisir le code de vérification qui vous a été envoyé par mail.",
  "login.newPassword": "Nouveau mot de passe",
  "login.newPasswordConfirm": "Confirmation du mot de passe",
  "login.forcePasswordTitle": "Mot de passe",
  "login.forcePasswordText": "Veuillez choisir un mot de passe.",

  "users.title": "Utilisateurs",
  "users.email": "Email",
  "users.name": "Nom",
  "users.firstName": "Prénom",
  "users.lastName": "Nom de famille",
  "users.userTitle": "Civilité",
  "users.userTitleMr": "M.",
  "users.userTitleMrs": "Mme.",
  "users.userTitleMrAndMrs": "M. et Mme.",
  "users.role": "Rôle",
  "users.roleNotVerified": "Non vérifié",
  "users.roleUnvalidated": "Non validé",
  "users.roleOwner": "Propriétaire",
  "users.roleAdmin": "Admin",
  "users.wordpressUserId": "ID utilisateur WordPress",
  "users.disabled": "Mis en sommeil",
  "users.cognitoStatus": "Statut Cognito",
  "users.cognitoGroups": "Groupes Cognito",
  "users.resetPasswordAndSendEmail": "Réinit. mdp et envoyer un email",
  "users.updateEmail": "Modification de l'adresse email",
  "users.previousEmail": "Email existant",
  "users.warning.updateEmail": "Veuillez vérifier que l'utilisateur a bien accès à cette nouvelle adresse email avant de la modifier.\nElle ne sera pas vérifiée par l'application, et l'utilisateur ne pourra immédiatement pas se connecter avec son ancien identifiant",
  "users.updateEmail.email.subject": "Modification adresse email",
  "users.updateEmail.email.body": "Bonjour,\n\nJe souhaiterais mettre à jour l'adresse email associée à mon compte ${email}\nMa nouvelle adresse email est la suivante : \n\nCordialement,\n${name}",

  "owners.title": "Propriétaires",
  "owners.code": "Code",
  "owners.displayRecipientFields": "Afficher champs destinataire (commande)",
  "owners.phoneNumber": "Téléphone",

  "hosts.title": "Hébergeurs",
  "hosts.name": "Nom",
  "hosts.thirdParty": "Tiers",
  "hosts.address": "Adresse",
  "hosts.contactName": "Contact",
  "hosts.contactPhoneNumber": "Téléphone",
  "hosts.email": "Email",
  "hosts.comment": "Commentaire",

  "apartments.title": "Appartements",
  "apartments.code": "Code",
  "apartments.building": "Bâtiment",
  "apartments.type": "Type",
  "apartments.doorNumber": "N° porte",
  "apartments.beddings": "Nb couchage",
  "apartments.host": "Hébergeur",
  "apartments.owner": "Propriétaire",
  "apartments.condo": "Copropriété",
  "apartments.comment": "Commentaire",

  "apartments.unassign": "Désassigner",
  "apartments.unassign.confirm": "Êtes-vous sûr(e) de vouloir désassigner cet appartement de ce propriétaire ?",

  "apartments.error.alreadyOwned": "Cet appartement est déjà assigné à un autre propriétaire",
  "apartments.error.alreadyOwnedBySelf": "Cet appartement est déjà assigné à ce propriétaire",

  "articles.title": "Articles",
  "articles.code": "Code",

  "articles.label": "Label",
  "articles.type": "Type",
  "articles.season": "Saison",
  "articles.seasonStart": "Début saison",
  "articles.seasonEnd": "Fin saison",
  "articles.validityDuration": "Durée de validité",
  "articles.validityDurationType": "Unité de validité",
  "articles.unitPriceInclVAT": "Prix TTC",
  "articles.VAT": "TVA",
  "articles.accountingCode": "Code compta",
  "articles.active": "Activer sur le web",
  "articles.limitToBeddingQuota": "Respecter quota de couchages",
  "articles.beddingQuota": "Nb couchage",
  "articles.isBlanche": "Carte blanche",
  "articles.accountingCategory": "Ventilation",

  "article.type.parking": "Parking",
  "article.type.poolBlanche": "Piscine Blanche",
  "article.type.poolGold": "Piscine Or",
  "article.type.poolGoldAdditional": "Piscine Or Supp.",
  "article.type.lostParking": "Parking perdue",
  "article.type.lostPool": "Piscine perdue",
  "article.type.parkingClone": "Parking Clone",
  "article.type.misc": "Divers",
  "article.accountingCategory.parking": "Parking",
  "article.accountingCategory.pool": "Piscine",

  "article.inactive.ownerInvisible": "Ce type d'article n'est pas montré aux propriétaires",
  "article.inactive.season": "Cet article est lié à une saison inactive et ne sera pas montré aux propriétaires",

  "register.title": "Inscription",
  "register.lastName": "Nom",
  "register.firstName": "Prénom",
  "register.title_gender": "Civilité",
  "register.address": "Adresse (de votre résidence principale)",
  "register.zipCode": "Code postal",
  "register.city": "Ville",
  "register.country": "Pays",
  "register.phoneNumber": "Mobile",
  "register.condoBuildingApartmentCode": "Copro/Bâtiment/Porte",
  "register.condo": "Copro",
  "register.building": "Bâtiment",
  "register.apartmentCode": "Porte",
  "register.email": "Email",
  "register.password": "Mot de passe",
  "register.passwordValidation": "Confirmation mot de passe",
  "register.submit": "Créer",
  "register.passwordValidationReject": "Les deux mots de passe entrés ne correspondent pas",
  "register.mustBeInternational": "Le numéro doit commencer par un indicatif international +XX",
  "register.invalidPhoneNumber": "Le numéro de téléphone doit être valide",
  "register.verificationCode": "Code de vérification",
  "register.pleaseEnterVerificationCode": "Veuillez saisir le code de vérification qui vous a été envoyé par mail.",
  "register.condoBuildingApartmentMessage": "(Infos se trouvant sur votre relevé de charges)",

  "register.password.minLength": "Votre mot de passe doit comporter au moins ${minLength} caractères",
  "register.password.lower": "Votre mot de passe doit comporter une lettre minuscule",
  "register.password.upper": "Votre mot de passe doit comporter une lettre majuscule",
  "register.password.digit": "Votre mot de passe doit comporter un number",
  "register.password.symbol": "Votre mot de passe doit comporter une caractère spécial",

  "register.success.completeNewPassword": "Votre mot de passe a été modifié avec succès. Veuillez vous connecter avec votre nouveau mot de passe",
  "register.signUpError": "Erreur d'inscription",
  "register.code": "Code",

  "cognito.error.UserNotFoundException": "Utilisateur introuvable",
  "cognito.error.UsernameExistsException": "Cet email est déjà utilisé par un autre utilisateur",
  "cognito.error.NotAuthorizedException": "Accès refusé",
  "cognito.error.CodeMismatchException": "Le code de vérification est invalide",
  "cognito.error.UserNotConfirmedException": "Votre adresse email n'a pas été vérifiée",

  "owner.error.cannotDeleteIfApartments": "Impossible de supprimer un propriétaire associé à des appartements. Veuillez désassigner ces appartements",

  "ownerRequests.title": "Comptes en attente de validation",
  "ownerRequests.fullName": "Nom complet",
  "ownerRequests.lastName": "Nom de famille",
  "ownerRequests.firstName": "Prénom",
  "ownerRequests.title_gender": "Civilité",
  "ownerRequests.address": "Adresse",
  "ownerRequests.zipCode": "CP",
  "ownerRequests.city": "Ville",
  "ownerRequests.country": "Pays",
  "ownerRequests.phoneNumber": "Téléphone",
  "ownerRequests.email": "Email",
  "ownerRequests.apartment": "Appartement",
  "ownerRequests.createDate": "Date de création",

  "ownerRequest.validate": "Valider",
  "ownerRequest.validateWarning": "Êtes-vous sûr(e) de vouloir créer ce propriétaire? Le propriétaire sera notifié par email",
  "ownerRequest.validateError": "L'appartement lié à cette requête est déjà lié à un propriétaire",

  "orders.title": "Commandes",
  "orders.newOrder": "Nouvelle commande",
  "orders.orderNumber": "N° commande",
  "orders.owner": "Propriétaire",
  "orders.orderedCards": "Cartes commandées",
  "orders.deliveredCards": "Cartes livrées",
  "orders.totalPrice": "Prix total TTC",
  "orders.status": "Statut",
  "orders.accountingStatus": "Statut compta",
  "orders.fabricationStatus": "Statut fabrication",
  "orders.articleQuantity": "Quantité",
  "orders.createDate": "Date d'achat",
  "orders.createDate.short": "Date",
  "orders.apartment": "Appartement",
  "orders.article": "Article",
  "orders.season": "Saison",
  "orders.articleType": "Type d'article",
  "orders.validityStart": "Début de validité",
  "orders.validityEnd": "Fin de validité",
  "orders.comment": "Commentaire",
  "orders.paymentType": "Type de paiement",
  "orders.isProxy": "Procuration",
  "orders.isProxy.form": "Je donne procuration à un tiers",
  "orders.proxy": "Nom complet du mandataire",
  "orders.proxyTemplateDownload": "Télécharger le fichier PDF de procuration à remplir",
  "orders.proxyFile": "Fichier de procuration rempli",
  "orders.type": "Type",
  "orders.discount": "Remise",
  "orders.netPrice": "Prix net",
  "orders.checkoutId": "ID panier",
  "orders.checkNumber": "N° chèque",
  "orders.isRenewal": "Carte à réactiver",
  "orders.isRenewal.short": "À renouveler",
  "orders.iHaveAClone": "J'ai un clone",
  "orders.recipient": "Destinataire",
  "orders.recipientName": "Nom (destinataire)",
  "orders.recipientAddr": "Adresse (destinataire)",
  "orders.recipientZipCodeAndCity": "Code postal - Ville (destinataire)",
  "orders.recipientZipCode": "Code postal",
  "orders.recipientCity": "Ville",
  "orders.recipientCountry": "Pays (destinataire)",
  "orders.nbGoldCards": "Nb cartes or",
  "orders.renewalCardNo": "Renseigner le N° de toutes les cartes à réactiver, séparés par une virgule",
  "orders.renewalCardNo.short": "Cartes à renouveler",
  "orders.renewalCardNo.gold": "Renseigner le nom des personnes ou le n° des cartes OR à réactiver dans le tableau de droite nommé CARTES",

  "orders.regeneratePDF.confirm": "Voulez-vous vraiment regénérer le PDF de cette commande ? Cela supprimera le PDF actuel (le cas échéant).",
  "orders.regeneratePDF.success": "Le PDF a été regénéré avec succès",
  "orders.regeneratePDF.error": "Une erreur est survenue lors de la regénération du PDF.",

  "orders.type.invoice": "Facture",
  "orders.type.credit": "Avoir",

  "orders.accountingStatus.submitted": "Enregistrée",
  "orders.accountingStatus.printed": "Imprimée",
  "orders.accountingStatus.paid": "Payée",
  "orders.accountingStatus.accounted": "Comptabilisée",
  "orders.accountingStatus.failed": "Echouée",

  "orders.fabricationStatus.submitted": "Enregistrée",
  "orders.fabricationStatus.paid": "Payée",
  "orders.fabricationStatus.available": "Disponible",
  "orders.fabricationStatus.delivered": "Livrée",

  "order.paymentType.paypal": "PayPal",
  "order.paymentType.sumup": "Carte bancaire (SumUp)",
  "order.paymentType.wire": "Virement bancaire",
  "order.paymentType.check": "Chèque",
  "order.paymentType.cash": "Espèces",
  "order.paymentType.pos": "TPE",
  "order.paymentType.soge": "Carte bancaire",
  "orders.payment.wireInstructions": "Coordonnées bancaires",
  "orders.payment.iban": "IBAN",
  "orders.payment.bic": "BIC",
  "orders.payment.checkInstructions": "Coordonnées chèque",
  "orders.payment.checkOrder": "Ordre",
  "orders.payment.checkAddress": "Adresse",

  "orders.securePayment": "Paiement sécurisé",

  "order.modal.title": "Confirmer la commande",
  "order.modal.confirm": "Confirmer avec obligation de paiement",
  "order.modal.summary": "Résumé",
  "orders.confirm.terms.prefix": "J'ai lu et j'approuve les ",
  "orders.confirm.terms.link": "conditions générales de vente",
  "orders.confirm.terms.suffix": "",

  "order.submitSuccess": "Votre commande a été envoyée avec succès",

  "order.error.noArticles": "Aucun article disponible pour cet appartement/saison. Merci de contacter le support",

  "orderLines.title": "Lignes de commande",

  "orderLines.article": "Article",
  "orderLines.unitPrice": "Prix unitaire",
  "orderLines.quantity": "Quantité",
  "orderLines.VAT": "TVA",
  "orderLines.totalPrice": "Prix total",
  "orderLines.discount": "Remise",
  "orderLines.netPrice": "Prix net",
  "orderLines.customNetPrice": "Prix manuel",

  "card.create": "Ajout de carte personalisée",
  "card.upload.warning": "Uniquement des photos reconnaissables (sans chapeau, sans lunettes de soleil…)",

  "cards.title": "Cartes",
  "card.lastName": "Nom de famille",
  "card.firstName": "Prénom",
  "card.isChild": "Enfant < 6 ans",
  "card.cost": "Montant",
  "card.photo": "Photo",

  "seasons.title": "Saisons",
  "seasons.code": "Code",
  "seasons.dateStart": "Date de début",
  "seasons.dateEnd": "Date de fin",
  "seasons.active": "Activer sur le web",

  "confirm.title": "Confirmation",
  "confirm.email": "Email",
  "confirm.code": "Code",
  "confirm.submit": "Confirmer",

  "settings.title": "Paramètres",
  "settings.nextOrderId": "Prochain n° de commande",
  "settings.nextInvoiceId": "Prochain n° de facture",
  "settings.nextThirdPartyId": "Prochain n° Tiers",
  "settings.vatRate": "Taux de TVA",
  "settings.bankAccountClient": "Compte Client",
  "settings.bankAccountSales": "Compte Vente",
  "settings.bankAccountVat": "Compte TVA",
  "settings.bankAccountBank": "Compte Banque",
  "settings.journalSales": "Journal Vente",
  "settings.journalBank": "Journal Banque",
  "settings.iban": "IBAN",
  "settings.bic": "BIC",
  "settings.poolIban": "IBAN Piscine",
  "settings.poolBic": "BIC Piscine",
  "settings.parkingIban": "IBAN Parking",
  "settings.parkingBic": "BIC Parking",
  "settings.settingsTab": "Paramètres / Hébergeurs",
  "settings.articlesTab": "Articles / Saisons",
  "settings.adminUsersTab": "Utilisateurs admin.",
  "settings.adminAllUsersTab": "Utilisateurs (tous)",
  "settings.checkOrder": "Ordre",
  "settings.checkAddress": "Adresse",

  "apartmentRequests.title": "Demandes d'appartement",
  "apartmentRequests.requestTitle": "Demande d'ajout d'un nouvel appartement",
  "apartmentRequests.fullName": "Propriétaire",
  "apartmentRequests.email": "Email propriétaire",
  "apartmentRequests.code": "Code",
  "apartmentRequests.createDate": "Date de création",

  "apartmentRequests.alreadyOwned": "Cet appartement est déjà associé à votre compte",
  "apartmentRequests.alreadyRequested": "Cet appartement est déjà en attente de vérification",

  "apartmentRequests.validateWarning": "Êtes-vous sûr(e) de vouloir lier cet appartement à ce propriétaire? Le propriétaire sera notifié par email",
  "apartmentRequests.validateError": "L'appartement lié à cette requête est déjà lié à un propriétaire",

  "creditCard.title": "Carte bancaire",
  "creditCard.name": "Titulaire de la carte",
  "creditCard.number": "Numéro de carte",
  "creditCard.expiryMonth": "Mois d'expiration",
  "creditCard.expiryYear": "Année d'expiration",
  "creditCard.cvv": "Cryptogramme visuel",

  "creditCard.error.invalidCC": "Numéro de carte invalide",
  "creditCard.error.expiredCC": "Carte expirée",
  "creditCard.error.invalidCVC": "Cryptogramme invalide",

  "checkoutResult.pending": "Paiement en attente de confirmation",

  "checkoutResult.notFound.title": "Commande introuvable",
  "checkoutResult.notFound.subTitle": "Nous n'avons pas pu récupérer les informations de paiement liés à votre commande. Veuillez contacter notre support pour plus d'informations",

  "checkoutResult.notCompleted.title": "Le paiement a été annulé ou a échoué",
  "checkoutResult.notCompleted.subTitle": "Votre commande n'a pas été débitée. Veuillez réessayer, ou contacter notre support pour plus d'informations",

  "checkoutResult.success.title": "Commande complétée",
  "checkoutResult.success.subTitle": "Votre paiement a bien été reçu, et votre commande sera bientôt traitée par nos services",

  "invoices.title": "Factures",
  "invoices.createDate": "Date de création",
  "invoices.invoicedName": "Nom",
  "invoices.amount": "Montant",
  "invoices.invoiceNumber": "Numéro facture",
  "invoices.status": "Statut",
  "invoices.type": "Type",

  "payments.title": "Paiements",
  "payments.type": "Type",
  "payments.paymentDate": "Date de paiement",
  "payments.payerName": "Nom",
  "payments.label": "Libellé",
  "payments.amount": "Montant",
  "payments.invoiceNumber": "Numéro facture",
  "payments.isAccounted": "Compta",
  "payments.updateOrderStatus": "Ce paiement paye la commande N°${orderNumber}",

  "payments.warn.unexpectedOrderStatus": "La commande ${orderNumber} n'est plus en attente, son statut actuel est \"${orderStatus}\"",
  "payments.warn.unexpectedPaymentAmount": "La commande ${orderNumber} a un montant total de ${orderAmount}, mais le paiement actuel est de ${paymentAmount}",
  "payments.warn.unexpectedInvoiceOrderNumber": "La commande associée à la facture est différente de la commande associée au paiement",

  "payments.error.amountMustBePositiveAndGreaterThanZero": "Le montant du paiement doit être strictement supérieur à 0,00",

  "cardsAwaitingProduction.title": "Cartes à fabriquer",
  "cardsAwaitingDelivery.title": "Cartes à livrer",

  "cards.startDate": "Date",
  "cards.order": "Commande",
  "cards.name": "Nom",
  "cards.apartment": "Appartement",
  "cards.article": "Article",
  "cards.count": "Nb cartes commandées",
  "cards.fabricatedCount": "Nb cartes fabriquées",
  "cards.deliveredCount": "Nb cartes livrées",
  "cards.remainder": "Reliquat",
  "cards.amount": "Montant TTC",
  "cards.photo": "Photo",

  "cards.bulkDeliverRenewals": "Livrer toutes les cartes à renouveler",

  "stats.title": "Statistiques",
  "stats.cognitoStats": "Stats utilisateurs",
  "stats.cognito.total": "Utilisateurs inscrits",
  "stats.cognito.pending": "Utilisateurs ayant changé leur mdp",
  "stats.salesStats": "Stats de ventes",
  "stats.offSeason": "Hors-saison ?",
  "stats.card.goldSeasonalExport": "Export Or livrées saisonales",

  "entries.title": "Écritures",
  "entries.export": "Export écritures",
  "entries.exportStartDate": "Date de début de facturation",
  "entries.exportEndDate": "Date de fin de facturation",

  "cardsGold.title": "Cartes Or",

  "vehicles": "Véhicules",
  "vehicles.title": "Véhicules",
  "vehicles.createDate": "Date de création",
  "vehicles.updateDate": "Date de modification",
  "vehicles.date": "Date",
  "vehicles.active": "Actif",
  "vehicles.marked": "Pointé",
  "vehicles.type": "Type",
  "vehicles.type.owner": "Propriétaire",
  "vehicles.type.occupant": "Locataire",
  "vehicles.ownerId": "Propriétaire",
  "vehicles.apartmentId": "Appartement",
  "vehicles.brandName": "Marque",
  "vehicles.model": "Modèle",
  "vehicles.licensePlateNo": "Plaque d'immatriculation",
  "vehicles.licensePlateNo.short": "Immat.",
  "vehicles.occupantName": "Nom locataire",
  "vehicles.phoneNumber": "Téléphone",
  "vehicles.dates": "Dates",
  "vehicles.startDate": "Date de début",
  "vehicles.endDate": "Date de fin",
  "vehicles.error.already_three_vehicles_for_this_owner": "Il y a déjà 3 véhicules actifs pour ce propriétaire.",
  "vehicles.error.already_one_vehicle_for_this_period": "Il y a déjà un véhicule pour cette période.",
  "vehicles.newOccupant": "Nouveau véhicule (locataire)",
  "vehicles.newVehicle": "Nouveau véhicule"
}
