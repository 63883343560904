import {CheckCircleTwoTone, PlusOutlined} from '@ant-design/icons';
import {Space, Button, Form, Spin, Row, Alert, Col, message} from 'antd';
import {SorterResult} from 'antd/es/table/interface';
import {isAxiosError} from 'axios';
import {FC, useState} from 'react';
import {useNavigate, Link, useParams} from 'react-router-dom';
import {
  useGetVehicle,
  updateVehicle,
  createVehicle,
} from '../../component/entity/AdminVehicle';
import {PhoneNumberLink} from '../../component/input/PhoneNumberLink';
import {ContentDiv} from '../../component/layout/ContentDiv';
import {
  TableColumnsProp,
  booleanFilters,
  TableList,
} from '../../component/table/Table';
import {
  VehicleRecord,
  VehicleFormValues,
  VehicleType,
  canEditVehicle,
} from '../../component/table/Vehicles';
import {useStateFn} from '../../component/useStateFn';
import {TranslateFn, LANGUAGE, useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {useApi} from '../../util/Auth';
import {TimestampToDayjsDate} from '../../util/date';
import {useOwnerMe} from '../util';
import {OwnerVehicleForm} from './Form';

const getOccupantVehiclesColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<VehicleRecord> => [
  // {
  //   title: t('vehicles.createDate'),
  //   key: 'createDate',
  //   dataIndex: 'createDate',
  //   render: (createDate) =>
  //     createDate ? TimestampToDayjsDate(createDate).format('L LT') : undefined,
  //   sorter: true,
  // },
  {
    title: t('vehicles.active'),
    key: 'active',
    dataIndex: 'active',
    sorter: true,
    render: (_, {active}) =>
      active ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : undefined,
    filters: booleanFilters(t),
  },
  {
    title: t('vehicles.apartmentId'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    sorter: true,
    // searchInput: true,
    render: (_, obj) => (obj.apartment ? obj.apartment.code : undefined),
  },
  {
    title: t('vehicles.brandName'),
    key: 'brandName',
    dataIndex: 'brandName',
    sorter: true,
    searchInput: true,
    render: (_, obj) => obj.brandName,
  },
  {
    title: t('vehicles.model'),
    key: 'model',
    dataIndex: 'model',
    sorter: true,
    searchInput: true,
    render: (_, obj) => obj.model,
  },
  {
    title: t('vehicles.licensePlateNo'),
    key: 'licensePlateNo',
    dataIndex: 'licensePlateNo',
    sorter: true,
    searchInput: true,
    render: (_, obj) => obj.licensePlateNo,
  },
  {
    title: t('vehicles.occupantName'),
    key: 'occupantName',
    dataIndex: 'occupantName',
    sorter: true,
    searchInput: true,
    render: (_, obj) => obj.occupantName,
  },
  {
    title: t('vehicles.phoneNumber'),
    key: 'phoneNumber',
    dataIndex: 'phoneNumber',
    sorter: true,
    searchInput: true,
    render: (_, obj) => {
      if (!!obj.phoneNumber) {
        return <PhoneNumberLink phoneNumber={obj.phoneNumber} />;
      }

      return '';
    },
  },
  {
    title: t('vehicles.startDate'),
    key: 'startDate',
    dataIndex: 'startDate',
    sorter: true,
    // searchInput: true,
    render: (_, obj) =>
      obj.startDate ? TimestampToDayjsDate(obj.startDate).format('L') : '',
  },
  {
    title: t('vehicles.endDate'),
    key: 'endDate',
    dataIndex: 'endDate',
    sorter: true,
    // searchInput: true,
    render: (_, obj) =>
      obj.endDate ? TimestampToDayjsDate(obj.endDate).format('L') : '',
  },
];

const singularRoute = '/vehicle';
const pluralRoute = '/vehicles';
const backUrl = '/owner/occupants';

const defaultSorter: SorterResult<any> = {
  columnKey: 'updateDate',
  order: 'descend',
};

export const OccupantVehicles: FC = () => {
  const {t, lang} = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const columns = useStateFn(
    () => getOccupantVehiclesColumns(t, lang),
    [t, lang],
  );
  const forcedFilter = useStateFn(() => ({type: [VehicleType.OCCUPANT]}), []);

  const title = 'header.owner.myOccupants';

  return (
    <ContentDiv
      title={total !== undefined ? `${t(title)}: ${total}` : t(title)}
      titleRightComponent={
        <Space>
          <Link to="/owner/occupant">
            <Button type="primary" icon={<PlusOutlined />} />
          </Link>
        </Space>
      }
    >
      <TableList<VehicleRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        defaultSorter={defaultSorter}
        refreshDate={refreshDate}
        setEditEntity={(v) => navigate(`/owner/occupant/${v.id}`)}
        onTotalChange={setTotal}
        forcedFilter={forcedFilter}
        actionButtonProps={{
          delete: (vehicle) => ({disabled: !canEditVehicle(vehicle)}),
        }}
      />
    </ContentDiv>
  );
};

export const OccupantVehicleUpdate: FC = (props) => {
  const api = useApi();
  const {id: vehicleId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm<VehicleFormValues>();
  const [loading, setLoading] = useState(false);

  const {owner} = useOwnerMe();

  const {
    vehicle,
    loading: vehicleLoading,
    error: vehicleFetchError,
  } = useGetVehicle(vehicleId);

  if (vehicleLoading) {
    return <Spin size="large" />;
  }

  if (vehicleFetchError) {
    return (
      <div>
        <Row>
          <Alert message={vehicleFetchError} />
        </Row>
        <Row>
          <Link to={backUrl}>
            <Button>{t('general.cancel')}</Button>
          </Link>
        </Row>
      </div>
    );
  }

  if (!owner || !vehicle) {
    return <Spin size="large" />;
  }

  const canEdit = canEditVehicle(vehicle);

  return (
    <ContentDiv>
      <AppTitle level={3} style={{marginTop: 0}}>
        {t('vehicles.title')}
      </AppTitle>
      <Row gutter={20} justify="space-between">
        <Col span={12}>
          <OwnerVehicleForm
            ownerId={owner.id}
            type={VehicleType.OCCUPANT}
            creating={false}
            form={form}
            initialValues={{
              ...vehicle,
              dates:
                vehicle?.startDate && vehicle?.endDate
                  ? [
                      TimestampToDayjsDate(vehicle.startDate),
                      TimestampToDayjsDate(vehicle.endDate),
                    ]
                  : undefined,
            }}
            onFinish={async (values) => {
              if (loading) {
                return;
              }
              if (!canEdit) {
                return;
              }
              setLoading(true);

              // when license plate changes, we want to reset "marked" flag
              // so that admin can see the update
              if (values.licensePlateNo !== vehicle.licensePlateNo) {
                values.marked = false;
              }

              try {
                await updateVehicle(api, vehicleId ?? '-1', {
                  ...values,
                  ownerId: owner.id,
                  type: VehicleType.OCCUPANT,
                });
              } catch (err: unknown) {
                if (isAxiosError(err) && err.response?.data) {
                  const data = err.response?.data as string;
                  message.warning(t(data));
                  // if (err.response.status === 400) {
                  // }
                }
                setLoading(false);
                return;
              }
              setLoading(false);
              navigate(backUrl);
            }}
          />
        </Col>
      </Row>
      <Row gutter={20} justify="space-between">
        <Col>
          <Space>
            <Link to={backUrl}>
              <Button>{t('general.cancel')}</Button>
            </Link>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
              disabled={!canEdit}
            >
              {t('general.submit')}
            </Button>
          </Space>
        </Col>
      </Row>
    </ContentDiv>
  );
};

export const OccupantVehicleCreate: FC = (props) => {
  const api = useApi();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm<VehicleFormValues>();
  const [loading, setLoading] = useState(false);

  const {owner} = useOwnerMe();
  if (!owner) {
    return <Spin size="large" />;
  }

  return (
    <ContentDiv>
      <AppTitle level={3} style={{marginTop: 0}}>
        {t('vehicles.newOccupant')}
      </AppTitle>
      <Row gutter={20} justify="space-between">
        <Col span={12}>
          <OwnerVehicleForm
            ownerId={owner.id}
            type={VehicleType.OCCUPANT}
            creating={true}
            form={form}
            initialValues={{
              active: true,
            }}
            onFinish={async (values) => {
              if (loading) {
                return;
              }
              setLoading(true);

              try {
                await createVehicle(api, {
                  ...values,
                  ownerId: owner.id,
                  type: VehicleType.OCCUPANT,
                });
              } catch (err: unknown) {
                if (isAxiosError(err) && err.response?.data) {
                  const data = err.response?.data as string;
                  message.warning(t(data));
                  // if (err.response.status === 400) {
                  // }
                }
                setLoading(false);
                return;
              }
              setLoading(false);
              navigate(backUrl);
            }}
          />
        </Col>
      </Row>
      <Row gutter={20} justify="space-between">
        <Col>
          <Space>
            <Link to={backUrl}>
              <Button>{t('general.cancel')}</Button>
            </Link>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              {t('general.submit')}
            </Button>
          </Space>
        </Col>
      </Row>
    </ContentDiv>
  );
};
