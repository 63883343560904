import {Row, Col, Result, Button} from 'antd';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from '../../translation';
import {AppContent} from './AppContent';
import {AppFooter} from './AppFooter';
import {AppHeader} from './AppHeader';
import {AppLayout} from './AppLayout';

export const AppNotFoundPage: FC = (props) => {
  const {t} = useTranslation();
  return (
    <AppLayout>
      <AppHeader big={false} />
      <AppContent>
        <Row justify="center">
          <Col span={24} style={{textAlign: 'center'}}>
            <Result
              status="404"
              title="404"
              subTitle={t('general.notFoundErrorMessage')}
              extra={
                <Link to="/">
                  <Button type="primary">{t('general.goBackHome')}</Button>
                </Link>
              }
            />
          </Col>
        </Row>
      </AppContent>
      <AppFooter />
    </AppLayout>
  );
};
