import {FC} from 'react';
import {COLORS} from '../../design';
import {useTranslation} from '../../translation';
import {UserRole, useUserRole} from '../../util/Auth';
import {getBlogBaseUrl} from '../../util/links';
import logoFooter from './logo-footer.svg';

/* const AppFooterTitle: FC<{children?: React.ReactNode}> = (props) => {
  return (
    <AppTitle level={4} style={{color: 'white', marginTop: 0}}>
      {props.children}
    </AppTitle>
  );
}; */

/* const AppFooterLink: FC<{to: string; children?: React.ReactNode}> = (props) => {
  return (
    <div style={{marginBottom: '0.25rem'}}>
      <Link to={props.to} style={{color: COLORS.LINK_COLOR}}>
        {props.children}
      </Link>
    </div>
  );
}; */

/** for external links (ie the blog) */
/* const AppFooterExternalLink: FC<{to: string; children?: React.ReactNode}> = (
  props,
) => {
  return (
    <div style={{marginBottom: '0.25rem'}}>
      <a href={props.to} style={{color: COLORS.LINK_COLOR}}>
        {props.children}
      </a>
    </div>
  );
}; */

export const AppFooter: FC = () => {
  const role = useUserRole();

  // we want to hide links for admins + owners
  const displayLinks =
    !role || ![UserRole.ADMIN, UserRole.OWNER].includes(role);

  const {t} = useTranslation();
  return (
    <div
      style={{
        backgroundColor: COLORS.LOGO_BG,
        color: COLORS.PRIVATE_COLOR,
        marginTop: 50 + 24,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          decoding="async"
          width="85"
          height="107"
          src={logoFooter}
          alt="Logo"
          style={{marginTop: -50}}
        />
      </div>

      {displayLinks ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: COLORS.LOGO_BG_DARKER,
              color: COLORS.LINK_COLOR,
              padding: '12px 0 12px 0',
              columnGap: 34,
              flexWrap: 'wrap',
            }}
          >
            <a
              style={{color: COLORS.LINK_COLOR}}
              href={
                getBlogBaseUrl() + '/documents-legaux/politique-de-cookies/'
              }
            >
              {t('footer.cookiePolicy')}
            </a>
            <a
              style={{color: COLORS.LINK_COLOR}}
              href={
                getBlogBaseUrl() +
                '/documents-legaux/politique-de-confidentialite/'
              }
            >
              {t('footer.privacyPolicy')}
            </a>
            <a
              style={{color: COLORS.LINK_COLOR}}
              href={getBlogBaseUrl() + '/documents-legaux/mentions-legales/'}
            >
              {t('footer.legalNotice')}
            </a>
            <a
              style={{color: COLORS.LINK_COLOR}}
              href={
                getBlogBaseUrl() +
                '/documents-legaux/conditions-generales-de-vente/'
              }
            >
              {t('footer.termsOfSales')}
            </a>
          </div>
        </>
      ) : (
        <div style={{marginBottom: 24}} />
      )}
    </div>
  );
};
