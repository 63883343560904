import {CheckOutlined, WarningOutlined} from '@ant-design/icons';
import {useRequest} from 'ahooks';
import {Alert, Button, Form, Input, Modal, Select, Space, Tooltip} from 'antd';
import humanizeDuration from 'humanize-duration';
import {DateTime} from 'luxon';
import {FC, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {LANGUAGE, TranslateFn, useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {ApartmentCodeSelect} from '../input/ApartmentCodeSelect';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {ApartmentRecord} from './Apartment';
import {HostRecord} from './Host';
import {OwnerRecord} from './Owner';
import {
  TableColumnsProp,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';

const singularRoute = '/apartmentRequest';
const pluralRoute = '/apartmentRequests';

export interface ApartmentRequestFormValues {
  ownerId: string;
  hostId?: string;
  apartmentId: string;

  ['owner.user.email']: string;
  ['owner.user.firstName']: string;
  ['owner.user.lastName']: string;
  ['owner.user.title']: string;

  ['apartment.code']: string;
  ['apartment.condo']: string;
  ['apartment.building']: string;
  ['apartment.doorNumber']: string;
  ['apartment.beddings']: string;
  ['apartment.host']: string;
  ['apartment.ownerId']: string;
}

export interface ApartmentRequestRecord extends ApartmentRequestFormValues {
  id: string;
  owner: OwnerRecord;
  apartment: ApartmentRecord;
  host?: HostRecord;
  createDate: number;
  updateDate: number;
}

const apartmentRequestInitialValues: Partial<ApartmentRequestFormValues> = {};

export const ApartmentRequestForm: FC<
  TableFormProps<ApartmentRequestFormValues>
> = ({form, onFinish, initialValues = {}}) => {
  const {t} = useTranslation();
  const api = useApi();
  const [apartment, setApartment] = useState<ApartmentRecord>();

  useMemo(() => {
    form.setFieldsValue({
      ...apartmentRequestInitialValues,
      ...initialValues,
    });
  }, [form, initialValues]);

  const fetchHosts = async (): Promise<HostRecord[] | undefined> => {
    if (!api) return;
    return (await api.get(DEFAULT_API_NAME, '/hosts', {}))?.entities ?? [];
  };
  const {data: hostsData, loading: hostsLoading} = useRequest(fetchHosts, {
    refreshDeps: [api],
  });

  return (
    <Form<ApartmentRequestFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...apartmentRequestInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Form.Item name="hostId" label={t('apartments.host')}>
        <Select
          options={hostsData?.map(({id, name}) => ({
            value: id,
            label: name,
          }))}
          loading={hostsLoading}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="apartmentId"
        label={t('apartments.code')}
        rules={[{required: true}]}
      >
        <ApartmentCodeSelect
          onApartmentChange={(apartment) => {
            setApartment(apartment);

            if (!apartment) {
              form.resetFields([
                'apartment.condo',
                'apartment.building',
                'apartment.doorNumber',
                'apartment.beddings',
                'apartment.host',
              ]);
              return;
            }

            form.setFieldsValue({
              'apartment.condo': apartment.condo,
              'apartment.building': apartment.building,
              'apartment.doorNumber': apartment.doorNumber,
              'apartment.beddings': apartment.beddings.toString(),
              'apartment.host': apartment.host?.id,
            });
          }}
        />
      </Form.Item>
      {apartment?.ownerId ? (
        <Alert
          type="error"
          description={
            <span>
              {t('apartments.error.alreadyOwned')}
              <br />
              <Link to={`/admin/owner/${apartment.ownerId}`}>
                {apartment.owner?.user.lastName}{' '}
                {apartment.owner?.user.firstName}
              </Link>
            </span>
          }
          style={{marginBottom: 10}}
        />
      ) : null}

      <Form.Item name="apartment.condo" label={t('apartments.condo')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="apartment.building" label={t('apartments.building')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="apartment.doorNumber" label={t('apartments.doorNumber')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="apartment.beddings" label={t('apartments.beddings')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="apartment.host" label={t('apartments.host')} hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item name="ownerId" label="ownerId" hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

export const getApartmentRequestColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<ApartmentRequestRecord> => [
  {
    title: t('apartmentRequests.code'),
    key: 'apartment.code',
    dataIndex: 'apartment.code',
  },
  {
    title: t('apartments.host'),
    key: 'hostId',
    dataIndex: 'hostId',
    render: (hostId, {host}) => host?.name ?? hostId,
  },
  {
    title: t('apartmentRequests.createDate'),
    key: 'createDate',
    dataIndex: 'createDate',
    render: (createDate) => (
      <span className="hint" title={DateTime.fromMillis(createDate).toISO()}>
        {t('general.timeIntervalAgo').replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${interval}',
          humanizeDuration(Date.now() - createDate, {
            language: lang,
            round: true,
          }).split(',')[0],
        )}
      </span>
    ),
  },
];

export const getApartmentRequestAdminColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<ApartmentRequestRecord> => [
  {
    title: t('apartmentRequests.fullName'),
    key: 'user.lastName',
    dataIndex: 'user.lastName',
    render: (_, owner) =>
      [owner['owner.user.lastName'], owner['owner.user.firstName']]
        .filter((val) => !!val)
        .join(' '),
  },
  {
    title: t('apartmentRequests.email'),
    key: 'owner.user.email',
    dataIndex: 'owner.user.email',
  },
  {
    title: t('apartmentRequests.code'),
    key: 'apartment.code',
    dataIndex: 'apartment.code',
    render: (code, {apartment}) => (
      <Space>
        {code}
        {!!apartment?.ownerId ? (
          <Tooltip title={t('apartments.error.alreadyOwned')}>
            <WarningOutlined color="#faad14" />
          </Tooltip>
        ) : null}
      </Space>
    ),
  },
  {
    title: t('apartments.host'),
    key: 'hostId',
    dataIndex: 'hostId',
    render: (hostId, {host}) => host?.name ?? hostId,
  },
  {
    title: t('apartmentRequests.createDate'),
    key: 'createDate',
    dataIndex: 'createDate',
    render: (createDate) => (
      <span className="hint" title={DateTime.fromMillis(createDate).toISO()}>
        {t('general.timeIntervalAgo').replace(
          // eslint-disable-next-line no-template-curly-in-string
          '${interval}',
          humanizeDuration(Date.now() - createDate, {
            language: lang,
            round: true,
          }).split(',')[0],
        )}
      </span>
    ),
  },
];

export const ApartmentRequests: FC = () => {
  const api = useApi();

  const [editEntity, setEditEntity] = useState<
    ApartmentRequestRecord | undefined
  >();
  const [refreshDate, setRefreshDate] = useState<number>();
  const {t, lang} = useTranslation();

  const [total, setTotal] = useState<number>();
  const [validateEntity, setValidateEntity] = useState<
    ApartmentRequestRecord | undefined
  >();
  const [validateLoading, setValidateLoading] = useState(false);
  const [validateError, setValidateError] = useState('');

  const canValidateEntity = !validateEntity?.apartment?.ownerId;

  const columns = useStateFn(
    () => getApartmentRequestAdminColumns(t, lang),
    [t, lang],
  );

  const handleValidate = async () => {
    if (!api || !validateEntity) return;
    setValidateError('');
    try {
      setValidateLoading(true);

      await api.post(
        DEFAULT_API_NAME,
        `/apartmentRequest/validateRequest/${validateEntity.id}`,
        {},
      );

      await api.del(
        DEFAULT_API_NAME,
        `/apartmentRequest/${validateEntity.id}`,
        {},
      );
    } catch (err) {
      setValidateError('Validation error');
    } finally {
      setValidateLoading(false);
    }
    setValidateEntity(undefined);
    setRefreshDate(Date.now());
  };

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: ApartmentRequestForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <ContentDiv
      title={
        total
          ? `${t('apartmentRequests.title')}: ${total}`
          : t('apartmentRequests.title')
      }
    >
      <TableList<ApartmentRequestRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        customActions={[
          (entity) => (
            <Tooltip
              title={!!entity.apartment?.ownerId ? 'Already owned' : ''}
              key="validate"
            >
              <Button
                icon={<CheckOutlined />}
                type="default"
                disabled={!entity.apartment || !!entity.apartment.ownerId}
                onClick={() => setValidateEntity(entity)}
              />
            </Tooltip>
          ),
        ]}
        onTotalChange={setTotal}
      />
      <TableUpdateModal<ApartmentRequestRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        width={700}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={(form: ApartmentRequestFormValues) => ({
          ownerId: form.ownerId,
          hostId: form.hostId,
          apartmentId: form.apartmentId,
        })}
      />
      <Modal
        centered
        open={!!validateEntity}
        onCancel={() => setValidateEntity(undefined)}
        okText={t('ownerRequest.validate')}
        okButtonProps={{disabled: !canValidateEntity}}
        confirmLoading={validateLoading}
        onOk={handleValidate}
      >
        {canValidateEntity ? (
          <Alert
            type="warning"
            description={t('apartmentRequests.validateWarning')}
          />
        ) : (
          <Alert
            type="error"
            description={t('apartmentRequests.validateError')}
          />
        )}
        {validateError ? (
          <Alert type="error" description={validateError} />
        ) : null}
      </Modal>
    </ContentDiv>
  );
};
