import {useRequest} from 'ahooks';
import {
  Col,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  TabsProps,
} from 'antd';
import {DateTime} from 'luxon';
import React, {FC} from 'react';
import {getDecimalSeparator, useTranslation} from '../translation';
import {DEFAULT_API_NAME, useApi} from '../util/Auth';
import {FormResetButton} from './FormCancelButton';
import {FormSubmitButton} from './FormSubmitButton';
import {ContentDiv} from './layout/ContentDiv';
import {Article} from './table/Article';
import {Host} from './table/Host';
import {Seasons} from './table/Season';
import {Users} from './table/User';

/* export const START_DATE_MUST_BE_LOWER_THAN_END_DATE =
  (t: TranslateFn): Rule =>
  ({getFieldValue}) => ({
    validateTrigger: 'onChange',
    message: t('settings.error.seasonStartMustBeBeforeSeasonEnd'),
    validator: async (rule, value) => {
      const seasonStart = getFieldValue('seasonStart');
      const seasonEnd = getFieldValue('seasonEnd');

      if (seasonStart && seasonEnd) {
        const seasonStartDate = dayjs(seasonStart);
        const seasonEndDate = dayjs(seasonEnd);

        if (seasonStartDate.isAfter(seasonEndDate)) {
          throw new Error();
        }
      }
    },
  }); */

const serializeNextInvoiceNumber = (
  nextInvoiceNumber: {
    [year: number]: number;
  } = {},
): string =>
  Object.entries(nextInvoiceNumber)
    .map(([year, val]) => `${year}=${val}`)
    .join(',');

const deserializeNextInvoiceNumber = (
  nextInvoiceNumber = '',
): {
  [year: number]: number;
} =>
  Object.fromEntries(
    nextInvoiceNumber
      .split(',')
      .filter((val) => !!val)
      .map((val) => val.split('=').map(Number)),
  );

export interface OwnerSettings {
  poolIban: string;
  poolBic: string;
  parkingIban: string;
  parkingBic: string;
  checkOrder: string;
  checkAddress: string;
}

export interface AdminSettings extends OwnerSettings {
  nextOrderNumber: number;
  yearlyNextInvoiceNumber: {[year: number]: number};
  nextThirdPartyId: string;
  vatRate: string;
  bankAccountClient: string;
  bankAccountSales: string;
  bankAccountVat: string;
  bankAccountBank: string;
  journalSales: string;
  journalBank: string;
}

export interface AdminFormSettings extends AdminSettings {
  nextInvoiceNumberYear?: string;
  nextInvoiceNumberValue?: string;
  nextInvoiceNumbers?: string;
}

const nextInvoiceNumberYears: number[] = [];

{
  const invoiceNumberYearStart = 2023;
  const invoiceNumberYearEnd = Number(DateTime.utc().toFormat('yyyy')) + 1;
  let iterator = invoiceNumberYearStart;
  do {
    nextInvoiceNumberYears.push(iterator++);
  } while (iterator <= invoiceNumberYearEnd);
}

const SettingsForm: FC<FormProps> = (props) => {
  const {t, lang} = useTranslation();
  const [form] = Form.useForm<AdminFormSettings>();

  const nextInvoiceNumberYearValue = Form.useWatch(
    'nextInvoiceNumberYear',
    form,
  );

  const handleNextInvoiceNumberYearChange = () => {
    const nextInvoiceNumberYearValue = form.getFieldValue(
      'nextInvoiceNumberYear',
    );

    if (!nextInvoiceNumberYearValue) {
      form.resetFields(['nextInvoiceNumberValue']);
      return;
    }

    const nextInvoiceNumbers = deserializeNextInvoiceNumber(
      form.getFieldValue('nextInvoiceNumbers'),
    );

    form.setFieldValue(
      'nextInvoiceNumberValue',
      nextInvoiceNumbers[nextInvoiceNumberYearValue] ?? 1,
    );
  };

  const handleNextInvoiceNumberChange = () => {
    if (!nextInvoiceNumberYearValue) return;

    const nextInvoiceNumberValue = form.getFieldValue('nextInvoiceNumberValue');
    console.log({nextInvoiceNumberValue, nextInvoiceNumberYearValue});

    const nextInvoiceNumbers = deserializeNextInvoiceNumber(
      form.getFieldValue('nextInvoiceNumbers'),
    );

    form.setFieldValue(
      'nextInvoiceNumbers',
      serializeNextInvoiceNumber({
        ...nextInvoiceNumbers,
        [nextInvoiceNumberYearValue]: nextInvoiceNumberValue ?? 1,
      }),
    );
  };

  return (
    <Form<AdminFormSettings>
      form={form}
      labelCol={{span: 10}}
      wrapperCol={{span: 24 - 10}}
      labelWrap
      {...props}
    >
      <Form.Item
        name="nextOrderNumber"
        label={t('settings.nextOrderId')}
        rules={[{required: true}]}
      >
        <InputNumber />
      </Form.Item>
      <Row>
        <Col span={14}>
          <Form.Item
            name="nextInvoiceNumberYear"
            label={t('settings.nextInvoiceId')}
            rules={[{required: true}]}
            labelCol={{span: 17}}
          >
            <Select
              options={nextInvoiceNumberYears.map((value) => ({
                value,
                label: value,
              }))}
              onChange={handleNextInvoiceNumberYearChange}
              style={{width: 90}}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="nextInvoiceNumberValue"
            noStyle
            rules={[{required: true}]}
          >
            <InputNumber
              disabled={!nextInvoiceNumberYearValue}
              onChange={handleNextInvoiceNumberChange}
              defaultValue={1}
              placeholder="1"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="nextInvoiceNumbers"
        label={'!DEBUG! serialized nextInvoiceNumbers !DEBUG!'}
        hidden
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="nextThirdPartyId"
        label={t('settings.nextThirdPartyId')}
        rules={[{required: true}]}
        style={{marginBottom: 24 + 8}}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="vatRate"
        label={t('settings.vatRate')}
        rules={[{required: true}]}
        style={{marginBottom: 24 + 8}}
      >
        <InputNumber
          min="0"
          precision={2}
          stringMode
          controls={false}
          decimalSeparator={getDecimalSeparator(lang)}
        />
      </Form.Item>
      <Form.Item
        name="bankAccountClient"
        label={t('settings.bankAccountClient')}
        rules={[{required: true}]}
      >
        <Input style={{width: 90}} />
      </Form.Item>
      <Form.Item
        name="bankAccountSales"
        label={t('settings.bankAccountSales')}
        rules={[{required: true}]}
      >
        <Input style={{width: 90}} />
      </Form.Item>
      <Form.Item
        name="bankAccountVat"
        label={t('settings.bankAccountVat')}
        rules={[{required: true}]}
      >
        <Input style={{width: 90}} />
      </Form.Item>
      <Form.Item
        name="bankAccountBank"
        label={t('settings.bankAccountBank')}
        rules={[{required: true}]}
      >
        <Input style={{width: 90}} />
      </Form.Item>
      <Form.Item
        name="journalSales"
        label={t('settings.journalSales')}
        rules={[{required: true}]}
      >
        <Input style={{width: 90}} />
      </Form.Item>
      <Form.Item
        name="journalBank"
        label={t('settings.journalBank')}
        rules={[{required: true}]}
      >
        <Input style={{width: 90}} />
      </Form.Item>
      <Form.Item
        name="poolIban"
        label={t('settings.poolIban')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="poolBic"
        label={t('settings.poolBic')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="parkingIban"
        label={t('settings.parkingIban')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="parkingBic"
        label={t('settings.parkingBic')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="checkOrder"
        label={t('settings.checkOrder')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="checkAddress"
        label={t('settings.checkAddress')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>

      <Row justify="end">
        <Space>
          <FormResetButton />
          <FormSubmitButton />
        </Space>
      </Row>
    </Form>
  );
};

const Settings: FC<{style?: React.CSSProperties}> = (props) => {
  const {t} = useTranslation();
  const api = useApi();

  const fetchSettings = async (): Promise<AdminSettings | undefined> => {
    if (!api) return;
    const data = await api.get(DEFAULT_API_NAME, '/settings', {});
    return data;
  };

  const {data: settings, loading} = useRequest(fetchSettings, {
    refreshDeps: [api],
  });

  const postSettings = async (values: AdminFormSettings) => {
    if (!api) return;
    values.yearlyNextInvoiceNumber = deserializeNextInvoiceNumber(
      values.nextInvoiceNumbers,
    );
    delete values.nextInvoiceNumbers;
    delete values.nextInvoiceNumberYear;
    delete values.nextInvoiceNumberValue;

    const data = await api.post(DEFAULT_API_NAME, '/settings', {body: values});
    return data;
  };

  const nextInvoiceNumberYear = Number(DateTime.utc().toFormat('yyyy'));

  return (
    <ContentDiv title={t('settings.title')} style={props.style}>
      {loading ? (
        <Spin />
      ) : (
        <SettingsForm
          initialValues={{
            ...(settings ?? {}),
            nextInvoiceNumberYear,
            nextInvoiceNumberValue:
              settings?.yearlyNextInvoiceNumber?.[nextInvoiceNumberYear] ?? 1,
            nextInvoiceNumbers: serializeNextInvoiceNumber(
              settings?.yearlyNextInvoiceNumber,
            ),
          }}
          onFinish={async (values) => {
            await postSettings(values);
          }}
        />
      )}
    </ContentDiv>
  );
};

export const SettingsPage: FC = () => {
  const {t} = useTranslation();

  const items: TabsProps['items'] = [
    {
      key: 'settings',
      label: t('settings.settingsTab'),
      children: (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 24,
          }}
        >
          <Settings style={{flex: '1 1 auto'}} />
          <Host style={{flex: '1 1 auto'}} />
        </div>
      ),
    },

    {
      key: 'articles-seasons',
      label: t('settings.articlesTab'),
      children: (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 24,
          }}
        >
          <Article style={{flex: '1 1 auto'}} />
          <Seasons style={{flex: '1 1 auto'}} />
        </div>
      ),
    },

    {
      key: 'admin-users',
      label: t('settings.adminUsersTab'),
      children: <Users />,
    },
  ];

  return <Tabs defaultActiveKey="settings" items={items} />;
};
