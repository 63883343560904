import {FC} from 'react';
import {Button, ButtonProps} from 'antd';
import {useTranslation} from '../translation';

export const FormSubmitButton: FC<ButtonProps> = (props) => {
  const {t} = useTranslation();
  return (
    <Button
      htmlType="submit"
      type="primary"
      children={t('general.submit')}
      {...props}
    />
  );
};
