import {Alert, Button, Col, Form, Input, Modal, Row, Select} from 'antd';
import {FormProps} from 'antd/es/form';
import {
  generate as generatePassword,
  GenerateOptions as GeneratePasswordOptions,
} from 'generate-password-browser';
import parsePhoneNumber from 'libphonenumber-js';
import {FC, useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from '../translation';
import {AppTitle} from '../util/AppTitle';
import {
  DEFAULT_ANONYMOUS_API_NAME,
  DEFAULT_API_NAME,
  useAnonymousApi,
  useApi,
  useConfirmSignUp,
  useSignIn,
  useSignOutAndDeleteCookies,
  useSignUp,
} from '../util/Auth';
import {getBlogBaseUrl} from '../util/links';
import {CachedApartmentCodeFormItem} from './AssignApartmentForm';
import {CountrySelect} from './input/CountrySelect';
import {getValidPhoneRule, PhoneInput} from './input/PhoneInput';
import {isAxiosError, isCodedError} from './table/Table';

export const DEFAULT_PASSWORD_OPTIONS: GeneratePasswordOptions = {
  length: 12,
  numbers: true,
  lowercase: true,
  uppercase: true,
  symbols: false,
  strict: true,
};

interface RegisterFormValues {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  phoneNumber: string;
  apartmentCode: string;
  password: string;

  condo: string;
  building: string;
}

interface ConfirmFormValues {
  email: string;
  code: string;
}

const {Option} = Select;

export const Register: FC<{
  setIsRegistrating?: (isRegistrating: boolean) => void;
}> = (props) => {
  const {setIsRegistrating} = props;
  const api = useApi();
  const anonymousApi = useAnonymousApi();
  const signUp = useSignUp();
  const confirmSignUp = useConfirmSignUp();
  const signIn = useSignIn();
  const navigate = useNavigate();

  const [signUpForm] = Form.useForm<RegisterFormValues>();
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [signUpError, setSignUpError] = useState('');

  const [confirmForm] = Form.useForm<ConfirmFormValues>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmError, setConfirmError] = useState('');

  const [sendRegister, setSendRegister] = useState(false);

  const {t, lang} = useTranslation();

  const signOutAndDeleteCookies = useSignOutAndDeleteCookies();

  const handleSignUp = async (): Promise<void> => {
    if (!anonymousApi) return;
    await signUpForm.validateFields();
    setSignUpError('');

    const values = signUpForm.getFieldsValue();

    const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
    if (!parsedPhoneNumber) return;

    await signOutAndDeleteCookies();

    setSignUpLoading(true);
    setIsRegistrating?.(true);

    const {email, lastName, password, firstName} = values;
    try {
      await signUp({
        email,
        password,
        name: `${lastName} ${firstName}`,
        phoneNumber: parsedPhoneNumber
          .formatInternational({})
          .replace(/\s+/g, ''),
      });
      confirmForm.setFieldsValue({email});
    } catch (err) {
      if (isCodedError(err) && err.code === 'UsernameExistsException') {
        try {
          const delRes = await anonymousApi.del(
            DEFAULT_ANONYMOUS_API_NAME,
            `/user/deleteUnassignedCognitoUser/${email}`,
            {},
          );
          console.log(delRes);
          return handleSignUp();
        } catch (err) {
          console.warn('delete failed');
          console.error(err);
        }
      }

      console.log(err, JSON.stringify(err));
      let message = t('register.signUpError');
      if (isCodedError(err)) {
        message += `:\n${t(`cognito.error.${err.code}`)}`;
      }
      setSignUpError(message);
      setIsRegistrating?.(false);
      return;
    } finally {
      setSignUpLoading(false);
    }

    setShowConfirmModal(true);
  };

  const handleConfirm: FormProps<ConfirmFormValues>['onFinish'] = async (
    values,
  ) => {
    await confirmForm.validateFields();
    setConfirmLoading(true);
    setConfirmError('');

    const {email, code} = values;

    try {
      await confirmSignUp({
        email,
        code,
      });
    } catch (err) {
      console.log(err, JSON.stringify(err));
      let message = t('register.signUpError');
      if (isCodedError(err)) {
        message += `:\n${t(`cognito.error.${err.code}`)}`;
      }
      setConfirmError(message);
      setIsRegistrating?.(false);
      return;
    } finally {
      setConfirmLoading(false);
    }

    setShowConfirmModal(false);

    const {password} = signUpForm.getFieldsValue();
    await signIn({username: email, password});
    setSendRegister(true);
  };

  const send = useCallback(async () => {
    await signUpForm.validateFields();

    setSignUpError('');
    if (!api) return;

    const {
      email,
      firstName,
      lastName,
      title,

      address,
      apartmentCode,
      zipCode,
      country,
      phoneNumber,
      city,
    } = signUpForm.getFieldsValue();

    setSignUpLoading(true);
    try {
      const user = await api.put(DEFAULT_API_NAME, '/user', {
        body: {
          email,
          firstName,
          lastName,
          title,
          lang,
        },
      });

      const apartment = await api.get(
        DEFAULT_API_NAME,
        `/apartment/code/${apartmentCode}`,
        {},
      );

      if (!apartment) throw new Error('Apartment not found');

      await api.put(DEFAULT_API_NAME, '/ownerRequest', {
        body: {
          userId: user.id,
          apartmentId: apartment.id,
          name: `${lastName} ${firstName}`,
          zipCode,
          address,
          city,
          country,
          phoneNumber: phoneNumber.replace(/\s+/g, ''),
        },
      });

      navigate('/');
    } catch (err: unknown) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setSignUpError(message);

      return;
    } finally {
      setSignUpLoading(false);
      setIsRegistrating?.(false);
    }
  }, [api, lang, navigate, setIsRegistrating, signUpForm, t]);

  useMemo(() => {
    if (!api || !sendRegister) return;
    setSendRegister(false);

    send();
  }, [api, sendRegister, send]);

  return (
    <div style={{width: 500}}>
      <AppTitle level={2} style={{marginTop: 0}}>
        {t('general.registerAction')}
      </AppTitle>
      <Form<RegisterFormValues>
        form={signUpForm}
        name="register"
        className="register-form"
        onFinish={handleSignUp}
        initialValues={{
          country: 'FR',
          phoneNumber: '+33',
          password: generatePassword(DEFAULT_PASSWORD_OPTIONS),
        }}
        labelCol={{span: 10}}
        wrapperCol={{span: 24 - 10}}
        labelWrap
      >
        <Form.Item
          name="lastName"
          label={t('register.lastName')}
          rules={[{required: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="firstName"
          label={t('register.firstName')}
          rules={[{required: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="title" label={t('register.title_gender')}>
          <Select allowClear>
            <Option value="M">{t('users.userTitleMr')}</Option>
            <Option value="Mme">{t('users.userTitleMrs')}</Option>
            <Option value="M et Mme">{t('users.userTitleMrAndMrs')}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="address"
          label={t('register.address')}
          rules={[{required: true}]}
        >
          <Input.TextArea autoSize={{minRows: 2, maxRows: 4}} />
        </Form.Item>

        <Form.Item
          label={t('register.zipCode') + ' - ' + t('register.city')}
          style={{marginBottom: 0}}
          required
        >
          <Form.Item
            name="zipCode"
            rules={[{required: true}]}
            style={{display: 'inline-block', width: '80px'}}
          >
            <Input placeholder={t('register.zipCode')} />
          </Form.Item>
          <Form.Item
            name="city"
            rules={[{required: true}]}
            style={{
              display: 'inline-block',
              width: 'calc(100% - 8px - 80px)',
              margin: '0 0 0 8px',
            }}
          >
            <Input placeholder={t('register.city')} />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="country"
          label={t('register.country')}
          rules={[{required: true}]}
        >
          <CountrySelect />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label={t('register.phoneNumber')}
          rules={[
            {required: true},
            getValidPhoneRule({
              t,
            }),
          ]}
        >
          <PhoneInput
            lang={lang}
            onBlur={() => {
              const phoneNumber = signUpForm.getFieldValue('phoneNumber');
              const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
              if (parsedPhoneNumber?.isValid()) {
                signUpForm.setFieldValue(
                  'phoneNumber',
                  parsedPhoneNumber.formatInternational(),
                );
              } else if (phoneNumber?.includes(' ')) {
                signUpForm.setFieldValue(
                  'phoneNumber',
                  phoneNumber.replace(/\s+/g, ''),
                );
              }
            }}
          />
        </Form.Item>

        <CachedApartmentCodeFormItem form={signUpForm} />

        <div
          style={{
            color: '#ff4d4f',
            textAlign: 'right',
            marginTop: -8,
            marginBottom: 8,
          }}
        >
          {t('register.condoBuildingApartmentMessage')}
        </div>

        <Form.Item
          name="email"
          label={t('register.email')}
          rules={[{required: true}]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item name="password" hidden>
          <Input />
        </Form.Item>

        {signUpError ? (
          <Alert
            message={signUpError}
            type="error"
            style={{whiteSpace: 'pre-line'}}
          />
        ) : null}

        <Row justify="space-around" gutter={10}>
          <Col>
            <p
              style={{
                color: '#ff4d4f',
                margin: 5,
              }}
            >
              {t('general.starMarksMandatory')}
            </p>
          </Col>
          <Col>
            <Row gutter={10}>
              <Col>
                <a href={getBlogBaseUrl()}>
                  <Button>{t('general.cancel')}</Button>
                </a>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="register-form-button"
                  loading={signUpLoading}
                >
                  {t('register.submit')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Modal
        centered
        forceRender
        open={showConfirmModal}
        onOk={() => handleConfirm(confirmForm.getFieldsValue())}
        okButtonProps={{loading: confirmLoading}}
        closable={false}
        cancelButtonProps={{style: {display: 'none'}}}
        title={t('register.verificationCode')}
      >
        <p>{t('register.pleaseEnterVerificationCode')}</p>
        <Form<ConfirmFormValues>
          form={confirmForm}
          name="confirm"
          className="confirm-form"
          onFinish={handleConfirm}
          labelCol={{span: 5}}
          wrapperCol={{span: 24 - 5}}
        >
          <Form.Item
            name="email"
            label={t('register.email')}
            rules={[{required: true}]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="code"
            label={t('register.code')}
            rules={[{required: true}]}
          >
            <Input minLength={6} maxLength={6} />
          </Form.Item>

          <Form.Item hidden={true}>
            <Button htmlType="submit">{t('general.submit')}</Button>
          </Form.Item>

          {confirmError ? (
            <Alert
              message={confirmError}
              type="error"
              style={{whiteSpace: 'pre-line'}}
            />
          ) : null}
        </Form>
      </Modal>
    </div>
  );
};
