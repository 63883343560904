import {FC, ReactElement, useEffect} from 'react';

const baseTitle = process.env.REACT_APP_TITLE ?? 'Cap Esterel';

export const Page: FC<{children: ReactElement; title?: string}> = ({
  title = '',
  children,
}) => {
  useEffect(() => {
    document.title = title ? [baseTitle, title].join(' - ') : baseTitle;
  }, [title]);

  return children ?? <div />;
};
