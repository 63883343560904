import {Select, SelectProps} from 'antd';
import {FC, useMemo, useState} from 'react';
import {useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {OrderRecord} from '../table/Order';

const singularRoute = '/order';
const pluralRoute = '/orders';

export interface OrderSelectProps extends SelectProps<string> {
  onOrderChange?: (order?: OrderRecord) => void;
}

export const OrderSelect: FC<OrderSelectProps> = ({
  onOrderChange,
  ...props
}) => {
  const api = useApi();
  const {t} = useTranslation();

  const [data, setData] = useState<OrderRecord[]>([]);
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  const {value: initialValue} = props;
  const [initialFetchedValue, setInitialFetchedValue] = useState<string>();

  useMemo(() => {
    if (!api) return;
    if (!initialValue || value || loading) return;
    if (initialFetchedValue === initialValue) return;
    setInitialFetchedValue(initialValue);
    setLoading(true);

    (async () => {
      try {
        const order = await api.get(
          DEFAULT_API_NAME,
          `${singularRoute}/${initialValue}`,
          {},
        );

        if (!order) return;

        setData([order]);
        setValue(order.id);
        onOrderChange?.(order);
      } finally {
        setLoading(false);
      }
    })();
  }, [api, initialValue, value, loading, initialFetchedValue, onOrderChange]);

  const fetch = (value: string, callback: Function) => {
    if (!api) return;

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }

    const req = async () => {
      setLoading(true);
      try {
        const res = await api.get(DEFAULT_API_NAME, pluralRoute, {
          queryStringParameters: {
            limit: 10,
            f_orderNumber: value,
            sortKey: 'orderNumber',
            order: 'ASC',
          },
        });

        callback(res.entities);
      } finally {
        setLoading(false);
      }
    };

    setTimeoutId(setTimeout(req, 300));
  };

  const handleSearch = (id: string) => {
    if (id) {
      fetch(id, setData);
    } else {
      setData([]);
    }
  };

  const handleChange: SelectProps<string>['onChange'] = (id, option) => {
    setValue(id);
    const order = data?.find((order) => order.id === id);
    if (order) {
      onOrderChange?.(order);
    }
    props.onChange?.(id, option);
  };

  return (
    <Select<string>
      {...props}
      showSearch
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((order) => ({
        value: order.id,
        label: `${order.orderNumber} (${order.owner.name})`,
      }))}
      value={value}
      loading={loading || props.loading}
    />
  );
};
