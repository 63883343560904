import {PhoneOutlined} from '@ant-design/icons';
import {parsePhoneNumber, PhoneNumber} from 'libphonenumber-js';
import {FC, useEffect, useState} from 'react';
import {NoWrap} from '../NoWrap';

export const PhoneNumberLink: FC<{phoneNumber: string}> = (props) => {
  const {phoneNumber} = props;
  const [parsed, setParsed] = useState<PhoneNumber>();

  useEffect(() => {
    try {
      setParsed(parsePhoneNumber(phoneNumber));
    } catch (err) {
      console.warn(`Cannot parse phone number ${phoneNumber}`);
    }
  }, [phoneNumber]);

  const label = parsed?.formatInternational() ?? phoneNumber;
  return (
    <NoWrap>
      <a href={`tel:${label}`}>
        <PhoneOutlined /> {label}
      </a>
    </NoWrap>
  );
};
