import {Col, Form, Input, Row} from 'antd';
import {FormInstance} from 'antd/lib/form/Form';
import {FC} from 'react';
import {useTranslation} from '../translation';
import {card, cvc, expiration} from 'creditcards';

export interface CreditCardFormValues {
  name: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
}

export interface CreditCardFormProps {
  form: FormInstance<CreditCardFormValues>;
}

export const CreditCardForm: FC<CreditCardFormProps> = ({form}) => {
  const {t} = useTranslation();

  return (
    <Form<CreditCardFormValues> form={form}>
      <Form.Item
        rules={[
          {required: true},
          () => ({
            validator(_, ccNumber) {
              if (!ccNumber) return Promise.reject();

              if (card.isValid(ccNumber.replace(/\s+/g, ''))) {
                return Promise.resolve();
              }

              return Promise.reject(new Error(t('creditCard.error.invalidCC')));
            },
          }),
        ]}
        name="number"
        label={t('creditCard.number')}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{required: true}]}
        name="name"
        label={t('creditCard.name')}
      >
        <Input />
      </Form.Item>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            rules={[{required: true}]}
            name="expiryMonth"
            label={t('creditCard.expiryMonth')}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {required: true},
              ({getFieldValue}) => ({
                validator(_, _expiryYear) {
                  const expiryMonth = getFieldValue('expiryMonth');
                  const expiryYear =
                    _expiryYear && _expiryYear < 100
                      ? Number(_expiryYear) + 2000
                      : _expiryYear;

                  if (!expiryYear || !expiryMonth) {
                    return Promise.reject();
                  }

                  if (!expiration.isPast(expiryMonth, expiryYear)) {
                    return Promise.resolve();
                  }

                  return Promise.reject(t('creditCard.error.expiredCC'));
                },
              }),
            ]}
            name="expiryYear"
            label={t('creditCard.expiryYear')}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        rules={[
          {required: true},
          () => ({
            validator(_, ccCVC) {
              if (!ccCVC) return Promise.reject();

              if (cvc.isValid(ccCVC)) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error(t('creditCard.error.invalidCVC')),
              );
            },
          }),
        ]}
        name="cvv"
        label={t('creditCard.cvv')}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
