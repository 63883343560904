import {
  ExclamationCircleOutlined,
  FileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {Button, Col, Modal, Row, Space} from 'antd';
import {FC, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {useApi} from '../../util/Auth';
import {ContentDiv} from '../layout/ContentDiv';
import {
  getOrderColumns,
  openInvoiceFileInNewTab,
  openProxyFileInNewTab,
  OrderAccountingStatus,
  OrderRecord,
} from '../table/Order';
import {getDefaultSorter, TableList} from '../table/Table';
import {useStateFn} from '../useStateFn';
import {useGetOwnerSettings} from './hooks';

const {confirm} = Modal;

const singularRoute = '/order';
const pluralRoute = '/orders';

const pluralRouteMe = `${pluralRoute}/me`;

export const OwnerOrders: FC = () => {
  const {t, lang} = useTranslation();
  const navigate = useNavigate();
  const api = useApi();
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [proxyLoading, setProxyLoading] = useState(false);
  const [total, setTotal] = useState<number>();

  const settings = useGetOwnerSettings();

  const showOversaturationModal = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{t('general.overstaturationError.message1')}</p>
          <p>{t('general.overstaturationError.message2')}</p>
        </div>
      ),
      okText: t('general.continue'),
      cancelButtonProps: {style: {display: 'none'}},
      onOk() {
        navigate('/owner/order');
      },
    });
  };

  /* const showCheckModal = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>{t('general.sumupCheckError.message1')}</p>
          <p>{t('general.sumupCheckError.message2')}</p>
          <p>{t('general.sumupCheckError.message3')}</p>
          <p>{t('general.sumupCheckError.message4')}</p>
          <p>{t('general.sumupCheckError.message5')}</p>
          <p>{t('general.sumupCheckError.message6')}</p>
        </div>
      ),
      okText: t('general.continue'),
      cancelButtonProps: {style: {display: 'none'}},
      onOk() {
        navigate('/owner/order');
      },
    });
  }; */

  const columns = useStateFn(
    () => [
      ...getOrderColumns(t, lang),
      {
        title: '',
        key: 'actions',
        dataIndex: 'actions',
        render: (_, order) => (
          <Space>
            {order.accountingStatus !== OrderAccountingStatus.SUBMITTED ? (
              <Button
                icon={<FileOutlined />}
                type="default"
                title={t('orders.type.invoice')}
                loading={invoiceLoading}
                onClick={async () => {
                  if (!api) return;
                  setInvoiceLoading(true);
                  try {
                    openInvoiceFileInNewTab(api, order.id);
                  } finally {
                    setInvoiceLoading(false);
                  }
                }}
              />
            ) : null}
            {order.proxy ? (
              <Button
                icon={<UserOutlined />}
                type="default"
                title={t('orders.proxy')}
                loading={proxyLoading}
                onClick={async () => {
                  if (!api || !order.proxy) return;
                  setProxyLoading(true);
                  try {
                    openProxyFileInNewTab(api, order.id);
                  } finally {
                    setProxyLoading(false);
                  }
                }}
              />
            ) : null}
          </Space>
        ),
        className: 'align-title-center',
      },
    ],
    [t, lang, api, invoiceLoading, proxyLoading],
  );
  const defaultSorter = useStateFn(
    () => getDefaultSorter(columns, 'orderNumber'),
    [columns],
  );

  return (
    <ContentDiv>
      <Row justify="space-between" align="middle">
        <Col>
          <AppTitle level={3} style={{marginTop: 0}}>
            {total ? `${t('orders.title')}: ${total}` : t('orders.title')}
          </AppTitle>
        </Col>
        <Col style={{margin: 'auto 0'}}>
          <Space>
            {/* <Link to="/owner/order" style={{display: 'none'}}>
              <Button type="primary">{t('orders.newOrder')}</Button>
            </Link> */}
            <Button
              type="primary"
              onClick={() => navigate('/owner/order')}
              disabled={settings?.disableOrderCreationOnClientPortal === true}
            >
              {t('orders.newOrder')}
            </Button>
            {/* <Button
              type="primary"
              style={{display: 'none'}}
              onClick={showOversaturationModal}
            >
              {t('orders.newOrder')}
            </Button> */}
          </Space>
        </Col>
      </Row>
      <TableList<OrderRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRouteMe}
        columns={columns}
        defaultSorter={defaultSorter}
        hideActionColumn
        onTotalChange={setTotal}
      />
    </ContentDiv>
  );
};
