import {Select, SelectProps} from 'antd';
import {FC, useMemo, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {ApartmentRecord} from '../table/Apartment';

const singularRoute = '/apartment';
const pluralRoute = '/apartments';

export interface ApartmentCodeSelectProps extends SelectProps<string> {
  onApartmentChange?: (apartment?: ApartmentRecord) => void;
  /** Used to filter request */
  ownerId?: string;
}

export const ApartmentCodeSelect: FC<ApartmentCodeSelectProps> = ({
  onApartmentChange,
  ownerId,
  ...props
}) => {
  const api = useApi();
  const [data, setData] = useState<ApartmentRecord[]>([]);
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  const {value: initialValue} = props;
  const [initialFetchedValue, setInitialFetchedValue] = useState<string>();

  useMemo(() => {
    if (!api) return;
    if (!initialValue || value || loading) return;
    if (initialFetchedValue === initialValue) return;
    setInitialFetchedValue(initialValue);
    setLoading(true);

    (async () => {
      try {
        const apartment = await api.get(
          DEFAULT_API_NAME,
          `${singularRoute}/${initialValue}`,
          {},
        );

        if (!apartment) return;

        setData([apartment]);
        setValue(apartment.id);
        onApartmentChange?.(apartment);
      } finally {
        setLoading(false);
      }
    })();
  }, [
    api,
    initialValue,
    value,
    loading,
    initialFetchedValue,
    onApartmentChange,
  ]);

  const fetch = (value: string, callback: Function) => {
    if (!api) return;

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }

    const req = async () => {
      setLoading(true);
      try {
        const res = await api.get(DEFAULT_API_NAME, pluralRoute, {
          queryStringParameters: {
            limit: 10,
            f_code: value,
            sortKey: 'doorNumber',
            order: 'ASC',
            ...(ownerId ? {f_ownerId: ownerId} : {}),
          },
        });

        callback(res.entities);
      } finally {
        setLoading(false);
      }
    };

    setTimeoutId(setTimeout(req, 300));
  };

  const handleSearch = (id: string) => {
    if (id) {
      fetch(id, setData);
    } else {
      setData([]);
    }
  };

  const handleChange: SelectProps<string>['onChange'] = (id, option) => {
    setValue(id);
    const apartment = data?.find((apartment) => apartment.id === id);
    if (apartment) {
      onApartmentChange?.(apartment);
    }
    props.onChange?.(id, option);
  };

  return (
    <Select<string>
      {...props}
      showSearch
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.id,
        label: d.code,
      }))}
      value={value}
      loading={loading || props.loading}
    />
  );
};
