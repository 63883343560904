import {AutoComplete, AutoCompleteProps} from 'antd';
import {FC} from 'react';
import {filterOptionByLabel} from '../../owner/util';

const brands: string[] = [
  'Abarth',
  'Aixam',
  'Alfa Romeo',
  'Alpine',
  'Aston Martin',
  'Audi',
  'Autobianchi',
  'Austin Healey',
  'Bentley',
  'BMW',
  'Buick',
  'Cadillac',
  'Caterham',
  'Chevrolet',
  'Chrysler',
  'Citroën',
  'Cupar',
  'Davia',
  'Daihatsu',
  'Daimler',
  'Datsun',
  'De Tomaso',
  'Didge',
  'DS',
  'Ferrari',
  'Fiat',
  'Ford',
  'Honda',
  'Hummer',
  'Hyundai',
  'Ineos',
  'Iveco',
  'Jagua',
  'Jeep',
  'Kia',
  'KTM',
  'Lada',
  'Lamborghini',
  'Lancia',
  'Land Rover',
  'Lexus',
  'Lynk&Co',
  'Ligier',
  'Lotus',
  'Matra',
  'Mazda',
  'Mclaren',
  'Mega',
  'Mercedes',
  'MG',
  'Microcar',
  'Mini',
  'Mitsubishi',
  'Morgan',
  'Nissan',
  'Opel',
  'Peugeot',
  'Pontiac',
  'Porsche',
  'Renault',
  'Rolls Royce',
  'Rover Mg',
  'Saab',
  'Santana',
  'Seat',
  'Simca',
  'Skoda',
  'Smart',
  'Ssangyong',
  'Subaru',
  'Sunbeam',
  'Suzuki',
  'Tesla',
  'Toyota',
  'Triumph',
  'TVR',
  'Venturi',
  'Vespa',
  'Volkswagen',
  'Volvo',
  'Maserati',
].map((v) => v.toLocaleUpperCase());

const options: AutoCompleteProps['options'] = brands.map((b) => ({
  label: b,
  value: b,
}));

export const VehicleBrandNameAutoComplete: FC<AutoCompleteProps> = (props) => {
  return (
    <AutoComplete
      options={options}
      filterOption={filterOptionByLabel}
      defaultActiveFirstOption
      {...props}
    />
  );
};
