import {Select, SelectProps} from 'antd';
import {FC, useMemo, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {InvoiceRecord} from '../table/Invoices';

const singularRoute = '/invoice';
const pluralRoute = '/invoices';

export interface InvoiceSelectProps extends SelectProps<string> {
  onInvoiceChange?: (invoice?: InvoiceRecord) => void;
}

export const InvoiceSelect: FC<InvoiceSelectProps> = ({
  onInvoiceChange,
  ...props
}) => {
  const api = useApi();
  const [data, setData] = useState<InvoiceRecord[]>([]);
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  const {value: initialValue} = props;
  const [initialFetchedValue, setInitialFetchedValue] = useState<string>();

  useMemo(() => {
    if (!api) return;
    if (!initialValue || value || loading) return;
    if (initialFetchedValue === initialValue) return;
    setInitialFetchedValue(initialValue);
    setLoading(true);

    (async () => {
      try {
        const invoice = await api.get(
          DEFAULT_API_NAME,
          `${singularRoute}/${initialValue}`,
          {},
        );

        if (!invoice) return;

        setData([invoice]);
        setValue(invoice.id);
        onInvoiceChange?.(invoice);
      } finally {
        setLoading(false);
      }
    })();
  }, [api, initialValue, value, loading, initialFetchedValue, onInvoiceChange]);

  const fetch = (value: string, callback: Function) => {
    if (!api) return;

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }

    const req = async () => {
      setLoading(true);
      try {
        const res = await api.get(DEFAULT_API_NAME, pluralRoute, {
          queryStringParameters: {
            limit: 10,
            f_invoiceNumberSearch: value,
            sortKey: 'invoiceNumber',
            order: 'ASC',
          },
        });

        callback(res.entities);
      } finally {
        setLoading(false);
      }
    };

    setTimeoutId(setTimeout(req, 300));
  };

  const handleSearch = (id: string) => {
    if (id) {
      fetch(id, setData);
    } else {
      setData([]);
    }
  };

  const handleChange: SelectProps<string>['onChange'] = (id, option) => {
    setValue(id);
    const invoice = data?.find((invoice) => invoice.id === id);
    if (invoice) {
      onInvoiceChange?.(invoice);
    }
    props.onChange?.(id, option);
  };

  return (
    <Select<string>
      {...props}
      showSearch
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.id,
        label: d.invoiceNumber,
      }))}
      value={value}
      loading={loading || props.loading}
    />
  );
};
