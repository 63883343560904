import {FormProps, Form, Checkbox, Input, DatePicker} from 'antd';
import {FC} from 'react';
import {getValidPhoneRule} from '../../component/input/PhoneInput';
import {VehicleBrandNameAutoComplete} from '../../component/input/VehicleBrandNameAutoComplete';
import {VehicleFormValues, VehicleType} from '../../component/table/Vehicles';
import {useTranslation, getDateFormat} from '../../translation';
import {OwnerApartmentCodeSelect} from './OwnerApartmentCodeSelect';

export const OwnerVehicleForm: FC<
  FormProps<VehicleFormValues> & {
    type: VehicleType;
    ownerId: string;
    creating?: boolean;
  }
> = (props) => {
  const {type, ownerId, creating = false, ...formProps} = props;
  const {t, lang} = useTranslation();

  const [form] = Form.useForm(props.form);
  // const isOwner = type === VehicleType.OWNER;
  const isOccupant = type === VehicleType.OCCUPANT;

  return (
    <Form<VehicleFormValues>
      labelCol={{span: 10}}
      wrapperCol={{span: 24 - 10}}
      labelWrap
      {...formProps}
      form={form}
    >
      <Form.Item
        label={t('vehicles.active')}
        name="active"
        valuePropName="checked"
      >
        <Checkbox disabled={creating} />
      </Form.Item>
      <Form.Item
        label={t('vehicles.apartmentId')}
        name="apartmentId"
        style={isOccupant ? {} : {display: 'none'}}
        rules={[isOccupant ? {required: true} : {}]}
      >
        <OwnerApartmentCodeSelect allowClear />
      </Form.Item>

      <Form.Item label={t('vehicles.brandName')} name="brandName">
        <VehicleBrandNameAutoComplete />
      </Form.Item>
      <Form.Item label={t('vehicles.model')} name="model">
        <Input />
      </Form.Item>
      <Form.Item
        label={t('vehicles.licensePlateNo')}
        name="licensePlateNo"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('vehicles.occupantName')}
        name="occupantName"
        rules={[isOccupant ? {required: true} : {}]}
        style={isOccupant ? {} : {display: 'none'}}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('vehicles.phoneNumber')}
        name="phoneNumber"
        rules={[
          getValidPhoneRule({
            t,
            allowEmpty: true,
          }),
        ]}
        style={isOccupant ? {} : {display: 'none'}}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('vehicles.dates')}
        name="dates"
        rules={[isOccupant ? {required: true} : {}]}
        style={isOccupant ? {} : {display: 'none'}}
      >
        <DatePicker.RangePicker format={getDateFormat(lang)} />
      </Form.Item>
      <Form.Item
        label={t('vehicles.marked')}
        name="marked"
        valuePropName="checked"
        hidden
      >
        <Checkbox />
      </Form.Item>
    </Form>
  );
};
