import {useRequest} from 'ahooks';
import {APIClass} from 'aws-amplify';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {OwnerSettings} from '../Settings';

const fetchOwnerSettings = async (
  api: APIClass | null,
): Promise<OwnerSettings | undefined> => {
  if (!api) return;
  return api.get(DEFAULT_API_NAME, '/settings', {});
};

export const useGetOwnerSettings = () => {
  const api = useApi();

  const {data: settings} = useRequest(() => fetchOwnerSettings(api), {
    refreshDeps: [api],
  });

  return settings;
};
