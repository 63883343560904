import {Badge, Col, Row} from 'antd';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {TranslateFn, useTranslation} from '../translation';
import {
  DEFAULT_API_NAME,
  useApi,
  useConnectedUser,
  useUserIsLGAdmin,
} from '../util/Auth';
import {ContentDiv} from './layout/ContentDiv';
import {LogoutButton} from './LogoutButton';

import settings from '../images/icons/00_parametres.svg';
import apartments from '../images/icons/01_appartement.svg';
import owners from '../images/icons/02_proprietaire.svg';
import stats from '../images/icons/03_statistique.svg';
import orders from '../images/icons/04_commande.svg';
import invoices from '../images/icons/05_facture.svg';
import payments from '../images/icons/06_reglement.svg';
import accountingEntries from '../images/icons/07_ecriture.svg';
import ownerRequests from '../images/icons/08_compteAttente.svg';
import apartmentRequests from '../images/icons/09_appartAttente.svg';
import cardsAwaitingProduction from '../images/icons/10_carteFabriquer.svg';
import cardsAwaitingDelivery from '../images/icons/11_carteLivrer.svg';
import car from '../images/icons/car.png';

import styled from '@emotion/styled';
import {AppTitle} from '../util/AppTitle';
import {useRequest} from 'ahooks';

interface AdminModule {
  key: string;
  route?: string;
  label: string;
  icon: string;
  badgeCount?: number;
}

interface BadgeData {
  ownerRequests: number;
  apartmentRequests: number;
  cardsAwaitingProduction: number;
  cardsAwaitingDelivery: number;
  vehiclesNotMarked: number;
}

const getModules = (
  t: TranslateFn,
  badgeData: BadgeData | undefined,
  isLGAdmin: boolean,
): AdminModule[] => [
  {
    key: 'settings',
    route: '/admin/settings',
    label: t('home.settings'),
    icon: settings,
  },
  {
    key: 'apartments',
    route: '/admin/apartments',
    label: t('home.apartments'),
    icon: apartments,
  },
  {
    key: 'owners',
    route: '/admin/owners',
    label: t('home.owners'),
    icon: owners,
  },
  {
    key: 'stats',
    route: '/admin/stats',
    label: t('home.stats'),
    icon: stats,
  },
  {
    key: 'orders',
    route: '/admin/orders',
    label: t('home.orders'),
    icon: orders,
  },
  {
    key: 'invoices',
    route: '/admin/invoices',
    label: t('home.invoices'),
    icon: invoices,
  },
  {
    key: 'payments',
    route: '/admin/payments',
    label: t('home.payments'),
    icon: payments,
  },
  {
    key: 'accountingEntries',
    route: '/admin/accountingEntries',
    label: t('home.accountingEntries'),
    icon: accountingEntries,
  },
  {
    key: 'ownerRequests',
    route: '/admin/ownerRequests',
    label: t('home.ownerRequests'),
    icon: ownerRequests,
    badgeCount: badgeData?.ownerRequests,
  },
  {
    key: 'apartmentRequests',
    route: '/admin/apartmentRequests',
    label: t('home.apartmentRequests'),
    icon: apartmentRequests,
    badgeCount: badgeData?.apartmentRequests,
  },
  {
    key: 'cardsAwaitingProduction',
    route: '/admin/cardsAwaitingProduction',
    label: t('home.cardsAwaitingProduction'),
    icon: cardsAwaitingProduction,
    badgeCount: badgeData?.cardsAwaitingProduction,
  },
  {
    key: 'cardsAwaitingDelivery',
    route: '/admin/cardsAwaitingDelivery',
    label: t('home.cardsAwaitingDelivery'),
    icon: cardsAwaitingDelivery,
    badgeCount: badgeData?.cardsAwaitingDelivery,
  },
  {
    key: 'cardsGold',
    route: '/admin/cardsGold',
    label: t('home.cardsGold'),
    icon: cardsAwaitingDelivery,
  },
  {
    key: 'vehicles',
    route: '/admin/vehicles',
    label: t('home.vehicles'),
    icon: car,
    badgeCount: badgeData?.vehiclesNotMarked,
  },
  ...(isLGAdmin
    ? [
        {
          key: 'super-admin',
          route: '/admin/super-admin',
          label: t('home.superAdmin'),
          icon: settings,
        },
      ]
    : []),
];

const AdminLink = styled(Link)`
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    img {
      filter: grayscale(1) brightness(0);
    }
  }
  &:not(:hover) img {
    filter: grayscale(1) brightness(0);
  }
  &:not(.disabled):hover h4 {
    color: #c58a4e;
  }
`;

export const UserAdminHome: FC = () => {
  const user = useConnectedUser();
  const {t} = useTranslation();

  const api = useApi();
  const fetchBadges = async (): Promise<BadgeData | undefined> => {
    if (!api) return;
    return api.get(DEFAULT_API_NAME, '/admin/badges', {});
  };
  const {data: badgesData} = useRequest(fetchBadges, {refreshDeps: [api]});

  const isLGAdmin = useUserIsLGAdmin();
  const modules = getModules(t, badgesData, isLGAdmin);

  return (
    <ContentDiv
      title={t('home.welcome')}
      titleRightComponent={<LogoutButton />}
    >
      <p>
        {t('home.user')}:{' '}
        {user?.attributes?.email
          ? user.attributes.email
          : t('home.notConnected')}
      </p>
      <Row gutter={20}>
        {modules.map(({key, route, label, icon, badgeCount}) => (
          <Col span={6} key={key} style={{marginBottom: 35}}>
            <Badge count={badgeCount}>
              <AdminLink to={route ?? '#'} className={route ? '' : 'disabled'}>
                <Row justify="start" align="middle" wrap={false}>
                  <div style={{height: 30}}>
                    <img
                      src={icon}
                      alt={label}
                      style={{width: 30, maxHeight: 'auto'}}
                    />
                  </div>
                  <AppTitle
                    level={4}
                    style={{
                      margin: 0,
                      marginLeft: 10,
                    }}
                  >
                    {label}
                  </AppTitle>
                </Row>
              </AdminLink>
            </Badge>
          </Col>
        ))}
      </Row>
    </ContentDiv>
  );
};
