import React, {FC} from 'react';
import {COLORS} from '../../design';
import {AppTitle} from '../../util/AppTitle';

export const ContentDiv: FC<{
  children?: React.ReactNode;
  style?: React.CSSProperties;
  /** title of the div */
  title?: string;
  /** if title is set, to put a component on the right */
  titleRightComponent?: React.ReactNode;
}> = (props) => {
  const {children, style, title, titleRightComponent} = props;
  return (
    <div
      style={{
        backgroundColor: COLORS.NEUTRAL_COLOR,
        padding: 24,
        border: `2px solid ${COLORS.PRIVATE_COLOR}`,
        ...style,
      }}
    >
      {title ? (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <AppTitle level={3} style={{marginTop: 0}}>
            {title}
          </AppTitle>
          <div>{titleRightComponent}</div>
        </div>
      ) : undefined}
      {children}
    </div>
  );
};
