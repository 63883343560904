import {FC} from 'react';
import {Select, SelectProps} from 'antd';
import {useRequest} from 'ahooks';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {ApartmentRecord} from '../../component/table/Apartment';
import {APIClass} from 'aws-amplify';
import {filterOptionByLabel} from '../util';

const fetchApartments = async (
  api: APIClass | null,
): Promise<ApartmentRecord[] | undefined> => {
  if (!api) return;
  const res = await api.get(DEFAULT_API_NAME, `/apartments/me`, {});
  return res?.entities ?? [];
};

export const OwnerApartmentCodeSelect: FC<SelectProps> = (props) => {
  const api = useApi();
  const {data, loading} = useRequest(() => fetchApartments(api), {
    refreshDeps: [api],
  });

  return (
    <Select
      options={data?.map((a) => ({
        label: a.code ?? '',
        value: a.id,
      }))}
      loading={loading}
      showSearch
      filterOption={filterOptionByLabel}
      {...props}
    />
  );
};
