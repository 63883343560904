import {Divider} from 'antd';
import {FC} from 'react';
import {SuperAdminVehicleClearData} from './SuperAdminClearPastData';
import {SuperAdminCognitoInfo} from './SuperAdminCognitoInfo';
// import {
//   SuperAdminAddDummyOrders,
//   SuperAdminDelDummyOrders,
// } from './SuperAdminDummyOrders';
import {SuperAdminVehicleBulkMark} from './SuperAdminVehicleBulkMark';

export const SuperAdminMisc: FC = (props) => {
  return (
    <div>
      <Divider orientation="left">
        Récupérer infos Cognito d'un utilisateur
      </Divider>
      <SuperAdminCognitoInfo />
      <Divider orientation="left">Lancer le script de clear data</Divider>
      <SuperAdminVehicleClearData />
      <Divider orientation="left">Pointer les véhicules</Divider>
      <SuperAdminVehicleBulkMark />

      {/* <Divider orientation="left">DUMMY ORDERS</Divider>
      <SuperAdminAddDummyOrders />
      <SuperAdminDelDummyOrders /> */}
    </div>
  );
};
