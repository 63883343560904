import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Alert, Button, Form, FormProps, Input, Modal, Space} from 'antd';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {COLORS, MAX_WIDTH, TITLE_FONT_FAMILY} from '../design';
import {useTranslation} from '../translation';
import {AppTitle} from '../util/AppTitle';
import {
  isAmplifyError,
  useCompleteNewPassword,
  useForgotPassword,
  useResetPassword,
  useSignIn,
  useSignOutAndDeleteCookies,
  useVerifyUserEmail,
} from '../util/Auth';
import {getBlogBaseUrl} from '../util/links';
import {getConfirmPasswordRule, getPasswordRules} from './input/PasswordInput';
import {ContentDiv} from './layout/ContentDiv';
import {Register} from './Register';
import {isCodedError} from './table/Table';

interface LoginValues {
  username: string;
  password: string;
}

interface ResetPasswordValues {
  email: string;
  code: string;
  password: string;
  passwordValidation: string;
}

interface NewPasswordValues {
  email: string;
  password: string;
  passwordValidation: string;
}

const ENCODED_CREDENTIALS_SEPARATOR = '_#_SEP_#_';
const parseEncodedCredentials = (credentials: string): LoginValues | null => {
  let decoded: string = '';
  try {
    decoded = decodeURIComponent(escape(window.atob(credentials)));
  } catch (err) {
    console.warn('Encoded credentials parse error', err);
    return null;
  }

  const [username, password] = decoded.split(ENCODED_CREDENTIALS_SEPARATOR);

  if (!username || !password) return null;

  return {username, password};
};

export const Login: FC = () => {
  const signIn = useSignIn();
  const signOutAndDeleteCookies = useSignOutAndDeleteCookies();
  const forgotPassword = useForgotPassword();
  const resetPassword = useResetPassword();
  const completeNewPassword = useCompleteNewPassword();
  const verifyUserEmail = useVerifyUserEmail();
  const navigate = useNavigate();

  const {encodedCredentials, resetPasswordEmail} = useParams();
  const attemptedToUseParamCredentials = useRef(false);
  const attemptedToResetPassword = useRef(false);

  const [loginForm] = Form.useForm<LoginValues>();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginSuccess, setLoginSuccess] = useState('');

  const [resetPasswordForm] = Form.useForm<ResetPasswordValues>();
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState('');

  const [newPasswordForm] = Form.useForm<NewPasswordValues>();
  const [newPasswordModalVisible, setNewPasswordModalVisible] = useState(false);
  const [newPasswordLoading, setNewPasswordLoading] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');

  const {t} = useTranslation();

  const [loginUsername, setLoginUsername] = useState(
    process.env.REACT_APP_DEFAULT_USERNAME,
  );
  const [loginPassword, setLoginPassword] = useState(
    process.env.REACT_APP_DEFAULT_PASSWORD,
  );

  const handleLogin = useCallback(
    async ({username, password}: LoginValues) => {
      if (!username || !password) return;

      await signOutAndDeleteCookies();

      setLoginLoading(true);
      try {
        const user = await signIn({username, password});

        console.log({user}, user?.attributes?.email_verified);
        if (user?.attributes?.email_verified === false) {
          await verifyUserEmail();
          return;
        }

        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          newPasswordForm.setFieldsValue({email: username});
          setNewPasswordModalVisible(true);
          return;
        } else if (user?.challengeName) {
          throw new Error('Unhandled sign in challenge: ' + user.challengeName);
        }
      } catch (err: any) {
        if (isAmplifyError(err)) {
          setLoginError(t(`cognito.error.${err.code}`));
        } else {
          setLoginError(err?.message ?? err);
        }
        return;
      } finally {
        setLoginLoading(false);
      }
      navigate('/');
    },
    [
      navigate,
      newPasswordForm,
      signIn,
      signOutAndDeleteCookies,
      t,
      verifyUserEmail,
    ],
  );

  const handleForgotPassword = useCallback(async () => {
    await loginForm.validateFields();

    const {username} = loginForm.getFieldsValue();

    setLoginLoading(true);
    try {
      await forgotPassword({email: username});
    } catch (err) {
      setLoginError((err as any)?.message ?? err);
      return;
    } finally {
      setLoginLoading(false);
    }

    resetPasswordForm.setFieldValue('email', username);
    setResetPasswordModalVisible(true);
  }, [forgotPassword, loginForm, resetPasswordForm]);

  const handleResetPasswordModalClose = () => {
    resetPasswordForm.resetFields();
    setResetPasswordModalVisible(false);
  };

  const handleResetPassword: FormProps<ResetPasswordValues>['onFinish'] =
    async (values) => {
      await resetPasswordForm.validateFields();
      setResetPasswordLoading(true);
      setResetPasswordError('');

      const {email, code, password} = values;

      try {
        await resetPassword({
          email,
          code,
          password,
        });
      } catch (err) {
        console.log(err, JSON.stringify(err));
        let message = t('register.signUpError');
        if (isCodedError(err)) {
          message += `:\n${t(`cognito.error.${err.code}`)}`;
        }
        setResetPasswordError(message);
        return;
      } finally {
        setResetPasswordLoading(false);
      }

      handleResetPasswordModalClose();
    };

  const handleNewPassword: FormProps<NewPasswordValues>['onFinish'] = async (
    values,
  ) => {
    await newPasswordForm.validateFields();
    setNewPasswordLoading(true);
    setNewPasswordError('');

    const {password} = values;

    try {
      await completeNewPassword({
        newPassword: password,
      });
      setLoginSuccess(t('register.success.completeNewPassword'));
    } catch (err) {
      console.log(err, JSON.stringify(err));
      let message = t('register.signUpError');
      if (isCodedError(err)) {
        message += `:\n${t(`cognito.error.${err.code}`)}`;
      }
      setNewPasswordError(message);
      return;
    } finally {
      setNewPasswordLoading(false);
    }

    newPasswordForm.resetFields();
    setNewPasswordModalVisible(false);
  };

  useEffect(() => {
    if (!encodedCredentials || attemptedToUseParamCredentials.current) return;
    attemptedToUseParamCredentials.current = true;
    const credentials = parseEncodedCredentials(encodedCredentials);
    if (!credentials) return;

    handleLogin(credentials);
  }, [attemptedToUseParamCredentials, encodedCredentials, handleLogin]);

  useEffect(() => {
    if (!resetPasswordEmail || attemptedToResetPassword.current) return;
    attemptedToResetPassword.current = true;

    loginForm.setFieldValue('username', resetPasswordEmail);
    handleForgotPassword();
  }, [
    attemptedToResetPassword,
    resetPasswordEmail,
    handleLogin,
    loginForm,
    handleForgotPassword,
  ]);

  return (
    <div
      style={{
        width: 500,
        height: '100%',
      }}
    >
      <AppTitle level={2} style={{marginTop: 0}}>
        {t('general.loginAction')}
      </AppTitle>
      <Form<LoginValues>
        form={loginForm}
        name="normal_login"
        className="login-form"
        initialValues={{
          username: process.env.REACT_APP_DEFAULT_USERNAME,
          password: process.env.REACT_APP_DEFAULT_PASSWORD,
        }}
        onFinish={handleLogin}
        labelCol={{span: 10}}
        wrapperCol={{span: 24 - 10}}
        labelWrap
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div>
            <Form.Item
              name="username"
              label={t('login.username')}
              rules={[{required: true}]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                onChange={() =>
                  setLoginUsername(loginForm.getFieldValue('username'))
                }
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t('login.password')}
              style={{marginBottom: 0}}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                visibilityToggle={true}
                onChange={() =>
                  setLoginPassword(loginForm.getFieldValue('password'))
                }
              />
            </Form.Item>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 12,
              }}
            >
              <Button
                type="link"
                disabled={!loginUsername}
                loading={loginLoading}
                onClick={handleForgotPassword}
              >
                {t('login.forgotPassword')}
              </Button>
            </div>

            {loginError ? (
              <Alert
                message={t('general.connectionError')}
                description={loginError}
                type="error"
                closable
                onClose={() => setLoginError('')}
                style={{marginBottom: 12}}
              />
            ) : null}

            {loginSuccess ? (
              <Alert
                description={loginSuccess}
                type="success"
                closable
                onClose={() => setLoginSuccess('')}
                style={{marginBottom: 12}}
              />
            ) : null}
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={!loginUsername || !loginPassword}
                loading={loginLoading}
              >
                {t('login.loginActionButton')}
              </Button>
            </div>
          </div>
        </div>
      </Form>

      <Modal
        centered
        forceRender
        open={resetPasswordModalVisible}
        onOk={() => handleResetPassword(resetPasswordForm.getFieldsValue())}
        onCancel={handleResetPasswordModalClose}
        okButtonProps={{loading: resetPasswordLoading}}
        cancelText={t('general.cancel')}
        title={t('login.newPasswordTitle')}
      >
        <p>{t('login.newPasswordText')}</p>
        <Form<ResetPasswordValues>
          form={resetPasswordForm}
          name="reset"
          className="reset-form"
          onFinish={handleResetPassword}
          labelCol={{span: 10}}
          wrapperCol={{span: 24 - 10}}
          labelWrap
        >
          <Form.Item
            name="email"
            label={t('register.email')}
            rules={[{required: true}]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="code"
            label={t('register.code')}
            rules={[{required: true}]}
          >
            <Input minLength={6} maxLength={6} placeholder="123456" />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('login.newPassword')}
            rules={[{required: true}, ...getPasswordRules({t})]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>
          <Form.Item
            name="passwordValidation"
            label={t('login.newPasswordConfirm')}
            rules={[{required: true}, getConfirmPasswordRule({t})]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>

          <Form.Item hidden={true}>
            <Button htmlType="submit">{t('general.submit')}</Button>
          </Form.Item>

          {resetPasswordError ? (
            <Alert
              message={resetPasswordError}
              type="error"
              style={{whiteSpace: 'pre-line'}}
            />
          ) : null}
        </Form>
      </Modal>

      <Modal
        centered
        forceRender
        open={newPasswordModalVisible}
        onOk={() => handleNewPassword(newPasswordForm.getFieldsValue())}
        okButtonProps={{loading: newPasswordLoading}}
        closable={false}
        cancelButtonProps={{style: {display: 'none'}}}
        cancelText={t('general.cancel')}
        title={t('login.forcePasswordTitle')}
      >
        <p>{t('login.forcePasswordText')}</p>
        <Form<NewPasswordValues>
          form={newPasswordForm}
          name="new"
          className="new-form"
          onFinish={handleNewPassword}
          labelCol={{span: 10}}
          wrapperCol={{span: 24 - 10}}
          labelWrap
        >
          <Form.Item
            name="email"
            label={t('register.email')}
            rules={[{required: true}]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('register.password')}
            rules={[{required: true}, ...getPasswordRules({t})]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>
          <Form.Item
            name="passwordValidation"
            label={t('register.passwordValidation')}
            rules={[{required: true}, getConfirmPasswordRule({t})]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>

          <Form.Item hidden={true}>
            <Button htmlType="submit">{t('general.submit')}</Button>
          </Form.Item>

          {newPasswordError ? (
            <Alert
              message={newPasswordError}
              type="error"
              style={{whiteSpace: 'pre-line'}}
            />
          ) : null}
        </Form>
      </Modal>
    </div>
  );
};

export const LoginPage: FC = (props) => {
  const {t} = useTranslation();

  return (
    <div>
      <div
        style={{
          maxWidth: MAX_WIDTH - 24 * 2,
          margin: '24px auto',
        }}
      >
        <div style={{marginBottom: 12}}>
          <Space>
            <a href={getBlogBaseUrl()} style={{textTransform: 'uppercase'}}>
              <span
                style={{
                  fontFamily: TITLE_FONT_FAMILY,
                  color: COLORS.PRIVATE_COLOR,
                }}
              >
                {t('login.home')}
              </span>
            </a>
            <span>{'>'}</span>
            <span
              style={{
                textTransform: 'uppercase',
                fontFamily: TITLE_FONT_FAMILY,
              }}
            >
              {t('general.ownersArea')}
            </span>
          </Space>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 24,
          justifyContent: 'center',
        }}
      >
        <ContentDiv style={{flex: '0 1 calc(50% - 52px - 24px)'}}>
          <Login />
        </ContentDiv>
        <ContentDiv style={{flex: '0 1 calc(50% - 52px - 24px)'}}>
          <Register />
        </ContentDiv>
      </div>
    </div>
  );
};
