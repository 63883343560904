import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import {Button, Checkbox, DatePicker, Form, Input} from 'antd';
import {Dayjs} from 'dayjs';
import {FC, useMemo, useState} from 'react';
import {getDateFormat, useTranslation} from '../../translation';
import {
  DayjsDateRangeToTimestampRange,
  TimestampToDayjsDate,
} from '../../util/date';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {
  booleanFilters,
  getValidateMessages,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';

const singularRoute = '/season';
const pluralRoute = '/seasons';

export interface SeasonFormValues {
  code: string;
  dateStart: number | Dayjs;
  dateEnd: number | Dayjs;
  active?: boolean;
}

export interface SeasonRecord extends SeasonFormValues {
  id: string;
  createDate: number;
  updateDate: number;
}

const seasonInitialValues: Partial<SeasonFormValues> = {};

export const SeasonForm: FC<TableFormProps<SeasonFormValues>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t, lang} = useTranslation();
  useMemo(() => {
    const {dateStart, dateEnd, ...other} = {
      ...seasonInitialValues,
      ...initialValues,
    };

    form.setFieldsValue({
      ...other,
      dateStart: dateStart ? TimestampToDayjsDate(dateStart) : undefined,
      dateEnd: dateEnd ? TimestampToDayjsDate(dateEnd) : undefined,
    });
  }, [form, initialValues]);

  return (
    <Form<SeasonFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      validateMessages={getValidateMessages(t)}
      autoComplete="off"
    >
      <Form.Item
        name="code"
        label={t('seasons.code')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="dateStart"
        label={t('seasons.dateStart')}
        rules={[{required: true}]}
      >
        <DatePicker format={getDateFormat(lang)} />
      </Form.Item>
      <Form.Item
        name="dateEnd"
        label={t('seasons.dateEnd')}
        rules={[{required: true}]}
      >
        <DatePicker format={getDateFormat(lang)} />
      </Form.Item>

      <Form.Item
        name="active"
        label={t('seasons.active')}
        valuePropName="checked"
        className="force-horizontal"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

const parseSeasonValues = ({
  dateStart: _dateStart,
  dateEnd: _dateEnd,
  ...values
}: SeasonFormValues): SeasonFormValues => {
  const [dateStart, dateEnd] = DayjsDateRangeToTimestampRange(
    _dateStart,
    _dateEnd,
  );

  return {
    dateStart,
    dateEnd,
    ...values,
  };
};

export const Seasons: FC<{style?: React.CSSProperties}> = (props) => {
  const {t} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<SeasonRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const seasonColumns: TableColumnsProp<SeasonRecord> = useStateFn(
    () => [
      {
        title: t('seasons.code'),
        key: 'code',
        dataIndex: 'code',
        searchInput: true,
        filterName: 'codeSearch',
        sorter: true,
      },
      ...['dateStart', 'dateEnd'].map((key) => ({
        title: t(`seasons.${key}`),
        key,
        dataIndex: key,
        render: (timestamp: number) =>
          TimestampToDayjsDate(timestamp).format('L'),
        sorter: true,
      })),
      {
        title: t('seasons.active'),
        key: 'active',
        dataIndex: 'active',
        render: (active) =>
          active ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#a30e10" />
          ),
        filters: booleanFilters(t),
        sorter: true,
      },
    ],
    [t],
  );

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: SeasonForm,
    parseValues: parseSeasonValues,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <ContentDiv
      title={total ? `${t('seasons.title')}: ${total}` : t('seasons.title')}
      titleRightComponent={
        <Button
          type="primary"
          onClick={() => setCreateModalOpen(true)}
          icon={<PlusOutlined />}
        />
      }
      style={props.style}
    >
      <TableList<SeasonRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={seasonColumns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        onTotalChange={setTotal}
      />
      <TableCreateModal<SeasonRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
      />
      <TableUpdateModal<SeasonRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
      />
    </ContentDiv>
  );
};
