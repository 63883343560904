import dayjs, {Dayjs} from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import duration, {Duration, DurationUnitType} from 'dayjs/plugin/duration';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(duration);

export const isDayjsDate = (date: string | number | Dayjs): date is Dayjs =>
  !!(date as Dayjs).isValid;

export const TimestampToDayjsDate = (date: number | Dayjs): Dayjs => {
  if (isDayjsDate(date)) return date;
  return dayjs.utc(date);
};

export const DayjsDateToTimestamp = (date: string | number | Dayjs): number => {
  if (isDayjsDate(date)) return date.valueOf();
  return dayjs.utc(date).valueOf();
};

export const DayjsDateRangeToTimestampRange = (
  dateStart: number | Dayjs,
  dateEnd: number | Dayjs,
): [number, number] => [
  DayjsDateToTimestamp(TimestampToDayjsDate(dateStart).utc().startOf('day')),
  DayjsDateToTimestamp(TimestampToDayjsDate(dateEnd).utc().endOf('day')),
];

export enum ValidityDurationType {
  DAYS = 1,
  WEEKS,
  MONTHS,
  YEARS,
}

/** WARN: do **not** add durations to dates using Duration objects */
export const getDayjsDurationFromDurationType = (
  durationType: ValidityDurationType,
  duration = 1,
): Duration => {
  switch (durationType) {
    case ValidityDurationType.DAYS:
      return dayjs.duration({days: duration});
    case ValidityDurationType.WEEKS:
      return dayjs.duration({weeks: duration});
    case ValidityDurationType.MONTHS:
      return dayjs.duration({months: duration});
    case ValidityDurationType.YEARS:
      return dayjs.duration({years: duration});
  }
};

export const getDurationFromDurationType = (
  durationType: ValidityDurationType,
  duration = 1,
): {value: number; unit: DurationUnitType} => {
  switch (durationType) {
    case ValidityDurationType.DAYS:
      return {value: duration, unit: 'days'};
    case ValidityDurationType.WEEKS:
      return {value: duration, unit: 'weeks'};
    case ValidityDurationType.MONTHS:
      return {value: duration, unit: 'months'};
    case ValidityDurationType.YEARS:
      return {value: duration, unit: 'years'};
  }
};
