import {useUpdateEffect} from 'ahooks';
import {DependencyList, useState} from 'react';

/**
 * Stores state from a factory function.
 * @param fn Factory function that returns a value.
 * @param deps Dependency list.
 * @returns value
 */
export const useStateFn = <T>(fn: () => T, deps: DependencyList): T => {
  const [value, setValue] = useState(fn());

  useUpdateEffect(() => {
    setValue(fn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return value;
};
