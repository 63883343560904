import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row} from 'antd';
import React, {FC, useMemo, useState} from 'react';
import {useTranslation} from '../../translation';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';

const singularRoute = '/host';
const pluralRoute = '/hosts';

export interface HostFormValues {
  name: string;
  thirdParty?: string;
  address?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  email?: string;
  comment?: string;
}

export interface HostRecord extends HostFormValues {
  id: string;
  createDate: number;
  updateDate: number;
}

const hostInitialValues: HostFormValues = {
  name: '',
  thirdParty: '',
  address: '',
  contactName: '',
  contactPhoneNumber: '',
  email: '',
  comment: '',
};

export const HostForm: FC<TableFormProps<HostFormValues>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t} = useTranslation();
  useMemo(() => {
    form.setFieldsValue({...hostInitialValues, ...initialValues});
  }, [form, initialValues]);

  return (
    <Form<HostFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...hostInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col flex={1}>
          <Form.Item
            name="name"
            label={t('hosts.name')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="thirdParty" label={t('hosts.thirdParty')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="address" label={t('hosts.address')}>
        <Input.TextArea autoSize={{minRows: 2, maxRows: 4}} />
      </Form.Item>
      <Form.Item name="contactName" label={t('hosts.contactName')}>
        <Input />
      </Form.Item>
      <Form.Item
        name="contactPhoneNumber"
        label={t('hosts.contactPhoneNumber')}
      >
        <Input type="tel" />
      </Form.Item>
      <Form.Item
        name="email"
        label={t('hosts.email')}
        rules={[{type: 'email'}]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item name="comment" label={t('hosts.comment')}>
        <Input.TextArea autoSize={{minRows: 3}} />
      </Form.Item>
      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

export const Host: FC<{style?: React.CSSProperties}> = (props) => {
  const {t} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<HostRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const hostColumns: TableColumnsProp<HostRecord> = useStateFn(
    () => [
      {
        title: t('hosts.name'),
        key: 'name',
        dataIndex: 'name',
        searchInput: true,
        sorter: true,
      },
    ],
    [t],
  );

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: HostForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <ContentDiv
      title={total ? `${t('hosts.title')}: ${total}` : t('hosts.title')}
      titleRightComponent={
        <Button
          type="primary"
          onClick={() => setCreateModalOpen(true)}
          icon={<PlusOutlined />}
        />
      }
      style={props.style}
    >
      <TableList<HostRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={hostColumns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        onTotalChange={setTotal}
      />
      <TableCreateModal<HostRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
      />
      <TableUpdateModal<HostRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
      />
    </ContentDiv>
  );
};
