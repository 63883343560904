import {useRequest} from 'ahooks';
import {Descriptions, Row, Spin} from 'antd';
import {FC} from 'react';
import {useTranslation} from '../translation';
import {DEFAULT_API_NAME, useApi} from '../util/Auth';
import {OwnerSettings} from './Settings';

export const CheckInstructions: FC = () => {
  const api = useApi();
  const {t} = useTranslation();

  const fetchSettings = async (): Promise<OwnerSettings | undefined> => {
    if (!api) return;
    return api.get(DEFAULT_API_NAME, '/settings', {});
  };

  const {data: settings, loading} = useRequest(fetchSettings, {
    refreshDeps: [api],
  });

  if (loading || !settings) {
    return <Spin />;
  }

  const description = (
    <Descriptions
      title={t('orders.payment.checkInstructions')}
      layout="vertical"
      size="small"
      bordered
    >
      <Descriptions.Item
        label={<Row justify="center">{t('orders.payment.checkOrder')}</Row>}
        style={{minWidth: 100}}
      >
        {settings.checkOrder}
      </Descriptions.Item>
      <Descriptions.Item
        label={<Row justify="center">{t('orders.payment.checkAddress')}</Row>}
      >
        {settings.checkAddress}
      </Descriptions.Item>
    </Descriptions>
  );

  return description;
};
