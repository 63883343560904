import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {useRequest} from 'ahooks';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import {isAxiosError} from 'axios';
import {FC, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {CachedApartmentCodeFormItem} from '../AssignApartmentForm';
import {ContentDiv} from '../layout/ContentDiv';
import {ApartmentRecord} from '../table/Apartment';
import {
  ApartmentRequestRecord,
  getApartmentRequestColumns,
} from '../table/ApartmentRequest';
import {HostRecord} from '../table/Host';
import {OwnerForm, OwnerFormValues, OwnerRecord} from '../table/Owner';
import {flattenObject} from '../table/Table';
import {getApartmentColumns, parseOwnerFormValues} from './Owner';

const singularRoute = '/owner';
// const pluralRoute = '/owners';

const routes = {
  apartment: {
    singular: '/apartment',
    plural: '/apartments',
  },
  apartmentRequest: {
    singular: '/apartmentRequest',
    plural: '/apartmentRequests',
  },
  user: {
    singular: '/user',
    plural: '/users',
  },
};

interface RequestApartmentFormValues {
  apartmentCode: string;
}

const updateEmailAddress = 'contact@capesterelascape.fr';

export const OwnerProfile: FC = () => {
  const api = useApi();
  const {t, lang} = useTranslation();

  const [ownerForm] = Form.useForm<OwnerFormValues>();
  const [ownerError, setOwnerError] = useState('');

  const [updateOwnerLoading, setUpdateOwnerLoading] = useState(false);

  const [requestApartmentForm] = Form.useForm<RequestApartmentFormValues>();
  const [showRequestApartmentModal, setShowRequestApartmentModal] =
    useState(false);
  const [requestApartmentLoading, setRequestApartmentLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const requestApartmentCode = Form.useWatch(
    'apartmentCode',
    requestApartmentForm,
  );
  const requestApartmentHostId = Form.useWatch('hostId', requestApartmentForm);

  const [deleteLoadingEntity, setDeleteLoadingEntity] = useState<
    ApartmentRequestRecord | undefined
  >();

  const fetchOwner = async (): Promise<OwnerRecord | undefined> => {
    if (!api) return;
    return flattenObject(
      await api.get(DEFAULT_API_NAME, `${singularRoute}/me`, {}),
    );
  };
  const {
    data: ownerData,
    loading: ownerLoading,
    runAsync: refetchOwner,
  } = useRequest(fetchOwner, {
    refreshDeps: [api],
  });

  const fetchApartments = async (): Promise<ApartmentRecord[] | undefined> => {
    if (!api) return;
    return (
      (await api.get(DEFAULT_API_NAME, `${routes.apartment.plural}/me`, {}))
        ?.entities ?? []
    );
  };
  const {data: apartmentsData, loading: apartmentsLoading} = useRequest(
    fetchApartments,
    {
      refreshDeps: [api],
    },
  );

  const fetchHosts = async (): Promise<HostRecord[] | undefined> => {
    if (!api) return;
    return (await api.get(DEFAULT_API_NAME, '/hosts', {}))?.entities ?? [];
  };
  const {data: hostsData, loading: hostsLoading} = useRequest(fetchHosts, {
    refreshDeps: [api],
  });

  const fetchApartmentRequests = async (): Promise<
    ApartmentRequestRecord[] | undefined
  > => {
    if (!api) return;
    return flattenObject(
      (
        await api.get(
          DEFAULT_API_NAME,
          `${routes.apartmentRequest.plural}/me`,
          {},
        )
      )?.entities ?? [],
    );
  };
  const {
    data: apartmentRequestsData,
    loading: apartmentRequestsLoading,
    runAsync: refetchApartmentRequests,
  } = useRequest(fetchApartmentRequests, {
    refreshDeps: [api],
  });

  const handleOwnerSubmit = async () => {
    if (!api || !ownerData) return;
    await ownerForm.validateFields();
    const formValues = ownerForm.getFieldsValue();
    const values = parseOwnerFormValues(formValues);

    setUpdateOwnerLoading(true);
    try {
      await api.post(DEFAULT_API_NAME, `${singularRoute}/${ownerData.id}`, {
        body: values,
      });

      if (
        ownerData.user.firstName !== formValues['user.firstName'] ||
        ownerData.user.lastName !== formValues['user.lastName'] ||
        ownerData.user.title !== formValues['user.title'] ||
        ownerData.user.disabled !== formValues['user.disabled']
      ) {
        await api.post(
          DEFAULT_API_NAME,
          `${routes.user.singular}/${formValues.userId}`,
          {
            body: {
              ...ownerData.user,
              firstName: formValues['user.firstName'],
              lastName: formValues['user.lastName'],
              title: formValues['user.title'],
              disabled: formValues['user.disabled'],
            },
          },
        );
      }
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOwnerError(message);
      return;
    } finally {
      setUpdateOwnerLoading(false);
    }

    return refetchOwner();
  };

  const isRequestApartmentCodeAlreadyOwned = useMemo(() => {
    if (!requestApartmentCode || !apartmentsData?.length) return false;

    return !!apartmentsData.find(({code}) => code === requestApartmentCode);
  }, [requestApartmentCode, apartmentsData]);

  const isRequestApartmentCodeAlreadyRequested = useMemo(() => {
    if (!requestApartmentCode || !apartmentRequestsData?.length) return false;

    return !!apartmentRequestsData.find(
      ({apartment: {code}}) => code === requestApartmentCode,
    );
  }, [requestApartmentCode, apartmentRequestsData]);

  const handleRequestApartmentModalClose = () => {
    requestApartmentForm.resetFields();
    setRequestError('');
    setShowRequestApartmentModal(false);
  };

  const handleRequestApartment = async () => {
    if (!api || !requestApartmentCode) return;

    await requestApartmentForm.validateFields();
    setRequestApartmentLoading(true);
    try {
      const apartment = await api.get(
        DEFAULT_API_NAME,
        `/apartment/code/${requestApartmentCode}`,
        {},
      );

      if (!apartment) throw new Error('Apartment not found');

      await api.put(
        DEFAULT_API_NAME,
        `${routes.apartmentRequest.singular}/me/${apartment.id}`,
        {body: {hostId: requestApartmentHostId}},
      );

      handleRequestApartmentModalClose();
      await refetchApartmentRequests();
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setRequestError(message);
    } finally {
      setRequestApartmentLoading(false);
    }
  };

  const handleDeleteApartmentRequest = async (
    entity: ApartmentRequestRecord,
  ) => {
    if (!api) return;
    setDeleteLoadingEntity(entity);
    try {
      await api.del(
        DEFAULT_API_NAME,
        `${routes.apartmentRequest.singular}/${entity.id}`,
        {},
      );
      return refetchApartmentRequests();
    } finally {
      setDeleteLoadingEntity(undefined);
    }
  };

  const updateEmailMailto = useMemo(
    () =>
      `mailto:${updateEmailAddress}?subject=${encodeURIComponent(
        t('users.updateEmail.email.subject'),
      )}&body=${encodeURIComponent(
        t('users.updateEmail.email.body')
          // eslint-disable-next-line no-template-curly-in-string
          .replace('${email}', ownerData?.['user.email'] ?? '')
          .replace(
            // eslint-disable-next-line no-template-curly-in-string
            '${name}',
            `${ownerData?.['user.lastName']} ${ownerData?.['user.firstName']}`,
          ),
      )}`,
    [ownerData, t],
  );

  if (ownerLoading) {
    return <Spin size="large" />;
  }

  if (ownerError) {
    return (
      <div>
        <Row>
          <Alert message={ownerError} />
        </Row>
        <Row>
          <Link to="/">
            <Button>{t('general.cancel')}</Button>
          </Link>
        </Row>
      </div>
    );
  }

  return (
    <ContentDiv>
      <Row gutter={20}>
        <Col span={10}>
          <AppTitle level={3} style={{marginTop: 0}}>
            {t('owners.title')}
          </AppTitle>
          <OwnerForm
            form={ownerForm}
            initialValues={ownerData}
            onFinish={handleOwnerSubmit}
          />

          <Row gutter={20} justify="space-between">
            <Col span={24}>
              <p>
                Si vous souhaitez changer votre adresse email, veuillez nous
                contacter à l'adresse suivante :{' '}
                <a href={updateEmailMailto}>{updateEmailAddress}</a>
              </p>
            </Col>
            <Col></Col>
            <Col>
              <Space>
                <Link to="/">
                  <Button>{t('general.cancel')}</Button>
                </Link>
                <Button
                  type="primary"
                  onClick={handleOwnerSubmit}
                  loading={updateOwnerLoading}
                >
                  {t('general.submit')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Row justify="space-between" align="middle">
            <Col>
              <AppTitle level={3} style={{marginTop: 0}}>
                {t('apartments.title')}
              </AppTitle>
            </Col>
            <Col style={{margin: 'auto 0'}}>
              <Button
                type="primary"
                onClick={() => setShowRequestApartmentModal(true)}
                icon={<PlusOutlined />}
              />
            </Col>
          </Row>

          <Table<ApartmentRecord>
            loading={apartmentsLoading}
            dataSource={apartmentsData ?? []}
            rowKey="id"
            pagination={false}
            size="small"
            bordered
            columns={getApartmentColumns(t)}
          />

          {apartmentRequestsData?.length ? (
            <>
              <AppTitle level={3}>{t('apartmentRequests.title')}</AppTitle>

              <Table<ApartmentRequestRecord>
                loading={apartmentRequestsLoading}
                dataSource={apartmentRequestsData ?? []}
                rowKey="id"
                pagination={false}
                size="small"
                bordered
                columns={[
                  ...getApartmentRequestColumns(t, lang),
                  {
                    dataIndex: 'actions',
                    key: 'actions',
                    width: 100,
                    fixed: 'right',
                    render: (_, entity) => (
                      <Space>
                        <Popconfirm
                          title={t('general.delete')}
                          okText={t('general.yes')}
                          cancelText={t('general.no')}
                          onConfirm={() => handleDeleteApartmentRequest(entity)}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            type="primary"
                            danger={true}
                            loading={deleteLoadingEntity === entity}
                          />
                        </Popconfirm>
                      </Space>
                    ),
                  },
                ]}
              />
            </>
          ) : null}
        </Col>
      </Row>

      <Modal
        centered
        forceRender
        open={showRequestApartmentModal}
        onOk={handleRequestApartment}
        onCancel={handleRequestApartmentModalClose}
        title={t('apartmentRequests.requestTitle')}
        okButtonProps={{
          loading: requestApartmentLoading,
          disabled:
            isRequestApartmentCodeAlreadyOwned ||
            isRequestApartmentCodeAlreadyRequested,
        }}
      >
        <Form<RequestApartmentFormValues>
          form={requestApartmentForm}
          name="request-apartment"
          className="request-apartment-form"
          onFinish={handleRequestApartment}
        >
          <Form.Item
            name="hostId"
            label={t('apartments.host')}
            labelCol={{span: 6}}
          >
            <Select
              options={hostsData?.map(({id, name}) => ({
                value: id,
                label: name,
              }))}
              loading={hostsLoading}
              allowClear
            />
          </Form.Item>
          <CachedApartmentCodeFormItem form={requestApartmentForm} />

          <div
            style={{
              color: '#ff4d4f',
              textAlign: 'right',
              marginTop: -8,
              marginBottom: 8,
            }}
          >
            {t('register.condoBuildingApartmentMessage')}
          </div>

          <Form.Item hidden={true}>
            <Button htmlType="submit">{t('general.submit')}</Button>
          </Form.Item>
        </Form>

        {isRequestApartmentCodeAlreadyOwned ? (
          <Alert message={t('apartmentRequests.alreadyOwned')} type="error" />
        ) : null}

        {isRequestApartmentCodeAlreadyRequested ? (
          <Alert
            message={t('apartmentRequests.alreadyRequested')}
            type="error"
          />
        ) : null}

        {requestError ? (
          <Alert
            message={requestError}
            type="error"
            style={{whiteSpace: 'pre-line'}}
          />
        ) : null}
      </Modal>
    </ContentDiv>
  );
};
