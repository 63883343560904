import {useRequest} from 'ahooks';
import {Descriptions, Row, Spin} from 'antd';
import {FC} from 'react';
import {useTranslation} from '../translation';
import {DEFAULT_API_NAME, useApi} from '../util/Auth';
import {OwnerSettings} from './Settings';
import {ArticleType} from './table/Article';

const getWireTransferSettingsForArticleType = (
  settings?: OwnerSettings,
  articleType?: ArticleType,
) => {
  if (!settings || !articleType) return null;
  if (
    [
      ArticleType.PARKING,
      ArticleType.LOST_PARKING,
      ArticleType.PARKING_CLONE,
    ].includes(articleType)
  )
    return {bic: settings.parkingBic, iban: settings.parkingIban};
  if (
    [
      ArticleType.POOL_BLANCHE,
      ArticleType.POOL_GOLD,
      ArticleType.POOL_GOLD_ADDITIONAL,
      ArticleType.LOST_POOL,
      ArticleType.MISC,
    ].includes(articleType)
  )
    return {bic: settings.poolBic, iban: settings.poolIban};

  return null;
};

interface WireTransferInstructionsProps {
  articleType?: ArticleType;
}

export const WireTransferInstructions: FC<WireTransferInstructionsProps> = ({
  articleType,
}) => {
  const api = useApi();
  const {t} = useTranslation();

  const fetchSettings = async (): Promise<OwnerSettings | undefined> => {
    if (!api) return;
    return api.get(DEFAULT_API_NAME, '/settings', {});
  };

  const {data: settings, loading} = useRequest(fetchSettings, {
    refreshDeps: [api],
  });

  if (loading) {
    return <Spin />;
  }

  const wireTransferSettings = getWireTransferSettingsForArticleType(
    settings,
    articleType,
  );
  if (!wireTransferSettings) return null;

  const description = (
    <Descriptions
      title={t('orders.payment.wireInstructions')}
      layout="vertical"
      size="small"
      bordered
    >
      <Descriptions.Item
        label={<Row justify="center">{t('orders.payment.iban')}</Row>}
      >
        {wireTransferSettings.iban}
      </Descriptions.Item>
      <Descriptions.Item
        label={<Row justify="center">{t('orders.payment.bic')}</Row>}
      >
        {wireTransferSettings.bic}
      </Descriptions.Item>
    </Descriptions>
  );

  return description;
};
