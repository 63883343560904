import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Select, SelectProps} from 'antd';

// TODO dynamic import (fetch) to reduce bundle size
import fr from './translations/fr.json';
import en from './translations/en.json';
import it from './translations/it.json';
import de from './translations/de.json';
import {getFlagEmoji} from './util/flags';

import enUS from 'antd/locale/en_US';
import frFR from 'antd/locale/fr_FR';
import deDE from 'antd/locale/de_DE';
import itIT from 'antd/locale/it_IT';
import {Locale} from 'antd/lib/locale';

// When adding a new language, please populate SUPPORTED_LANGUAGES,
// SUPPORTED_LANGUAGES_STR (with language name in own language) and SUPPORTED_LANGUAGE_DICT
export enum LANGUAGE {
  fr = 'fr',
  en = 'en',
  it = 'it',
  de = 'de',
}

const SUPPORTED_LANGUAGES: LANGUAGE[] = [
  LANGUAGE.fr,
  LANGUAGE.en,
  LANGUAGE.it,
  LANGUAGE.de,
];

const SUPPORTED_LANGUAGES_STR: Record<LANGUAGE, string> = {
  [LANGUAGE.fr]: 'Français',
  [LANGUAGE.en]: 'English',
  [LANGUAGE.it]: 'Italiano',
  [LANGUAGE.de]: 'Deutsch',
};
const SUPPORTED_LANGUAGE_DICT: Record<string, Record<string, string>> = {
  [LANGUAGE.fr]: fr,
  [LANGUAGE.en]: en,
  [LANGUAGE.it]: it,
  [LANGUAGE.de]: de,
};
const SUPPORTED_LANGUAGES_EMOJI: Record<LANGUAGE, string> = {
  [LANGUAGE.fr]: getFlagEmoji('fr'),
  [LANGUAGE.en]: getFlagEmoji('gb'),
  [LANGUAGE.it]: getFlagEmoji('it'),
  [LANGUAGE.de]: getFlagEmoji('de'),
};
export const SUPPORTED_LANGUAGE_LOCALE: Record<string, string> = {
  [LANGUAGE.fr]: 'fr-FR',
  [LANGUAGE.en]: 'en-GB',
  [LANGUAGE.it]: 'it-IT',
  [LANGUAGE.de]: 'de-DE',
};
export const SUPPORTED_LANGUAGE_ANTD_LOCALE: Record<string, Locale> = {
  [LANGUAGE.fr]: frFR,
  [LANGUAGE.en]: enUS,
  [LANGUAGE.it]: deDE,
  [LANGUAGE.de]: itIT,
};

/** Local storage key where language is stored */
const LS_LANGUAGE_KEY = 'ce_lang';

export type TranslateFn = (code: string) => string;

/** Retrieves language stored in local storage. If a value exists and
 * language is supported we return the value, else null.
 */
const getLocalStorageLanguage = (): LANGUAGE | null => {
  const lang = localStorage.getItem(LS_LANGUAGE_KEY) as LANGUAGE;
  if (lang && SUPPORTED_LANGUAGES.includes(lang)) {
    return lang;
  }
  return null;
};

/** Returns default user language from browser.
 * Defaults to "en" if no supported language is found. */
const getDefaultLanguage = (): LANGUAGE => {
  let defaultLanguage: LANGUAGE | undefined = undefined;

  for (const language of navigator.languages) {
    const lang = language.split('-')[0] as LANGUAGE;
    if (SUPPORTED_LANGUAGES.includes(lang)) {
      defaultLanguage = lang;
      break;
    }
  }

  return defaultLanguage ?? LANGUAGE.en;
};

/** for a given language, make sure we have the same translation strings (no
 * missing nor deprecated strings). */
const logMissingTranslations = (
  forLang: LANGUAGE,
  fromLang: LANGUAGE = LANGUAGE.fr,
): void => {
  if (forLang === fromLang) {
    return;
  }

  const forDict = SUPPORTED_LANGUAGE_DICT[forLang];
  const fromDict = SUPPORTED_LANGUAGE_DICT[fromLang];

  let nbDeprecated = 0;
  for (const key of Object.keys(forDict)) {
    if (fromDict[key] === undefined) {
      console.log(
        `translations: language "${forLang}" has deprecated translation string "${key}"`,
      );
      nbDeprecated += 1;
    }
  }

  let nbMissing = 0;
  for (const key of Object.keys(fromDict)) {
    if (forDict[key] === undefined) {
      console.log(
        `translations: language "${forLang}" has a missing translation string "${key}"`,
      );
      nbMissing += 1;
    }
  }

  if (nbDeprecated === 0 && nbMissing === 0) {
    console.log(
      `translations: no missing nor deprecated translation strings for language "${forLang}"`,
    );
  }
};

interface TranslationState {
  /** selected language ("fr", "en", etc.) */
  lang: LANGUAGE;
  /** to change selected language */
  setLang: React.Dispatch<React.SetStateAction<LANGUAGE>>;
}

export const TranslationContext = createContext<TranslationState>({
  lang: LANGUAGE.en,
  setLang: () => {},
});

export const TranslationProvider: FC<{children?: React.ReactNode}> = (
  props,
) => {
  const [lang, setLang] = useState(
    getLocalStorageLanguage() ?? getDefaultLanguage(),
  );

  // when "lang" changes, we store it in local storage
  useEffect(() => {
    if (lang) {
      localStorage.setItem(LS_LANGUAGE_KEY, lang);

      // if we're in development, we want to make sure no translation strings
      // for this language is missing nor deprecated:
      if (process.env.NODE_ENV === 'development') {
        logMissingTranslations(lang);
      }
    }
  }, [lang]);

  return <TranslationContext.Provider value={{lang, setLang}} {...props} />;
};

/** Hook to retrieve translated strings (with function `t`), the current `lang`
 * and `setLang` to change language.
 */
export const useTranslation = (): TranslationState & {
  /** to retrieve a translation for the selected language. Returns the code
   * if no translation for language has been found.
   */
  t: TranslateFn;
} => {
  const {lang, setLang} = useContext(TranslationContext);

  const t: TranslateFn = useCallback(
    (code) => {
      const translatedStr = SUPPORTED_LANGUAGE_DICT[lang]?.[code];
      if (!SUPPORTED_LANGUAGE_DICT[lang]?.hasOwnProperty(code)) {
        console.warn(`Unknown ${lang} translation for code "${code}"`);
      }
      return translatedStr ?? code;
    },
    [lang],
  );

  return {lang, setLang, t};
};

/** Select that allows user to change current selected language. */
export const LanguageSelect: FC<SelectProps<LANGUAGE>> = (props) => {
  const {lang, setLang} = useTranslation();
  return (
    <Select
      value={lang}
      onChange={(lang) => setLang(lang)}
      options={SUPPORTED_LANGUAGES.map((lang) => ({
        value: lang,
        label: (
          <span>
            <span className="emoji">{SUPPORTED_LANGUAGES_EMOJI[lang]}</span>{' '}
            {SUPPORTED_LANGUAGES_STR[lang]}
          </span>
        ),
      }))}
      className="language-select"
      bordered={false}
      {...props}
    />
  );
};

export const formatCurrency = (value: number, lang = LANGUAGE.fr) =>
  new Intl.NumberFormat(SUPPORTED_LANGUAGE_LOCALE[lang], {
    style: 'currency',
    currency: 'EUR',
  }).format(value);

export const getLocaleFromLang = (lang: LANGUAGE): Locale =>
  SUPPORTED_LANGUAGE_ANTD_LOCALE[lang];

/** to be used with DateTimePicker */
export const getDateTimeFormat = (lang: LANGUAGE): string => {
  switch (lang) {
    case LANGUAGE.fr:
      return 'DD/MM/YYYY HH:mm';
    default:
      return 'YYYY-MM-DD HH:mm';
  }
};

/** to be used with DatePicker `<DatePicker format={getDateFormat(lang)} />` */
export const getDateFormat = (lang: LANGUAGE): string => {
  switch (lang) {
    case LANGUAGE.fr:
      return 'DD/MM/YYYY';
    default:
      return 'YYYY-MM-DD';
  }
};

export const getMonthFormat = (lang: LANGUAGE): string => {
  switch (lang) {
    case LANGUAGE.fr:
      return 'MM/YYYY';
    default:
      return 'YYYY-MM';
  }
};

/** to be used with InputNumber components */
export const getDecimalSeparator = (lang: LANGUAGE): string => {
  switch (lang) {
    case LANGUAGE.fr:
      return ',';
    default:
      return '.';
  }
};

export const getThousandsSeparator = (lang: LANGUAGE): string => {
  switch (lang) {
    case LANGUAGE.fr:
      return ' ';
    default:
      return ',';
  }
};
