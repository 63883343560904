import {Rule} from 'antd/es/form';
import escapeStringRegexp from 'escape-string-regexp';
import {TranslateFn} from '../../translation';

const COGNITO_PASSWORD_SYMBOL_REGEX = new RegExp(
  `[${escapeStringRegexp('$^*.[]{}()?"!@#%&/\\,><\':;|_~`=+-')}]`,
);
const PASSWORD_MIN_LENGTH = Number(
  process.env.REACT_APP_PASSWORD_MIN_LENGTH ?? '0',
);

export const getPasswordRules = ({t}: {t: TranslateFn}): Rule[] => [
  () => ({
    validator(_, password) {
      if (!password) return Promise.reject();

      if (password.length < PASSWORD_MIN_LENGTH)
        return Promise.reject(
          new Error(
            t('register.password.minLength').replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${minLength}',
              PASSWORD_MIN_LENGTH.toString(),
            ),
          ),
        );

      return Promise.resolve();
    },
  }),
  ...[
    process.env.REACT_APP_PASSWORD_REQUIRE_LOWERCASE,
    process.env.REACT_APP_PASSWORD_REQUIRE_UPPERCASE,
    process.env.REACT_APP_PASSWORD_REQUIRE_DIGITS,
    process.env.REACT_APP_PASSWORD_REQUIRE_SYMBOLS,
  ].map(
    (requirement, index): Rule =>
      () => ({
        validator(_, password) {
          if (requirement !== 'true') return Promise.resolve();
          if (!password || password.length < PASSWORD_MIN_LENGTH)
            return Promise.reject();

          switch (index) {
            case 0:
              if (!password.match(/[a-z]/))
                return Promise.reject(t('register.password.lower'));
              break;
            case 1:
              if (!password.match(/[A-Z]/))
                return Promise.reject(t('register.password.upper'));
              break;
            case 2:
              if (!password.match(/[0-9]/))
                return Promise.reject(t('register.password.digit'));
              break;
            case 3:
              if (!password.match(COGNITO_PASSWORD_SYMBOL_REGEX))
                return Promise.reject(t('register.password.symbol'));
              break;
          }

          return Promise.resolve();
        },
      }),
  ),
];

export const getConfirmPasswordRule =
  ({
    t,
    passwordField = 'password',
  }: {
    t: TranslateFn;
    passwordField?: string;
  }): Rule =>
  ({getFieldValue}) => ({
    validator(_, value) {
      if (!value || getFieldValue(passwordField) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('register.passwordValidationReject')));
    },
  });
