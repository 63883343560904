import {FC, useState} from 'react';
import {DEFAULT_API_NAME, useApi, useUserIsAdmin} from '../util/Auth';
import {API} from 'aws-amplify';
import {useRequest} from 'ahooks';
import {Button, message, Popconfirm} from 'antd';
import {useTranslation} from '../translation';
import {ReloadOutlined} from '@ant-design/icons';

const generateInvoicePDF = async (
  api: typeof API | null,
  orderId: string,
): Promise<{url: string; status: number}> => {
  if (!api) {
    throw new Error('Expected API object');
  }

  const res = await api.post(
    DEFAULT_API_NAME,
    `/order/${orderId}/generateInvoicePDF`,
    {},
  );
  return res;
};

/** Admin button to re-generate PDF for a given order */
export const GenerateInvoicePDF: FC<{orderId: string}> = (props) => {
  const {orderId} = props;
  const api = useApi();
  const {t} = useTranslation();

  const isAdmin = useUserIsAdmin();

  const [loading, setLoading] = useState(false);

  const {runAsync} = useRequest(() => generateInvoicePDF(api, orderId), {
    manual: true,
  });

  if (!isAdmin) {
    return <div></div>;
  }

  return (
    <Popconfirm
      title={t('orders.regeneratePDF.confirm')}
      okText={t('general.yes')}
      cancelText={t('general.no')}
      onConfirm={async () => {
        if (loading) {
          return;
        }
        setLoading(true);

        let res: {url: string; status: number};
        try {
          res = await runAsync();
        } catch (err) {
          const msg = `${t('orders.regeneratePDF.error')}`;
          message.error(msg);
          console.log(msg, err);
          setLoading(false);
          return;
        }

        const msg = `${t('orders.regeneratePDF.success')}`;
        message.success(msg);
        console.log(msg, res);
        setLoading(false);
      }}
      okButtonProps={{loading}}
    >
      <Button
        icon={<ReloadOutlined />}
        type="primary"
        danger={true}
        loading={loading}
      />
    </Popconfirm>
  );
};
