import {CheckCircleTwoTone, PlusOutlined} from '@ant-design/icons';
import {Button, Popover, Space} from 'antd';
import {ColumnFilterItem, SorterResult} from 'antd/es/table/interface';
import dayjs, {Dayjs} from 'dayjs';
import {FC, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {LANGUAGE, TranslateFn, useTranslation} from '../../translation';
import {TimestampToDayjsDate} from '../../util/date';
import {OwnerSelect} from '../input/OwnerSelect';
import {PhoneNumberLink} from '../input/PhoneNumberLink';
import {ContentDiv} from '../layout/ContentDiv';
import {NoWrap} from '../NoWrap';
import {useStateFn} from '../useStateFn';
import {VehicleMarkButton} from '../VehicleMarkButton';
import {ApartmentRecord} from './Apartment';
import {OwnerRecord} from './Owner';
import {booleanFilters, TableColumnsProp, TableList} from './Table';
import {getNumericEnumKeys} from './util';

const singularRoute = '/vehicle';
const pluralRoute = '/vehicles';

export enum VehicleType {
  OWNER = 1,
  OCCUPANT,
}
export const VehicleTypeLabel: {[key in VehicleType]: string} = {
  [VehicleType.OWNER]: 'vehicles.type.owner',
  [VehicleType.OCCUPANT]: 'vehicles.type.occupant',
};

export interface VehicleFormValues {
  active: boolean;
  type: VehicleType;
  ownerId: string;
  apartmentId: string;
  brandName: string;
  model: string;
  licensePlateNo: string;
  occupantName: string;
  phoneNumber: string;
  startDate: number;
  endDate: number;
  // in form:
  dates: [Dayjs, Dayjs];
  marked: boolean;
}

export interface VehicleRecord extends VehicleFormValues {
  id: string;
  createDate: number;
  updateDate: number;
  illexUpToDate: boolean;
  owner: OwnerRecord;
  apartment: ApartmentRecord;
}

/**
 * Returns whether a vehicle can be modified.
 * @param vehicle
 * @param referenceDate Defaults to "now".
 * @returns
 */
export const canEditVehicle = (
  vehicle: VehicleRecord,
  referenceDate?: Dayjs,
): boolean => {
  if (vehicle.type === VehicleType.OWNER) {
    return true;
  } else if (vehicle.type === VehicleType.OCCUPANT) {
    // we check if location is finished for given reference date
    const ref = referenceDate ?? dayjs();
    const locIsFinished = ref.isAfter(TimestampToDayjsDate(vehicle.endDate));
    return locIsFinished === false;
  }
  return false;
};

export const vehicleTypeFilters = (t: TranslateFn): ColumnFilterItem[] =>
  getNumericEnumKeys(VehicleType).map((v) => ({
    value: v,
    text: t(VehicleTypeLabel[v as VehicleType]),
  }));

export const getAdminVehiclesColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<VehicleRecord> => [
  // {
  //   title: t('vehicles.createDate'),
  //   key: 'createDate',
  //   dataIndex: 'createDate',
  //   render: (createDate) =>
  //     createDate ? TimestampToDayjsDate(createDate).format('L LT') : undefined,
  //   sorter: true,
  // },
  {
    title: t('vehicles.date'),
    key: 'updateDate',
    dataIndex: 'updateDate',
    render: (updateDate) =>
      updateDate ? TimestampToDayjsDate(updateDate).format('L LT') : undefined,
    sorter: true,
  },
  {
    title: t('vehicles.type'),
    key: 'type',
    dataIndex: 'type',
    sorter: true,
    render: (_, {type}) => t(VehicleTypeLabel[type]),
    filters: vehicleTypeFilters(t),
  },
  {
    title: t('vehicles.active'),
    key: 'active',
    dataIndex: 'active',
    sorter: true,
    render: (_, {active}) =>
      active ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : undefined,
    filters: booleanFilters(t),
  },
  {
    title: t('vehicles.ownerId'),
    key: 'ownerId',
    dataIndex: 'ownerId',
    sorter: true,
    searchInput: true,
    render: (_, {owner}) =>
      owner ? (
        owner?.user ? (
          <Popover
            title={owner?.name}
            content={
              <div>
                {t('owners.phoneNumber')} :{' '}
                {owner.phoneNumber ? (
                  <PhoneNumberLink phoneNumber={owner.phoneNumber} />
                ) : (
                  ''
                )}
              </div>
            }
          >
            <Link to={`/admin/owner/${owner.id}`}>{owner?.name}</Link>
          </Popover>
        ) : (
          <Link to={`/admin/owner/${owner.id}`}>{owner.id}</Link>
        )
      ) : null,
    searchSelect: (props) => <OwnerSelect {...props} />,
  },
  {
    title: t('vehicles.apartmentId'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    sorter: true,
    // searchInput: true,
    render: (_, obj) => (obj.apartment ? obj.apartment.code : undefined),
  },
  {
    title: t('vehicles.occupantName'),
    key: 'occupantName',
    dataIndex: 'occupantName',
    sorter: true,
    searchInput: true,
    render: (_, obj) => obj.occupantName,
  },
  {
    title: t('vehicles.phoneNumber'),
    key: 'phoneNumber',
    dataIndex: 'phoneNumber',
    sorter: true,
    searchInput: true,
    render: (_, obj) => {
      if (obj.type === VehicleType.OWNER) {
        if (!!obj.owner?.phoneNumber) {
          return <PhoneNumberLink phoneNumber={obj.owner.phoneNumber} />;
        }
      } else {
        if (!!obj.phoneNumber) {
          return <PhoneNumberLink phoneNumber={obj.phoneNumber} />;
        }
      }
      return '';
    },
  },
  {
    title: t('vehicles.licensePlateNo.short'),
    key: 'licensePlateNo',
    dataIndex: 'licensePlateNo',
    sorter: true,
    searchInput: true,
    render: (_, obj) => <NoWrap>{obj.licensePlateNo}</NoWrap>,
  },
  {
    title: t('vehicles.brandName'),
    key: 'brandName',
    dataIndex: 'brandName',
    sorter: true,
    searchInput: true,
    render: (_, obj) => obj.brandName,
  },
  {
    title: t('vehicles.model'),
    key: 'model',
    dataIndex: 'model',
    sorter: true,
    searchInput: true,
    render: (_, obj) => obj.model,
  },
  {
    title: t('vehicles.startDate'),
    key: 'startDate',
    dataIndex: 'startDate',
    sorter: true,
    // searchInput: true,
    render: (_, obj) =>
      obj.startDate ? TimestampToDayjsDate(obj.startDate).format('L') : '',
  },
  {
    title: t('vehicles.endDate'),
    key: 'endDate',
    dataIndex: 'endDate',
    sorter: true,
    // searchInput: true,
    render: (_, obj) =>
      obj.endDate ? TimestampToDayjsDate(obj.endDate).format('L') : '',
  },
  {
    title: t('vehicles.marked'),
    key: 'marked',
    dataIndex: 'marked',
    sorter: true,
    render: (_, {marked}) =>
      marked ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : undefined,
    filters: booleanFilters(t),
  },
];

const defaultSorter: SorterResult<any> = {
  columnKey: 'updateDate',
  order: 'descend',
};

export const AdminVehicles: FC = () => {
  const {t, lang} = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const columns = useStateFn(() => getAdminVehiclesColumns(t, lang), [t, lang]);

  const title = 'vehicles';

  return (
    <ContentDiv
      title={total !== undefined ? `${t(title)}: ${total}` : t(title)}
      titleRightComponent={
        <Space>
          {/* <Button
            loading={exportLoading}
            onClick={handleExportClick}
            type="primary"
          >
            {t('general.export')}
          </Button> */}
          <Link to="/admin/vehicle">
            <Button type="primary" icon={<PlusOutlined />} />
          </Link>
        </Space>
      }
    >
      <TableList<VehicleRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        defaultSorter={defaultSorter}
        refreshDate={refreshDate}
        setEditEntity={(v) => navigate(`/admin/vehicle/${v.id}`)}
        onTotalChange={setTotal}
        actionButtonProps={{
          delete: (vehicle) => ({disabled: !canEditVehicle(vehicle)}),
        }}
        customActions={[
          (vehicle) => (
            <VehicleMarkButton vehicle={vehicle} key="mark-vehicle" />
          ),
        ]}
      />
    </ContentDiv>
  );
};
