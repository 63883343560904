import {CheckCircleTwoTone, FileImageOutlined} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import {API, APIClass} from 'aws-amplify';
import {Dayjs} from 'dayjs';
import {saveAs} from 'file-saver';
import JSZip from 'jszip';
import {FC, useMemo, useState} from 'react';
import {
  formatCurrency,
  getDateFormat,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {DayjsDateToTimestamp, TimestampToDayjsDate} from '../../util/date';
import {ApartmentCodeSelect} from '../input/ApartmentCodeSelect';
import {OwnerSelect} from '../input/OwnerSelect';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {ApartmentRecord} from './Apartment';
import {ArticleType, ArticleTypeLabel} from './Article';
import {OrderFabricationStatus, OrderRecord, renderOrderNumber} from './Order';
import {OrderLineRecord} from './OrderLine';
import {OwnerRecord} from './Owner';
import {
  getDefaultSorter,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableListProps,
  TableUpdateModal,
} from './Table';
import {openApiUrlInNewTab} from './util';

const singularRoute = '/card';
const pluralRoute = '/cards';

export const openPhotoFileInNewTab = async (
  api: typeof API,
  cardId: string,
): Promise<void> => openApiUrlInNewTab(api, `/card/${cardId}/photo`);

export enum CardStatus {
  PENDING_PAYMENT = 1,
  PENDING_PRODUCTION,
  PENDING_DELIVERY,
  DELIVERED,
  PENDING_DELIVERY_OR_DELIVERED_BUT_HIDDEN,
}

export interface CardFormValues {
  firstName: string;
  lastName: string;
  isChild: string;
  ownerId: string;
  orderId: string;
  orderLineId: string;
  apartmentId: string;
  articleType: ArticleType;
  count: number;
  fabricatedCount?: number;
  deliveredCount?: number;
  status: CardStatus;
  startDate: number | Dayjs;
}

export interface CardRecord extends CardFormValues {
  id: string;
  order: OrderRecord;
  orderNumber: number;
  orderLine: OrderLineRecord;
  owner: OwnerRecord;
  apartment: ApartmentRecord;
  createDate: number | Dayjs;
  updateDate: number;
}

const cardInitialValues: Partial<CardFormValues> = {};

export const CardForm: FC<TableFormProps<CardRecord>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t, lang} = useTranslation();

  useMemo(() => {
    const {startDate, createDate, ...other} = {
      ...cardInitialValues,
      ...initialValues,
    };
    form.setFieldsValue({
      ...other,
      startDate: startDate ? TimestampToDayjsDate(startDate) : undefined,
      createDate: createDate ? TimestampToDayjsDate(createDate) : undefined,
    });
  }, [form, initialValues]);

  const articleTypeValue = Form.useWatch('articleType', form);
  const isGold = [
    ArticleType.POOL_GOLD,
    ArticleType.POOL_GOLD_ADDITIONAL,
  ].includes(articleTypeValue);

  const statusValue = Form.useWatch('status', form);

  const countValue = Form.useWatch('count', form);
  const fabricatedCountValue = Form.useWatch('fabricatedCount', form);
  const deliveredCountValue = Form.useWatch('deliveredCountValue', form);

  useMemo(() => {
    if (
      fabricatedCountValue &&
      Number(Number(fabricatedCountValue).toFixed(0)) !== fabricatedCountValue
    ) {
      form.setFieldValue(
        'fabricatedCount',
        Number(fabricatedCountValue.toFixed(0)),
      );
    }
  }, [fabricatedCountValue, form]);
  useMemo(() => {
    if (
      deliveredCountValue &&
      Number(Number(deliveredCountValue).toFixed(0)) !== deliveredCountValue
    ) {
      form.setFieldValue(
        'deliveredCount',
        Number(deliveredCountValue.toFixed(0)),
      );
    }
  }, [deliveredCountValue, form]);

  return (
    <Form<CardFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...cardInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            name="createDate"
            label={t('cards.startDate')}
            rules={[{required: true}]}
          >
            <DatePicker format={getDateFormat(lang)} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="articleType" label={t('cards.article')}>
            <Select<ArticleType>
              disabled
              options={Object.entries(ArticleTypeLabel).map(
                ([type, label]) => ({
                  value: Number(type),
                  label: t(label),
                }),
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="order.isRenewal"
            label={t('orders.isRenewal')}
            valuePropName="checked"
            className="force-horizontal"
            style={{marginTop: 35}}
          >
            <Checkbox disabled />
          </Form.Item>
        </Col>
      </Row>

      {isGold ? (
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="lastName"
              label={t('card.lastName')}
              rules={[{required: true}]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="firstName"
              label={t('card.firstName')}
              rules={[{required: true}]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="isChild"
              label={t('card.isChild')}
              valuePropName="checked"
              className="force-horizontal"
              style={{marginTop: 35}}
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <>
          <Form.Item name="lastName" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="firstName" hidden>
            <Input />
          </Form.Item>
        </>
      )}
      <Row>
        <Col span={8}>
          <Form.Item name="count" label={t('cards.count')}>
            <InputNumber disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="fabricatedCount" label={t('cards.fabricatedCount')}>
            <InputNumber
              disabled={statusValue === CardStatus.PENDING_DELIVERY}
              min={0}
              max={countValue}
              step={1}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="deliveredCount"
            label={t('cards.deliveredCount')}
            hidden={statusValue !== CardStatus.PENDING_DELIVERY}
          >
            <InputNumber min={0} max={fabricatedCountValue} step={1} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="orderLineId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="orderId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="orderNumber" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="ownerId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="apartmentId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="articleId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="status" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="startDate" hidden>
        <Input />
      </Form.Item>

      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

const getPendingProductionCardColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<CardRecord> => [
  {
    title: t('cards.startDate'),
    key: 'createDate',
    dataIndex: 'createDate',
    render: (createDate) => {
      if (!createDate) return;
      return TimestampToDayjsDate(createDate).format('L');
    },
    sorter: true,
  },
  {
    title: t('cards.order'),
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    searchInput: true,
    sorter: true,
    render: (_, {order}) => renderOrderNumber(order),
  },
  {
    title: t('orders.isRenewal'),
    key: 'order.isRenewal',
    dataIndex: 'order.isRenewal',
    render: (isRenewal) =>
      isRenewal ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null,
  },
  {
    title: t('card.lastName'),
    key: 'lastName',
    dataIndex: 'lastName',
    sorter: true,
    searchInput: true,
  },
  {
    title: t('card.firstName'),
    key: 'firstName',
    dataIndex: 'firstName',
    sorter: true,
    searchInput: true,
  },
  {
    title: t('cards.apartment'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    render: (_, {apartment}) => apartment?.code,
    sorter: true,
    searchInput: true,
    searchSelect: (props) => <ApartmentCodeSelect {...props} />,
  },
  {
    title: t('cards.article'),
    key: 'articleType',
    dataIndex: 'articleType',
    render: (articleType: ArticleType) => t(ArticleTypeLabel[articleType]),
    sorter: true,
  },
  {
    title: t('cards.count'),
    key: 'count',
    dataIndex: 'count',
    sorter: true,
  },
  {
    title: t('cards.fabricatedCount'),
    key: 'fabricatedCount',
    dataIndex: 'fabricatedCount',
    render: (fabricatedCount = 0) => fabricatedCount,
    sorter: true,
  },
  {
    title: t('cards.remainder'),
    key: 'remainder',
    dataIndex: 'remainder',
    render: (_, {count, fabricatedCount = 0}) => fabricatedCount - count,
  },
  {
    title: t('cards.amount'),
    key: 'amount',
    dataIndex: 'amount',
    render: (_, {order: {netPrice}}) => formatCurrency(netPrice, lang),
    sorter: true,
  },
];

const getPendingDeliveryCardColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<CardRecord> => [
  {
    title: t('cards.startDate'),
    key: 'createDate',
    dataIndex: 'createDate',
    render: (createDate) => {
      if (!createDate) return;
      return TimestampToDayjsDate(createDate).format('L');
    },
    sorter: true,
  },
  {
    title: t('cards.order'),
    key: 'orderNumber',
    dataIndex: 'orderNumber',
    searchInput: true,
    sorter: true,
    render: (_, {order}) => renderOrderNumber(order),
  },
  {
    title: t('orders.isRenewal'),
    key: 'order.isRenewal',
    dataIndex: 'order.isRenewal',
    render: (isRenewal) =>
      isRenewal ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null,
  },
  {
    title: t('orders.owner'),
    key: 'ownerId',
    dataIndex: 'ownerId',
    render: (_, {owner}) => owner?.name,
    sorter: true,
    searchInput: true,
    searchSelect: (props) => <OwnerSelect {...props} />,
  },
  {
    title: t('cards.apartment'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    render: (_, {apartment}) => apartment?.code,
    sorter: true,
    searchInput: true,
    searchSelect: (props) => <ApartmentCodeSelect {...props} />,
  },
  {
    title: t('cards.article'),
    key: 'articleType',
    dataIndex: 'articleType',
    render: (articleType: ArticleType) => t(ArticleTypeLabel[articleType]),
    sorter: true,
  },
  {
    title: t('cards.count'),
    key: 'count',
    dataIndex: 'count',
    sorter: true,
  },
  {
    title: t('cards.fabricatedCount'),
    key: 'fabricatedCount',
    dataIndex: 'fabricatedCount',
    render: (fabricatedCount = 0) => fabricatedCount,
    sorter: true,
  },
  {
    title: t('cards.deliveredCount'),
    key: 'deliveredCount',
    dataIndex: 'deliveredCount',
    render: (deliveredCount = 0) => deliveredCount,
    sorter: true,
  },
  {
    title: t('cards.remainder'),
    key: 'remainder',
    dataIndex: 'remainder',
    render: (_, {fabricatedCount = 0, deliveredCount = 0}) =>
      deliveredCount - fabricatedCount,
  },
];

const parseCardValues = ({
  startDate,
  ...values
}: CardRecord): CardFormValues => ({
  startDate: DayjsDateToTimestamp(startDate),
  ...values,
});

interface CardsProps {
  columns: TableColumnsProp<CardRecord>;
  forcedFilter: TableListProps<CardRecord>['forcedFilter'];
  title: string;
  showExport?: boolean;
  showBulkDeliver?: boolean;
  updateProductionStatus: (
    api: APIClass | null,
    card: CardRecord,
    formValues: CardRecord,
  ) => Promise<void>;
}

const Cards: FC<CardsProps> = ({
  forcedFilter: _forcedFilter,
  title,
  columns: _columns,
  updateProductionStatus,
  showExport = false,
  showBulkDeliver = false,
}) => {
  const api = useApi();
  const {t} = useTranslation();
  // const [messageApi] = message.useMessage();

  const forcedFilter = useStateFn(() => _forcedFilter, [_forcedFilter]);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<CardRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const [photoLoading, setPhotoLoading] = useState(false);
  const handlePhotoFileClick = async (card: CardRecord) => {
    if (!api) return;
    setPhotoLoading(true);
    try {
      openPhotoFileInNewTab(api, card.id);
    } finally {
      setPhotoLoading(false);
    }
  };

  const [exportLoading, setExportLoading] = useState(false);
  const handleExportClick = async () => {
    if (!api) return;
    setExportLoading(true);
    try {
      const {csv, photos} = (await api.get(
        DEFAULT_API_NAME,
        `${pluralRoute}/export/awaitingProduction`,
        {},
      )) as {csv: string; photos: {[key: string]: string}};

      const fileName = `export-${new Date().toISOString().replace(/:/g, '-')}`;

      const zip = new JSZip();
      zip.file(`${fileName}.csv`, csv);
      const photosFolder = zip.folder('photos')!;
      for (const [id, url] of Object.entries(photos)) {
        const res = await fetch(url);
        if (!res.ok) continue;
        const photo = await res.arrayBuffer();

        let extension = '';
        switch (res.headers.get('Content-Type')) {
          case 'image/jpeg':
          case 'image/jpg':
            extension = '.jpg';
            break;
          case 'image/png':
            extension = '.png';
            break;
        }
        photosFolder.file([id, extension].join(''), photo);
      }

      const content = await zip.generateAsync({type: 'blob'});
      saveAs(content, `${fileName}.zip`);
    } finally {
      setExportLoading(false);
    }
  };

  const [bulkDeliverLoading, setBulkDeliverLoading] = useState(false);
  const handleBulkDeliverClick = async () => {
    if (!api) return;
    setBulkDeliverLoading(true);
    try {
      const {count} = (await api.post(
        DEFAULT_API_NAME,
        `${pluralRoute}/bulkDeliverRenewals`,
        {},
      )) as {count: number};

      console.log(count);
      if (count) {
        /* messageApi.open({
          type: 'success',
          content:
            'Cartes ${} à renouveller ont été validées et les propriétaires ont été notifiés',
        }); */
        setRefreshDate(Date.now());
      } else {
        /* messageApi.open({
          content:
            'Aucune carte à renouveller était à livrée, aucune modification a été effectuée',
        }); */
      }
    } finally {
      setBulkDeliverLoading(false);
    }
  };

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: CardForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  const columns = useStateFn(() => _columns, [_columns]);
  const defaultSorter = useStateFn(
    () => getDefaultSorter(columns, 'orderNumber'),
    [columns],
  );

  return (
    <ContentDiv>
      <Row justify="space-between" align="middle">
        <Col>
          <AppTitle level={3} style={{marginTop: 0}}>
            {total ? `${t(title)}: ${total}` : t(title)}
          </AppTitle>
        </Col>
        <Col style={{margin: 'auto 0'}}>
          <Space>
            <Button
              loading={exportLoading}
              onClick={handleExportClick}
              type="primary"
              style={{display: showExport ? '' : 'none'}}
            >
              {t('general.export')}
            </Button>
            <Button
              loading={bulkDeliverLoading}
              onClick={handleBulkDeliverClick}
              type="primary"
              style={{display: showBulkDeliver ? '' : 'none'}}
            >
              {t('cards.bulkDeliverRenewals')}
            </Button>
          </Space>
        </Col>
      </Row>
      <TableList<CardRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        defaultSorter={defaultSorter}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        forcedFilter={forcedFilter}
        customActions={[
          (card) =>
            showExport &&
            [ArticleType.POOL_GOLD, ArticleType.POOL_GOLD_ADDITIONAL].includes(
              card.articleType,
            ) ? (
              <Button
                key="photo"
                icon={<FileImageOutlined />}
                type="default"
                title={t('cards.photo')}
                loading={photoLoading}
                onClick={() => handlePhotoFileClick(card)}
              />
            ) : (
              <></>
            ),
        ]}
        onTotalChange={setTotal}
      />
      <TableCreateModal<CardRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
        parseValues={parseCardValues}
      />
      <TableUpdateModal<CardRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        width={700}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={parseCardValues}
        afterSubmit={async (formValues, card) => {
          try {
            await updateProductionStatus(api, card, formValues);
          } catch (err) {
            console.error(err);
          }
        }}
      />
    </ContentDiv>
  );
};

export const CardsAwaitingProduction: FC = () => {
  const {t, lang} = useTranslation();
  return (
    <Cards
      columns={getPendingProductionCardColumns(t, lang)}
      forcedFilter={{status: [CardStatus.PENDING_PRODUCTION]}}
      showExport
      title="cardsAwaitingProduction.title"
      updateProductionStatus={async (api, card, formValues) => {
        console.log(
          card.fabricatedCount,
          card.count,
          (card.fabricatedCount ?? 0) < card.count,
        );
        if (!api) return;

        if ((card.fabricatedCount ?? 0) < card.count) return;

        const isGold = [
          ArticleType.POOL_GOLD,
          ArticleType.POOL_GOLD_ADDITIONAL,
        ].includes(card.articleType);

        await api.post(DEFAULT_API_NAME, `${singularRoute}/${card.id}`, {
          body: {
            ...card,
            status: isGold
              ? CardStatus.PENDING_DELIVERY_OR_DELIVERED_BUT_HIDDEN
              : CardStatus.PENDING_DELIVERY,
          },
        });

        if (isGold) {
          const [existingDeliveryCard] = ((
            await api.get(DEFAULT_API_NAME, pluralRoute, {
              queryStringParameters: {
                f_orderId: card.orderId,
                f_status: CardStatus.PENDING_DELIVERY,
              },
            })
          )?.entities ?? []) as CardRecord[];

          if (existingDeliveryCard) {
            await api.post(
              DEFAULT_API_NAME,
              `${singularRoute}/${existingDeliveryCard.id}`,
              {
                body: {
                  ...existingDeliveryCard,
                  count:
                    (existingDeliveryCard.count ?? 0) +
                    (card.fabricatedCount ?? 1),
                  fabricatedCount:
                    (existingDeliveryCard.fabricatedCount ?? 0) +
                    (card.fabricatedCount ?? 1),
                },
              },
            );
          } else {
            await api.put(DEFAULT_API_NAME, singularRoute, {
              body: {
                ...parseCardValues(card),
                id: undefined,
                status: CardStatus.PENDING_DELIVERY,
                count: card.fabricatedCount ?? 1,
                fabricatedCount: card.fabricatedCount ?? 1,
              },
            });
          }
        }

        const orderLine: OrderLineRecord = await api.get(
          DEFAULT_API_NAME,
          `/orderLine/${card.orderLineId}`,
          {},
        );
        if (!orderLine) return;
        const order: OrderRecord = await api.get(
          DEFAULT_API_NAME,
          `/order/${orderLine.orderId}`,
          {},
        );
        if (!orderLine) return;

        if (
          !order ||
          order.fabricationStatus === OrderFabricationStatus.AVAILABLE
        )
          return;

        await api.post(DEFAULT_API_NAME, `/order/${order.id}`, {
          body: {
            ...order,
            fabricationStatus: OrderFabricationStatus.AVAILABLE,
          },
        });
      }}
    />
  );
};

export const CardsAwaitingDelivery: FC = () => {
  const {t, lang} = useTranslation();

  return (
    <Cards
      columns={getPendingDeliveryCardColumns(t, lang)}
      forcedFilter={{status: [CardStatus.PENDING_DELIVERY]}}
      title="cardsAwaitingDelivery.title"
      showBulkDeliver
      updateProductionStatus={async (
        api: APIClass | null,
        card: CardRecord,
      ) => {
        console.log(
          card.deliveredCount,
          card.count,
          (card.deliveredCount ?? 0) < card.count,
        );
        if (!api) return;

        if ((card.deliveredCount ?? 0) < card.count) return;

        await api.post(DEFAULT_API_NAME, `${singularRoute}/${card.id}`, {
          body: {
            ...card,
            status: CardStatus.DELIVERED,
          },
        });

        await api.post(
          DEFAULT_API_NAME,
          `/card/validateCardFabrication/${card.id}`,
          {},
        );

        const orderLine: OrderLineRecord = await api.get(
          DEFAULT_API_NAME,
          `/orderLine/${card.orderLineId}`,
          {},
        );
        if (!orderLine) return;
        const order: OrderRecord = await api.get(
          DEFAULT_API_NAME,
          `/order/${orderLine.orderId}`,
          {},
        );
        if (!orderLine) return;

        if (
          !order ||
          order.fabricationStatus === OrderFabricationStatus.DELIVERED
        )
          return;

        await api.post(DEFAULT_API_NAME, `/order/${order.id}`, {
          body: {
            ...order,
            fabricationStatus: OrderFabricationStatus.DELIVERED,
          },
        });
      }}
    />
  );
};
