{
  "general.login": "Login",
  "general.logout": "Log out",
  "general.updateTitle": "Modification",
  "general.update": "Update",
  "general.addTitle": "Add",
  "general.add": "Add",
  "general.yes": "Yes",
  "general.no": "No",
  "general.close": "Close",
  "general.cancel": "Cancel",
  "general.delete": "Delete",
  "general.submit": "Submit",
  "general.notConnected": "Not connected",
  "general.uploadError": "Upload error",
  "general.form.messages.required": "Field \"${label}\" is mandatory",
  "general.form.messages.types_email": "Field \"${label}\" is not a valid email address",
  "general.form.messages.types_number": "Field \"${label}\" is not a valid number",
  "general.form.messages.number_range": "Field \"${label}\" must be between ${min} and ${max}",
  "general.connectionError": "Connection error",
  "general.timeIntervalAgo": "${interval} ago",
  "general.loginAction": "I already have an account",
  "general.registerAction": "I create my account",
  "general.upload": "Upload",
  "general.invalidUploadFileType": "Invalid file type",
  "general.clearFilters": "Clear filters",
  "general.clearSorters": "Clear sorter",
  "general.reset": "Reset",
  "general.search": "Search...",
  "general.searchSomething": "Search ${label}...",
  "general.ok": "OK",
  "general.goBackHome": "Back to Home",
  "general.notFoundErrorMessage": "Page not found",
  "general.starMarksMandatory": "* fields are mandatory",
  "general.export": "Export",
  "general.continue": "Continue",
  "general.createDate": "Create date",
  "general.updateDate": "Update date",

  "general.overstaturationError.message1": "Due to a saturated network, orders for the white swimming pool are temporarily suspended.",
  "general.overstaturationError.message2": "We apologize for the inconvenience caused and invite you to renew the operation within 24/48 hours.",

  "general.sumupCheckError.message1": "The payment of white card orders in CB is currently suspended on this application for technical problems.",
  "general.sumupCheckError.message2": "You can place your orders by making a transfer. To do this, register your order with the payment method Transfer. You can also send us a check:",
  "general.sumupCheckError.message3": "ASCAPE - Résidence du Belvédère - Boite N° 6 - 99 rue des Calanques - 83530 SAINT RAPHAEL",
  "general.sumupCheckError.message4": "The cards will be made after receipt of payment.",
  "general.sumupCheckError.message5": "Tolerance instructions have been given to beach attendants at the entrance to the Grand Bleu swimming pool in order to allow access with the old cards to owners and tenants during this period.",
  "general.sumupCheckError.message6": "We apologize for the inconvenience caused.",

  "general.duration.days": "Days",
  "general.duration.weeks": "Weeks",
  "general.duration.months": "Months",
  "general.duration.years": "Years",

  "general.error.negativeDuration": "Start date must start before end date",

  "general.ownersArea": "Owners area",
  "footer.theVillage": "The site",
  "footer.theVillage.visit": "Visit",
  "footer.theVillage.news": "News",
  "footer.theVillage.findAccomodation": "Find accommodation",
  "footer.theVillage.shopsAndServices": "Shops and services",
  "footer.theArea": "The area",
  "footer.theArea.events": "Events",
  "footer.theArea.ourSelection": "CAP ESTEREL and its surroundings",
  "footer.theArea.infoAndResources": "Information and Resources",
  "footer.ownersArea": "Owners area",
  "footer.ownersArea.swimmingPoolOrParkingCards": "Pool or parking cards",
  "footer.ownersArea.administrativeDocuments": "Administrative documents",
  "footer.ownersArea.makeAStatement": "Make a statement",
  "footer.ownersArea.news": "News",
  "footer.ourNewsletter": "Our newsletter",
  "footer.yourEmail": "Your email",
  "footer.newsletterSignup": "Subscribe",
  "footer.newsletterConfidentialyText": "I accept the privacy policy.",
  "footer.legalNotice": "Legal Notice",
  "footer.cookiePolicy": "Cookies Policy",
  "footer.privacyPolicy": "Privacy Policy",
  "footer.termsOfSales": "Terms of Sales",

  "header.theVillage": "The village",
  "header.activities": "Activities",
  "header.tourism": "Tourism",
  "header.news": "News",
  "header.contact": "Contact",
  "header.owner.documents": "Documents",
  "header.owner.info": "Information",
  "header.owner.news": "News",
  "header.owner.orderTracking": "Order tracking",
  "header.owner.myAccount": "My account",
  "header.owner.myVehicles": "My vehicles",
  "header.owner.myOccupants": "My rentals",
  "header.admin.settings": "Settings",
  "header.admin.apartments": "Apartments",
  "header.admin.owners": "Owners",
  "header.admin.orders": "Orders",
  "header.admin.invoices": "Invoices",
  "header.admin.payments": "Payments",
  "header.admin.entries": "Entries",
  "header.admin.stats": "Statistics",

  "home.welcome": "Welcome",
  "home.user": "User",
  "home.notConnected": "Not connected",
  "home.users": "Users",
  "home.owners": "Owners",
  "home.ownerRequests": "Accounts awaiting verification",
  "home.apartments": "Apartments",
  "home.hosts": "Hosts",
  "home.articles": "Articles",

  "home.ordersUnavailable": "The order module for swimming pool and parking cards is in its test phase, and will soon be open.\nThank you for your understanding",

  "home.settings": "Settings",
  "home.stats": "Stats",
  "home.orders": "Orders",
  "home.invoices": "Invoices",
  "home.payments": "Payments",
  "home.accountingEntries": "Entries",
  "home.apartmentRequests": "Apartment requests",
  "home.cardsAwaitingProduction": "Cards pending prod",
  "home.cardsAwaitingDelivery": "Cards pending delivery",
  "home.cardsGold": "Gold cards",
  "home.vehicles": "Vehicles",
  "home.occupants": "Rentals",
  "home.superAdmin": "Super admin",

  "home.unconfirmed.title": "Account confirmation",
  "home.unconfirmed.message1": "Please validate your email address by clicking on the link in the email you received.",
  "home.unconfirmed.message2": "Goodbye",

  "home.unvalidated.title": "New account verification",
  "home.unvalidated.message1": "We have received your account creation request.",
  "home.unvalidated.message2": "It is being validated by our services.",
  "home.unvalidated.message3": "A confirmation email will be sent to you shortly.",
  "home.unvalidated.message4": "",
  "home.unvalidated.message5": "",
  "home.unvalidated.message6": "Goodbye",

  "login.title": "Login",
  "login.loginActionButton": "Login",
  "login.error.pleaseInputUsername": "Please input your username",
  "login.username": "Username",
  "login.error.pleaseInputPassword": "Please input your password",
  "login.password": "Password",
  "login.forgotPassword": "Forgot password",
  "login.home": "Home",
  "login.newPasswordTitle": "New password",
  "login.newPasswordText": "Please enter the verification code sent to you by email.",
  "login.newPassword": "New password",
  "login.newPasswordConfirm": "Password validation",
  "login.forcePasswordTitle": "Password",
  "login.forcePasswordText": "Please choose a password.",

  "users.title": "Users",
  "users.email": "Email",
  "users.name": "Name",
  "users.firstName": "First name",
  "users.lastName": "Last name",
  "users.userTitle": "Title",
  "users.userTitleMr": "Mr",
  "users.userTitleMrs": "Mrs",
  "users.userTitleMrAndMrs": "Mr and Mrs",
  "users.role": "Role",
  "users.roleNotVerified": "Not verified",
  "users.roleUnvalidated": "Not validated",
  "users.roleOwner": "Owner",
  "users.roleAdmin": "Admin",
  "users.wordpressUserId": "WordPress user ID",
  "users.disabled": "Disabled",
  "users.cognitoStatus": "Cognito status",
  "users.cognitoGroups": "Cognito groups",
  "users.resetPasswordAndSendEmail": "Reset password and send email",
  "users.changeEmailAndResetPassword": "Change email + reset password",
  "users.changeEmailAndResetPassword.info": "This will change the email address associated with the account and then initiate a password reset. An email will be sent to the user with their temporary password.",
  "users.updateEmail": "Change email address",
  "users.previousEmail": "Existing Email",
  "users.warning.updateEmail": "Please verify that the user has access to this new email address before modifying it.\nIt will not be verified by the application, and the user will not immediately be able to log in with their old username",
  "users.updateEmail.email.subject": "Change email address",
  "users.updateEmail.email.body": "Hello,\n\nI would like to update the email address associated with my ${email} account\nMy new email address is as follows: \n\nRegards,\n${name}",

  "owners.title": "Owners",
  "owners.code": "Code",
  "owners.displayRecipientFields": "Display recipient fields (orders)",
  "owners.phoneNumber": "Phone number",

  "hosts.title": "Hosts",
  "hosts.name": "Name",
  "hosts.thirdParty": "Third party",
  "hosts.address": "Address",
  "hosts.contactName": "Contact",
  "hosts.contactPhoneNumber": "Phone number",
  "hosts.email": "Email",
  "hosts.comment": "Comment",

  "apartments.title": "Apartments",
  "apartments.code": "Code",
  "apartments.building": "Building",
  "apartments.type": "Type",
  "apartments.doorNumber": "Door no",
  "apartments.beddings": "Beddings",
  "apartments.host": "Host",
  "apartments.owner": "Owner",
  "apartments.condo": "Condo",
  "apartments.comment": "Comment",

  "apartments.unassign": "Unassign",
  "apartments.unassign.confirm": "Are you sure you want to unassign this apartment from this owner?",

  "apartments.error.alreadyOwned": "This apartment is already assigned to another owner",
  "apartments.error.alreadyOwnedBySelf": "This apartment is already assigned to this owner",

  "articles.title": "Articles",
  "articles.code": "Code",
  "articles.label": "Label",
  "articles.type": "Type",
  "articles.season": "Season",
  "articles.seasonStart": "Season start",
  "articles.seasonEnd": "Season end",
  "articles.validityDuration": "Validity duration",
  "articles.validityDurationType": "Validity unit",
  "articles.unitPriceInclVAT": "Unit price incl. VAT",
  "articles.VAT": "VAT",
  "articles.accountingCode": "Accounting code",
  "articles.active": "Active on web",
  "articles.limitToBeddingQuota": "Limit to bedding quota",
  "articles.beddingQuota": "Beddings",
  "articles.isBlanche": "Carte blanche",
  "articles.accountingCategory": "Category",

  "article.type.parking": "Parking",
  "article.type.poolBlanche": "Pool Blanche",
  "article.type.poolGold": "Pool Or",
  "article.type.poolGoldAdditional": "Pool Or Add.",
  "article.type.lostParking": "Lost parking",
  "article.type.lostPool": "Lost pool",
  "article.type.parkingClone": "Parking Clone",
  "article.type.misc": "Miscellaneous",
  "article.accountingCategory.parking": "Parking",
  "article.accountingCategory.pool": "Pool",

  "article.inactive.ownerInvisible": "This article type is not shown to owners",
  "article.inactive.season": "This article is attached to an inactive season and will not be shown to owners",

  "register.title": "Register",
  "register.lastName": "Last name",
  "register.firstName": "First name",
  "register.title_gender": "Title",
  "register.address": "Address (primary\u00A0residence)",
  "register.zipCode": "Zip code",
  "register.city": "City",
  "register.country": "Country",
  "register.phoneNumber": "Phone number",
  "register.condoBuildingApartmentCode": "Condo/Building/Door",
  "register.condo": "Condo",
  "register.building": "Building",
  "register.apartmentCode": "Door",
  "register.email": "Email",
  "register.password": "Password",
  "register.passwordValidation": "Password validation",
  "register.submit": "Submit",
  "register.passwordValidationReject": "The two passwords that you entered do not match!",
  "register.mustBeInternational": "Phone number must start with a +XX international identifier",
  "register.invalidPhoneNumber": "Phone number must be valid",
  "register.verificationCode": "Verification code",
  "register.pleaseEnterVerificationCode": "Please enter the verification code sent to you by email.",
  "register.condoBuildingApartmentMessage": "(Information found on your statement of charges)",

  "register.password.minLength": "Your password must be at least ${minLength} characters long",
  "register.password.lower": "Your password must contain a lower case character",
  "register.password.upper": "Your password must contain an upper case character",
  "register.password.digit": "Your password must contain a digit character",
  "register.password.symbol": "Your password must contain a special character",

  "register.signUpError": "Sign up error",
  "register.code": "Code",

  "cognito.error.UserNotFoundException": "User not found",
  "cognito.error.UsernameExistsException": "Email already exists for an existing user",
  "cognito.error.NotAuthorizedException": "Access denied",
  "cognito.error.CodeMismatchException": "The verification code is invalid",
  "cognito.error.UserNotConfirmedException": "Your email account has not been verified",

  "register.success.completeNewPassword": "Your password was successfully changed. Please log in with your new password.",
  "owner.error.cannotDeleteIfApartments": "Cannot delete owner if they have apartments assigned to them. Please unassign them first",

  "ownerRequests.title": "Accounts awaiting verification",
  "ownerRequests.fullName": "Full name",
  "ownerRequests.lastName": "Last name",
  "ownerRequests.firstName": "First name",
  "ownerRequests.title_gender": "Title",
  "ownerRequests.address": "Address",
  "ownerRequests.zipCode": "Zip code",
  "ownerRequests.city": "City",
  "ownerRequests.country": "Country",
  "ownerRequests.phoneNumber": "Phone number",
  "ownerRequests.email": "Email",
  "ownerRequests.apartment": "Apartment",
  "ownerRequests.createDate": "Creation date",

  "ownerRequest.validate": "Validate",
  "ownerRequest.validateWarning": "Are you sure you want to create this owner? The owner will be notified via email",
  "ownerRequest.validateError": "The apartment linked to this request is already associated to a verified owner",

  "orders.title": "Orders",
  "orders.newOrder": "New order",
  "orders.orderNumber": "Order number",
  "orders.owner": "Owner",
  "orders.orderedCards": "Ordered cards",
  "orders.deliveredCards": "Delivered cards",
  "orders.totalPrice": "Total price w/VAT",
  "orders.status": "Status",
  "orders.accountingStatus": "Accounting status",
  "orders.fabricationStatus": "Fabrication status",
  "orders.articleQuantity": "Quantity",
  "orders.createDate": "Buy date",
  "orders.createDate.short": "Date",
  "orders.apartment": "Apartment",
  "orders.article": "Article",
  "orders.season": "Season",
  "orders.articleType": "Article type",
  "orders.validityStart": "Validity date start",
  "orders.validityEnd": "Validity date end",
  "orders.comment": "Comment",
  "orders.paymentType": "Payment type",
  "orders.isProxy": "Proxy",
  "orders.isProxy.form": "I give a proxy to a third party",
  "orders.proxy": "Proxy full name",
  "orders.proxyTemplateDownload": "Download proxy PDF to fill out",
  "orders.proxyFile": "Filled out proxy PDF",
  "orders.type": "Type",
  "orders.discount": "Discount",
  "orders.netPrice": "Net price",
  "orders.checkoutId": "Checkout ID",
  "orders.checkNumber": "Check number",
  "orders.isRenewal": "Card to be renewed",
  "orders.isRenewal.short": "To be renewed",
  "orders.iHaveAClone": "I have a clone",
  "orders.recipient": "Recipient",
  "orders.recipientName": "Name (recipient)",
  "orders.recipientAddr": "Address (recipient)",
  "orders.recipientZipCodeAndCity": "Zip code - City (recipient)",
  "orders.recipientZipCode": "Zip code",
  "orders.recipientCity": "City",
  "orders.recipientCountry": "Country (recipient)",
  "orders.nbGoldCards": "Nb of gold cards",
  "orders.renewalCardNo": "Enter the number or last name/first name of all cards to be renewed, separated by a comma",
  "orders.renewalCardNo.short": "Cards to be renewed",
  "orders.renewalCardNo.gold": "Enter the name of the people or the number of the gold cards to be renewed in the table on the right",

  "orders.regeneratePDF.confirm": "Are you sure you want to regenerate PDF for this order? This will erase current PDF (if any).",
  "orders.regeneratePDF.success": "PDF has been regenerated successfully",
  "orders.regeneratePDF.error": "An error occurred while regenerating the PDF.",

  "orders.type.invoice": "Invoice",
  "orders.type.credit": "Credit",

  "orders.accountingStatus.submitted": "Submited",
  "orders.accountingStatus.printed": "Printed",
  "orders.accountingStatus.paid": "Paid",
  "orders.accountingStatus.accounted": "Accounted",
  "orders.accountingStatus.failed": "Failed",

  "orders.fabricationStatus.submitted": "Submited",
  "orders.fabricationStatus.paid": "Paid",
  "orders.fabricationStatus.available": "Available",
  "orders.fabricationStatus.delivered": "Delivered",

  "order.paymentType.paypal": "PayPal",
  "order.paymentType.sumup": "Credit card (SumUp)",
  "order.paymentType.wire": "Wire transfer",
  "order.paymentType.check": "Check",
  "order.paymentType.cash": "Cash",
  "order.paymentType.pos": "POS",
  "order.paymentType.soge": "Credit card",
  "orders.payment.wireInstructions": "Bank details",
  "orders.payment.iban": "IBAN",
  "orders.payment.bic": "BIC",
  "orders.payment.checkOrder": "Order",
  "orders.payment.checkAddress": "Address",
  "settings.poolIban": "Pool IBAN",
  "settings.poolBic": "Pool BIC",
  "settings.parkingIban": "Parking IBAN",
  "settings.parkingBic": "Parking BIC",
  "orders.payment.checkInstructions": "Check details",
  "settings.checkOrder": "Order",
  "settings.checkAddress": "Address",
  "settings.disableOrderCreationOnClientPortal": "Disable order creation on client portal",

  "orders.securePayment": "Secure payment",

  "order.modal.title": "Confirm order",
  "order.modal.confirm": "Confirm with payment obligation",
  "order.modal.summary": "Summary",
  "orders.confirm.terms.prefix": "I have read and agree to the ",
  "orders.confirm.terms.link": "terms of sale",
  "orders.confirm.terms.suffix": "",

  "order.submitSuccess": "Your order has been successfully submitted",

  "order.error.noArticles": "No articles available for this appartment/season. Please contact our support",

  "orderLines.title": "Order lines",

  "orderLines.article": "Article",
  "orderLines.unitPrice": "Unit price",
  "orderLines.quantity": "Quantity",
  "orderLines.VAT": "VAT",
  "orderLines.totalPrice": "Total price",
  "orderLines.discount": "Discount",
  "orderLines.netPrice": "Net price",
  "orderLines.customNetPrice": "Manual price",

  "card.create": "Add personalized card",
  "card.upload.warning": "Only recognizable photos (without hat, without sunglasses, etc.)",

  "cards.title": "Cards",
  "card.lastName": "Last name",
  "card.firstName": "First name",
  "card.isChild": "Child < 6 yo",
  "card.cost": "Amount",
  "card.photo": "Photo",

  "seasons.title": "Seasons",
  "seasons.code": "Code",
  "seasons.dateStart": "Start date",
  "seasons.dateEnd": "End date",
  "seasons.active": "Active on web",

  "confirm.title": "Confirm",
  "confirm.email": "Email",
  "confirm.code": "Code",
  "confirm.submit": "Confirm",

  "settings.title": "Settings",
  "settings.nextOrderId": "Next order number",
  "settings.nextInvoiceId": "Next invoice number",
  "settings.nextThirdPartyId": "Next third party number",
  "settings.vatRate": "VAT rate",
  "settings.bankAccountClient": "Customer account",
  "settings.bankAccountSales": "Sales account",
  "settings.bankAccountVat": "VAT account",
  "settings.bankAccountBank": "Bank account",
  "settings.journalSales": "Sales Journal",
  "settings.journalBank": "Bank Journal",
  "settings.iban": "IBAN",
  "settings.bic": "BIC",
  "settings.settingsTab": "Settings / Hosts",
  "settings.articlesTab": "Articles / Seasons",
  "settings.adminUsersTab": "Admin users",
  "settings.adminAllUsersTab": "Users (all)",

  "apartmentRequests.title": "Apartment request",
  "apartmentRequests.requestTitle": "New appartment request",
  "apartmentRequests.fullName": "Owner",
  "apartmentRequests.email": "Owner email",
  "apartmentRequests.code": "Code",
  "apartmentRequests.createDate": "Create date",

  "apartmentRequests.alreadyOwned": "This apartment is already associated to your account",
  "apartmentRequests.alreadyRequested": "This apartment is already pending verification",

  "apartmentRequests.validateWarning": "Are you sure you want link this apartment to that owner? The owner will be notified via email",
  "apartmentRequests.validateError": "The apartment linked to this request is already associated to another owner",

  "creditCard.title": "Credit card",
  "creditCard.name": "Holder name",
  "creditCard.number": "Card number",
  "creditCard.expiryMonth": "Expiration month",
  "creditCard.expiryYear": "Expiration year",
  "creditCard.cvv": "Security code",

  "creditCard.error.invalidCC": "Invalid card number",
  "creditCard.error.expiredCC": "Expired card",
  "creditCard.error.invalidCVC": "Invalid security code",

  "checkoutResult.pending": "Checkout pending verification",

  "checkoutResult.notFound.title": "Checkout not found",
  "checkoutResult.notFound.subTitle": "We were unable to retrieve the checkout information of your order. Please contact our support for more information",

  "checkoutResult.notCompleted.title": "Checkout was cancelled or failed",
  "checkoutResult.notCompleted.subTitle": "You were not charged for your order. Please try again or contact our support for more information",

  "checkoutResult.success.title": "Checkout success",
  "checkoutResult.success.subTitle": "Your payment was successfully recieved, and your order will be processed soon",

  "invoices.title": "Invoices",
  "invoices.createDate": "Create date",
  "invoices.invoicedName": "Name",
  "invoices.amount": "Amount",
  "invoices.invoiceNumber": "Invoice number",
  "invoices.status": "Status",
  "invoices.type": "Type",

  "payments.title": "Payments",
  "payments.type": "Type",
  "payments.paymentDate": "Payment date",
  "payments.payerName": "Name",
  "payments.label": "Label",
  "payments.amount": "Amount",
  "payments.invoiceNumber": "Invoice number",
  "payments.isAccounted": "Accounting",
  "payments.updateOrderStatus": "This payment pays order N°${orderNumber}",

  "payments.warn.unexpectedOrderStatus": "Order ${orderNumber} is no longer pending, its current status is \"${orderStatus}\"",
  "payments.warn.unexpectedPaymentAmount": "The order ${orderNumber} has a total amount of ${orderAmount}, but the current payment is ${paymentAmount}",
  "payments.warn.unexpectedInvoiceOrderNumber": "The order associated with the invoice is different from the order associated with the payment",

  "payments.error.amountMustBePositiveAndGreaterThanZero": "The payment amount must be strictly greater than 0.00",

  "cardsAwaitingProduction.title": "Cards pending prod",
  "cardsAwaitingDelivery.title": "Cards pending delivery",

  "cards.startDate": "Date",
  "cards.order": "Order",
  "cards.name": "Name",
  "cards.apartment": "Apartment",
  "cards.article": "Article",
  "cards.count": "Ordered card count",
  "cards.fabricatedCount": "Fabricated card count",
  "cards.deliveredCount": "Delivered card count",
  "cards.remainder": "Remainder",
  "cards.amount": "Amount",
  "cards.photo": "Photo",

  "cards.bulkDeliverRenewals": "Deliver all cards to be renewed",

  "stats.title": "Statistics",
  "stats.cognitoStats": "User Stats",
  "stats.cognito.total": "Registered Users",
  "stats.cognito.pending": "Users who have changed their password",
  "stats.salesStats": "Sales stats",
  "stats.offSeason": "Off season?",
  "stats.card.goldSeasonalExport": "Delivered Gold seasonal export",

  "entries.title": "Entries",
  "entries.export": "Entries export",
  "entries.exportStartDate": "Invoice start date",
  "entries.exportEndDate": "Invoice end date",

  "cardsGold.title": "Gold cards",

  "vehicles": "Vehicles",
  "vehicles.title": "Vehicles",
  "vehicles.createDate": "Creation date",
  "vehicles.updateDate": "Modification date",
  "vehicles.date": "Date",
  "vehicles.active": "Active",
  "vehicles.marked": "Marked",
  "vehicles.type": "Type",
  "vehicles.type.owner": "Owner",
  "vehicles.type.occupant": "Tenant",
  "vehicles.ownerId": "Owner",
  "vehicles.apartmentId": "Apartment",
  "vehicles.brandName": "Brand",
  "vehicles.model": "Model",
  "vehicles.licensePlateNo": "License plate",
  "vehicles.licensePlateNo.short": "Plate",
  "vehicles.occupantName": "Tenant name",
  "vehicles.phoneNumber": "Phone number",
  "vehicles.dates": "Dates",
  "vehicles.startDate": "Start date",
  "vehicles.endDate": "End date",
  "vehicles.error.already_three_vehicles_for_this_owner": "There are already 3 active vehicles for this owner.",
  "vehicles.error.already_one_vehicle_for_this_period": "There is already a vehicle for this period.",
  "vehicles.newOccupant": "New vehicle (rental)",
  "vehicles.newVehicle": "New vehicle"
}
