import {
  Alert,
  Button,
  Col,
  Form,
  FormInstance,
  FormItemProps,
  Input,
  Row,
  Select,
} from 'antd';
import {FC, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from '../translation';
import {DEFAULT_API_NAME, useApi} from '../util/Auth';
import {
  fetchApartments,
  fetchBuildings,
  fetchCondos,
} from '../util/cache/apartment';
import {ApartmentCodeSelect} from './input/ApartmentCodeSelect';
import {ApartmentRecord} from './table/Apartment';
import {HostRecord} from './table/Host';
import {TableFormProps} from './table/Table';
import {useRequest} from 'ahooks';

export interface CachedApartmentCodeFormItemProps extends FormItemProps {
  form: FormInstance;
}

export const CachedApartmentCodeFormItem: FC<
  CachedApartmentCodeFormItemProps
> = ({form, ...props}) => {
  const {t} = useTranslation();

  const [apartmentsLoading, setApartmentsLoading] = useState(false);

  const [condos, setCondos] = useState<string[]>();
  const [buildings, setBuildings] = useState<string[]>();
  const [apartments, setApartments] = useState<string[]>();

  useMemo(() => {
    (async () => {
      setApartmentsLoading(true);
      const condos = await (await fetchCondos()).json();
      setApartmentsLoading(false);

      setCondos(condos);
    })();
  }, []);

  useMemo(() => {
    (async () => {
      setApartmentsLoading(true);
      const condos = await (await fetchCondos()).json();
      setApartmentsLoading(false);

      setCondos(condos);
    })();
  }, []);

  const handleCondoChange = async () => {
    const {condo} = form.getFieldsValue();

    setApartmentsLoading(true);
    const buildings = await (await fetchBuildings(condo)).json();
    setApartmentsLoading(false);

    setBuildings(buildings);
    setApartments([]);
  };

  const handleBuildingChange = async () => {
    const {condo, building} = form.getFieldsValue();

    setApartmentsLoading(true);
    const apartments = await (await fetchApartments(condo, building)).json();
    setApartmentsLoading(false);

    setApartments(apartments);
  };

  return (
    <Form.Item
      label={t('register.condoBuildingApartmentCode')}
      style={{marginBottom: 0}}
      required
      {...props}
    >
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item name="condo" rules={[{required: true}]}>
            <Select
              placeholder={t('register.condo')}
              options={condos?.map((condo) => ({
                value: condo,
                label: condo,
              }))}
              loading={apartmentsLoading}
              onChange={() => {
                handleCondoChange();
                form.resetFields(['building', 'apartmentCode']);
              }}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="building" rules={[{required: true}]}>
            <Select
              placeholder={t('register.building')}
              options={buildings?.map((building) => ({
                value: building,
                label: building,
              }))}
              disabled={!buildings?.length}
              loading={apartmentsLoading}
              onChange={() => {
                handleBuildingChange();
                form.resetFields(['apartmentCode']);
              }}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="apartmentCode" rules={[{required: true}]}>
            <Select
              placeholder={t('register.apartmentCode')}
              options={apartments?.map((code) => ({
                value: code,
                label: code,
              }))}
              disabled={!apartments?.length}
              loading={apartmentsLoading}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export interface AssignApartmentFormValues {
  hostId?: string;
  ownerId: string;
  apartmentId: string;

  condo?: string;
  building?: string;
  doorNumber?: string;
  beddings?: string;
}

export interface AssignApartmentFormProps
  extends TableFormProps<AssignApartmentFormValues> {
  onApartmentChange?: (apartment: ApartmentRecord | undefined) => void;
  ownerId?: string;
}

export const AssignApartmentForm: FC<AssignApartmentFormProps> = ({
  form,
  onFinish,
  ownerId,
  onApartmentChange,
}) => {
  const api = useApi();
  const {t} = useTranslation();
  const [assignApartment, setAssignApartment] = useState<ApartmentRecord>();

  const fetchHosts = async (): Promise<HostRecord[] | undefined> => {
    if (!api) return;
    return (await api.get(DEFAULT_API_NAME, '/hosts', {}))?.entities ?? [];
  };
  const {data: hostsData, loading: hostsLoading} = useRequest(fetchHosts, {
    refreshDeps: [api],
  });

  return (
    <Form<AssignApartmentFormValues>
      form={form}
      name="assign-apartment"
      className="assign-apartment-form"
      onFinish={onFinish}
    >
      <Form.Item name="hostId" label={t('apartments.host')}>
        <Select
          options={hostsData?.map(({id, name}) => ({
            value: id,
            label: name,
          }))}
          loading={hostsLoading}
          allowClear
        />
      </Form.Item>

      <Form.Item
        name="apartmentId"
        label={t('apartments.code')}
        rules={[{required: true}]}
      >
        <ApartmentCodeSelect
          onApartmentChange={(apartment) => {
            setAssignApartment(apartment);
            onApartmentChange?.(apartment);
            if (!apartment) {
              form.resetFields([
                'condo',
                'building',
                'doorNumber',
                'beddings',
                'host',
              ]);
              return;
            }

            form.setFieldsValue({
              condo: apartment.condo,
              building: apartment.building,
              doorNumber: apartment.doorNumber,
              beddings: apartment.beddings.toString(),
              hostId: apartment.hostId,
            });
          }}
        />
      </Form.Item>
      {ownerId &&
      assignApartment?.ownerId &&
      assignApartment?.ownerId !== ownerId ? (
        <Alert
          type="error"
          description={
            <span>
              {t('apartments.error.alreadyOwned')}
              <br />
              <Link to={`/admin/owner/${assignApartment.ownerId}`}>
                {assignApartment.owner?.user.lastName}{' '}
                {assignApartment.owner?.user.firstName}
              </Link>
            </span>
          }
          style={{marginBottom: 10}}
        />
      ) : null}
      {ownerId && assignApartment?.ownerId === ownerId ? (
        <Alert
          type="error"
          description={t('apartments.error.alreadyOwnedBySelf')}
          style={{marginBottom: 10}}
        />
      ) : null}

      <Form.Item name="condo" label={t('apartments.condo')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="building" label={t('apartments.building')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="doorNumber" label={t('apartments.doorNumber')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="beddings" label={t('apartments.beddings')}>
        <Input disabled />
      </Form.Item>

      <Form.Item name="userId" label="userId" hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};
