import {Form, Input, message, Modal} from 'antd';
import {APIClass} from 'aws-amplify';
import {FC, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {FormSubmitButton} from '../FormSubmitButton';

const fetchUserWPInfo = async (
  api: APIClass | null,
  email: string,
): Promise<any | undefined> => {
  if (!api) return;

  const res = await api.get(
    DEFAULT_API_NAME,
    `/admin/checkIfWPUserExists/${email}`,
    {},
  );

  if (!res) {
    throw new Error('Impossible de récupérer les infos WP');
  }

  if (!!res.error) {
    throw new Error(res.error);
  }

  return res;
};

export const SuperAdminWP: FC = (props) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState<any>();

  return (
    <div>
      <Form
        layout="inline"
        onFinish={async (values) => {
          if (loading || !values.email) {
            return;
          }

          setError('');
          setLoading(true);
          try {
            const info = await fetchUserWPInfo(api, values.email);
            setInfo(info);
          } catch (err: any) {
            message.warning(err.message);
            setInfo(undefined);
            setError(err.message);
          }
          setLoading(false);
        }}
      >
        <Form.Item
          label="Email utilisateur"
          name="email"
          rules={[{required: true}]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item>
          <FormSubmitButton loading={loading}>
            Vérifier si l'utilisateur existe sur Wordpress
          </FormSubmitButton>
        </Form.Item>
      </Form>

      {error}

      {info ? JSON.stringify(info) : undefined}
    </div>
  );
};

const fetchRecreateWPUser = async (
  api: APIClass | null,
  userId: string,
): Promise<any | undefined> => {
  if (!api) return;

  const res = await api.post(
    DEFAULT_API_NAME,
    `/admin/recreateWPUser/${userId}`,
    {},
  );

  if (!res) {
    throw new Error(`Impossible de re-créer l'utilisateur Wordpress`);
  }

  if (!!res.error) {
    throw new Error(res.error);
  }

  return res;
};

export const SuperAdminWPRecreateUser: FC = (props) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState<any>();

  return (
    <div>
      <Form
        layout="inline"
        onFinish={async (values) => {
          if (loading || !values.id) {
            return;
          }

          Modal.confirm({
            title: `Re-créer le propriétaire sur Wordpress ?`,
            content: (
              <p>
                L'utilisateur ne sera pas re-créé si un utilisateur avec la même
                adresse email existe déjà. Attention: fonctionnalité seulement
                pour les propriétaires !
              </p>
            ),
            onOk: async () => {
              setError('');
              setLoading(true);
              try {
                const info = await fetchRecreateWPUser(api, values.id);
                setInfo(info);
              } catch (err: any) {
                message.warning(err.message);
                setInfo(undefined);
                setError(err.message);
              }
              setLoading(false);
            },
          });
        }}
      >
        <Form.Item
          label="ID utilisateur (interne)"
          name="id"
          rules={[{required: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <FormSubmitButton loading={loading}>
            Re-créer le propriétaire sur Wordpress...
          </FormSubmitButton>
        </Form.Item>
      </Form>

      {error}

      {info ? JSON.stringify(info) : undefined}
    </div>
  );
};
