import {Badge, Col, Row} from 'antd';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {TranslateFn, useTranslation} from '../translation';
import {useConnectedUser} from '../util/Auth';
import {ContentDiv} from '../component/layout/ContentDiv';
import {LogoutButton} from '../component/LogoutButton';

import settings from '../images/icons/00_parametres.svg';
import car from '../images/icons/car.png';
import orders from '../images/icons/04_commande.svg';

import styled from '@emotion/styled';
import {AppTitle} from '../util/AppTitle';

interface OwnerModule {
  key: string;
  route?: string;
  label: string;
  icon: string;
  badgeCount?: number;
}

const getModules = (t: TranslateFn): OwnerModule[] => [
  {
    key: 'orders',
    route: '/owner/orders',
    label: t('home.orders'),
    icon: orders,
  },
  {
    key: 'vehicles',
    route: '/owner/vehicles',
    label: t('header.owner.myVehicles'),
    icon: car,
  },
  {
    key: 'occupants',
    route: '/owner/occupants',
    label: t('header.owner.myOccupants'),
    icon: car,
  },
  {
    key: 'owner-profile',
    route: '/owner/profile',
    label: t('header.owner.myAccount'),
    icon: settings,
  },
];

const OwnerLink = styled(Link)`
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    img {
      filter: grayscale(1) brightness(0);
    }
  }
  &:not(:hover) img {
    filter: grayscale(1) brightness(0);
  }
  &:not(.disabled):hover h4 {
    color: #c58a4e;
  }
`;

export const OwnerHome: FC = () => {
  const user = useConnectedUser();
  const {t} = useTranslation();

  const modules = getModules(t);

  return (
    <ContentDiv
      title={t('home.welcome')}
      titleRightComponent={<LogoutButton />}
    >
      {/* <p>
        {t('home.user')}:{' '}
        {user?.attributes?.email
          ? user.attributes.email
          : t('home.notConnected')}
      </p> */}
      {/* <p>Bienvenue {user.attributes?.name} sur votre espace propriétaire.</p> */}
      <p></p>
      <Row gutter={20}>
        {modules.map(({key, route, label, icon, badgeCount}) => (
          <Col span={6} key={key} style={{marginBottom: 35}}>
            <Badge count={badgeCount}>
              <OwnerLink to={route ?? '#'} className={route ? '' : 'disabled'}>
                <Row justify="start" align="middle" wrap={false}>
                  <div style={{height: 30}}>
                    <img
                      src={icon}
                      alt={label}
                      style={{width: 30, maxHeight: 'auto'}}
                    />
                  </div>
                  <AppTitle
                    level={4}
                    style={{
                      margin: 0,
                      marginLeft: 10,
                    }}
                  >
                    {label}
                  </AppTitle>
                </Row>
              </OwnerLink>
            </Badge>
          </Col>
        ))}
      </Row>
    </ContentDiv>
  );
};
