import {FC} from 'react';
import {Navigate} from 'react-router-dom';
import {useUser} from './Auth';

/** Makes sure the user is connected, else redirect to login page. */
export const LoggedInRoute: FC<{children: React.ReactNode}> = (props) => {
  const user = useUser();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <>{props.children}</>;
};
