import {CheckCircleTwoTone, PlusOutlined} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import {FC, useMemo, useState} from 'react';
import {
  formatCurrency,
  getDateFormat,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {DayjsDateToTimestamp, TimestampToDayjsDate} from '../../util/date';
import {compileTemplate} from '../../util/template';
import {InvoiceSelect} from '../input/InvoiceSelect';
import {OrderSelect} from '../input/OrderSelect';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {ArticleTypeLabel} from './Article';
import {InvoiceRecord} from './Invoices';
import {
  OrderAccountingStatus,
  OrderAccountingStatusLabel,
  OrderRecord,
  PaymentType,
  PaymentTypeLabel,
} from './Order';
import {
  booleanFilters,
  getDefaultSorter,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';

const singularRoute = '/payment';
const pluralRoute = '/payments';

export interface PaymentFormValues {
  payerName: string;
  amount: number;
  type: PaymentType;
  label: string;
  paymentDate: number | Dayjs;
  isAccounted: boolean;
  orderId?: string;
  invoiceId: string;

  updateOrderStatus?: boolean;
}

export interface PaymentRecord extends PaymentFormValues {
  id: string;
  order?: OrderRecord;
  invoice?: InvoiceRecord;
  createDate: number;
  updateDate: number;
}

const paymentInitialValues: Partial<PaymentFormValues> = {};

export const PaymentForm: FC<TableFormProps<PaymentFormValues>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t, lang} = useTranslation();

  useMemo(() => {
    const {paymentDate, ...other} = {
      ...paymentInitialValues,
      ...initialValues,
    };

    form.setFieldsValue({
      ...other,
      paymentDate: paymentDate ? TimestampToDayjsDate(paymentDate) : dayjs(),
    });
  }, [form, initialValues]);

  const isCreate = ((initialValues): initialValues is PaymentRecord =>
    !(initialValues as PaymentRecord).id)(initialValues);

  const [order, setOrder] = useState<OrderRecord>();
  const [invoice, setInvoice] = useState<InvoiceRecord>();

  const orderIdValue = Form.useWatch('orderId', form);
  const amountValue = Form.useWatch('amount', form);
  const updateOrderStatusValue = Form.useWatch('updateOrderStatus', form);

  useMemo(() => {
    if (!orderIdValue || order?.id !== orderIdValue) setOrder(undefined);
  }, [order, orderIdValue]);

  const handleOrderChange = (order?: OrderRecord) => {
    setOrder(order);
    if (!order) return;

    form.setFieldValue('payerName', order.owner.name);
  };

  return (
    <Form<PaymentFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...paymentInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="orderId"
            label={t('orders.orderNumber')}
            rules={[{required: true}]}
            help={
              order
                ? `${order.netPrice} € - ${t(
                    ArticleTypeLabel[order.articleType],
                  )}`
                : null
            }
          >
            <OrderSelect onOrderChange={handleOrderChange} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="invoiceId"
            label={t('payments.invoiceNumber')}
            rules={[{required: !isCreate}]}
          >
            <InvoiceSelect onInvoiceChange={setInvoice} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="payerName"
            label={t('payments.payerName')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="paymentDate"
            label={t('payments.paymentDate')}
            rules={[{required: true}]}
          >
            <DatePicker format={getDateFormat(lang)} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="type"
            label={t('payments.type')}
            rules={[{required: true}]}
          >
            <Select
              options={Object.entries(PaymentTypeLabel)?.map(
                ([type, label]) => ({
                  value: Number(type),
                  label: t(label),
                  disabled: Number(type) === PaymentType.SUMUP,
                }),
              )}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="label" label={t('payments.label')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="amount"
            label={t('payments.amount')}
            rules={[
              {required: true},
              () => ({
                validator(_, amount) {
                  if (amount === null) return Promise.reject();
                  if (amount > 0) return Promise.resolve();

                  return Promise.reject(
                    new Error(
                      t(
                        'payments.error.amountMustBePositiveAndGreaterThanZero',
                      ),
                    ),
                  );
                },
              }),
            ]}
          >
            <InputNumber addonAfter="€" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="isAccounted"
            label={t('payments.isAccounted')}
            valuePropName="checked"
            className="force-horizontal"
            style={{marginTop: 35}}
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>

      {isCreate &&
      order?.accountingStatus === OrderAccountingStatus.SUBMITTED ? (
        <Form.Item
          name="updateOrderStatus"
          label={compileTemplate(t('payments.updateOrderStatus'), {
            orderNumber: order?.orderNumber,
          })}
          valuePropName="checked"
          className="force-horizontal"
          initialValue={true}
          style={{marginTop: 35}}
        >
          <Checkbox />
        </Form.Item>
      ) : null}

      {isCreate &&
      order &&
      order?.accountingStatus !== OrderAccountingStatus.SUBMITTED ? (
        <Alert
          type="warning"
          closable={false}
          style={{marginTop: 10}}
          message={compileTemplate(t('payments.warn.unexpectedOrderStatus'), {
            orderNumber: order?.orderNumber,
            orderStatus: t(OrderAccountingStatusLabel[order?.accountingStatus]),
          })}
        />
      ) : null}

      {isCreate &&
      order?.accountingStatus === OrderAccountingStatus.SUBMITTED &&
      order?.netPrice !== amountValue ? (
        <Alert
          type={updateOrderStatusValue ? 'error' : 'warning'}
          closable={false}
          style={{marginTop: 10}}
          message={compileTemplate(t('payments.warn.unexpectedPaymentAmount'), {
            orderNumber: order?.orderNumber,
            orderAmount: formatCurrency(order?.netPrice, lang),
            paymentAmount: formatCurrency(amountValue ?? 0, lang),
          })}
        />
      ) : null}

      {order && invoice && order.id !== invoice.orderId ? (
        <Alert
          type="error"
          closable={false}
          style={{marginTop: 10}}
          message={t('payments.warn.unexpectedInvoiceOrderNumber')}
        />
      ) : null}

      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

export const getPaymentColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<PaymentRecord> => [
  {
    title: t('payments.paymentDate'),
    key: 'paymentDate',
    dataIndex: 'paymentDate',
    render: (paymentDate) => TimestampToDayjsDate(paymentDate).format('L'),
    sorter: true,
  },
  {
    title: t('orders.orderNumber'),
    key: 'orderId',
    dataIndex: 'orderId',
    align: 'right',
    render: (_, {order}) => order?.orderNumber,
    searchInput: true,
    searchSelect: (props) => <OrderSelect {...props} />,
  },
  {
    title: t('payments.payerName'),
    key: 'payerName',
    dataIndex: 'payerName',
    sorter: true,
    searchInput: true,
  },
  {
    title: t('orders.apartment'),
    key: 'apartmentId',
    dataIndex: 'apartmentId',
    sorter: true,
    searchInput: true,
    render: (_, {order}) => order?.apartment?.code,
  },
  {
    title: t('payments.label'),
    key: 'label',
    dataIndex: 'label',
    sorter: true,
    searchInput: true,
  },
  {
    title: t('payments.amount'),
    key: 'amount',
    dataIndex: 'amount',
    align: 'right',
    render: (amount) => formatCurrency(amount, lang),
    sorter: true,
    searchInput: true,
  },
  {
    title: t('payments.invoiceNumber'),
    key: 'invoiceId',
    dataIndex: 'invoiceId',
    align: 'right',
    render: (_, {invoice}) => invoice?.invoiceNumber,
    searchInput: true,
    searchSelect: (props) => <InvoiceSelect {...props} />,
  },
  {
    title: t('payments.isAccounted'),
    key: 'isAccounted',
    dataIndex: 'isAccounted',
    render: (isAccounted) =>
      isAccounted ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null,
    sorter: true,
    filters: booleanFilters(t),
  },
];

const parsePaymentValues = ({
  paymentDate,
  ...values
}: PaymentRecord): PaymentFormValues => ({
  paymentDate: DayjsDateToTimestamp(paymentDate),
  ...values,
});

export const Payments: FC = () => {
  const api = useApi();
  const {t, lang} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<PaymentRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: PaymentForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  const updateOrderAccountingStatus = async (payment: PaymentRecord) => {
    if (!api) return;

    if (!payment.isAccounted) return;
    const singularRoute = '/order';
    const order: OrderRecord = await api.get(
      DEFAULT_API_NAME,
      `${singularRoute}/${payment.orderId}`,
      {},
    );

    if (!order || order.accountingStatus === OrderAccountingStatus.ACCOUNTED)
      return;

    await api.post(DEFAULT_API_NAME, `${singularRoute}/${payment.orderId}`, {
      body: {
        ...order,
        accountingStatus: OrderAccountingStatus.ACCOUNTED,
      },
    });
  };

  const columns = useStateFn(() => getPaymentColumns(t, lang), [t, lang]);
  const defaultSorter = useStateFn(
    () => getDefaultSorter(columns, 'paymentDate'),
    [columns],
  );

  return (
    <ContentDiv
      title={total ? `${t('payments.title')}: ${total}` : t('payments.title')}
      titleRightComponent={
        <Button
          type="primary"
          onClick={() => setCreateModalOpen(true)}
          icon={<PlusOutlined />}
        />
      }
    >
      <TableList<PaymentRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        defaultSorter={defaultSorter}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        actionButtonProps={{
          delete: (payment) => (payment.isAccounted ? {disabled: true} : {}),
        }}
        onTotalChange={setTotal}
      />
      <TableCreateModal<PaymentRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
        parseValues={parsePaymentValues}
        afterSubmit={async (form, payment) => {
          if (!api || !form.updateOrderStatus) return;

          await api.post(
            DEFAULT_API_NAME,
            `/order/${payment.orderId}/confirmPayment`,
            {},
          );

          await api.post(
            DEFAULT_API_NAME,
            `/order/${payment.orderId}/generateInvoicePDF`,
            {},
          );
        }}
      />
      <TableUpdateModal<PaymentRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        width={700}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={parsePaymentValues}
        afterSubmit={async (_, payment) => updateOrderAccountingStatus(payment)}
      />
    </ContentDiv>
  );
};
