import {Spin, Row, Col} from 'antd';
import {FC} from 'react';
import {AppContent} from './AppContent';
import {AppFooter} from './AppFooter';
import {AppHeader} from './AppHeader';
import {AppLayout} from './AppLayout';

export const AppLoadingPage: FC = (props) => {
  return (
    <AppLayout>
      <AppHeader big={false} />
      <AppContent>
        <Row justify="center">
          <Col span={24} style={{textAlign: 'center'}}>
            <Spin size="large" />
          </Col>
        </Row>
      </AppContent>
      <AppFooter />
    </AppLayout>
  );
};
