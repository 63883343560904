import {Form, Input, message} from 'antd';
import {APIClass} from 'aws-amplify';
import {FC, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {FormSubmitButton} from '../FormSubmitButton';

export interface ClearPastDataResponse {
  nbCleared: number;
}

const clearPastData = async (
  api: APIClass | null,
  token: string,
): Promise<ClearPastDataResponse | undefined> => {
  if (!api) return;

  const res = await api.post(DEFAULT_API_NAME, '/vehicles/clear-past-data', {
    body: {token},
  });

  if (!res) {
    throw new Error('Erreur');
  }

  if (!!res.error) {
    throw new Error(res.error);
  }

  return res;
};

export const SuperAdminVehicleClearData: FC = (props) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState<ClearPastDataResponse>();

  return (
    <div>
      <Form
        layout="inline"
        onFinish={async (values) => {
          if (loading || !values.token) {
            return;
          }

          setError('');
          setLoading(true);
          try {
            const info = await clearPastData(api, values.token);
            setInfo(info);
          } catch (err: any) {
            message.warning(err.message);
            setInfo(undefined);
            setError(err.message);
          }
          setLoading(false);
        }}
      >
        <Form.Item label="Token" name="token" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <FormSubmitButton loading={loading}>
            Lancer le script
          </FormSubmitButton>
        </Form.Item>
      </Form>

      {error}

      {info ? (
        <ul>
          <li>Nb lignes impactées: {info.nbCleared}</li>
        </ul>
      ) : undefined}
    </div>
  );
};
