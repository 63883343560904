import Countries from 'i18n-iso-countries';
import CountriesFR from 'i18n-iso-countries/langs/fr.json';
import {Select, SelectProps} from 'antd';
import {FC} from 'react';

Countries.registerLocale(CountriesFR);
const countries = Countries.getNames('fr', {select: 'official'});
const countryOptions = Object.entries(countries).map(([code, label]) => ({
  value: code,
  label,
}));

export const CountrySelect: FC<SelectProps> = (props) => (
  <Select
    {...props}
    options={countryOptions}
    showSearch
    filterOption={(input, option) =>
      (option?.label ?? '')
        .toString()
        .toLowerCase()
        .includes(input.toLowerCase())
    }
  />
);
