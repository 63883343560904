import {useRequest} from 'ahooks';
import {API} from 'aws-amplify';
import dayjs, {Dayjs} from 'dayjs';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {
  getDayjsDurationFromDurationType,
  getDurationFromDurationType,
} from '../../util/date';
import {
  ArticleRecord,
  isArticleValuesPeriodic,
  PeriodicArticleFormValues,
} from './Article';
import {Composition} from './Order';
import {OwnerRecord} from './Owner';

export type PeriodicArticle = ArticleRecord & PeriodicArticleFormValues;

/** Returns periodic articles, sorted by duration DESC (articles with biggest
 * durations **first**) */
const getPeriodicArticlesSortedByDuration = (
  articles: PeriodicArticle[],
): PeriodicArticle[] => {
  const sorted = [...articles];

  sorted.sort((a, b) => {
    const durationA = getDayjsDurationFromDurationType(
      a.validityDurationType,
      a.validityDuration,
    );
    const durationB = getDayjsDurationFromDurationType(
      b.validityDurationType,
      b.validityDuration,
    );

    return durationB.asMilliseconds() - durationA.asMilliseconds();
  });

  return sorted;
};

/** get the maximum quantity that can fit on a given date range for one article  */
export const getMaxQtyForArticle = (
  start: Dayjs,
  end: Dayjs,
  article: PeriodicArticle,
  /** Controls whether we can exceed the "end" date or not. */
  allowExceed = false,
): {qty: number; dateAfterQty: Dayjs} => {
  const duration = getDurationFromDurationType(
    article.validityDurationType,
    article.validityDuration,
  );

  let qty = 0;
  let iterator = start.clone();

  do {
    iterator = iterator.add(duration.value, duration.unit);
    qty += 1;
  } while (iterator.isBefore(end, 'day') || iterator.isSame(end, 'day'));

  if (iterator.isAfter(end.add(1, 'day'), 'day') && !allowExceed) {
    qty -= 1;
    iterator = iterator.subtract(duration.value, duration.unit);
  }

  return {qty, dateAfterQty: iterator};
};

/** return one composition with only one article (for every article passed as argument). */
export const getCompositionsWithSameArticle = (
  start: Dayjs,
  end: Dayjs,
  periodicArticles: PeriodicArticle[],
): Composition[] => {
  const compositions: Composition[] = [];
  for (const article of periodicArticles) {
    const {qty} = getMaxQtyForArticle(start, end, article, true);

    compositions.push({
      price: (article.unitPriceInclVAT ?? 0) * qty,
      lines: [{article, quantity: qty}],
    });
  }
  return compositions;
};

export const getCompositionWithDifferentArticles = (
  start: Dayjs,
  end: Dayjs,
  periodicArticles: PeriodicArticle[],
  maxNumberOfDifferentArticles?: number,
): Composition => {
  const articlesSortedByDuration =
    getPeriodicArticlesSortedByDuration(periodicArticles);

  const compo: Composition = {lines: [], price: 0};

  let nbOfDifferentArticles = 0;

  if (maxNumberOfDifferentArticles && maxNumberOfDifferentArticles < 2) {
    throw new Error('expected maxNumberOfDifferentArticles to be >= 2');
  }

  let iterator = start.clone();
  for (const article of articlesSortedByDuration) {
    const canExceed = maxNumberOfDifferentArticles
      ? nbOfDifferentArticles + 1 === maxNumberOfDifferentArticles
      : false;

    const {qty, dateAfterQty} = getMaxQtyForArticle(
      iterator,
      end,
      article,
      canExceed,
    );
    iterator = dateAfterQty;

    if (qty > 0) {
      nbOfDifferentArticles += 1;

      compo.lines.push({article, quantity: qty});
      compo.price += (article.unitPriceInclVAT ?? 0) * qty;
    }

    if (
      maxNumberOfDifferentArticles &&
      (nbOfDifferentArticles === maxNumberOfDifferentArticles ||
        !iterator.isBefore(end.add(1, 'day'), 'day'))
    )
      break;
  }

  return compo;
};

/** sort compositions, from better to worse */
export const sortCompositions = (compositions: Composition[]): Composition[] =>
  [...compositions].sort((comp1, comp2) => {
    const [price1, price2] = [comp1.price, comp2.price];
    if (price1 !== price2) return price1 - price2;

    const [linesLength1, linesLength2] = [
      comp1.lines.length,
      comp2.lines.length,
    ];
    if (linesLength1 !== linesLength2) return linesLength1 - linesLength2;

    const [artQty1, artQty2] = [comp1.lines, comp2.lines].map((lines) =>
      lines.reduce((sum, {quantity}) => sum + quantity, 0),
    );
    return artQty1 - artQty2;
  });

/** returns compositions for a given duration (start, end) ; sorted by optimal price/qty. */
export const getOrderCompositions = (
  _start: Dayjs,
  _end: Dayjs,
  articles: ArticleRecord[],
): Composition[] => {
  // strip hours/minutes/seconds to avoid problems:
  const start = dayjs(_start.format('YYYY-MM-DD'));
  const end = dayjs(_end.format('YYYY-MM-DD'));

  const compositions: Composition[] = [];

  const periodicArticles: PeriodicArticle[] = [];
  for (const article of articles) {
    if (!isArticleValuesPeriodic(article)) continue;
    periodicArticles.push(article);
  }

  // compositions with same article
  const compositionsWithSameArticle = getCompositionsWithSameArticle(
    start,
    end,
    periodicArticles,
  );
  compositions.push(...compositionsWithSameArticle);

  // compositions with different articles
  const compositionWithDifferentArticles = getCompositionWithDifferentArticles(
    start,
    end,
    periodicArticles,
  );
  compositions.push(compositionWithDifferentArticles);

  // compositions with different articles and with 2 different articles max
  const compositionWith2DifferentArticles = getCompositionWithDifferentArticles(
    start,
    end,
    periodicArticles,
    2,
  );
  compositions.push(compositionWith2DifferentArticles);

  const sortedCompositions = sortCompositions(compositions);

  return sortedCompositions;
};

export const getEffectiveEndDate = (
  start: Dayjs,
  composition: Composition,
): Dayjs => {
  let iterator = start.clone();
  for (const line of composition.lines) {
    if (!isArticleValuesPeriodic(line.article)) continue;

    const duration = getDurationFromDurationType(
      line.article.validityDurationType,
      line.article.validityDuration,
    );

    for (let i = 0; i < line.quantity; i++) {
      iterator = iterator.add(duration.value, duration.unit);
    }
  }
  return iterator.subtract(1, 'day').endOf('day');
};

export const openApiUrlInNewTab = async (
  api: typeof API,
  apiPath: string,
): Promise<void> => {
  if (!api) return;
  const url = await api.get(DEFAULT_API_NAME, apiPath, {});
  if (!url) return;
  window.open(url, '_blank')?.focus();
};

export const useGetOwner = (ownerId: string | undefined) => {
  const api = useApi();

  const fetchOwner = async (): Promise<OwnerRecord | undefined> => {
    if (!api || !ownerId) {
      return;
    }
    return api.get(DEFAULT_API_NAME, `/owner/${ownerId}`, {});
  };

  const res = useRequest(fetchOwner, {
    refreshDeps: [api, ownerId],
  });

  return res;
};

/** From an enum with **numeric** values, return an array with all values. */
export const getNumericEnumKeys = (inputEnum: object): number[] =>
  Object.values(inputEnum).filter((v) => typeof v === 'number');
