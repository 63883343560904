const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT;
const APARTMENT_CACHE_ROOT = [ASSET_ENDPOINT, 'apartment_cache'].join('/');

const APARTMENT_CONDO_CACHE_PATH = [APARTMENT_CACHE_ROOT, 'condos.json'].join(
  '/',
);

const getApartmentBuildingCachePath = (condo: string) =>
  [
    APARTMENT_CACHE_ROOT,
    'buildings',
    `${condo.replace(/\W+/g, '_')}.json`,
  ].join('/');

const getApartmentCodeCachePath = (condo: string, building: string) =>
  [
    APARTMENT_CACHE_ROOT,
    'codes',
    condo.replace(/\W+/g, '_'),
    `${building.replace(/\W+/g, '_')}.json`,
  ].join('/');

export const fetchCondos = () => fetch(APARTMENT_CONDO_CACHE_PATH);

export const fetchBuildings = (condo: string) =>
  fetch(getApartmentBuildingCachePath(condo));

export const fetchApartments = (condo: string, building: string) =>
  fetch(getApartmentCodeCachePath(condo, building));
