import {FC} from 'react';
import {Navigate} from 'react-router-dom';
import {UserRole, useUserRole} from './Auth';
import {LoggedInRoute} from './LoggedInRoute';

/** Makes sure the user is connected AND admin, else redirect to login page. */
export const AdminRoute: FC<{children: React.ReactNode}> = (props) => {
  const role = useUserRole();

  if (!role || role !== UserRole.ADMIN) {
    return <Navigate to="/login" />;
  }

  return <LoggedInRoute>{props.children}</LoggedInRoute>;
};
