export const COLORS = {
  LOGO_BG: '#002454',
  LOGO_BG_DARKER: 'rgb(0, 25, 53)',
  TEXT_COLOR: '#2D2D2D',
  DRESSING_COLOR: '#FFCF84',
  LINK_COLOR: '#B85632',
  PRIVATE_COLOR: '#C58A4E',
  NEUTRAL_COLOR: '#ECE1D7',
};

export const MAX_WIDTH = 1352;
export const TITLE_FONT_FAMILY = 'Aviano Sans W00 Regular';
export const BASE_FONT_FAMILY = 'Source Code Pro';
