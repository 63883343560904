import {EditOutlined} from '@ant-design/icons';
import {Button, Checkbox, Col, Form, Input, Row, Select, Space} from 'antd';
import {FilterValue} from 'antd/es/table/interface';
import saveAs from 'file-saver';
import {parsePhoneNumber} from 'libphonenumber-js';
import {FC, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {TranslateFn, useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi, UserRole, useUserRole} from '../../util/Auth';
import {CountrySelect} from '../input/CountrySelect';
import {getValidPhoneRule, PhoneInput} from '../input/PhoneInput';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {
  getDefaultSorter,
  getFiltersForAPI,
  TableColumnsProp,
  TableFormProps,
  TableList,
} from './Table';
import {UserRecord} from './User';
const {Option} = Select;

const singularRoute = '/owner';
const pluralRoute = '/owners';

export interface OwnerFormValues {
  name: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  phoneNumber: string;
  userId: string;
  displayRecipientFields?: boolean;

  ['user.email']: string;
  ['user.firstName']: string;
  ['user.lastName']: string;
  ['user.title']: string;
  ['user.disabled']: boolean;
}

export interface OwnerRecord extends OwnerFormValues {
  id: string;
  user: UserRecord;
  createDate: number;
  updateDate: number;
}

const ownerInitialValues: Partial<OwnerFormValues> = {
  'user.disabled': false,
};

export interface OwnerFormProps extends TableFormProps<OwnerFormValues> {
  onEmailChangeClick?: () => void;
}

export const OwnerForm: FC<OwnerFormProps> = ({
  form,
  onFinish,
  onEmailChangeClick,
  initialValues = {},
}) => {
  const userRole = useUserRole();
  const {t} = useTranslation();
  useMemo(() => {
    form.setFieldsValue({...ownerInitialValues, ...initialValues});
  }, [form, initialValues]);

  return (
    <Form<OwnerFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...ownerInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Form.Item
        name="user.lastName"
        label={t('ownerRequests.lastName')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>

      <Row gutter={24}>
        <Col>
          <Form.Item name="user.title" label={t('ownerRequests.title_gender')}>
            <Select allowClear>
              <Option value="M">{t('users.userTitleMr')}</Option>
              <Option value="Mme">{t('users.userTitleMrs')}</Option>
              <Option value="M et Mme">{t('users.userTitleMrAndMrs')}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            name="user.firstName"
            label={t('ownerRequests.firstName')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="address"
        label={t('ownerRequests.address')}
        rules={[{required: true}]}
      >
        <Input.TextArea autoSize={{minRows: 2, maxRows: 4}} />
      </Form.Item>
      <Row gutter={24}>
        <Col>
          <Form.Item
            name="zipCode"
            label={t('ownerRequests.zipCode')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            name="city"
            label={t('ownerRequests.city')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="country"
        label={t('ownerRequests.country')}
        rules={[{required: true}]}
      >
        <CountrySelect />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label={t('ownerRequests.phoneNumber')}
        rules={[{required: true}, getValidPhoneRule({t})]}
      >
        <PhoneInput />
      </Form.Item>
      <Form.Item
        name="user.email"
        label={t('ownerRequests.email')}
        rules={[{required: true}]}
      >
        {onEmailChangeClick ? (
          <Space.Compact style={{width: '100%'}}>
            <Input
              value={initialValues?.['user.email']}
              type="email"
              disabled
            />
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={onEmailChangeClick}
            />
          </Space.Compact>
        ) : (
          <Input type="email" disabled />
        )}
      </Form.Item>
      <Form.Item
        name="user.disabled"
        label={t('users.disabled')}
        valuePropName="checked"
        className="force-horizontal"
        hidden={userRole !== UserRole.ADMIN}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name="displayRecipientFields"
        label={t('owners.displayRecipientFields')}
        valuePropName="checked"
        className="force-horizontal"
        hidden={userRole !== UserRole.ADMIN}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item name="userId" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

const getOwnerColumns = (t: TranslateFn): TableColumnsProp<OwnerRecord> => [
  {
    title: t('ownerRequests.fullName'),
    key: 'name',
    dataIndex: 'name',
    searchInput: true,
    sorter: true,
  },
  {
    title: t('ownerRequests.address'),
    key: 'address',
    dataIndex: 'address',
    render: (address) => (
      <span className="hint" title={address}>
        {address}
      </span>
    ),
    searchInput: true,
    sorter: true,
  },
  {
    title: t('ownerRequests.zipCode'),
    key: 'zipCode',
    dataIndex: 'zipCode',
    searchInput: true,
    sorter: true,
  },
  {
    title: t('ownerRequests.city'),
    key: 'city',
    dataIndex: 'city',
    searchInput: true,
    sorter: true,
  },
  {
    title: t('ownerRequests.country'),
    key: 'country',
    dataIndex: 'country',
    sorter: true,
  },
  {
    title: t('ownerRequests.phoneNumber'),
    key: 'phoneNumber',
    dataIndex: 'phoneNumber',
    render: (phoneNumber) =>
      parsePhoneNumber(phoneNumber)?.formatInternational({}) ?? phoneNumber,
    searchInput: true,
    sorter: true,
  },
  {
    title: t('ownerRequests.email'),
    key: 'user.email',
    dataIndex: 'user.email',
    searchInput: true,
    filterName: 'user.email',
  },
];

export const Owner: FC = () => {
  const api = useApi();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [total, setTotal] = useState<number>();

  const ownerColumns = useStateFn(() => getOwnerColumns(t), [t]);
  const defaultSorter = useStateFn(
    () => getDefaultSorter(ownerColumns, 'name', 'ascend'),
    [ownerColumns],
  );

  const [exportLoading, setExportLoading] = useState(false);
  const [exportFilter, setExportFilter] = useState<
    Record<string, FilterValue | null>
  >({});
  const handleExportClick = async () => {
    if (!api) return;
    setExportLoading(true);
    try {
      const csv = (await api.get(DEFAULT_API_NAME, `${pluralRoute}/export`, {
        queryStringParameters: {
          ...getFiltersForAPI(exportFilter, {}, ownerColumns),
        },
      })) as string;

      console.log(csv);

      const fileName = `export-${new Date().toISOString().replace(/:/g, '-')}`;

      saveAs(
        new Blob(['\uFEFF' + csv], {
          type: 'text/csv; charset=UTF-8',
        }),
        `${fileName}.csv`,
        {
          autoBom: true,
        },
      );
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <ContentDiv
      title={total ? `${t('owners.title')}: ${total}` : t('owners.title')}
      titleRightComponent={
        <Button
          loading={exportLoading}
          onClick={handleExportClick}
          type="primary"
        >
          {t('general.export')}
        </Button>
      }
    >
      <TableList<OwnerRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={ownerColumns}
        defaultSorter={defaultSorter}
        setEditEntity={(owner) => navigate(`/admin/owner/${owner.id}`)}
        actionButtonProps={{delete: {style: {display: 'none'}}}}
        onTotalChange={setTotal}
        onFilterChange={setExportFilter}
      />
    </ContentDiv>
  );
};
