import styled from '@emotion/styled';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FC, useCallback, useMemo, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import slugify from 'slugify';
import {
  formatCurrency,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {
  DayjsDateRangeToTimestampRange,
  TimestampToDayjsDate,
} from '../../util/date';
import {CheckInstructions} from '../CheckInstructions';
import {CreditCardFormValues} from '../CreditCardForm';
import {ContentDiv} from '../layout/ContentDiv';
import {ApartmentRecord} from '../table/Apartment';
import {
  ArticleRecord,
  ArticleType,
  ArticleTypeRequireNamedCards,
  ArticleTypeRequiresCardFabrication,
  ArticleTypeRequiresValidityInterval,
} from '../table/Article';
import {CardStatus} from '../table/Cards';
import {
  AdminOrderFormValues,
  OrderForm,
  OrderFormValues,
  OrderLineFormValues,
  OrderRecord,
  PaymentType,
} from '../table/Order';
import {OrderLineRecord} from '../table/OrderLine';
import {OwnerRecord} from '../table/Owner';
import {SeasonRecord} from '../table/Season';
import {flattenObject, getValidateMessages, isAxiosError} from '../table/Table';
import {WireTransferInstructions} from '../WireTransferInstructions';
import {CardFormValues, OwnerOrderCards} from './OwnerOrderCards';

const singularRoute = '/order';
// const pluralRoute = '/orders';

const backUrl = '/admin/orders';

const MaxWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const AUTOMATICALLY_PAID_PAYMENT_TYPES = [
  PaymentType.CASH,
  PaymentType.CHECK,
  PaymentType.POS,
];

const parseOrderFormValues = ({
  validityStart: _validityStart,
  validityEnd: _validityEnd,
  ...values
}: OrderFormValues): OrderFormValues => {
  if (
    !ArticleTypeRequiresValidityInterval.includes(values.articleType) ||
    !_validityStart ||
    !_validityEnd
  ) {
    return values;
  }

  const [validityStart, validityEnd] = DayjsDateRangeToTimestampRange(
    _validityStart!,
    _validityEnd!,
  );

  return {
    validityStart,
    validityEnd,
    ...values,
  };
};

export const getOrderLineSummaryColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): ColumnsType<OrderLineFormValues> => [
  {
    title: t('orderLines.article'),
    key: 'articleId',
    dataIndex: 'articleId',
    render: (_, {article}) => article?.label,
    className: 'align-title-center',
  },
  {
    title: t('orderLines.unitPrice'),
    key: 'unitPriceInclVAT',
    dataIndex: 'unitPriceInclVAT',
    render: (_, {article: {unitPriceInclVAT = 0}}) =>
      formatCurrency(unitPriceInclVAT, lang),
    align: 'right',
    className: 'align-title-center',
  },
  {
    title: t('orderLines.quantity'),
    key: 'quantity',
    dataIndex: 'quantity',
    align: 'right',
    className: 'align-title-center',
  },
  {
    title: t('orderLines.totalPrice'),
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    render: (_, {article: {unitPriceInclVAT = 0}, quantity = 1}) =>
      formatCurrency(unitPriceInclVAT * quantity, lang),
    align: 'right',
  },
  {
    title: t('orderLines.discount'),
    key: 'discount',
    dataIndex: 'discount',
    render: (discount = 0) => `${discount * 100}%`,
    align: 'right',
  },
  {
    title: t('orderLines.netPrice'),
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    render: (
      _,
      {article: {unitPriceInclVAT = 0}, quantity = 1, discount = 0},
    ) => {
      const totalPrice = unitPriceInclVAT * quantity;
      return formatCurrency(totalPrice - totalPrice * discount, lang);
    },
    align: 'right',
    className: 'align-title-center',
  },
];

export interface OrderConfirmFormValues {
  terms: boolean;
}

export const AdminOrder: FC = () => {
  const api = useApi();
  const {id: orderId} = useParams();
  const {t, lang} = useTranslation();
  const navigate = useNavigate();

  const [order, setOrder] = useState<OrderRecord>();
  const [orderFetchError, setOrderFetchError] = useState('');
  const [orderLoading, setOrderLoading] = useState(!!order);

  const [orderSubmitError, setOrderSubmitError] = useState('');

  const [orderForm] = Form.useForm<AdminOrderFormValues>();
  const [orderConfirmForm] = Form.useForm<OrderConfirmFormValues>();
  const [creditCardForm] = Form.useForm<CreditCardFormValues>();

  const [orderFormValues, setOrderFormValues] = useState<
    AdminOrderFormValues | undefined
  >();
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);

  const [modalSummaryOpen, setModalSummaryOpen] = useState(false);

  const [season, setSeason] = useState<SeasonRecord>();
  const [owner, setOwner] = useState<OwnerRecord>();
  const [apartment, setApartment] = useState<ApartmentRecord>();
  const [article, setArticle] = useState<ArticleRecord>();
  const [articleType, setArticleType] = useState<ArticleType>();
  const [orderLines, setOrderLines] = useState<OrderLineFormValues[]>();
  const [, setCards] = useState<CardFormValues[]>();
  const [isRenewal, setIsRenewal] = useState<boolean>();

  /* const [secureCheckoutOrder, setSecureCheckoutOrder] = useState<OrderRecord>();
  const [isSecureCheckoutPending, setIsSecureCheckoutPending] = useState(false); */

  /* const {run: runConfirmPaymentPolling, cancel: cancelConfirmPaymentPolling} =
    useRequest(confirmPayment, {
      refreshDeps: [api, order, isSecureCheckoutPending],
      pollingInterval: 3000,
      pollingWhenHidden: false,
      manual: true,
      // debounceLeading: true,
      // debounceWait: 3000,
    }); */

  const netPrice = useMemo(
    () =>
      (orderLines ?? []).reduce(
        (
          sum,
          {
            customNetPrice,
            article: {unitPriceInclVAT = 0},
            quantity = 1,
            discount = 0,
          },
        ) => {
          if (typeof customNetPrice !== 'undefined')
            return sum + customNetPrice;
          const totalPrice = quantity * unitPriceInclVAT;
          return sum + (totalPrice - totalPrice * discount);
        },
        0,
      ),
    [orderLines],
  );

  const fetchOrder = useCallback(async () => {
    if (!api || !orderId || orderFetchError || orderLoading) return;

    setOrder(undefined);
    setOrderLoading(true);

    try {
      const order = (await api.get(
        DEFAULT_API_NAME,
        [singularRoute, orderId].join('/'),
        {},
      )) as OrderRecord;

      setOrder(flattenObject(order));
      return order;
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOrderFetchError(message);
    } finally {
      setOrderLoading(false);
    }
  }, [api, orderFetchError, orderId, orderLoading, t]);

  useMemo(() => {
    if (!api || !orderId || order?.id === orderId || orderLoading) return;

    (async () => fetchOrder())();
  }, [api, orderId, order?.id, orderLoading, fetchOrder]);

  const handleOrderSubmit = async () => {
    await orderForm.validateFields();

    const formValues = parseOrderFormValues(orderForm.getFieldsValue());
    setOrderFormValues(formValues as AdminOrderFormValues);

    orderConfirmForm.resetFields();
    setModalSummaryOpen(true);
  };

  const handleOrderConfirm = async () => {
    console.log({api, orderFormValues, orderLines});
    if (!api || !orderFormValues || !orderLines?.length || !apartment) return;

    if (orderFormValues.paymentType === PaymentType.SUMUP)
      await creditCardForm.validateFields();

    setUpdateOrderLoading(true);
    setOrderSubmitError('');

    const {...orderValues} = orderFormValues;

    try {
      const {order} = (await api.put(DEFAULT_API_NAME, singularRoute, {
        body: orderValues,
      })) as {order: OrderRecord};

      const owner: OwnerRecord = await api.get(
        DEFAULT_API_NAME,
        `/owner/${order.ownerId}`,
        {},
      );

      for (const orderLine of orderLines) {
        const {customNetPrice, article, discount = 0, quantity = 1} = orderLine;

        const totalPrice = (article.unitPriceInclVAT ?? 0) * quantity;
        const orderLineRecord = (await api.put(DEFAULT_API_NAME, `/orderLine`, {
          body: {
            orderId: order.id,
            articleId: article.id,
            quantity,
            discount,
            unitPrice: article.unitPriceInclVAT,
            VAT: article.VAT ?? 0,
            totalPrice,
            netPrice:
              typeof customNetPrice !== 'undefined'
                ? customNetPrice
                : totalPrice - totalPrice * discount,
          },
        })) as OrderLineRecord;
        orderLine.id = orderLineRecord.id;
      }

      for (const {id, article, cards, quantity = 1} of orderLines) {
        if (!id || !ArticleTypeRequiresCardFabrication.includes(article.type))
          continue;

        if (!cards) {
          await api.put(DEFAULT_API_NAME, `/card`, {
            body: {
              count:
                article.type === ArticleType.POOL_BLANCHE
                  ? apartment.beddings
                  : article.type === ArticleType.PARKING
                  ? 1
                  : quantity,
              fabricatedCount: 0,
              status: CardStatus.PENDING_PRODUCTION,
              startDate: order.validityStart,
              lastName: owner.user.lastName,
              firstName: owner.user.firstName,

              ownerId: order.ownerId,
              orderId: order.id,
              orderNumber: order.orderNumber,
              apartmentId: orderValues.apartmentId,
              orderLineId: id,
              articleType: article.type,
            },
          });

          if (articleType === ArticleType.PARKING) break;
          continue;
        }

        for (const {
          article,
          firstName,
          lastName,
          isChild,
          photos = [],
        } of cards) {
          let photoFile: {name: string; type?: string} | undefined = undefined;
          const [photo] = photos;
          if (photo) {
            photoFile = {
              name: slugify(photo.name),
              type: photo.type,
            };
          }

          const {photoFileSignedUrl} = await api.put(
            DEFAULT_API_NAME,
            `/card`,
            {
              body: {
                count: 1,
                fabricatedCount: 0,
                status: CardStatus.PENDING_PRODUCTION,

                firstName,
                lastName,
                isChild,
                photoFile,

                ownerId: order.ownerId,
                orderId: order.id,
                orderNumber: order.orderNumber,
                apartmentId: orderValues.apartmentId,
                orderLineId: id,
                articleType: article.type,
                startDate: order.validityStart,
              },
            },
          );

          if (photoFile && photoFileSignedUrl) {
            const headers: HeadersInit = {};
            if (photoFile.type) {
              headers['Content-Type'] = photoFile.type;
            }
            if (photoFile.name) {
              headers[
                'Content-Disposition'
              ] = `attachment; filename="${photoFile.name}"`;
            }

            console.log(headers, photo);

            await fetch(photoFileSignedUrl, {
              method: 'PUT',
              body: photo.originFileObj,
              headers,
            });
          }
        }
      }

      if (
        !netPrice ||
        AUTOMATICALLY_PAID_PAYMENT_TYPES.includes(order.paymentType)
      ) {
        await api.post(
          DEFAULT_API_NAME,
          netPrice
            ? `${singularRoute}/${order.id}/confirmPayment`
            : `${singularRoute}/${order.id}/confirmFreeOrder`,
          {},
        );

        await api.post(
          DEFAULT_API_NAME,
          `${singularRoute}/${order.id}/generateInvoicePDF`,
          {},
        );
      }

      message.success(t('order.submitSuccess'));
      navigate(backUrl);
    } catch (err) {
      console.error(err);
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOrderSubmitError(message);
      return;
    } finally {
      setUpdateOrderLoading(false);
    }
  };

  if (orderLoading) {
    return <Spin size="large" />;
  }

  if (orderFetchError) {
    return (
      <div>
        <Row>
          <Alert message={orderFetchError} />
        </Row>
        <Row>
          <Link to={backUrl}>
            <Button>{t('general.cancel')}</Button>
          </Link>
        </Row>
      </div>
    );
  }

  return (
    <ContentDiv>
      <AppTitle level={3} style={{marginTop: 0}}>
        {t('orders.title')}
      </AppTitle>
      <Row gutter={20} justify="space-between">
        <Col span={12}>
          <OrderForm
            admin
            form={orderForm}
            initialValues={order}
            orderLines={orderLines}
            onFinish={handleOrderSubmit}
            onOwnerChange={setOwner}
            onSeasonChange={setSeason}
            onApartmentChange={setApartment}
            onArticleChange={setArticle}
            onArticleTypeChange={setArticleType}
            onOrderLinesChange={setOrderLines}
            onIsRenewalChange={setIsRenewal}
          />
        </Col>
        <Col span={12}>
          {articleType &&
          ArticleTypeRequireNamedCards.includes(articleType) &&
          article &&
          season &&
          owner ? (
            <OwnerOrderCards
              owner={owner}
              season={season}
              apartment={apartment}
              article={article}
              isRenewal={isRenewal}
              additionalArticle={article}
              onOrderLinesChange={setOrderLines}
              onCardsChange={setCards}
            />
          ) : null}
        </Col>
      </Row>
      <Row gutter={20} justify="space-between">
        <Col>
          <Space>
            <Link to={backUrl}>
              <Button>{t('general.cancel')}</Button>
            </Link>
            <Button
              type="primary"
              onClick={handleOrderSubmit}
              loading={updateOrderLoading}
            >
              {t('general.submit')}
            </Button>
          </Space>
        </Col>
      </Row>

      <Modal
        centered
        forceRender
        title={t('order.modal.title')}
        open={modalSummaryOpen}
        onOk={handleOrderConfirm}
        onCancel={() => setModalSummaryOpen(false)}
        okButtonProps={{loading: updateOrderLoading}}
        okText={t('confirm.submit')}
        width={900}
      >
        <AppTitle level={3}>{t('order.modal.summary')}</AppTitle>
        {orderFormValues?.paymentType === PaymentType.WIRE_TRANSFER ? (
          <WireTransferInstructions articleType={articleType} />
        ) : null}
        {orderFormValues?.paymentType === PaymentType.CHECK ? (
          <CheckInstructions />
        ) : null}
        {orderFormValues?.validityStart && orderFormValues?.validityEnd ? (
          <>
            <AppTitle level={3}>{t('articles.validityDuration')}</AppTitle>
            <Descriptions bordered layout="vertical" size="small">
              <Descriptions.Item
                label={<Row justify="center">{t('orders.validityStart')}</Row>}
              >
                {TimestampToDayjsDate(orderFormValues?.validityStart).format(
                  'L',
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={<Row justify="center">{t('orders.validityEnd')}</Row>}
              >
                {TimestampToDayjsDate(
                  orderFormValues?.effectiveValidityEnd ??
                    orderFormValues?.validityEnd,
                ).format('L')}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : null}
        <Form<OrderConfirmFormValues>
          form={orderConfirmForm}
          layout="inline"
          onSubmitCapture={handleOrderConfirm}
          onFinish={handleOrderConfirm}
          validateMessages={getValidateMessages(t)}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <AppTitle level={4}>{t('orderLines.title')}</AppTitle>
            </Col>
            <Col span={24}>
              <Table<OrderLineFormValues>
                className="centered-thead"
                size="small"
                bordered
                dataSource={orderLines ?? []}
                columns={[
                  ...getOrderLineSummaryColumns(t, lang),
                  {
                    title: t('orderLines.customNetPrice'),
                    key: 'customNetPrice',
                    dataIndex: 'customNetPrice',
                    width: 150,
                    render: (_, orderLine) => {
                      const {
                        article: {unitPriceInclVAT = 0},
                        quantity = 1,
                        discount = 0,
                      } = orderLine;
                      const totalPrice = unitPriceInclVAT * quantity;
                      const netPrice = totalPrice - totalPrice * discount;
                      return (
                        <MaxWidthInputNumber
                          addonAfter="€"
                          placeholder={formatCurrency(netPrice, lang)}
                          onChange={(customNetPrice) => {
                            if (typeof customNetPrice === 'number') {
                              orderLine.customNetPrice = customNetPrice;
                            } else {
                              delete orderLine.customNetPrice;
                            }

                            setOrderLines([...(orderLines ?? [])]);
                          }}
                        />
                      );
                    },
                  },
                ]}
                pagination={false}
              />
            </Col>
            <Col span={24}>
              <p style={{float: 'right'}}>
                {t('orders.netPrice')} : {formatCurrency(netPrice, lang)}
              </p>
            </Col>
          </Row>
        </Form>
        {orderSubmitError ? (
          <Alert
            message={orderSubmitError}
            type="error"
            style={{whiteSpace: 'pre-line'}}
          />
        ) : null}
      </Modal>
    </ContentDiv>
  );
};
