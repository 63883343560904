import {Input, InputProps} from 'antd';
import {Rule} from 'antd/es/form';
import parsePhoneNumber from 'libphonenumber-js';
import {FC, useMemo, useState} from 'react';
import {LANGUAGE, TranslateFn} from '../../translation';
import {getFlagEmoji} from '../../util/flags';

const PHONE_NUMBER_PLACEHOLDER: {[lang in LANGUAGE]: string} = {
  [LANGUAGE.fr]: '+33 6 12 34 56 78',
  [LANGUAGE.en]: '+44 7911 123456',
  [LANGUAGE.it]: '+39 338 123 4567',
  [LANGUAGE.de]: '+49 30 123456',
};
interface getValidPhoneRuleProps {
  t: TranslateFn;
  /** defaults to false */
  allowEmpty?: boolean;
}

export const getValidPhoneRule =
  ({t, allowEmpty}: getValidPhoneRuleProps): Rule =>
  () => ({
    validator(_, phoneNumber) {
      if (allowEmpty && !phoneNumber) {
        return Promise.resolve();
      }

      if (!phoneNumber) return Promise.reject();

      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      if (parsedPhoneNumber?.isValid()) {
        return Promise.resolve();
      }

      if (!phoneNumber.startsWith('+'))
        return Promise.reject(new Error(t('register.mustBeInternational')));

      return Promise.reject(new Error(t('register.invalidPhoneNumber')));
    },
  });

interface PhoneInputProps extends InputProps {
  lang?: LANGUAGE;
}

export const PhoneInput: FC<PhoneInputProps> = ({lang, ...props}) => {
  const [country, setCountry] = useState<string | undefined>();
  useMemo(() => {
    if (typeof props.value !== 'string') {
      setCountry(undefined);
      return;
    }

    const parsedPhoneNumber = parsePhoneNumber(props.value);
    setCountry(
      parsedPhoneNumber?.isValid() ? parsedPhoneNumber.country : undefined,
    );
  }, [props.value]);

  return (
    <Input
      {...props}
      type="tel"
      placeholder={
        (lang && PHONE_NUMBER_PLACEHOLDER[lang]) ?? PHONE_NUMBER_PLACEHOLDER.fr
      }
      suffix={
        <span className="emoji">
          {country ? getFlagEmoji(country) : '\uD83C\uDFF3\uFE0F'}
        </span>
      }
      onChange={(event) => {
        const parsedPhoneNumber = parsePhoneNumber(event.target.value);
        setCountry(
          parsedPhoneNumber?.isValid() ? parsedPhoneNumber.country : undefined,
        );

        props.onChange?.(event);
      }}
    />
  );
};
