/** Replaces all "${key}" substrings with some values from a context, ie:
 * > compileTemplate('Hello I'm ${name}', {name: 'Mike'});
 */
export const compileTemplate = (
  str: string,
  context: Record<string, string>,
): string => {
  return str.replace(/\${([^{}]*)}/g, (match, key) => {
    const replacement = context[key];
    if (!replacement) {
      console.warn(
        `stringReplace: value with key "${key}" not found in context`,
      );
    }
    return replacement ?? '';
  });
};
