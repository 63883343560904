import {Button} from 'antd';
import {FC, useState} from 'react';
import {useTranslation} from '../translation';
import {useSignOutAndDeleteCookies, useUser} from '../util/Auth';

export const LogoutButton: FC = (props) => {
  const user = useUser();
  const signOutAndDeleteCookies = useSignOutAndDeleteCookies();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <Button
      disabled={!user}
      loading={loading}
      onClick={async () => {
        setLoading(true);
        await signOutAndDeleteCookies();
        setLoading(false);
      }}
    >
      {t('general.logout')}
    </Button>
  );
};
