import KRGlue from '@lyracom/embedded-form-glue';
import {ArticleType} from './component/table/Article';
import {LANGUAGE, SUPPORTED_LANGUAGE_LOCALE} from './translation';

const endpoint = 'https://api-sogecommerce.societegenerale.eu';

export enum SoGeCrendential {
  PARKING = 1,
  POOL,
}

export const getSogeCredentialTypeFromArticleType = (
  articleType: ArticleType,
): SoGeCrendential =>
  [
    ArticleType.PARKING,
    ArticleType.LOST_PARKING,
    ArticleType.PARKING_CLONE,
  ].includes(articleType)
    ? SoGeCrendential.PARKING
    : SoGeCrendential.POOL;

const getPublicKey = (type: SoGeCrendential) =>
  type === SoGeCrendential.PARKING
    ? process.env.REACT_APP_PARKING_SOGE_PUBLIC_KEY
    : process.env.REACT_APP_POOL_SOGE_PUBLIC_KEY;

interface CompleteSoGeCheckoutProps {
  formToken: string;
  articleType: ArticleType;
  form: HTMLDivElement;
  language?: LANGUAGE;
  onSubmit?: (paymentData: KRPaymentResponse) => void;
}

export const loadSoGeCheckout = async ({
  formToken,
  articleType,
  form,
  onSubmit,
  language = LANGUAGE.fr,
}: CompleteSoGeCheckoutProps) => {
  const publicKey = getPublicKey(
    getSogeCredentialTypeFromArticleType(articleType),
  );
  if (!publicKey) throw new Error('Missing SoGe publicKey env variable');

  const {KR} = await KRGlue.loadLibrary(endpoint, publicKey);

  await KR.setShopName('Cap Esterel');
  await KR.setFormConfig({
    formToken,
    'kr-language': SUPPORTED_LANGUAGE_LOCALE[language],
    // 'kr-read-only': true,
  });

  await KR.onSubmit(async (paymentData) => {
    // TODO check paymentData
    /* const response = await fetch('http://localhost:3000/validatePayment', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(paymentData),
    });
    if (response.status === 200) message.success('Payment successful!');
    else message.error('Payment error');

    submitRes = await response.json(); */
    onSubmit?.(paymentData);

    // Return false to prevent the redirection
    return false;
  });

  const {result} = await KR.attachForm(`#${form.id}`);

  console.log(result);
  await KR.showForm(result.formId);

  // await KR.submit();
};
