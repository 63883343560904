import {Select, SelectProps} from 'antd';
import {FC, useMemo, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {OwnerRecord} from '../table/Owner';

const singularRoute = '/owner';
const pluralRoute = '/owners';

/** same as OwnerSelect *except* we don't track "value/setValue"
 * in this component, we let antd handle that in Select.  */
export const OwnerSelect2: FC<SelectProps<string>> = ({...props}) => {
  const api = useApi();
  const [data, setData] = useState<OwnerRecord[]>([]);
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  const {value: initialValue} = props;
  const [initialFetchedValue, setInitialFetchedValue] = useState<string>();

  useMemo(() => {
    if (!api) return;
    if (!initialValue || value || loading) return;
    if (initialFetchedValue === initialValue) return;
    setInitialFetchedValue(initialValue);
    setLoading(true);

    (async () => {
      try {
        const owner = await api.get(
          DEFAULT_API_NAME,
          `${singularRoute}/${initialValue}`,
          {},
        );

        if (!owner) return;

        setData([owner]);
        setValue(owner.id);
      } finally {
        setLoading(false);
      }
    })();
  }, [api, initialValue, value, loading, initialFetchedValue]);

  const fetch = (value: string, callback: Function) => {
    if (!api) return;

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }

    const req = async () => {
      setLoading(true);
      try {
        const res = await api.get(DEFAULT_API_NAME, pluralRoute, {
          queryStringParameters: {
            limit: 10,
            f_name: value,
            sortKey: 'name',
            order: 'ASC',
          },
        });

        callback(res.entities);
      } finally {
        setLoading(false);
      }
    };

    setTimeoutId(setTimeout(req, 300));
  };

  const handleSearch = (id: string) => {
    if (id) {
      fetch(id, setData);
    } else {
      setData([]);
    }
  };

  return (
    <Select<string>
      {...props}
      showSearch
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.id,
        label: d.name,
      }))}
      loading={loading || props.loading}
    />
  );
};
