import {Select, SelectProps} from 'antd';
import {FC} from 'react';
import {useTranslation} from '../../translation';
import {VehicleType, VehicleTypeLabel} from '../table/Vehicles';

export const VehicleTypeSelect: FC<SelectProps<VehicleType>> = (props) => {
  const {t} = useTranslation();
  return (
    <Select
      options={Object.entries(VehicleTypeLabel).map(([type, label]) => ({
        label: t(label),
        value: Number(type),
      }))}
      {...props}
    />
  );
};
