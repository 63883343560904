import {PlusOutlined} from '@ant-design/icons';
import {Button, Form} from 'antd';
import {FC, useMemo, useState} from 'react';
import {
  formatCurrency,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {ContentDiv} from '../layout/ContentDiv';
import {ArticleRecord} from './Article';
import {OrderRecord} from './Order';
import {
  getValidateMessages,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';

const singularRoute = '/orderLine';
const pluralRoute = '/orderLines';

export interface OrderLineFormValues {
  unitPrice: number;
  VAT: number;
  quantity: number;
  totalPrice: number;
  netPrice: number;
  discount: number;

  articleId: string;
  orderId: string;
}

export interface OrderLineRecord extends OrderLineFormValues {
  id: string;
  article: ArticleRecord;
  order: OrderRecord;

  createDate: number;
  updateDate: number;
}

const orderLineInitialValues: Partial<OrderLineFormValues> = {};

export const OrderLineForm: FC<TableFormProps<OrderLineFormValues>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t} = useTranslation();
  useMemo(() => {
    form.setFieldsValue({
      ...orderLineInitialValues,
      ...initialValues,
    });
  }, [form, initialValues]);

  return (
    <Form<OrderLineFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      validateMessages={getValidateMessages(t)}
      autoComplete="off"
    >
      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

const parseOrderLineValues = (
  values: OrderLineFormValues,
): OrderLineFormValues => values;

export const getOrderLineColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<OrderLineRecord> => [
  {
    title: t('orderLines.article'),
    key: 'articleId',
    dataIndex: 'articleId',
    render: (_, {article}) => article?.label,
  },
  {
    title: t('orderLines.unitPrice'),
    key: 'unitPrice',
    dataIndex: 'unitPrice',
    align: 'right',
    className: 'align-title-center',
    render: (unitPrice: number) => formatCurrency(unitPrice, lang),
  },
  {
    title: t('orderLines.discount'),
    key: 'discount',
    dataIndex: 'discount',
    align: 'right',
    className: 'align-title-center',
    render: (discount = 0) => `${discount * 100}%`,
  },
  {
    title: t('orderLines.quantity'),
    key: 'quantity',
    dataIndex: 'quantity',
    align: 'right',
    className: 'align-title-center',
  },
  {
    title: t('orderLines.VAT'),
    key: 'VAT',
    dataIndex: 'VAT',
    align: 'right',
    className: 'align-title-center',
    render: (vat: number) => `${vat} %`,
  },
  {
    title: t('orderLines.netPrice'),
    key: 'netPrice',
    dataIndex: 'netPrice',
    align: 'right',
    className: 'align-title-center',
    render: (netPrice: number) => formatCurrency(netPrice, lang),
  },
];

export const OrderLines: FC<{style?: React.CSSProperties}> = (props) => {
  const {t, lang} = useTranslation();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<OrderLineRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: OrderLineForm,
    parseValues: parseOrderLineValues,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <ContentDiv
      title={
        total ? `${t('orderLines.title')}: ${total}` : t('orderLines.title')
      }
      titleRightComponent={
        <Button
          type="primary"
          onClick={() => setCreateModalOpen(true)}
          icon={<PlusOutlined />}
        />
      }
      style={props.style}
    >
      <TableList<OrderLineRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={getOrderLineColumns(t, lang)}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        onTotalChange={setTotal}
      />
      <TableCreateModal<OrderLineRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
      />
      <TableUpdateModal<OrderLineRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
      />
    </ContentDiv>
  );
};
