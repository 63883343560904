{
  "general.login": "Anmeldung",
  "general.logout": "Ausloggen",
  "general.updateTitle": "Änderung",
  "general.update": "Aktualisieren",
  "general.addTitle": "Hinzufügen",
  "general.add": "Hinzufügen",
  "general.yes": "Ja",
  "general.no": "Nein",
  "general.close": "Schließen",
  "general.cancel": "Absagen",
  "general.delete": "Löschen",
  "general.submit": "Einreichen",
  "general.notConnected": "Nicht verbunden",
  "general.uploadError": "Upload-Fehler",
  "general.form.messages.required": "Das Feld \"${label}\" ist obligatorisch",
  "general.form.messages.types_email": "Das Feld \"${label}\" ist keine gültige E-Mail-Adresse",
  "general.form.messages.types_number": "Das Feld \"${label}\" ist keine gültige Zahl",
  "general.form.messages.number_range": "Feld \"${label}\" muss zwischen ${min} und ${max} liegen",
  "general.connectionError": "Verbindungsfehler",
  "general.timeIntervalAgo": "vor ${interval}",
  "general.loginAction": "Ich habe einen Account",
  "general.registerAction": "Ich erstelle mein Konto",
  "general.upload": "Hochladen",
  "general.invalidUploadFileType": "Ungültiger Dateityp",
  "general.clearFilters": "Filter zurücksetzen",
  "general.clearSorters": "Sortierungen zurücksetzen",
  "general.reset": "Zurücksetzen",
  "general.search": "Forschen...",
  "general.searchSomething": "Suche nach ${label}...",
  "general.ok": "OK",
  "general.goBackHome": "Zurück nach Hause",
  "general.notFoundErrorMessage": "Seite nicht gefunden",
  "general.starMarksMandatory": "* felder sind erforderlich",
  "general.export": "Export",
  "general.continue": "Weitermachen",
  "general.createDate": "Erstellungsdatum",
  "general.updateDate": "Änderungsdatum",

  "general.overstaturationError.message1": "Aufgrund eines überfüllten Netzes werden Bestellungen für das weiße Schwimmbad vorübergehend ausgesetzt.",
  "general.overstaturationError.message2": "Wir entschuldigen uns für die entstandenen Unannehmlichkeiten und bitten Sie, den Vorgang innerhalb von 24/48 Stunden zu erneuern.",

  "general.sumupCheckError.message1": "Die Zahlung von White-Card-Bestellungen in CB ist derzeit für diese Anwendung wegen technischer Probleme ausgesetzt.",
  "general.sumupCheckError.message2": "Sie können Ihre Bestellungen per Überweisung aufgeben. Melden Sie dazu Ihre Bestellung mit der Zahlungsart Überweisung an. Sie können uns auch einen Scheck schicken:",
  "general.sumupCheckError.message3": "ASCAPE - Résidence du Belvédère - Boite N° 6 - 99 rue des Calanques - 83530 SAINT RAPHAEL",
  "general.sumupCheckError.message4": "Die Karten werden nach Zahlungseingang erstellt.",
  "general.sumupCheckError.message5": "An den Strandwärtern am Eingang zum Grand Bleu-Schwimmbad wurden Toleranzanweisungen erteilt, um Eigentümern und Mietern in diesem Zeitraum den Zugang mit den alten Karten zu ermöglichen.",
  "general.sumupCheckError.message6": "Wir entschuldigen uns für die entstandenen Unannehmlichkeiten.",

  "general.duration.days": "Tage",
  "general.duration.weeks": "Wochen",
  "general.duration.months": "Monat",
  "general.duration.years": "Jahre",

  "general.error.negativeDuration": "Das Startdatum muss vor dem Enddatum beginnen",

  "general.ownersArea": "Eigentümerbereich",
  "footer.theVillage": "Der Standort",
  "footer.theVillage.visit": "Besuch",
  "footer.theVillage.news": "Nachricht",
  "footer.theVillage.findAccomodation": "Unterkunft finden",
  "footer.theVillage.shopsAndServices": "Geschäfte und Dienstleistungen",
  "footer.theArea": "Das Gebiet",
  "footer.theArea.events": "Veranstaltungen",
  "footer.theArea.ourSelection": "CAP ESTEREL und seine Umgebung",
  "footer.theArea.infoAndResources": "Informationen und Ressourcen",
  "footer.ownersArea": "Eigentümerbereich",
  "footer.ownersArea.swimmingPoolOrParkingCards": "Pool- oder Parkkarten",
  "footer.ownersArea.administrativeDocuments": "Verwaltungsdokumente",
  "footer.ownersArea.makeAStatement": "Stellung nehmen",
  "footer.ownersArea.news": "Nachricht",
  "footer.ourNewsletter": "Unser Newsletter",
  "footer.yourEmail": "Deine E-Mail",
  "footer.newsletterSignup": "Abonnieren",
  "footer.newsletterConfidentialyText": "Ich akzeptiere die Datenschutzerklärung.",
  "footer.legalNotice": "Impressum",
  "footer.cookiePolicy": "Cookie-Richtlinie",
  "footer.privacyPolicy": "Datenschutz-Bestimmungen",
  "footer.termsOfSales": "Verkaufsbedingungen",

  "header.theVillage": "Das Dorf",
  "header.activities": "Aktivitäten",
  "header.tourism": "Tourismus",
  "header.news": "Nachricht",
  "header.contact": "Kontakt",
  "header.owner.documents": "Unterlagen",
  "header.owner.info": "Information",
  "header.owner.news": "Nachricht",
  "header.owner.orderTracking": "Sendungsverfolgung",
  "header.owner.myAccount": "Mein Konto",
  "header.owner.myVehicles": "Meine Fahrzeuge",
  "header.owner.myOccupants": "Meine Vermietungen",
  "header.admin.settings": "Einstellungen",
  "header.admin.apartments": "Wohnungen",
  "header.admin.owners": "Besitzer",
  "header.admin.orders": "Aufträge",
  "header.admin.invoices": "Rechnungen",
  "header.admin.payments": "Vorschriften",
  "header.admin.entries": "Schriften",
  "header.admin.stats": "Statistiken",

  "home.welcome": "Herzlich willkommen",
  "home.user": "Benutzer",
  "home.notConnected": "Nicht verbunden",
  "home.users": "Benutzer",
  "home.owners": "Besitzer",
  "home.ownerRequests": "Konten warten auf Überprüfung",
  "home.apartments": "Wohnungen",
  "home.hosts": "Gastgeber",
  "home.articles": "Artikel",

  "home.ordersUnavailable": "Das Modul zur Erfassung von Bestellungen für Schwimmbad- und Parkkarten befindet sich in der Testphase, es wird in Kürze freigeschaltet.\nVielen Dank für Ihr Verständnis",

  "home.settings": "Einstellungen",
  "home.stats": "Statistiken",
  "home.orders": "Aufträge",
  "home.invoices": "Rechnungen",
  "home.payments": "Vorschriften",
  "home.accountingEntries": "Schriften",
  "home.apartmentRequests": "Wohnungen in der Warteschleife",
  "home.cardsAwaitingProduction": "Karten zu machen",
  "home.cardsAwaitingDelivery": "Lieferkarten",
  "home.cardsGold": "Goldene karten",
  "home.vehicles": "Fahrzeuge",
  "home.occupants": "Vermietungen",
  "home.superAdmin": "Super admin",

  "home.unconfirmed.title": "Account Bestätigung",
  "home.unconfirmed.message1": "Bitte bestätigen Sie Ihre E-Mail-Adresse, indem Sie auf den Link in der erhaltenen E-Mail klicken.",
  "home.unconfirmed.message2": "Auf Wiedersehen",

  "home.unvalidated.title": "Neue Kontobestätigung",
  "home.unvalidated.message1": "Wir haben Ihre Anfrage zur Kontoerstellung erhalten.",
  "home.unvalidated.message2": "Es wird von unseren Diensten validiert.",
  "home.unvalidated.message3": "Eine Bestätigungs-E-Mail wird Ihnen in Kürze zugesandt.",
  "home.unvalidated.message4": "",
  "home.unvalidated.message5": "",
  "home.unvalidated.message6": "Auf Wiedersehen",

  "login.title": "Anmeldung",
  "login.loginActionButton": "Anmeldung",
  "login.error.pleaseInputUsername": "Bitte geben Sie Ihren Benutzernamen ein",
  "login.username": "Nutzername",
  "login.error.pleaseInputPassword": "Bitte geben Sie Ihr Passwort ein",
  "login.password": "Passwort",
  "login.forgotPassword": "Passwort vergessen",
  "login.home": "Startseite",
  "login.newPasswordTitle": "Neues Passwort",
  "login.newPasswordText": "Bitte geben Sie den Bestätigungscode ein, den Sie per E-Mail erhalten haben.",
  "login.newPassword": "Neues Passwort",
  "login.newPasswordConfirm": "Passwort Bestätigung",
  "login.forcePasswordTitle": "Passwort",
  "login.forcePasswordText": "Bitte wählen Sie ein Passwort.",

  "users.title": "Benutzer",
  "users.email": "Email",
  "users.name": "Name",
  "users.firstName": "Vorname",
  "users.lastName": "Nachname",
  "users.userTitle": "Titel",
  "users.userTitleMr": "Herr",
  "users.userTitleMrs": "Frau",
  "users.userTitleMrAndMrs": "Herr und Frau",
  "users.role": "Rolle",
  "users.roleNotVerified": "Nicht verifiziert",
  "users.roleUnvalidated": "Nicht validiert",
  "users.roleOwner": "Inhaber",
  "users.roleAdmin": "Administrator",
  "users.wordpressUserId": "WordPress-Benutzer-ID",
  "users.disabled": "Deaktiviert",
  "users.cognitoStatus": "Cognito status",
  "users.cognitoGroups": "Cognito groups",
  "users.resetPasswordAndSendEmail": "Passwort zurücksetzen und E-Mail senden",
  "users.changeEmailAndResetPassword": "Change email + reset password",
  "users.changeEmailAndResetPassword.info": "This will change the email address associated with the account and then initiate a password reset. An email will be sent to the user with their temporary password.",
  "users.updateEmail": "E-Mail Adresse ändern",
  "users.previousEmail": "Vorhandene E-Mail",
  "users.warning.updateEmail": "Bitte überprüfen Sie, ob der Benutzer Zugriff auf diese neue E-Mail-Adresse hat, bevor Sie sie ändern.\nSie wird von der Anwendung nicht überprüft und der Benutzer kann sich nicht sofort mit seinem alten Benutzernamen anmelden.",
  "users.updateEmail.email.subject": "E-Mail Adresse ändern",
  "users.updateEmail.email.body": "Hallo,\n\nIch möchte die mit meinem ${email}-Konto verknüpfte E-Mail-Adresse aktualisieren.\nMeine neue E-Mail-Adresse lautet wie folgt: \n\nGrüße,\n${name}",

  "owners.title": "Besitzer",
  "owners.code": "Code",
  "owners.displayRecipientFields": "Empfängerfelder (Bestellungen) anzeigen)",
  "owners.phoneNumber": "Telefon",

  "hosts.title": "Gastgeber",
  "hosts.name": "Name",
  "hosts.thirdParty": "Dritte Seite",
  "hosts.address": "Die Anschrift",
  "hosts.contactName": "Kontakt",
  "hosts.contactPhoneNumber": "Telefonnummer",
  "hosts.email": "Email",
  "hosts.comment": "Kommentar",

  "apartments.title": "Wohnungen",
  "apartments.code": "Code",
  "apartments.building": "Gebäude",
  "apartments.type": "Art",
  "apartments.doorNumber": "Türnummer",
  "apartments.beddings": "Bettwäsche",
  "apartments.host": "Gastgeber",
  "apartments.owner": "Inhaber",
  "apartments.condo": "Eigentumswohnung",
  "apartments.comment": "Kommentar",

  "apartments.unassign": "Zuweisung aufheben",
  "apartments.unassign.confirm": "Sind Sie sicher, dass Sie die Zuweisung dieses Apartments von diesem Eigentümer aufheben möchten?",

  "apartments.error.alreadyOwned": "Diese Wohnung ist bereits einem anderen Eigentümer zugeteilt",
  "apartments.error.alreadyOwnedBySelf": "Diese Wohnung ist diesem Eigentümer bereits zugeordnet",

  "articles.title": "Artikel",
  "articles.code": "Codiert",

  "articles.label": "Etikett",
  "articles.type": "Nett",
  "articles.season": "Jahreszeit",
  "articles.seasonStart": "Beginn der Saison",
  "articles.seasonEnd": "Saisonende",
  "articles.validityDuration": "Gültigkeitsdauer",
  "articles.validityDurationType": "Einheit der Gültigkeit",
  "articles.unitPriceInclVAT": "Alle Steuern inklusive Preis",
  "articles.VAT": "MwSt",
  "articles.accountingCode": "Konto Code",
  "articles.active": "Web-Asset",
  "articles.limitToBeddingQuota": "Schlafquote respektieren",
  "articles.beddingQuota": "Bettwäsche",
  "articles.isBlanche": "Blankovollmacht",
  "articles.accountingCategory": "Category",

  "article.type.parking": "Parkplatz",
  "article.type.poolBlanche": "Schwimmbad weiß",
  "article.type.poolGold": "Schwimmbad Gold",
  "article.type.poolGoldAdditional": "Schwimmbad Gold Add.",
  "article.type.lostParking": "Verlorene Parkplatz",
  "article.type.lostPool": "Verlorene Schwimmbad",
  "article.type.parkingClone": "Parking-Klon",
  "article.type.misc": "Sonstig",
  "article.accountingCategory.parking": "Parking",
  "article.accountingCategory.pool": "Pool",

  "article.inactive.ownerInvisible": "Dieser Artikeltyp wird Eigentümern nicht angezeigt",
  "article.inactive.season": "Dieser Artikel ist an eine inaktive Saison angehängt und wird Eigentümern nicht angezeigt",

  "register.title": "Anmeldung",
  "register.lastName": "Familienname, Nachname",
  "register.firstName": "Vorname",
  "register.title_gender": "Höflichkeit",
  "register.address": "Adresse (Ihres Hauptwohnsitzes)",
  "register.zipCode": "Postleitzahl",
  "register.city": "Stadt",
  "register.country": "Land",
  "register.phoneNumber": "Handy",
  "register.condoBuildingApartmentCode": "Kopro/Gebäude/Wohnung",
  "register.condo": "Kopro",
  "register.building": "Gebäude",
  "register.apartmentCode": "Wohnung",
  "register.email": "Email",
  "register.password": "Passwort",
  "register.passwordValidation": "Passwort Bestätigung",
  "register.submit": "Erstellen",
  "register.passwordValidationReject": "Die beiden eingegebenen Passwörter stimmen nicht überein",
  "register.mustBeInternational": "Die Telefonnummer muss mit einer internationalen Kennung +XX beginnen",
  "register.invalidPhoneNumber": "Telefonnummer muss gültig sein",
  "register.verificationCode": "Bestätigungscode",
  "register.pleaseEnterVerificationCode": "Bitte geben Sie den Bestätigungscode ein, den Sie per E-Mail erhalten haben.",
  "register.condoBuildingApartmentMessage": "(Informationen auf Ihrer Gebührenabrechnung)",

  "register.password.minLength": "Ihr Passwort muss mindestens ${minLength} Zeichen lang sein",
  "register.password.lower": "Ihr Passwort muss einen Kleinbuchstaben enthalten",
  "register.password.upper": "Ihr Passwort muss einen Großbuchstaben enthalten",
  "register.password.digit": "Ihr Passwort muss eine Ziffer enthalten",
  "register.password.symbol": "Ihr Passwort muss ein Sonderzeichen enthalten",

  "register.success.completeNewPassword": "Ihr Passwort wurde erfolgreich geändert. Bitte melden Sie sich mit Ihrem neuen Passwort an",
  "register.signUpError": "Anmeldefehler",
  "register.code": "Code",

  "cognito.error.UserNotFoundException": "Benutzer nicht gefunden",
  "cognito.error.UsernameExistsException": "E-Mail existiert bereits für einen bestehenden Benutzer",
  "cognito.error.NotAuthorizedException": "Zugriff verweigert",
  "cognito.error.CodeMismatchException": "Der Validierungscode ist ungültig",
  "cognito.error.UserNotConfirmedException": "Ihre E-Mail-Adresse wurde nicht bestätigt",

  "owner.error.cannotDeleteIfApartments": "Ein Eigentümer, der Apartments zugeordnet ist, kann nicht gelöscht werden. Bitte heben Sie die Zuweisung dieser Apartments auf",

  "ownerRequests.title": "Konten warten auf Überprüfung",
  "ownerRequests.fullName": "Vollständiger Name",
  "ownerRequests.lastName": "Nachname",
  "ownerRequests.firstName": "Vorname",
  "ownerRequests.title_gender": "Höflichkeit",
  "ownerRequests.address": "Die Anschrift",
  "ownerRequests.zipCode": "Postleitzahl",
  "ownerRequests.city": "Stadt",
  "ownerRequests.country": "Land",
  "ownerRequests.phoneNumber": "Telefonnummer",
  "ownerRequests.email": "Email",
  "ownerRequests.apartment": "Wohnung",
  "ownerRequests.createDate": "Erstellungsdatum",

  "ownerRequest.validate": "Bestätigen",
  "ownerRequest.validateWarning": "Möchten Sie diesen Eigentümer wirklich erstellen? Der Eigentümer wird per E-Mail benachrichtigt",
  "ownerRequest.validateError": "Die mit dieser Anfrage verknüpfte Wohnung ist bereits einem verifizierten Eigentümer zugeordnet",

  "orders.title": "Aufträge",
  "orders.newOrder": "Neue Bestellung",
  "orders.orderNumber": "Bestellnummer",
  "orders.owner": "Inhaber",
  "orders.orderedCards": "Karten bestellt",
  "orders.deliveredCards": "Karten geliefert",
  "orders.totalPrice": "Gesamtpreis inkl. MwSt",
  "orders.status": "Status",
  "orders.accountingStatus": "Kontostand",
  "orders.fabricationStatus": "Herstellungsstatus",
  "orders.articleQuantity": "Höhe",
  "orders.createDate": "Kaufdatum",
  "orders.createDate.short": "Kaufdatum",
  "orders.apartment": "Wohnung",
  "orders.article": "Artikel",
  "orders.season": "Jahreszeit",
  "orders.articleType": "Artikeltyp",
  "orders.validityStart": "Gültigkeitsbeginn",
  "orders.validityEnd": "Ende der Gültigkeit",
  "orders.comment": "Anmerkung",
  "orders.paymentType": "Zahlungsart",
  "orders.isProxy": "Proxy",
  "orders.isProxy.form": "Ich erteile Vollmacht an einen Dritten",
  "orders.proxy": "Vollständiger Name des Proxys",
  "orders.proxyTemplateDownload": "Laden Sie das Proxy-PDF zum Ausfüllen herunter",
  "orders.proxyFile": "Ausgefülltes Proxy-PDF",
  "orders.type": "Nett",
  "orders.discount": "Rabatt",
  "orders.netPrice": "Nettopreis",
  "orders.checkoutId": "ID-Panier",
  "orders.checkNumber": "Schecknummer",
  "orders.isRenewal": "Karte erneuert werden",
  "orders.isRenewal.short": "Erneuern",
  "orders.iHaveAClone": "Ich habe einen Klon",
  "orders.recipient": "Empfänger",
  "orders.recipientName": "Name (empfänger)",
  "orders.recipientAddr": "Adresse (empfänger)",
  "orders.recipientZipCodeAndCity": "Postleitzahl - Stadt (empfänger)",
  "orders.recipientZipCode": "Postleitzahl",
  "orders.recipientCity": "Stadt",
  "orders.recipientCountry": "Land (empfänger)",
  "orders.nbGoldCards": "Anzahl der Goldkarten",
  "orders.renewalCardNo": "Geben Sie die Nummer oder den Nachnamen/Vornamen aller zu verlängernden Karten ein, getrennt durch ein Komma",
  "orders.renewalCardNo.short": "Karten müssen erneuert werden",
  "orders.renewalCardNo.gold": "Tragen Sie in der Tabelle rechts den Namen der Person oder die Anzahl der Goldkarten ein, die erneuert werden sollen",

  "orders.regeneratePDF.confirm": "Are you sure you want to regenerate PDF for this order? This will erase current PDF (if any).",
  "orders.regeneratePDF.success": "PDF has been regenerated successfully",
  "orders.regeneratePDF.error": "An error occurred while regenerating the PDF.",

  "orders.type.invoice": "Rechnung",
  "orders.type.credit": "Haben",

  "orders.accountingStatus.submitted": "Gerettet",
  "orders.accountingStatus.printed": "gedruckt",
  "orders.accountingStatus.paid": "Bezahlt",
  "orders.accountingStatus.accounted": "Gezählt",
  "orders.accountingStatus.failed": "Fehlgeschlagen",

  "orders.fabricationStatus.submitted": "Gerettet",
  "orders.fabricationStatus.paid": "Bezahlt",
  "orders.fabricationStatus.available": "Erhältlich",
  "orders.fabricationStatus.delivered": "Lackierung",

  "order.paymentType.paypal": "PayPal",
  "order.paymentType.sumup": "Kreditkarte (SumUp)",
  "order.paymentType.wire": "Banküberweisung",
  "order.paymentType.check": "Überprüfen",
  "order.paymentType.cash": "Spezies",
  "order.paymentType.pos": "Elektronisches Zahlungsterminal",
  "order.paymentType.soge": "Kreditkarte",
  "orders.payment.wireInstructions": "Bankdaten",
  "orders.payment.iban": "IBAN",
  "orders.payment.bic": "BIC",
  "orders.payment.checkInstructions": "Details prüfen",
  "orders.payment.checkOrder": "Befehl",
  "orders.payment.checkAddress": "Adresse",
  "orders.securePayment": "Sichere Bezahlung",

  "order.modal.title": "Bestellung bestätigen",
  "order.modal.confirm": "Bestätigen Sie mit Zahlungsverpflichtung",
  "order.modal.summary": "Zusammenfassung",
  "orders.confirm.terms.prefix": "Ich habe die ",
  "orders.confirm.terms.link": "Verkaufsbedingungen",
  "orders.confirm.terms.suffix": " gelesen und stimme ihnen zu",

  "order.submitSuccess": "Ihre Bestellung wurde erfolgreich versendet",

  "order.error.noArticles": "Keine Artikel für diese Wohnung / Jahreszeiten verfügbar. Bitte wenden Sie sich an unseren Support",

  "orderLines.title": "Befehlszeilen",

  "orderLines.article": "Artikel",
  "orderLines.unitPrice": "Einzelpreis",
  "orderLines.quantity": "Höhe",
  "orderLines.VAT": "MwSt",
  "orderLines.totalPrice": "Gesamtpreis",
  "orderLines.discount": "Rabatt",
  "orderLines.netPrice": "Nettopreis",
  "orderLines.customNetPrice": "Manueller Preis",

  "card.create": "Hinzufügen einer personalisierten Karte",
  "card.upload.warning": "Nur erkennbare Fotos (ohne Hut, ohne Sonnenbrille etc.)",

  "cards.title": "Karten",
  "card.lastName": "Nachname",
  "card.firstName": "Vorname",
  "card.isChild": "Kind < 6 Jahre alt",
  "card.cost": "Steigend",
  "card.photo": "Foto",

  "seasons.title": "Jahreszeiten",
  "seasons.code": "Code",
  "seasons.dateStart": "Anfangsdatum",
  "seasons.dateEnd": "Endtermin",
  "seasons.active": "Aktiv im Web",

  "confirm.title": "Bestätigung",
  "confirm.email": "Email",
  "confirm.code": "Codiert",
  "confirm.submit": "Bestätigtr",

  "settings.title": "Einstellungen",
  "settings.nextOrderId": "Nächste Bestellnummer",
  "settings.nextInvoiceId": "Nächste Rechnungsnummer",
  "settings.nextThirdPartyId": "Nächste Drittanbieternummer",
  "settings.vatRate": "MwSt.-Satz",
  "settings.bankAccountClient": "Kundenkonto",
  "settings.bankAccountSales": "Verkaufskonto",
  "settings.bankAccountVat": "Mehrwertsteuerkonto",
  "settings.bankAccountBank": "Bankkonto",
  "settings.journalSales": "Zeitungsverkauf",
  "settings.journalBank": "Bankzeitung",
  "settings.iban": "IBAN",
  "settings.bic": "BIC",
  "settings.poolIban": "Pool-IBAN",
  "settings.poolBic": "Pool-BIC",
  "settings.parkingIban": "Parkplatz-IBAN",
  "settings.parkingBic": "Park-BIC",
  "settings.settingsTab": "Einstellungen / Gastgeber",
  "settings.articlesTab": "Artikel / Jahreszeiten",
  "settings.adminUsersTab": "Admin-Benutzer",
  "settings.adminAllUsersTab": "Benutzer (alle)",
  "settings.checkOrder": "Befehl",
  "settings.checkAddress": "Adresse",
  "settings.disableOrderCreationOnClientPortal": "Deaktivieren der Auftragserstellung im Kundenportal",

  "apartmentRequests.title": "Wohnungsgesuche",
  "apartmentRequests.requestTitle": "Anfrage zum Hinzufügen einer neuen Wohnung",
  "apartmentRequests.fullName": "Inhaber",
  "apartmentRequests.email": "Besitzer-E-Mail",
  "apartmentRequests.code": "Codiert",
  "apartmentRequests.createDate": "Erstellungsdatum",

  "apartmentRequests.alreadyOwned": "Diese Wohnung ist bereits mit Ihrem Konto verknüpft",
  "apartmentRequests.alreadyRequested": "Diese Wohnung steht bereits zur Verifizierung aus",

  "apartmentRequests.validateWarning": "Sind Sie sicher, dass Sie dieses Apartment mit diesem Eigentümer verknüpfen möchten? Der Eigentümer wird per E-Mail benachrichtigt.",
  "apartmentRequests.validateError": "Die mit dieser Anfrage verknüpfte Wohnung ist bereits mit einem Eigentümer verknüpft",

  "creditCard.title": "Kreditkarte",
  "creditCard.name": "Name des Halters",
  "creditCard.number": "Kartennummer",
  "creditCard.expiryMonth": "Monat des Ablaufens",
  "creditCard.expiryYear": "Ablaufjahr",
  "creditCard.cvv": "Sicherheitscode",

  "creditCard.error.invalidCC": "Ungültige Kartennummer",
  "creditCard.error.expiredCC": "Abgelaufene Karte",
  "creditCard.error.invalidCVC": "Ungültiger Sicherheitscode",

  "checkoutResult.pending": "Zahlung wartet auf Bestätigung",

  "checkoutResult.notFound.title": "Kasse nicht gefunden",
  "checkoutResult.notFound.subTitle": "Wir konnten die Checkout-Informationen Ihrer Bestellung nicht abrufen. Bitte kontaktieren Sie unseren Support für weitere Informationen",

  "checkoutResult.notCompleted.title": "Kasse wurde abgebrochen oder ist fehlgeschlagen",
  "checkoutResult.notCompleted.subTitle": "Ihre Bestellung wurde Ihnen nicht in Rechnung gestellt. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Support für weitere Informationen",

  "checkoutResult.success.title": "Checkout-Erfolg",
  "checkoutResult.success.subTitle": "Ihre Zahlung wurde erfolgreich empfangen und Ihre Bestellung wird bald bearbeitet.",

  "invoices.title": "Rechnungen",
  "invoices.createDate": "Erstellungsdatum",
  "invoices.invoicedName": "Name",
  "invoices.amount": "Menge",
  "invoices.invoiceNumber": "Rechnungsnummer",
  "invoices.status": "Status",
  "invoices.type": "Typ",

  "payments.title": "Zahlungen",
  "payments.type": "Typ",
  "payments.paymentDate": "Zahlungsdatum",
  "payments.payerName": "Name",
  "payments.label": "Etikett",
  "payments.amount": "Menge",
  "payments.invoiceNumber": "Rechnungsnummer",
  "payments.isAccounted": "Buchhaltung",
  "payments.updateOrderStatus": "Diese Zahlung bezahlt die Bestellung Nr. ${orderNumber}",

  "payments.warn.unexpectedOrderStatus": "Bestellung ${orderNumber} ist nicht mehr ausstehend, ihr aktueller Status ist \"${orderStatus}\"",
  "payments.warn.unexpectedPaymentAmount": "Die Bestellung ${orderNumber} hat einen Gesamtbetrag von ${orderAmount}, aber die aktuelle Zahlung beträgt ${paymentAmount}",
  "payments.warn.unexpectedInvoiceOrderNumber": "Die der Rechnung zugeordnete Bestellung unterscheidet sich von der der Zahlung zugeordneten Bestellung",

  "payments.error.amountMustBePositiveAndGreaterThanZero": "Der Zahlungsbetrag muss unbedingt größer als 0,00 sein",

  "cardsAwaitingProduction.title": "Karten warten auf Prod",
  "cardsAwaitingDelivery.title": "Karten warten auf Lieferung",

  "cards.startDate": "Datum",
  "cards.order": "Befehl",
  "cards.name": "Name",
  "cards.apartment": "Wohnung",
  "cards.article": "Artikel",
  "cards.count": "Bestellte Kartenzählung",
  "cards.fabricatedCount": "Fabrizierte Kartenzählung",
  "cards.deliveredCount": "Gelieferte Kartenanzahl",
  "cards.remainder": "Rest",
  "cards.amount": "Menge",
  "cards.photo": "Foto",

  "cards.bulkDeliverRenewals": "Alle zu erneuernden Karten ausliefern",

  "stats.title": "Statistiken",
  "stats.cognitoStats": "Benutzerstatistiken",
  "stats.cognito.total": "Registrierte Benutzer",
  "stats.cognito.pending": "Benutzer, die ihr Passwort geändert haben",
  "stats.salesStats": "Verkaufsstatistiken",
  "stats.offSeason": "Außerhalb der Saison?",
  "stats.card.goldSeasonalExport": "Gelieferter Gold-Saisonexport",

  "entries.title": "Einträge",
  "entries.export": "Einträge exportieren",
  "entries.exportStartDate": "Rechnungsstartdatum",
  "entries.exportEndDate": "Enddatum der Rechnunge",

  "cardsGold.title": "Goldene karten",

  "vehicles": "Fahrzeuge",
  "vehicles.title": "Fahrzeuge",
  "vehicles.createDate": "Erstellungsdatum",
  "vehicles.updateDate": "Änderungsdatum",
  "vehicles.date": "Datum",
  "vehicles.active": "Aktiv",
  "vehicles.marked": "Markiert",
  "vehicles.type": "Art",
  "vehicles.type.owner": "Eigentümer",
  "vehicles.type.occupant": "Mieter",
  "vehicles.ownerId": "Eigentümer",
  "vehicles.apartmentId": "Wohnung",
  "vehicles.brandName": "Marke",
  "vehicles.model": "Modell",
  "vehicles.licensePlateNo": "Nummernschild",
  "vehicles.licensePlateNo.short": "Nummernschild",
  "vehicles.occupantName": "Name des Mieters",
  "vehicles.phoneNumber": "Telefon",
  "vehicles.dates": "Termine",
  "vehicles.startDate": "Startdatum",
  "vehicles.endDate": "Endtermin",
  "vehicles.error.already_three_vehicles_for_this_owner": "Für diesen Besitzer sind bereits 3 aktive Fahrzeuge vorhanden.",
  "vehicles.error.already_one_vehicle_for_this_period": "Für diesen Zeitraum gibt es bereits ein Fahrzeug.",
  "vehicles.newOccupant": "Neufahrzeug (Miete)",
  "vehicles.newVehicle": "Neues Fahrzeug"
}
