import {Button} from 'antd';
import {FilterValue} from 'antd/es/table/interface';
import saveAs from 'file-saver';
import {FC, useState} from 'react';
import {useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {ApartmentRecord} from './Apartment';
import {getFiltersForAPI, TableColumnsProp} from './Table';

const pluralRoute = '/apartments';

export const ApartmentExportButton: FC<{
  apartmentColumns: TableColumnsProp<ApartmentRecord>;
  exportFilter: Record<string, FilterValue | null>;
}> = (props) => {
  const {apartmentColumns, exportFilter} = props;
  const {t} = useTranslation();
  const api = useApi();

  const [loading, setLoading] = useState(false);

  return (
    <Button
      loading={loading}
      onClick={async () => {
        if (!api) return;
        if (loading) return;

        setLoading(true);
        try {
          const csv = (await api.get(
            DEFAULT_API_NAME,
            `${pluralRoute}/export`,
            {
              queryStringParameters: {
                ...getFiltersForAPI(exportFilter, {}, apartmentColumns),
              },
            },
          )) as string;

          console.log(csv);

          const fileName = `export-${new Date()
            .toISOString()
            .replace(/:/g, '-')}`;

          saveAs(
            new Blob(['\uFEFF' + csv], {
              type: 'text/csv; charset=UTF-8',
            }),
            `${fileName}.csv`,
            {
              autoBom: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }}
      type="primary"
    >
      {t('general.export')}
    </Button>
  );
};
