import {Tabs, TabsProps} from 'antd';
import {FC} from 'react';
import {useUserIsLGAdmin} from '../../util/Auth';
import {ContentDiv} from '../layout/ContentDiv';
import {SuperAdminAllUsersTable} from '../table/User';
import {SuperAdminMisc} from './SuperAdminMisc';

export const SuperAdminPage: FC = (props) => {
  const isLGAdmin = useUserIsLGAdmin();

  if (!isLGAdmin) {
    return <div />;
  }

  const items: TabsProps['items'] = [
    {
      key: 'users',
      label: 'Utilisateurs',
      children: (
        <ContentDiv>
          <SuperAdminAllUsersTable />
        </ContentDiv>
      ),
    },
    {
      key: 'misc',
      label: 'Misc',
      children: (
        <ContentDiv title="Misc">
          <SuperAdminMisc />
        </ContentDiv>
      ),
    },
  ];

  return <Tabs defaultActiveKey="users" items={items} />;
};
