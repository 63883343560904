import {green, red} from '@ant-design/colors';
import {CarOutlined} from '@ant-design/icons';
import {Button, message} from 'antd';
import {API} from 'aws-amplify';
import {FC, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../util/Auth';
import {VehicleRecord} from './table/Vehicles';

const markVehicle = async (
  api: typeof API | null,
  vehicleId: string,
  marked: boolean,
): Promise<{url: string; status: number}> => {
  if (!api) {
    throw new Error('Expected API object');
  }

  const res = await api.post(DEFAULT_API_NAME, `/vehicle/${vehicleId}/mark`, {
    body: {
      marked,
    },
  });

  return res;
};

export const VehicleMarkButton: FC<{
  vehicle: Pick<VehicleRecord, 'id' | 'marked'>;
}> = (props) => {
  const {vehicle} = props;
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [marked, setMarked] = useState(vehicle.marked ?? false);

  const color = marked ? green[5] : red[5];

  return (
    <Button
      icon={<CarOutlined style={{color: 'white'}} />}
      style={{background: color, borderColor: color}}
      onClick={async () => {
        if (loading) {
          return;
        }
        setLoading(true);

        try {
          await markVehicle(api, vehicle.id, !marked);
          setMarked(!marked);
        } catch (err: any) {
          message.warning(err.message);
          console.log(err);
        }

        setLoading(false);
      }}
    />
  );
};
