import {QuestionCircleOutlined, SearchOutlined} from '@ant-design/icons';
import styled from '@emotion/styled';
import {useRequest} from 'ahooks';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import {ColumnType} from 'antd/es/table';
import {isAxiosError} from 'axios';
import dayjs, {Dayjs} from 'dayjs';
import saveAs from 'file-saver';
import {FC, useMemo, useState} from 'react';
import {
  formatCurrency,
  getDateFormat,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {DayjsDateToTimestamp} from '../../util/date';
import {getIntervalEndDateRule} from '../input/IntervalInput';
import {ContentDiv} from '../layout/ContentDiv';
import {ArticleRecord} from '../table/Article';
import {SeasonRecord} from '../table/Season';

const MaxWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

interface AdminCognitoStats {
  registeredOwners: number;
  registeredOwnersPendingPasswordChange: number;
}

const CognitoStats: FC = () => {
  const api = useApi();
  const {t} = useTranslation();

  const fetchCognitoStats = async (): Promise<
    AdminCognitoStats | undefined
  > => {
    if (!api) return;
    const data = await api.get(DEFAULT_API_NAME, '/admin/stats/cognito', {});
    return data;
  };

  const {data: cognitoStats, loading: cognitoStatsLoading} = useRequest(
    fetchCognitoStats,
    {
      refreshDeps: [api],
    },
  );

  if (cognitoStatsLoading) return <Spin size="large" />;

  return (
    <>
      <AppTitle level={4} style={{marginTop: 0}}>
        {t('stats.cognitoStats')}
      </AppTitle>
      {cognitoStats ? (
        <Descriptions bordered layout="vertical" size="small">
          <Descriptions.Item
            label={<Row justify="center">{t('stats.cognito.total')}</Row>}
          >
            <Row justify="end">{cognitoStats.registeredOwners}</Row>
          </Descriptions.Item>
          <Descriptions.Item
            label={<Row justify="center">{t('stats.cognito.pending')}</Row>}
          >
            <Row justify="end">
              {cognitoStats.registeredOwnersPendingPasswordChange}
            </Row>
          </Descriptions.Item>
        </Descriptions>
      ) : null}
    </>
  );
};

interface CognitoLookupForm {
  email: string;
}

const CognitoLookup: FC = () => {
  const api = useApi();
  const [form] = Form.useForm<CognitoLookupForm>();
  const {t} = useTranslation();

  const fetchCognitoStatus = async (): Promise<string | undefined> => {
    form.validateFields();
    const {email} = form.getFieldsValue();
    if (!api || !email) return;

    try {
      const data = await api.get(
        DEFAULT_API_NAME,
        `/user/cognitoStatus/${email}`,
        {},
      );
      return data;
    } catch (err) {
      if (isAxiosError(err) && err.response?.status === 404) {
        return 'NOT_FOUND';
      }
    }
  };

  const {
    data: cognitoStatus,
    loading: cognitoStatusLoading,
    run,
  } = useRequest(fetchCognitoStatus, {
    refreshDeps: [api],
    manual: true,
  });

  return (
    <>
      <AppTitle level={4} style={{marginTop: 0}}>
        {t('users.cognitoStatus')}
      </AppTitle>
      <Form<CognitoLookupForm> form={form} onFinish={run}>
        <Form.Item
          rules={[{required: true}]}
          name="email"
          label={t('users.email')}
        >
          <Space.Compact style={{width: '100%'}}>
            <Input type="email" />
            <Button
              htmlType="submit"
              type="primary"
              loading={cognitoStatusLoading}
              icon={<SearchOutlined />}
            />
          </Space.Compact>
        </Form.Item>
      </Form>
      {cognitoStatus ? (
        <div>
          <Space>
            <a
              href="https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UserType.html#CognitoUserPools-Type-UserType-UserStatus"
              target="_blank"
              rel="noreferrer"
            >
              <QuestionCircleOutlined />
            </a>
            <span>
              {t('users.cognitoStatus')}: <code>{cognitoStatus}</code>
            </span>
          </Space>
        </div>
      ) : null}
    </>
  );
};

interface SalesStatsFormValues {
  seasonId: string;
  offSeason: boolean;
  startDate: Dayjs;
  endDate: Dayjs;
}

interface AdminSalesStatRecord {
  quantity: number;
  netPrice: number;
  article: ArticleRecord;
}

interface AdminSalesStats {
  salesStats: {[articleId: string]: AdminSalesStatRecord};
}

const getSalesStatsColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): ColumnType<AdminSalesStatRecord>[] => [
  {
    title: t('articles.code'),
    key: 'article.code',
    dataIndex: 'article.code',
    sorter: (a, b) => a.article.code.localeCompare(b.article.code),
    render: (_, {article}) => article?.code,
  },
  {
    title: t('orderLines.quantity'),
    key: 'quantity',
    dataIndex: 'quantity',
    sorter: (a, b) => a.quantity - b.quantity,
  },
  {
    title: t('orderLines.totalPrice'),
    key: 'netPrice',
    dataIndex: 'netPrice',
    sorter: (a, b) => a.netPrice - b.netPrice,
    render: (netPrice) => formatCurrency(netPrice, lang),
  },
];

const SalesStats: FC = () => {
  const api = useApi();
  const {t, lang} = useTranslation();

  const [form] = Form.useForm<SalesStatsFormValues>();
  const seasonIdValue = Form.useWatch('seasonId', form);
  const isOffSeason = Form.useWatch('offSeason', form);
  const startDateValue = Form.useWatch('startDate', form);
  const endDateValue = Form.useWatch('endDate', form);

  const fetchSeasons = async (): Promise<SeasonRecord[] | undefined> => {
    if (!api) return;
    const data = await api.get(DEFAULT_API_NAME, '/seasons', {});
    return data?.entities ?? [];
  };
  const {data: seasons, loading: seasonsLoading} = useRequest(fetchSeasons, {
    refreshDeps: [api],
  });

  const fetchSalesStats = async (): Promise<
    AdminSalesStatRecord[] | undefined
  > => {
    if (!api) return [];
    if (!isOffSeason && !seasonIdValue) return [];
    if (isOffSeason && (!startDateValue || !endDateValue)) return [];

    try {
      const data = (await api.get(
        DEFAULT_API_NAME,
        isOffSeason
          ? `/admin/stats/sales/offSeason/` +
              [startDateValue.startOf('day'), endDateValue.endOf('day')]
                .map(DayjsDateToTimestamp)
                .join('/')
          : `/admin/stats/sales/season/${seasonIdValue}`,
        {},
      )) as AdminSalesStats;

      return Object.values(data.salesStats).sort(
        (s1, s2) => s2.netPrice - s1.netPrice,
      );
    } catch (err) {
      return [];
    }
  };
  const {data: salesStats, loading: salesStatsLoading} = useRequest(
    fetchSalesStats,
    {
      refreshDeps: [
        api,
        seasonIdValue,
        isOffSeason,
        startDateValue,
        endDateValue,
      ],
    },
  );

  const [exportLoading, setExportLoading] = useState(false);
  const handleGoldSeasonalExportClick = async () => {
    if (!api) return;
    setExportLoading(true);
    try {
      const {csv} = (await api.get(
        DEFAULT_API_NAME,
        `/admin/stats/cardsExport/gold/season/${seasonIdValue}`,
        {},
      )) as {csv: string};

      const fileName = `export-${new Date().toISOString().replace(/:/g, '-')}`;

      saveAs(
        new Blob(['\uFEFF' + csv], {
          type: 'text/csv; charset=UTF-8',
        }),
        `${fileName}.csv`,
        {
          autoBom: true,
        },
      );
    } finally {
      setExportLoading(false);
    }
  };

  const columns = useMemo(() => getSalesStatsColumns(t, lang), [t, lang]);

  return (
    <>
      <AppTitle level={4} style={{marginTop: 0}}>
        {t('stats.salesStats')}
      </AppTitle>

      <Form<SalesStatsFormValues>
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          startDate: dayjs().utc().startOf('year'),
          endDate: dayjs().utc().endOf('year'),
        }}
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="seasonId"
              label={t('articles.season')}
              rules={[{required: true}]}
            >
              <Select
                options={seasons?.map(({id, code}) => ({
                  value: id,
                  label: code,
                }))}
                loading={seasonsLoading}
                disabled={isOffSeason}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="offSeason"
              label={t('stats.offSeason')}
              valuePropName="checked"
              className="force-horizontal"
              style={{marginTop: 35}}
            >
              <Checkbox
                onChange={({target: {checked}}) => {
                  if (checked) form.resetFields(['seasonId']);
                }}
              />
            </Form.Item>
          </Col>
          {isOffSeason ? (
            <>
              <Col span={12}>
                <Form.Item
                  name="startDate"
                  label={t('orders.validityStart')}
                  rules={[{required: true}]}
                >
                  <MaxWidthDatePicker format={getDateFormat(lang)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="endDate"
                  label={t('orders.validityEnd')}
                  rules={[
                    {required: true},
                    getIntervalEndDateRule({
                      startDateFieldName: 'startDate',
                      t,
                    }),
                  ]}
                >
                  <MaxWidthDatePicker format={getDateFormat(lang)} />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={8} style={{textAlign: 'right'}}>
              <Button
                type="primary"
                disabled={!seasonIdValue}
                loading={exportLoading}
                onClick={handleGoldSeasonalExportClick}
                style={{marginTop: 35}}
              >
                {t('stats.card.goldSeasonalExport')}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
      {salesStats && !salesStatsLoading ? (
        <Table columns={columns} dataSource={salesStats} pagination={false} />
      ) : seasonIdValue ? (
        <Spin size="large" />
      ) : null}
    </>
  );
};

export const Stats: FC = () => {
  const {t} = useTranslation();

  return (
    <ContentDiv title={t('stats.title')}>
      <Row gutter={20}>
        <Col span={18}>
          <SalesStats />
        </Col>
        <Col span={6}>
          <div>
            <CognitoStats />
          </div>
          <div style={{marginTop: 20}}>
            <CognitoLookup />
          </div>
        </Col>
      </Row>
    </ContentDiv>
  );
};
