import {App as AntdApp, ConfigProvider} from 'antd';
import {FC, useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import {AdminOrder} from './component/entity/AdminOrder';
import {Owner} from './component/entity/Owner';
import {OwnerOrder} from './component/entity/OwnerOrder';
import {OwnerOrders} from './component/entity/OwnerOrders';
import {OwnerProfile} from './component/entity/OwnerProfile';
import {Stats} from './component/entity/Stats';
import {Home} from './component/Home';
import {AppContent} from './component/layout/AppContent';
import {AppFooter} from './component/layout/AppFooter';
import {AppHeader} from './component/layout/AppHeader';
import {AppLayout} from './component/layout/AppLayout';
import {AppNotFoundPage} from './component/layout/AppNotFoundPage';
import {LoginPage} from './component/Login';
import {SettingsPage} from './component/Settings';
import {Apartment} from './component/table/Apartment';
import {AdminOrders} from './component/table/Order';
import {Owner as Owners} from './component/table/Owner';
import {OwnerRequest} from './component/table/OwnerRequest';
import {getValidateMessages} from './component/table/Table';
import {Users} from './component/table/User';
import {BASE_FONT_FAMILY, COLORS} from './design';
import {
  getLocaleFromLang,
  TranslationProvider,
  useTranslation,
} from './translation';
import {AdminRoute} from './util/AdminRoute';
import {AuthProvider} from './util/Auth';
import {LoggedInRoute} from './util/LoggedInRoute';
import {Page} from './util/Page';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import {SumupSecureCheckoutSuccess} from './component/SumupSecureCheckoutSuccess';
import {ApartmentRequests} from './component/table/ApartmentRequest';
import {
  CardsAwaitingDelivery,
  CardsAwaitingProduction,
} from './component/table/Cards';
import {Invoices} from './component/table/Invoices';
import {Payments} from './component/table/Payments';
import {Entries} from './component/entity/Entries';
import {AdminGoldCards} from './component/table/GoldCards';
import {SuperAdminPage} from './component/SuperAdmin/SuperAdmin';
import {AdminVehicles} from './component/table/Vehicles';
import {
  AdminVehicleCreate,
  AdminVehicleUpdate,
} from './component/entity/AdminVehicle';
import {
  OwnerVehicleCreate,
  OwnerVehicles,
  OwnerVehicleUpdate,
} from './owner/vehicles/OwnerVehicles';
import {
  OccupantVehicleCreate,
  OccupantVehicles,
  OccupantVehicleUpdate,
} from './owner/vehicles/OccupantVehicles';

/*<PayPalScriptProvider
    options={{
    'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID ?? '',
    'buyer-country': 'FR',
    locale: 'fr_FR',
    sdkBaseURL: process.env.REACT_APP_PAYPAL_ENDPOINT,
    debug: !!process.env.REACT_APP_PAYPAL_DEBUG,
    currency: 'EUR',
  }}
></PayPalScriptProvider>*/

interface RouteProps {
  key: string;
  path: string;
  title?: string;
  RouteFC: FC;
  bigHeader?: boolean;
}

const routes: RouteProps[] = [
  {key: 'home', path: '/', RouteFC: Home},
  {
    key: 'reset',
    path: '/login/reset/:resetPasswordEmail?',
    title: 'login.title',
    RouteFC: LoginPage,
    bigHeader: false,
  },
  {
    key: 'login',
    path: '/login/:encodedCredentials?',
    title: 'login.title',
    RouteFC: LoginPage,
    bigHeader: false,
  },
];
const ownerRoutes: RouteProps[] = [
  {
    key: 'orders',
    path: '/owner/orders',
    title: 'orders.title',
    RouteFC: OwnerOrders,
  },
  {
    key: 'order',
    path: '/owner/order/:id?',
    title: 'orders.title',
    RouteFC: OwnerOrder,
  },
  {
    key: 'profile',
    path: '/owner/profile',
    title: 'header.owner.myAccount',
    RouteFC: OwnerProfile,
  },
  {
    key: 'sumupSuccess',
    path: '/order/sumup/success',
    // title: 'header.owner.myAccount',
    RouteFC: SumupSecureCheckoutSuccess,
  },
  {
    key: 'vehicles',
    path: '/owner/vehicles',
    title: 'home.vehicles',
    RouteFC: OwnerVehicles,
  },
  {
    key: 'vehicle-update',
    path: '/owner/vehicle/:id',
    title: 'vehicles.title',
    RouteFC: OwnerVehicleUpdate,
  },
  {
    key: 'vehicle-create',
    path: '/owner/vehicle',
    title: 'vehicles.title',
    RouteFC: OwnerVehicleCreate,
  },
  {
    key: 'occupants',
    path: '/owner/occupants',
    title: 'home.occupants',
    RouteFC: OccupantVehicles,
  },
  {
    key: 'occupant-update',
    path: '/owner/occupant/:id',
    title: 'home.occupants',
    RouteFC: OccupantVehicleUpdate,
  },
  {
    key: 'occupant-create',
    path: '/owner/occupant',
    title: 'home.occupants',
    RouteFC: OccupantVehicleCreate,
  },
];

const adminRoutes: RouteProps[] = [
  // TODO switch to a admin user management page
  {
    key: 'users',
    path: '/admin/users',
    title: 'users.title',
    RouteFC: Users,
  },
  {
    key: 'orders',
    path: '/admin/orders',
    title: 'orders.title',
    RouteFC: AdminOrders,
  },
  {
    key: 'order',
    path: '/admin/order',
    title: 'orders.title',
    RouteFC: AdminOrder,
  },
  {
    key: 'owners',
    path: '/admin/owners',
    title: 'owners.title',
    RouteFC: Owners,
  },
  {
    key: 'owner',
    path: '/admin/owner/:id',
    title: 'owners.title',
    RouteFC: Owner,
  },
  {
    key: 'stats',
    path: '/admin/stats',
    title: 'home.stats',
    RouteFC: Stats,
  },
  {
    key: 'accountingEntries',
    path: '/admin/accountingEntries',
    title: 'home.accountingEntries',
    RouteFC: Entries,
  },
  {
    key: 'ownerRequests',
    path: '/admin/ownerRequests',
    title: 'ownerRequests.title',
    RouteFC: OwnerRequest,
  },
  {
    key: 'apartmentRequests',
    path: '/admin/apartmentRequests',
    title: 'apartmentRequests.title',
    RouteFC: ApartmentRequests,
  },
  {
    key: 'apartments',
    path: '/admin/apartments',
    title: 'apartments.title',
    RouteFC: Apartment,
  },
  {
    key: 'settings',
    path: '/admin/settings',
    title: 'settings.title',
    RouteFC: SettingsPage,
  },
  {
    key: 'invoices',
    path: '/admin/invoices',
    title: 'invoices.title',
    RouteFC: Invoices,
  },
  {
    key: 'payments',
    path: '/admin/payments',
    title: 'payments.title',
    RouteFC: Payments,
  },
  {
    key: 'cardsAwaitingProduction',
    path: '/admin/cardsAwaitingProduction',
    title: 'cardsAwaitingProduction.title',
    RouteFC: CardsAwaitingProduction,
  },
  {
    key: 'cardsAwaitingDelivery',
    path: '/admin/cardsAwaitingDelivery',
    title: 'cardsAwaitingDelivery.title',
    RouteFC: CardsAwaitingDelivery,
  },
  {
    key: 'orders',
    path: '/admin/cardsGold',
    title: 'home.cardsGold',
    RouteFC: AdminGoldCards,
  },
  {
    key: 'super-admin',
    path: '/admin/super-admin',
    title: 'home.superAdmin',
    RouteFC: SuperAdminPage,
  },
  {
    key: 'vehicles',
    path: '/admin/vehicles',
    title: 'home.vehicles',
    RouteFC: AdminVehicles,
  },
  {
    key: 'vehicle-update',
    path: '/admin/vehicle/:id',
    title: 'vehicles.title',
    RouteFC: AdminVehicleUpdate,
  },
  {
    key: 'vehicle-create',
    path: '/admin/vehicle',
    title: 'vehicles.title',
    RouteFC: AdminVehicleCreate,
  },
];

const AppRoutes: FC = (props) => {
  const {t} = useTranslation();
  return (
    <Routes>
      {routes.map(({key, RouteFC, path, title, bigHeader = true}) => (
        <Route
          key={key}
          path={path}
          element={
            <Page title={title ? t(title) : ''}>
              <AppLayout>
                <AppHeader big={bigHeader} />
                <AppContent>
                  <RouteFC />
                </AppContent>
                <AppFooter />
              </AppLayout>
            </Page>
          }
        />
      ))}
      {ownerRoutes.map(({key, RouteFC, path, title, bigHeader = true}) => (
        <Route
          key={key}
          path={path}
          element={
            <LoggedInRoute>
              <Page title={title ? t(title) : ''}>
                <AppLayout>
                  <AppHeader big={bigHeader} displayLogoutButton />
                  <AppContent>
                    <RouteFC />
                  </AppContent>
                  <AppFooter />
                </AppLayout>
              </Page>
            </LoggedInRoute>
          }
        />
      ))}
      {adminRoutes.map(({key, RouteFC, path, title, bigHeader = true}) => (
        <Route
          key={key}
          path={path}
          element={
            <AdminRoute>
              <Page title={title ? t(title) : ''}>
                <AppLayout>
                  <AppHeader big={bigHeader} />
                  <AppContent>
                    <RouteFC />
                  </AppContent>
                  <AppFooter />
                </AppLayout>
              </Page>
            </AdminRoute>
          }
        />
      ))}
      <Route path="*" element={<AppNotFoundPage />} />
    </Routes>
  );
};

const AppConfigProvider: FC<{children?: React.ReactNode}> = (props) => {
  const {t, lang} = useTranslation();

  const locale = getLocaleFromLang(lang);

  useEffect(() => {
    dayjs.locale(lang);
  }, [lang]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `'${BASE_FONT_FAMILY}', monospace`,
          colorPrimary: COLORS.PRIVATE_COLOR,
          borderRadius: 0,
        },
        components: {
          // Button: {
          //   colorPrimary: COLORS.PRIVATE_COLOR,
          // },
        },
      }}
      locale={locale}
      form={{validateMessages: getValidateMessages(t)}}
    >
      {props.children}
    </ConfigProvider>
  );
};

export const App: FC = () => {
  return (
    <BrowserRouter>
      <TranslationProvider>
        <AppConfigProvider>
          <AntdApp>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </AntdApp>
        </AppConfigProvider>
      </TranslationProvider>
    </BrowserRouter>
  );
};

export default App;
