import {CheckOutlined, WarningOutlined} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import humanizeDuration from 'humanize-duration';
import {parsePhoneNumber} from 'libphonenumber-js';
import {DateTime} from 'luxon';
import {FC, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {ApartmentCodeSelect} from '../input/ApartmentCodeSelect';
import {CountrySelect} from '../input/CountrySelect';
import {getValidPhoneRule, PhoneInput} from '../input/PhoneInput';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {ApartmentRecord} from './Apartment';
import {
  TableColumnsProp,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';
import {UserRecord} from './User';

const {Option} = Select;

const singularRoute = '/ownerRequest';
const pluralRoute = '/ownerRequests';

export interface OwnerRequestFormValues {
  address: string;
  zipCode: string;
  city: string;
  country: string;
  phoneNumber: string;
  userId: string;
  apartmentId: string;

  ['user.email']: string;
  ['user.firstName']: string;
  ['user.lastName']: string;
  ['user.title']: string;

  ['apartment.code']: string;
  ['apartment.condo']: string;
  ['apartment.building']: string;
  ['apartment.doorNumber']: string;
  ['apartment.beddings']: string;
  ['apartment.host']: string;
  ['apartment.ownerId']: string;
}

export interface OwnerRequestRecord extends OwnerRequestFormValues {
  id: string;
  user: UserRecord;
  apartment: ApartmentRecord;
  createDate: number;
  updateDate: number;
}

const ownerRequestInitialValues: OwnerRequestFormValues = {
  'user.email': '',
  'user.firstName': '',
  'user.lastName': '',
  'user.title': '',
  address: '',
  zipCode: '',
  city: '',
  country: '',
  phoneNumber: '',
  userId: '',
  apartmentId: '',
  'apartment.code': '',
  'apartment.condo': '',
  'apartment.building': '',
  'apartment.doorNumber': '',
  'apartment.beddings': '',
  'apartment.host': '',
  'apartment.ownerId': '',
};

export const OwnerRequestForm: FC<TableFormProps<OwnerRequestFormValues>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t} = useTranslation();
  const [apartment, setApartment] = useState<ApartmentRecord>();

  useMemo(() => {
    const parsedPhoneNumber =
      initialValues.phoneNumber && parsePhoneNumber(initialValues.phoneNumber);

    form.setFieldsValue({
      ...ownerRequestInitialValues,
      ...initialValues,
      phoneNumber: parsedPhoneNumber
        ? parsedPhoneNumber.formatInternational({})
        : initialValues.phoneNumber,
    });
  }, [form, initialValues]);

  return (
    <Form<OwnerRequestFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...ownerRequestInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="user.lastName"
            label={t('ownerRequests.lastName')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="user.firstName"
            label={t('ownerRequests.firstName')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="user.title" label={t('ownerRequests.title_gender')}>
            <Select allowClear>
              <Option value="M">{t('users.userTitleMr')}</Option>
              <Option value="Mme">{t('users.userTitleMrs')}</Option>
              <Option value="M et Mme">{t('users.userTitleMrAndMrs')}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="address"
            label={t('ownerRequests.address')}
            rules={[{required: true}]}
          >
            <Input.TextArea autoSize={{minRows: 2, maxRows: 4}} />
          </Form.Item>
          <Form.Item
            name="zipCode"
            label={t('ownerRequests.zipCode')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="city"
            label={t('ownerRequests.city')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label={t('ownerRequests.country')}
            rules={[{required: true}]}
          >
            <CountrySelect />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label={t('ownerRequests.phoneNumber')}
            rules={[
              {required: true},
              getValidPhoneRule({
                t,
              }),
            ]}
          >
            <PhoneInput />
          </Form.Item>
          <Form.Item
            name="user.email"
            label={t('ownerRequests.email')}
            rules={[{required: true}]}
          >
            <Input type="email" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="apartmentId"
            label={t('apartments.code')}
            rules={[{required: true}]}
          >
            <ApartmentCodeSelect
              onApartmentChange={(apartment) => {
                setApartment(apartment);

                if (!apartment) {
                  form.resetFields([
                    'apartment.condo',
                    'apartment.building',
                    'apartment.doorNumber',
                    'apartment.beddings',
                    'apartment.host',
                  ]);
                  return;
                }

                form.setFieldsValue({
                  'apartment.condo': apartment.condo,
                  'apartment.building': apartment.building,
                  'apartment.doorNumber': apartment.doorNumber,
                  'apartment.beddings': apartment.beddings.toString(),
                  'apartment.host': apartment.host?.id,
                });
              }}
            />
          </Form.Item>
          {apartment?.ownerId ? (
            <Alert
              type="error"
              description={
                <span>
                  {t('apartments.error.alreadyOwned')}
                  <br />
                  <Link to={`/admin/owner/${apartment.ownerId}`}>
                    {apartment.owner?.user.lastName}{' '}
                    {apartment.owner?.user.firstName}
                  </Link>
                </span>
              }
              style={{marginBottom: 10}}
            />
          ) : null}

          <Form.Item name="apartment.condo" label={t('apartments.condo')}>
            <Input disabled />
          </Form.Item>

          <Form.Item name="apartment.building" label={t('apartments.building')}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="apartment.doorNumber"
            label={t('apartments.doorNumber')}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item name="apartment.beddings" label={t('apartments.beddings')}>
            <Input disabled />
          </Form.Item>

          <Form.Item name="apartment.host" label={t('apartments.host')}>
            <Input disabled />
          </Form.Item>

          <Form.Item name="userId" label="userId" hidden>
            <Input disabled />
          </Form.Item>

          <Form.Item hidden={true}>
            <Button htmlType="submit">{t('general.submit')}</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const OwnerRequest: FC = () => {
  const api = useApi();
  const {t, lang} = useTranslation();

  const [total, setTotal] = useState<number>();
  const [editEntity, setEditEntity] = useState<
    OwnerRequestRecord | undefined
  >();
  const [refreshDate, setRefreshDate] = useState<number>();

  const [validateEntity, setValidateEntity] = useState<
    OwnerRequestRecord | undefined
  >();
  const [validateLoading, setValidateLoading] = useState(false);
  const [validateError, setValidateError] = useState('');

  const canValidateEntity = !validateEntity?.apartment?.ownerId;

  const handleValidate = async () => {
    if (!api || !validateEntity) return;
    setValidateError('');
    try {
      setValidateLoading(true);
      const owner = await api.put(DEFAULT_API_NAME, '/owner', {
        body: {
          name: `${validateEntity['user.lastName']} ${validateEntity['user.firstName']}`,
          address: validateEntity.address,
          zipCode: validateEntity.zipCode,
          city: validateEntity.city,
          country: validateEntity.country,
          phoneNumber: validateEntity.phoneNumber.replace(/\s+/g, ''),
          userId: validateEntity.userId,
        },
      });

      await api.post(
        DEFAULT_API_NAME,
        `/apartment/${validateEntity.apartmentId}`,
        {
          body: {
            ...validateEntity.apartment,
            ownerId: owner.id,
          },
        },
      );

      await api.post(
        DEFAULT_API_NAME,
        `/user/validateOwner/${validateEntity.userId}`,
        {},
      );

      await api.del(DEFAULT_API_NAME, `/ownerRequest/${validateEntity.id}`, {});
    } catch (err) {
      setValidateError('Validation error');
    } finally {
      setValidateLoading(false);
    }
    setValidateEntity(undefined);
    setRefreshDate(Date.now());
  };

  const ownerRequestColumns: TableColumnsProp<OwnerRequestRecord> = useStateFn(
    () => [
      {
        title: t('ownerRequests.fullName'),
        key: 'name',
        dataIndex: 'name',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('ownerRequests.address'),
        key: 'address',
        dataIndex: 'address',
        render: (address) => (
          <span className="hint" title={address}>
            {address}
          </span>
        ),
        searchInput: true,
        sorter: true,
      },
      {
        title: t('ownerRequests.zipCode'),
        key: 'zipCode',
        dataIndex: 'zipCode',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('ownerRequests.city'),
        key: 'city',
        dataIndex: 'city',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('ownerRequests.country'),
        key: 'country',
        dataIndex: 'country',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('ownerRequests.phoneNumber'),
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        render: (phoneNumber) =>
          parsePhoneNumber(phoneNumber)?.formatInternational({}) ?? phoneNumber,
        searchInput: true,
        sorter: true,
      },
      {
        title: t('ownerRequests.email'),
        key: 'user.email',
        dataIndex: 'user.email',
      },
      {
        title: t('ownerRequests.apartment'),
        key: 'apartmentId',
        dataIndex: 'apartmentId',
        render: (_, {apartment}) => (
          <Space>
            {apartment?.code}
            {!!apartment?.ownerId ? (
              <Tooltip title={t('apartments.error.alreadyOwned')}>
                <WarningOutlined color="#faad14" />
              </Tooltip>
            ) : null}
          </Space>
        ),
        searchInput: true,
        searchSelect: (props) => <ApartmentCodeSelect {...props} />,
      },
      {
        title: t('ownerRequests.createDate'),
        key: 'createDate',
        dataIndex: 'createDate',
        render: (createDate) => (
          <span
            className="hint"
            title={DateTime.fromMillis(createDate).toISO()}
          >
            {t('general.timeIntervalAgo').replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${interval}',
              humanizeDuration(Date.now() - createDate, {
                language: lang,
                round: true,
              }).split(',')[0],
            )}
          </span>
        ),
      },
    ],
    [t, lang],
  );

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: OwnerRequestForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <ContentDiv
      title={
        total
          ? `${t('ownerRequests.title')}: ${total}`
          : t('ownerRequests.title')
      }
    >
      <TableList<OwnerRequestRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={ownerRequestColumns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        customActions={[
          (entity) => (
            <Tooltip
              title={!!entity.apartment?.ownerId ? 'Already owned' : ''}
              key="validate"
            >
              <Button
                icon={<CheckOutlined />}
                type="default"
                disabled={!entity.apartment || !!entity.apartment.ownerId}
                onClick={() => setValidateEntity(entity)}
              />
            </Tooltip>
          ),
        ]}
        onTotalChange={setTotal}
      />
      <TableUpdateModal<OwnerRequestRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        width={700}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={(form: OwnerRequestFormValues) => ({
          name: `${form['user.lastName']} ${form['user.firstName']}`,
          address: form.address,
          zipCode: form.zipCode,
          city: form.city,
          country: form.country,
          phoneNumber: form.phoneNumber,
          userId: form.userId,
          apartmentId: form.apartmentId,
        })}
        afterSubmit={async (formValues: OwnerRequestFormValues) => {
          if (!api || !editEntity) throw new Error('never');

          if (
            editEntity.user.firstName === formValues['user.firstName'] &&
            editEntity.user.lastName === formValues['user.lastName'] &&
            editEntity.user.title === formValues['user.title']
          ) {
            return;
          }

          const singularRoute = '/user';
          await api.post(
            DEFAULT_API_NAME,
            `${singularRoute}/${formValues.userId}`,
            {
              body: {
                ...editEntity.user,
                firstName: formValues['user.firstName'],
                lastName: formValues['user.lastName'],
                title: formValues['user.title'],
              },
            },
          );
        }}
      />
      <Modal
        centered
        open={!!validateEntity}
        onCancel={() => setValidateEntity(undefined)}
        okText={t('ownerRequest.validate')}
        okButtonProps={{disabled: !canValidateEntity}}
        confirmLoading={validateLoading}
        onOk={handleValidate}
      >
        {canValidateEntity ? (
          <Alert
            type="warning"
            description={t('ownerRequest.validateWarning')}
          />
        ) : (
          <Alert type="error" description={t('ownerRequest.validateError')} />
        )}
        {validateError ? (
          <Alert type="error" description={validateError} />
        ) : null}
      </Modal>
    </ContentDiv>
  );
};
