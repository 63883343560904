/** returns base URL for our app */
export const getAppBaseUrl = (): string => {
  const endPoint = process.env.REACT_APP_APP_ENDPOINT;
  if (!endPoint) {
    console.warn('APP URL is not set');
  }
  return endPoint ?? '#';
};

/** returns base URL for blog */
export const getBlogBaseUrl = (): string => {
  const endPoint = process.env.REACT_APP_BLOG_ENDPOINT;
  if (!endPoint) {
    console.warn('Blog URL is not set');
  }
  return endPoint ?? '#';
};
