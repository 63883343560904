import {DeleteOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import {
  Alert,
  Button,
  ButtonProps,
  Col,
  Divider,
  Form,
  FormInstance,
  FormProps,
  Input,
  InputRef,
  Modal,
  ModalProps,
  Pagination,
  PaginationProps,
  Popconfirm,
  Row,
  SelectProps,
  Space,
  Table,
} from 'antd';
import {ColumnProps, TableProps} from 'antd/es/table';
import {
  ColumnType,
  FilterConfirmProps,
  FilterValue,
  SorterResult,
  SortOrder,
} from 'antd/es/table/interface';
import {ColumnFilterItem} from 'antd/lib/table/interface';
import {AxiosError} from 'axios';
import {FC, ReactElement, ReactNode, useMemo, useRef, useState} from 'react';
import {TranslateFn, useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {compileTemplate} from '../../util/template';
import {Dayjs} from 'dayjs';
import {useRequest} from 'ahooks';

/** same as Table native pagination with the ability to put a component
 * on the same row.
 */
const TablePagination: FC<
  PaginationProps & {
    left?: ReactNode;
    marginBottom?: number;
    marginTop?: number;
  }
> = (props) => {
  const {left, marginBottom, marginTop, ...paginationProps} = props;
  return (
    <Row justify="space-between" style={{marginBottom, marginTop}}>
      <Col>{left}</Col>
      <Col>
        <Pagination {...paginationProps} />
      </Col>
    </Row>
  );
};

const hasAnActiveFilter = (
  filter: Record<string, FilterValue | null> | undefined,
): boolean =>
  filter !== undefined &&
  Object.values(filter).filter((v) => !!v && v.length > 0).length > 0;

const hasAnActiveSorter = (sorter: SorterResult<any> | undefined): boolean =>
  sorter !== undefined && sorter['column'] !== undefined;

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

// https://stackoverflow.com/a/53739792
export const flattenObject = (ob: any): any => {
  const toReturn: any = ob;

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const isAxiosError = (err: unknown): err is AxiosError =>
  !!(err as AxiosError)?.isAxiosError;

export interface CodedError {
  name: string;
  code: string;
}

export const isCodedError = (err: unknown): err is CodedError =>
  !!(err as CodedError)?.code;

interface EntityRecord {
  id: string;
  createDate: number | Dayjs;
  updateDate: number | Dayjs;
}

export const isRecord = (entity: object): entity is EntityRecord =>
  entity.hasOwnProperty('id');

export function getDefaultSorter<TableRecord extends EntityRecord>(
  columns: TableColumnsProp<TableRecord>,
  columnKey: string,
  direction: SortOrder = 'descend',
): SorterResult<TableRecord> | undefined {
  const column = columns.find(({dataIndex}) => dataIndex === columnKey);
  if (!column) return;
  return {
    column,
    columnKey,
    field: columnKey,
    order: direction,
  };
}

export type TableColumnsProp<TableRecord extends EntityRecord> =
  (ColumnType<TableRecord> & {
    dataIndex: string;
    searchInput?: boolean;
    searchSelect?: (props: SelectProps<string>) => ReactNode;
    /** Custom field name for the server filter. Defaults to "dataIndex". */
    filterName?: string;
  })[];

export interface TableListProps<TableRecord extends EntityRecord> {
  refreshDate?: number;
  singularRoute: string;
  pluralRoute: string;
  columns: TableColumnsProp<TableRecord>;
  setEditEntity?: (entity: TableRecord) => void;
  onTotalChange?: (total: number) => void;
  onChange?: TableProps<TableRecord>['onChange'];
  onFilterChange?: (filter: Record<string, FilterValue | null>) => void;
  actionButtonProps?: {
    update?:
      | Partial<ButtonProps>
      | ((entity: TableRecord) => Partial<ButtonProps>);
    delete?:
      | Partial<ButtonProps>
      | ((entity: TableRecord) => Partial<ButtonProps>);
  };
  customActions?: ((entity: TableRecord) => ReactElement)[];
  hideActionColumn?: boolean;
  forcedFilter?: Record<string, FilterValue | null>;
  defaultSorter?: SorterResult<TableRecord>;
}

export function getFiltersForAPI<TableRecord extends EntityRecord>(
  filters: Record<string, FilterValue | null> | undefined,
  forcedFilters: Record<string, FilterValue | null> | undefined,
  columns: TableColumnsProp<TableRecord>,
): Record<string, string> {
  return Object.fromEntries(
    Object.entries({...(filters ?? {}), ...(forcedFilters ?? {})})
      .filter(([, value]) => value !== null)
      // transforms filter names if server filter name is different than actual data index
      .map(([key, value]) => {
        const column = columns.find((column) => column.key === key);
        if (column && !!column.filterName) {
          return [column.filterName, value];
        }
        return [key, value];
      })
      .map(([key, value]) => [`f_${key}`, value!.toString()]),
  );
}

export function TableList<TableRecord extends EntityRecord>({
  refreshDate,
  singularRoute,
  pluralRoute,
  columns: _columns,
  setEditEntity,
  onTotalChange,
  onChange,
  onFilterChange,
  forcedFilter,
  hideActionColumn = false,
  actionButtonProps = {},
  customActions = [],
  defaultSorter,
}: TableListProps<TableRecord>): ReactElement | null {
  const [deleteLoadingEntity, setDeleteLoadingEntity] = useState<
    TableRecord | undefined
  >();
  const [entities, setEntities] = useState<TableRecord[] | undefined>();
  const [total, setTotal] = useState<number>();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [filter, setFilter] = useState<Record<string, FilterValue | null>>();
  const [sorter, setSorter] = useState<SorterResult<TableRecord>>();

  const searchFilterRefMap = useRef<(InputRef | null)[]>([]);

  const api = useApi();
  const {t} = useTranslation();

  const {loading, run} = useRequest(
    async () => {
      if (!api) return;

      const _sorter = sorter ?? defaultSorter;

      const res = await api.get(DEFAULT_API_NAME, pluralRoute, {
        queryStringParameters: {
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
          ...getFiltersForAPI(filter, forcedFilter, _columns),
          ...(_sorter
            ? {
                sortKey: _sorter.columnKey,
                sortOrder: _sorter.order,
              }
            : {}),
        },
      });

      setEntities(res.entities.map(flattenObject));
      setTotal(res.total);
      onTotalChange?.(res.total);
    },
    {
      refreshDeps: [
        api,
        pluralRoute,
        pageSize,
        filter,
        sorter,
        currentPage,
        forcedFilter,
        _columns,
        defaultSorter,
        // we don't use it in function body, but we want
        // to trigger refetch on refreshDate change:
        refreshDate,
      ],
    },
  );

  const handleChange: TableProps<TableRecord>['onChange'] = (
    {pageSize = DEFAULT_PAGE_SIZE, current = DEFAULT_PAGE},
    filter,
    sorter,
  ) => {
    setCurrentPage(current);
    setPageSize(pageSize);

    setFilter(filter);
    onFilterChange?.(filter);

    setSorter(Array.isArray(sorter) ? sorter[0] : sorter);
  };

  const deleteEntity = async (entity: TableRecord) => {
    if (!api) return;
    setDeleteLoadingEntity(entity);
    try {
      await api.del(DEFAULT_API_NAME, `${singularRoute}/${entity.id}`, {});
      run();
    } finally {
      setDeleteLoadingEntity(undefined);
    }
  };

  if (!api) {
    return <div>{t('general.notConnected')}</div>;
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    filterName: string,
  ) => {
    confirm();

    const newFilters = {...filter};
    if (selectedKeys.length === 0) {
      delete newFilters[filterName];
    } else {
      newFilters[filterName] = selectedKeys;
    }
    setFilter(newFilters);
    onFilterChange?.(newFilters);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    // setSearchText('');
  };

  const columns: ColumnProps<TableRecord>[] = _columns.map(
    ({searchInput, searchSelect, ...column}, index) => {
      const fieldName = column.dataIndex.toString();

      const col: ColumnProps<TableRecord> = {
        ...column,
        sortOrder: sorter?.columnKey === fieldName ? sorter?.order : null,
        filteredValue: fieldName ? filter?.[fieldName] ?? null : null,
        ...(searchInput
          ? {
              filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                close,
              }) => (
                <div onKeyDown={(e) => e.stopPropagation()}>
                  <div style={{padding: 8}}>
                    {searchSelect ? (
                      searchSelect({
                        placeholder: compileTemplate(
                          t('general.searchSomething'),
                          {
                            label: column.title?.toString() ?? '',
                          },
                        ),
                        value: selectedKeys[0] as string,
                        onChange: (value) =>
                          setSelectedKeys(value ? [value] : []),
                        style: {display: 'block'},
                        allowClear: true,
                      })
                    ) : (
                      <Input
                        ref={(ref) => (searchFilterRefMap.current[index] = ref)}
                        placeholder={compileTemplate(
                          t('general.searchSomething'),
                          {
                            label: column.title?.toString() ?? '',
                          },
                        )}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                          setSelectedKeys(
                            e.target.value ? [e.target.value] : [],
                          )
                        }
                        onPressEnter={() =>
                          handleSearch(
                            selectedKeys as string[],
                            confirm,
                            fieldName,
                          )
                        }
                        style={{display: 'block'}}
                      />
                    )}
                  </div>
                  <Divider style={{margin: 0}} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '7px 8px',
                    }}
                  >
                    {!searchSelect ? (
                      <Button
                        onClick={() =>
                          clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        type="link"
                        disabled={!selectedKeys[0]}
                      >
                        {t('general.reset')}
                      </Button>
                    ) : (
                      <div />
                    )}

                    <Button
                      type="primary"
                      onClick={() =>
                        handleSearch(
                          selectedKeys as string[],
                          confirm,
                          fieldName,
                        )
                      }
                      icon={<SearchOutlined />}
                      size="small"
                    >
                      {t('general.ok')}
                    </Button>
                  </div>
                </div>
              ),
              // taken from antd docs, for some reason the timeout is necessary
              onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                  setTimeout(
                    () => searchFilterRefMap.current[index]?.select(),
                    100,
                  );
                }
              },
            }
          : {}),
      };

      return col;
    },
  );

  const actionColumn: ColumnType<TableRecord> = {
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    fixed: 'right',
    render: (_, entity) => (
      <Space>
        {customActions.map((customAction) => customAction(entity))}

        <Button
          icon={<EditOutlined />}
          type="primary"
          onClick={() => setEditEntity?.(entity)}
          {...(actionButtonProps.update
            ? typeof actionButtonProps.update === 'function'
              ? actionButtonProps.update(entity)
              : actionButtonProps.update
            : {})}
        />

        <Popconfirm
          title={t('general.delete')}
          okText={t('general.yes')}
          cancelText={t('general.no')}
          onConfirm={() => deleteEntity(entity)}
          disabled={
            actionButtonProps.delete
              ? typeof actionButtonProps.delete === 'function'
                ? actionButtonProps.delete(entity)?.disabled
                : actionButtonProps.delete?.disabled
              : false
          }
        >
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            danger={true}
            loading={deleteLoadingEntity === entity}
            {...(actionButtonProps.delete
              ? typeof actionButtonProps.delete === 'function'
                ? actionButtonProps.delete(entity)
                : actionButtonProps.delete
              : {})}
          />
        </Popconfirm>
      </Space>
    ),
  };

  const tablePaginationProps: PaginationProps & {left?: ReactNode} = {
    size: 'small',
    current: currentPage,
    pageSize: pageSize,
    total: total,
    // hideOnSinglePage: true,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    defaultPageSize: 10,
    onChange: (current, pageSize) => {
      setCurrentPage(current);
      setPageSize(pageSize);
    },
    left: undefined,
  };

  return (
    <div>
      <TablePagination
        {...tablePaginationProps}
        marginBottom={8}
        left={
          <Space>
            {columns.some(
              (column) => !!column.filters || !!column.filterDropdown,
            ) && (
              <Button
                onClick={() => {
                  setFilter({});
                  onFilterChange?.({});
                }}
                size="small"
                disabled={!hasAnActiveFilter(filter)}
              >
                {t('general.clearFilters')}
              </Button>
            )}
            {columns.some((column) => !!column.sorter) && (
              <Button
                onClick={() => setSorter({})}
                size="small"
                disabled={!hasAnActiveSorter(sorter)}
              >
                {t('general.clearSorters')}
              </Button>
            )}
          </Space>
        }
      />
      <Table<TableRecord>
        scroll={{x: true}}
        bordered
        columns={hideActionColumn ? columns : [...columns, actionColumn]}
        dataSource={entities}
        onChange={(...params) => {
          onChange?.(...params);
          handleChange(...params);
        }}
        pagination={false}
        // pagination={{
        //   size: 'small',
        //   current: currentPage,
        //   pageSize: pageSize,
        //   total,
        //   position: ['topRight'],
        //   hideOnSinglePage: true,
        //   showSizeChanger: true,
        //   pageSizeOptions: [10, 20, 50],
        //   defaultPageSize: 10,
        // }}
        loading={loading}
        rowKey="id"
      />
      <TablePagination marginTop={8} {...tablePaginationProps} />
    </div>
  );
}

export const getValidateMessages = (
  t: (code: string) => string,
): FormProps['validateMessages'] => {
  return {
    required: t('general.form.messages.required'),
    types: {
      email: t('general.form.messages.types_email'),
      number: t('general.form.messages.types_number'),
    },
    number: {
      range: t('general.form.messages.number_range'),
    },
  };
};

export interface TableFormProps<TableRecord> {
  form: FormInstance<TableRecord>;
  onFinish?: () => void;
  initialValues?: Partial<TableRecord>;
  defaultInitialValues?: Partial<TableRecord>;
}

export function TableForm<TableRecord extends object>({
  form,
  onFinish,
  initialValues = {},
  defaultInitialValues = {},
}: TableFormProps<TableRecord>): ReactElement | null {
  useMemo(() => {
    form.setFieldsValue({...defaultInitialValues, ...initialValues} as any);
  }, [form, defaultInitialValues, initialValues]);

  return (
    <Form<TableRecord>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...defaultInitialValues, ...initialValues}}
      autoComplete="off"
    >
      {/* TODO custom items/layout etc, probably just externalise this component */}
      <Form.Item name="name" label="Nom" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Form.Item hidden={true}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
}

export interface GenericModalProps<TableRecord extends object>
  extends ModalProps {
  singularRoute: string;
  onCancel: () => void;
  onOk: () => void;
  FormComponent: (p: TableFormProps<TableRecord>) => ReactElement | null;
  parseValues?: (values: TableRecord) => any;
  afterSubmit?: (formValues: TableRecord, result: TableRecord) => Promise<void>;
}

export function TableCreateModal<TableRecord extends object>({
  singularRoute,
  onOk,
  onCancel,
  okButtonProps,
  FormComponent,
  parseValues,
  afterSubmit,
  ...modalProps
}: GenericModalProps<TableRecord>): ReactElement | null {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [form] = Form.useForm<TableRecord>();
  const api = useApi();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    setError('');
    if (!api) return;
    await form.validateFields();

    const formValues = form.getFieldsValue();
    const values = parseValues ? parseValues(formValues) : formValues;

    setLoading(true);
    try {
      const result = (await api.put(DEFAULT_API_NAME, singularRoute, {
        body: values,
      })) as TableRecord;
      await afterSubmit?.(formValues, result);
    } catch (err: unknown | AxiosError) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setError(message);

      return;
    } finally {
      setLoading(false);
    }

    form.resetFields();
    onOk?.();
  };

  const handleCancel = () => {
    form.resetFields();
    setError('');
    onCancel?.();
  };

  return (
    <Modal
      centered
      forceRender
      onOk={handleSubmit}
      onCancel={handleCancel}
      okButtonProps={{...okButtonProps, loading: loading}}
      {...modalProps}
    >
      <FormComponent form={form} onFinish={handleSubmit} />

      {error ? (
        <Alert message={error} type="error" style={{whiteSpace: 'pre-line'}} />
      ) : null}
    </Modal>
  );
}

export function TableUpdateModal<TableRecord extends EntityRecord>({
  entity,
  singularRoute,
  onOk,
  onCancel,
  okButtonProps,
  FormComponent,
  parseValues,
  afterSubmit,
  ...modalProps
}: GenericModalProps<TableRecord> & {
  entity?: TableRecord;
}): ReactElement | null {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [form] = Form.useForm<TableRecord>();
  const api = useApi();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    setError('');
    if (!api || !entity) return;
    await form.validateFields();

    const formValues = form.getFieldsValue();
    const values = parseValues ? parseValues(formValues) : formValues;

    setLoading(true);
    try {
      const result = (await api.post(
        DEFAULT_API_NAME,
        `${singularRoute}/${entity.id}`,
        {
          body: values,
        },
      )) as TableRecord;
      await afterSubmit?.(formValues, result);
    } catch (err: unknown | AxiosError) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setError(message);

      return;
    } finally {
      setLoading(false);
    }

    form.resetFields();
    onOk?.();
  };

  const handleCancel = () => {
    form.resetFields();
    setError('');
    onCancel?.();
  };

  return (
    <Modal
      centered
      forceRender
      onOk={handleSubmit}
      onCancel={handleCancel}
      okButtonProps={{...okButtonProps, loading: loading}}
      {...modalProps}
    >
      <FormComponent
        form={form}
        onFinish={handleSubmit}
        initialValues={entity}
      />

      {error ? (
        <Alert message={error} type="error" style={{whiteSpace: 'pre-line'}} />
      ) : null}
    </Modal>
  );
}

export const booleanFilters = (t: TranslateFn): ColumnFilterItem[] => [
  {value: true, text: t('general.yes')},
  {value: false, text: t('general.no')},
];
