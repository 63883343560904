import {PlusOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  SelectProps,
  Space,
  Spin,
} from 'antd';
import {FC, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {TranslateFn, useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {fetchCondos} from '../../util/cache/apartment';
import {ContentDiv} from '../layout/ContentDiv';
import {HostRecord} from './Host';
import {OwnerRecord} from './Owner';
import {OwnerSelect} from '../input/OwnerSelect';
import {
  getDefaultSorter,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';
import {useStateFn} from '../useStateFn';
import {useRequest} from 'ahooks';
import {APIClass} from 'aws-amplify';
import {ApartmentExportButton} from './ApartmentExportButton';
import {FilterValue} from 'antd/es/table/interface';

const singularRoute = '/apartment';
const pluralRoute = '/apartments';

export enum ApartmentType {
  APT_1_P = 1,
  APT_2_P,
  APT_3_P,
  APT_4_P,
  MAISON,
}

export const ApartmentTypeLabel: {[key in ApartmentType]: string} = {
  [ApartmentType.APT_1_P]: 'APT 1 P',
  [ApartmentType.APT_2_P]: 'APT 2 P',
  [ApartmentType.APT_3_P]: 'APT 3 P',
  [ApartmentType.APT_4_P]: 'APT 4 P',
  [ApartmentType.MAISON]: 'Maison',
};

const ApartmentTypeSelect: FC<SelectProps<number>> = (props) => {
  const options = Object.entries(ApartmentTypeLabel).map(([value, label]) => ({
    value: Number(value),
    label,
  }));

  return <Select<number> options={options} {...props} />;
};

export interface ApartmentFormValues {
  code?: string;
  building: string;
  doorNumber: string;
  type?: number;
  beddings: number;
  condo: string;
  hostId?: string;
  ownerId?: string;
  comment?: string;

  ['host.name']?: string;
}

export interface ApartmentRecord extends ApartmentFormValues {
  id: string;
  host?: HostRecord;
  owner?: OwnerRecord;
  createDate: number;
  updateDate: number;
}

const apartmentInitialValues: ApartmentFormValues = {
  condo: '',
  code: '',
  building: '',
  doorNumber: '',
  beddings: 0,
};

export const parseApartmentFormValues = (form: ApartmentFormValues) => ({
  code: form.code,
  building: form.building,
  doorNumber: form.doorNumber,
  type: form.type,
  beddings: form.beddings,
  condo: form.condo,
  hostId: form.hostId,
  ownerId: form.ownerId,
  comment: form.comment,
});

export const ApartmentForm: FC<TableFormProps<ApartmentFormValues>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t} = useTranslation();
  const api = useApi();

  const [hosts, setHosts] = useState<HostRecord[]>();
  const [hostsLoading, setHostsLoading] = useState(false);

  /* const [owners, setOwners] = useState<(OwnerRecord & {user: UserRecord})[]>();
  const [ownersLoading, setOwnersLoading] = useState(false); */

  useMemo(() => {
    form.setFieldsValue({
      ...apartmentInitialValues,
      ...initialValues,
      hostId:
        (initialValues as unknown as ApartmentRecord)?.host?.id ??
        initialValues.hostId,
      ownerId:
        (initialValues as unknown as ApartmentRecord)?.owner?.id ??
        initialValues.ownerId,
    });
  }, [form, initialValues]);

  const handleCodeChange = () => {
    const {building, doorNumber} = form.getFieldsValue();
    form.setFieldValue(
      'code',
      building && doorNumber ? [building, doorNumber].join('') : '',
    );
  };

  useEffect(() => {
    if (!api) return;

    (async () => {
      setHostsLoading(true);
      const {entities} = await api.get(DEFAULT_API_NAME, '/hosts', {});
      setHostsLoading(false);
      setHosts(entities);
    })();
  }, [api]);

  return (
    <Form<ApartmentFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...apartmentInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={12}>
        <Col>
          <Form.Item
            name="condo"
            label={t('apartments.condo')}
            rules={[{required: true}]}
            style={{width: 150}}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="building"
            label={t('apartments.building')}
            rules={[{required: true}]}
            style={{width: 100}}
          >
            <Input onChange={handleCodeChange} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="doorNumber"
            label={t('apartments.doorNumber')}
            rules={[{required: true}]}
            style={{width: 100}}
          >
            <Input onChange={handleCodeChange} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="code" label={t('apartments.code')}>
        <Input disabled />
      </Form.Item>
      <Row gutter={12}>
        <Col>
          <Form.Item
            name="beddings"
            label={t('apartments.beddings')}
            rules={[{required: true}]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item name="type" label={t('apartments.type')}>
            <ApartmentTypeSelect />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="hostId" label={t('apartments.host')}>
        <Select
          options={hosts?.map(({id, name}) => ({
            value: id,
            label: name,
          }))}
          loading={hostsLoading}
          allowClear
        />
      </Form.Item>
      <Form.Item name="ownerId" label={t('apartments.owner')}>
        <OwnerSelect allowClear />
      </Form.Item>
      <Form.Item name="comment" label={t('apartments.comment')}>
        <Input.TextArea autoSize={{minRows: 2, maxRows: 4}} />
      </Form.Item>
      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

const getApartmentColumns = (
  t: TranslateFn,
  condos: string[] = [],
  hosts: HostRecord[] = [],
): TableColumnsProp<ApartmentRecord> => [
  {
    title: t('apartments.condo'),
    key: 'condo',
    dataIndex: 'condo',
    filters: condos?.map((condo) => ({text: condo, value: condo})),
    sorter: true,
  },
  {
    title: t('apartments.building'),
    key: 'building',
    dataIndex: 'building',
    searchInput: true,
    sorter: true,
  },
  {
    title: t('apartments.code'),
    key: 'code',
    dataIndex: 'code',
    searchInput: true,
    sorter: true,
  },
  {
    title: t('apartments.type'),
    key: 'type',
    dataIndex: 'type',
    render: (type: ApartmentType) => ApartmentTypeLabel[type],
    filters: Object.entries(ApartmentTypeLabel).map(([type, label]) => ({
      text: label,
      value: type,
    })),
    sorter: true,
  },
  {
    title: t('apartments.doorNumber'),
    key: 'doorNumber',
    dataIndex: 'doorNumber',
    searchInput: true,
    sorter: true,
  },
  {
    title: t('apartments.beddings'),
    key: 'beddings',
    dataIndex: 'beddings',
    searchInput: true,
    sorter: true,
  },
  {
    title: t('apartments.host'),
    key: 'hostId',
    dataIndex: 'hostId',
    render: (_, apartment) => apartment?.['host.name'],
    filters: hosts
      ? hosts.map(({id, name}) => ({
          text: name,
          value: id,
        }))
      : undefined,
    filterName: 'hostIdSearch',
    sorter: true,
  },
  {
    title: t('apartments.owner'),
    key: 'ownerId',
    dataIndex: 'ownerId',
    render: (_, apartment) =>
      apartment?.owner?.user ? (
        <Link to={`/admin/owner/${apartment?.owner.id}`}>
          {apartment.owner.user.lastName} {apartment.owner.user.firstName}
        </Link>
      ) : null,
    sorter: true,
    searchInput: true,
    searchSelect: (props) => <OwnerSelect {...props} />,
  },
];

const fetchData = async (
  api: APIClass | null,
): Promise<{hosts: HostRecord[]; condos: string[]} | undefined> => {
  if (!api) return;

  const {entities} = await api.get(DEFAULT_API_NAME, '/hosts', {});
  const condos = await (await fetchCondos()).json();

  return {hosts: entities, condos};
};

export const Apartment: FC = () => {
  const api = useApi();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<ApartmentRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const {t} = useTranslation();

  const [total, setTotal] = useState<number>();

  const {data} = useRequest(() => fetchData(api), {refreshDeps: [api]});

  const {apartmentColumns, defaultSorter} = useStateFn(() => {
    if (!data) {
      return {apartmentColumns: undefined, defaultSorter: undefined};
    }

    const apartmentColumns = getApartmentColumns(t, data?.condos, data?.hosts);
    return {
      apartmentColumns,
      defaultSorter: getDefaultSorter(apartmentColumns, 'code', 'ascend'),
    };
  }, [t, data]);

  const [exportFilter, setExportFilter] = useState<
    Record<string, FilterValue | null>
  >({});

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: ApartmentForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  if (!data || !apartmentColumns) {
    return <Spin />;
  }

  return (
    <ContentDiv
      title={
        total ? `${t('apartments.title')}: ${total}` : t('apartments.title')
      }
      titleRightComponent={
        <Space>
          <ApartmentExportButton
            apartmentColumns={apartmentColumns ?? []}
            exportFilter={exportFilter}
          />
          <Button
            type="primary"
            onClick={() => setCreateModalOpen(true)}
            icon={<PlusOutlined />}
          />
        </Space>
      }
    >
      <TableList<ApartmentRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={apartmentColumns}
        defaultSorter={defaultSorter}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        onTotalChange={setTotal}
        onFilterChange={setExportFilter}
      />
      <TableCreateModal<ApartmentRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
      />
      <TableUpdateModal<ApartmentRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        parseValues={parseApartmentFormValues}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
      />
    </ContentDiv>
  );
};
