import styled from '@emotion/styled';
import {Button, Col, DatePicker, Form, Row} from 'antd';
import dayjs, {Dayjs} from 'dayjs';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import {FC, useCallback, useState} from 'react';
import {getDateFormat, useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {TimestampToDayjsDate} from '../../util/date';
import {getIntervalEndDateRule} from '../input/IntervalInput';
import {ContentDiv} from '../layout/ContentDiv';

const MaxWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

interface EntriesExportFormValues {
  startDate: number | Dayjs;
  endDate: number | Dayjs;
}

const EntriesExport: FC = () => {
  const api = useApi();
  const {t, lang} = useTranslation();

  const [form] = Form.useForm<EntriesExportFormValues>();

  const [exportLoading, setExportLoading] = useState(false);
  const handleEntriesExport = useCallback(async () => {
    await form.validateFields();
    if (!api) return;
    const {startDate, endDate} = form.getFieldsValue();

    const [startISO, endISO] = [startDate, endDate].map((date) =>
      TimestampToDayjsDate(date).toISOString().slice(0, 10),
    );

    setExportLoading(true);
    try {
      const {csvParking, csvPool} = (await api.get(
        DEFAULT_API_NAME,
        ['/admin/payment/entriesExport', startISO, endISO].join('/'),
        {},
      )) as {csvParking: string; csvPool: string};

      const fileName = `export-ecritures-${startISO}-${endISO}_${new Date()
        .toISOString()
        .replace(/:/g, '-')}`;

      const zip = new JSZip();
      zip.file(`VEPI_${startISO}-${endISO}.csv`, csvPool);
      zip.file(`VEPA_${startISO}-${endISO}.csv`, csvParking);

      const content = await zip.generateAsync({type: 'blob'});
      saveAs(content, `${fileName}.zip`);
    } finally {
      setExportLoading(false);
    }
  }, [form, api]);

  return (
    <>
      <AppTitle level={4} style={{marginTop: 0}}>
        {t('entries.export')}
      </AppTitle>

      <Form<EntriesExportFormValues>
        form={form}
        onFinish={handleEntriesExport}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          startDate: dayjs().utc().startOf('month'),
          endDate: dayjs().utc().endOf('month'),
        }}
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="startDate"
              label={t('entries.exportStartDate')}
              rules={[{required: true}]}
            >
              <MaxWidthDatePicker format={getDateFormat(lang)} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="endDate"
              label={t('entries.exportEndDate')}
              rules={[
                {required: true},
                getIntervalEndDateRule({
                  startDateFieldName: 'startDate',
                  t,
                }),
              ]}
            >
              <MaxWidthDatePicker format={getDateFormat(lang)} />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item style={{marginTop: 30}}>
              <Button htmlType="submit" type="primary" loading={exportLoading}>
                {t('general.submit')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const Entries: FC = () => {
  const {t} = useTranslation();

  return (
    <ContentDiv title={t('entries.title')}>
      <EntriesExport />
    </ContentDiv>
  );
};
