import {Rule} from 'antd/es/form';
import {TranslateFn} from '../../translation';

interface getIntervalEndDateRuleProps {
  startDateFieldName: string;
  t: TranslateFn;
}

export const getIntervalEndDateRule =
  ({startDateFieldName, t}: getIntervalEndDateRuleProps): Rule =>
  ({getFieldValue}) => ({
    validator(_, endDate) {
      const startDate = getFieldValue(startDateFieldName);
      if (!endDate || !startDate) return Promise.reject();

      if (startDate > endDate)
        return Promise.reject(new Error(t('general.error.negativeDuration')));

      return Promise.resolve();
    },
  });
