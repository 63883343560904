import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import {Button, Checkbox, Form, Input, message, Select, Tooltip} from 'antd';
import {APIClass} from 'aws-amplify';
import {DateTime} from 'luxon';
import {FC, useMemo, useState} from 'react';
import {useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi, useUserIsLGAdmin} from '../../util/Auth';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {
  booleanFilters,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';

const {Option} = Select;

const singularRoute = '/user';
const pluralRoute = '/users';

export enum UserRole {
  UNCONFIRMED = 1,
  UNVALIDATED,
  OWNER,
  ADMIN,
}

export const UserRoleLabel: {[role in UserRole]: string} = {
  [UserRole.UNCONFIRMED]: 'users.roleNotVerified',
  [UserRole.UNVALIDATED]: 'users.roleUnvalidated',
  [UserRole.OWNER]: 'users.roleOwner',
  [UserRole.ADMIN]: 'users.roleAdmin',
};

export interface UserFormValues {
  email: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  role: number;
  disabled: boolean;
}

export interface UserRecord extends UserFormValues {
  id: string;
  createDate: number;
  updateDate: number;
}

const userInitialValues: UserFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  role: 1,
  disabled: false,
};

export const UserForm: FC<TableFormProps<UserFormValues>> = ({
  form,
  onFinish,
  initialValues = {role: UserRole.ADMIN},
}) => {
  const {t} = useTranslation();
  useMemo(() => {
    form.setFieldsValue({...userInitialValues, ...initialValues});
  }, [form, initialValues]);

  const isUpdate = ((
    initialValues: Partial<UserFormValues>,
  ): initialValues is UserRecord => !!(initialValues as UserRecord).id)(
    initialValues,
  );

  return (
    <Form<UserFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="email"
        label={t('users.email')}
        rules={[{required: true, type: 'email'}]}
      >
        <Input type="email" disabled={isUpdate} />
      </Form.Item>

      <Form.Item
        name="lastName"
        label={t('users.lastName')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="firstName"
        label={t('users.firstName')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="title" label={t('users.userTitle')}>
        <Select allowClear>
          <Option value="M">{t('users.userTitleMr')}</Option>
          <Option value="Mme">{t('users.userTitleMrs')}</Option>
          <Option value="M et Mme">{t('users.userTitleMrAndMrs')}</Option>
        </Select>
      </Form.Item>

      <Form.Item name="role" label={t('users.role')} rules={[{required: true}]}>
        <Select
          options={Object.entries(UserRoleLabel).map(([value, label]) => ({
            value: Number(value),
            label: t(label),
          }))}
          disabled
        />
      </Form.Item>

      <Form.Item
        name="disabled"
        label={t('users.disabled')}
        valuePropName="checked"
        className="force-horizontal"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

const fetchCognitoStatus = async (
  api: APIClass | null,
  userId: string,
): Promise<string | undefined> => {
  if (!api) return;
  return await api.get(DEFAULT_API_NAME, `/user/cognitoStatus/${userId}`, {});
};

// see "AdminListGroupsForUserResponse" & "GroupType" types in @aws-cdk for
// complete type
interface GroupType {
  GroupName: string;
  Precedence: number;
}

/** Returns Cognito groups of a user, sorted by precedence. */
const fetchCognitoGroups = async (
  api: APIClass | null,
  userId: string,
): Promise<GroupType[] | undefined> => {
  if (!api) return;

  const res = await api.get(
    DEFAULT_API_NAME,
    `/user/cognitoGroups/${userId}`,
    {},
  );

  if (!res || !res.groups) {
    throw new Error("Impossible de récupérer les groupes de l'utilisateur");
  }

  const groups = res.groups as GroupType[];

  return groups.sort((a, b) => a.Precedence - b.Precedence);
};

export const SuperAdminAllUsersTable: FC = (props) => {
  const {t} = useTranslation();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();
  const [editEntity, setEditEntity] = useState<UserRecord | undefined>();
  const api = useApi();

  const userColumns: TableColumnsProp<UserRecord> = useStateFn(
    () => [
      {
        title: t('users.lastName'),
        key: 'lastName',
        dataIndex: 'lastName',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('users.firstName'),
        key: 'firstName',
        dataIndex: 'firstName',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('users.email'),
        key: 'email',
        dataIndex: 'email',
        filterName: 'emailSearch',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('users.role'),
        key: 'role',
        dataIndex: 'role',
        render: (role: UserRole) => t(UserRoleLabel[role]),
        filters: Object.entries(UserRoleLabel).map(([value, label]) => ({
          value: Number(value),
          text: t(label),
        })),
      },
      {
        title: t('users.disabled'),
        key: 'disabled',
        dataIndex: 'disabled',
        render: (disabled) =>
          disabled ? (
            <CheckCircleTwoTone twoToneColor="#a30e10" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#52c41a" />
          ),
        filters: booleanFilters(t),
        sorter: true,
      },
      {
        title: t('general.createDate'),
        key: 'createDate',
        dataIndex: 'createDate',
        render: (createDate: number | undefined) =>
          createDate
            ? DateTime.fromMillis(createDate)
                .setLocale('fr')
                .toLocaleString(DateTime.DATETIME_SHORT)
            : '',
        sorter: true,
      },
      {
        title: t('general.updateDate'),
        key: 'updateDate',
        dataIndex: 'updateDate',
        render: (updateDate: number | undefined) =>
          updateDate
            ? DateTime.fromMillis(updateDate)
                .setLocale('fr')
                .toLocaleString(DateTime.DATETIME_SHORT)
            : '',
        sorter: true,
      },
    ],
    [t],
  );

  // only for Logic'Gram administrators
  const isLGAdmin = useUserIsLGAdmin();
  if (!isLGAdmin) {
    return <div />;
  }

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: UserForm,
    parseValues: parseUserValues,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <div>
      <AppTitle level={3} style={{marginTop: 0}}>
        {total
          ? `${t('settings.adminAllUsersTab')}: ${total}`
          : t('settings.adminAllUsersTab')}
      </AppTitle>
      <TableList<UserRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={userColumns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        onTotalChange={setTotal}
        customActions={[
          (user) => (
            <Tooltip
              title="Récupérer le statut Cognito"
              key="check-cognito-status"
            >
              <Button
                key="check-cognito-status"
                onClick={async () => {
                  const cognitoStatus = await fetchCognitoStatus(api, user.id);

                  const msg = `${t('users.cognitoStatus')}: ${cognitoStatus} (${
                    user.id
                  }/${user.email})`;
                  message.success(msg);
                  console.log(msg);
                }}
              >
                Statut
              </Button>
            </Tooltip>
          ),
          (user) => (
            <Tooltip
              title="Récupérer les groupes Cognito"
              key="check-cognito-groups"
            >
              <Button
                key="check-cognito-groups"
                onClick={async () => {
                  try {
                    const groups = await fetchCognitoGroups(api, user.id);

                    if (!groups) {
                      return;
                    }

                    if (groups.length === 0) {
                      const msg = `${t('users.cognitoGroups')}: aucun groupe (${
                        user.id
                      }/${user.email})`;
                      message.warning(msg);
                      console.log(msg);
                    } else {
                      const msg = `${t('users.cognitoGroups')}: ${groups
                        .map((g) => g.GroupName)
                        .join(', ')} (${user.id}/${user.email})`;
                      message.success(msg);
                      console.log(msg);
                    }
                  } catch (err: any) {
                    message.warning(err.message);
                    return;
                  }
                }}
              >
                Groupes
              </Button>
            </Tooltip>
          ),
        ]}
      />

      <TableUpdateModal<UserRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
      />
    </div>
  );
};

const parseUserValues = (values: UserFormValues): UserFormValues => values;

export const Users: FC = () => {
  const {t} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<UserRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const userColumns: TableColumnsProp<UserRecord> = useStateFn(
    () => [
      {
        title: t('users.lastName'),
        key: 'lastName',
        dataIndex: 'lastName',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('users.firstName'),
        key: 'firstName',
        dataIndex: 'firstName',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('users.email'),
        key: 'email',
        dataIndex: 'email',
        filterName: 'emailSearch',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('users.disabled'),
        key: 'disabled',
        dataIndex: 'disabled',
        render: (disabled) =>
          disabled ? (
            <CheckCircleTwoTone twoToneColor="#a30e10" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#52c41a" />
          ),
        filters: booleanFilters(t),
        sorter: true,
      },
    ],
    [t],
  );
  const forcedFilter = useStateFn(() => ({role: [UserRole.ADMIN]}), []);

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: UserForm,
    parseValues: parseUserValues,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <ContentDiv
      title={
        total
          ? `${t('settings.adminUsersTab')}: ${total}`
          : t('settings.adminUsersTab')
      }
      titleRightComponent={
        <Button
          type="primary"
          onClick={() => setCreateModalOpen(true)}
          icon={<PlusOutlined />}
        />
      }
    >
      <TableList<UserRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={userColumns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        forcedFilter={forcedFilter}
        onTotalChange={setTotal}
      />

      <TableCreateModal<UserRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
      />
      <TableUpdateModal<UserRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
      />
    </ContentDiv>
  );
};
