import {Col, Row, Spin} from 'antd';
import {FC} from 'react';
import {Navigate} from 'react-router-dom';
import {useTranslation} from '../translation';
import {useConnectedUser, UserRole, useUser, useUserRole} from '../util/Auth';
import {ContentDiv} from './layout/ContentDiv';
import {LogoutButton} from './LogoutButton';
import {UserAdminHome} from './UserAdminHome';
import {OwnerHome} from '../owner/OwnerHome';

const UserUnverifiedHome: FC = () => {
  const {t} = useTranslation();
  const user = useConnectedUser();

  return (
    <ContentDiv
      title={t('home.unvalidated.title')}
      titleRightComponent={<LogoutButton />}
    >
      <div style={{textAlign: 'center', fontSize: '1rem'}}>
        <p>{user?.attributes?.name?.toUpperCase()}</p>
        <p>{t('home.unvalidated.message1')}</p>
        <p>{t('home.unvalidated.message2')}</p>
        <p>{t('home.unvalidated.message3')}</p>
        <p>{t('home.unvalidated.message6')}</p>
      </div>
    </ContentDiv>
  );
};

const ConnectedHome: FC = (props) => {
  const role = useUserRole();

  if (role === UserRole.UNVERIFIED) {
    return <UserUnverifiedHome />;
  } else if (role === UserRole.OWNER) {
    return <OwnerHome />;
  } else if (role === UserRole.ADMIN) {
    return <UserAdminHome />;
  } else {
    console.error(`Unknown user role ${role}`);
  }

  return <></>;
};

/** "Home" is a special route because it can be accessed by both connected
 * AND non-connected users. */
export const Home: FC = () => {
  const user = useUser();

  // if the user is connected we go to ConnectedHome, or navigate to loading page
  if (!user) {
    return (
      <Row justify="center">
        <Col span={24} style={{textAlign: 'center'}}>
          <Spin size="large" /> <Navigate to="/login" />
        </Col>
      </Row>
    );
  } else {
    return <ConnectedHome />;
  }
};
