{
  "general.login": "Login",
  "general.logout": "Disconnettersi",
  "general.updateTitle": "Modifica",
  "general.update": "Modificare",
  "general.addTitle": "Aggiunta",
  "general.add": "Aggiungere",
  "general.yes": "Sì",
  "general.no": "No",
  "general.close": "Chiudere",
  "general.cancel": "Annulla",
  "general.delete": "Elimina",
  "general.submit": "Invia",
  "general.notConnected": "Non connesso",
  "general.uploadError": "Errore di caricamento",
  "general.form.messages.required": "Il campo \"${label}\" è obbligatorio",
  "general.form.messages.types_email": "Il campo \"${label}\" non è un indirizzo email valido",
  "general.form.messages.types_number": "Il campo \"${label}\" non è un numero valido",
  "general.form.messages.number_range": "Il campo \"${label}\" deve essere compreso tra ${min} e ${max}",
  "general.connectionError": "Errore di connessione",
  "general.timeIntervalAgo": "${interval} fa",
  "general.loginAction": "Ho già un account",
  "general.registerAction": "Creo il mio account",
  "general.upload": "Caricamento",
  "general.invalidUploadFileType": "Tipo di file non valido",
  "general.clearFilters": "Reimposta i filtri",
  "general.clearSorters": "Ripristina ordinamenti",
  "general.reset": "Ripristina",
  "general.search": "Ricerca...",
  "general.searchSomething": "Ricerca ${label}...",
  "general.ok": "OK",
  "general.goBackHome": "Torna alla homepage",
  "general.notFoundErrorMessage": "Pagina non trovata",
  "general.starMarksMandatory": "I campi * sono obbligatori",
  "general.export": "Esportare",
  "general.continue": "Continuare",
  "general.createDate": "Data di creazione",
  "general.updateDate": "Data di modifica",

  "general.overstaturationError.message1": "A causa di una rete satura, gli ordini per la piscina bianca sono temporaneamente sospesi.",
  "general.overstaturationError.message2": "Ci scusiamo per il disagio arrecato e vi invitiamo a rinnovare l'operazione entro 24/48 ore.",

  "general.sumupCheckError.message1": "Il pagamento degli ordini di carta bianca in CB è attualmente sospeso su questa applicazione per problemi tecnici.",
  "general.sumupCheckError.message2": "Puoi effettuare i tuoi ordini effettuando un bonifico. Per fare ciò, registra il tuo ordine con il metodo di pagamento Bonifico. Puoi anche inviarci un assegno:",
  "general.sumupCheckError.message3": "ASCAPE - Résidence du Belvédère - Boite N° 6 - 99 rue des Calanques - 83530 SAINT RAPHAEL",
  "general.sumupCheckError.message4": "Le carte saranno effettuate dopo il ricevimento del pagamento.",
  "general.sumupCheckError.message5": "Sono state date istruzioni di tolleranza ai bagnini all'ingresso della piscina Grand Bleu al fine di consentire l'accesso con le vecchie tessere a proprietari e inquilini in questo periodo",
  "general.sumupCheckError.message6": "Ci scusiamo per il disagio causato.",

  "general.duration.days": "Giorni",
  "general.duration.weeks": "Settimane",
  "general.duration.months": "Mese",
  "general.duration.years": "Anni",

  "general.error.negativeDuration": "La data di inizio deve iniziare prima della data di fine",

  "general.ownersArea": "Zona proprietari",
  "footer.theVillage": "Il sito",
  "footer.theVillage.visit": "Visita",
  "footer.theVillage.news": "Notizia",
  "footer.theVillage.findAccomodation": "Trova un alloggio",
  "footer.theVillage.shopsAndServices": "Negozi e servizi",
  "footer.theArea": "La zona",
  "footer.theArea.events": "Eventi",
  "footer.theArea.ourSelection": "CAP ESTEREL e dintorni",
  "footer.theArea.infoAndResources": "Informazioni e risorse",
  "footer.ownersArea": "Zona proprietari",
  "footer.ownersArea.swimmingPoolOrParkingCards": "Carte piscina o parcheggio",
  "footer.ownersArea.administrativeDocuments": "Documenti amministrativi",
  "footer.ownersArea.makeAStatement": "Fare una dichiarazione",
  "footer.ownersArea.news": "Notizia",
  "footer.ourNewsletter": "Il nostro bollettino",
  "footer.yourEmail": "La tua email",
  "footer.newsletterSignup": "Sottoscrivi",
  "footer.newsletterConfidentialyText": "Accetto l'informativa sulla privacy.",
  "footer.legalNotice": "Avviso legale",
  "footer.cookiePolicy": "Politica sui cookie",
  "footer.privacyPolicy": "Politica sulla riservatezza",
  "footer.termsOfSales": "Condizioni di vendita",

  "header.theVillage": "Il villaggio",
  "header.activities": "Attività",
  "header.tourism": "Turismo",
  "header.news": "Notizia",
  "header.contact": "Contatto",
  "header.owner.documents": "Documenti",
  "header.owner.info": "Informazione",
  "header.owner.news": "Notizia",
  "header.owner.orderTracking": "Tracciamento dell'ordine",
  "header.owner.myAccount": "Il mio account",
  "header.owner.myVehicles": "I miei veicoli",
  "header.owner.myOccupants": "I miei affitti",
  "header.admin.settings": "Impostazioni",
  "header.admin.apartments": "Appartamenti",
  "header.admin.owners": "Proprietari",
  "header.admin.orders": "Ordini",
  "header.admin.invoices": "Fatture",
  "header.admin.payments": "Regolamento",
  "header.admin.entries": "Scritture",
  "header.admin.stats": "Statistiche",

  "home.welcome": "Benarrivato",
  "home.user": "Utente",
  "home.notConnected": "Non connesso",
  "home.users": "Utenti",
  "home.owners": "Proprietari",
  "home.ownerRequests": "Account in attesa di convalida",
  "home.apartments": "Appartamenti",
  "home.hosts": "Host",
  "home.articles": "Articoli",

  "home.ordersUnavailable": "Il modulo per l'inserimento degli ordini delle tessere piscina e parcheggi è in fase di test, sarà aperto a breve.\nGrazie per la comprensione",

  "home.settings": "Impostazioni",
  "home.stats": "Statistiche",
  "home.orders": "Ordini",
  "home.invoices": "Fatture",
  "home.payments": "Regolamento",
  "home.accountingEntries": "Scritture",
  "home.apartmentRequests": "Appartamenti in attesa",
  "home.cardsAwaitingProduction": "Carte da realizzare",
  "home.cardsAwaitingDelivery": "Carte di consegna",
  "home.cardsGold": "Carte d'oro",
  "home.vehicles": "Veicoli",
  "home.occupants": "Noleggi",
  "home.superAdmin": "Super admin",

  "home.unconfirmed.title": "Conferma dell'account",
  "home.unconfirmed.message1": "Convalida il tuo indirizzo e-mail facendo clic sul collegamento nell'e-mail che hai ricevuto.",
  "home.unconfirmed.message2": "Arrivederci",

  "home.unvalidated.title": "Nuova verifica dell'account",
  "home.unvalidated.message1": "Abbiamo ricevuto la tua richiesta di creazione dell'account.",
  "home.unvalidated.message2": "Viene convalidato dai nostri servizi.",
  "home.unvalidated.message3": "Ti verrà inviata a breve una mail di conferma.",
  "home.unvalidated.message4": "",
  "home.unvalidated.message5": "",
  "home.unvalidated.message6": "Arrivederci",

  "login.title": "Login",
  "login.loginActionButton": "Login",
  "login.error.pleaseInputUsername": "Inserisci il tuo nome utente",
  "login.username": "Nome utente",
  "login.error.pleaseInputPassword": "Inserisci la tua password",
  "login.password": "Parola d'ordine",
  "login.forgotPassword": "Ha dimenticato la password",
  "login.home": "Accoglienza",
  "login.newPasswordTitle": "Nuova password",
  "login.newPasswordText": "Inserisci il codice di verifica che ti è stato inviato via email.",
  "login.newPassword": "Nuova password",
  "login.newPasswordConfirm": "Conferma password",
  "login.forcePasswordTitle": "Parola d'ordine",
  "login.forcePasswordText": "Si prega di scegliere una password.",

  "users.title": "Utenti",
  "users.email": "E-mail",
  "users.name": "Nome",
  "users.firstName": "Nome di battesimo",
  "users.lastName": "Cognome",
  "users.userTitle": "Titolo",
  "users.userTitleMr": "Signor",
  "users.userTitleMrs": "Signora",
  "users.userTitleMrAndMrs": "Signor e Signora",
  "users.role": "Ruolo",
  "users.roleNotVerified": "Non verificato",
  "users.roleUnvalidated": "Non validato",
  "users.roleOwner": "Proprietario",
  "users.roleAdmin": "Admin",
  "users.wordpressUserId": "ID utente WordPress",
  "users.disabled": "In pausa",
  "users.cognitoStatus": "Status di Cognito",
  "users.cognitoGroups": "Cognito groups",
  "users.resetPasswordAndSendEmail": "Reimposta password e invia email",
  "users.changeEmailAndResetPassword": "Change email + reset password",
  "users.changeEmailAndResetPassword.info": "This will change the email address associated with the account and then initiate a password reset. An email will be sent to the user with their temporary password.",
  "users.updateEmail": "Cambia indirizzo email",
  "users.previousEmail": "E-mail esistente",
  "users.warning.updateEmail": "Verificare che l'utente abbia accesso a questo nuovo indirizzo email prima di modificarlo.\nNon verrà verificato dall'applicazione e l'utente non sarà immediatamente in grado di accedere con il vecchio nome utente",
  "users.updateEmail.email.subject": "Cambia indirizzo email",
  "users.updateEmail.email.body": "Ciao,\n\nVorrei aggiornare l'indirizzo email associato al mio account ${email}\nIl mio nuovo indirizzo email è il seguente: \n\nSaluti,\n${name}",

  "owners.title": "Proprietari",
  "owners.code": "Codice",
  "owners.displayRecipientFields": "Visualizza campi destinatario (ordini)",
  "owners.phoneNumber": "Telefono",

  "hosts.title": "Host",
  "hosts.name": "Nome",
  "hosts.thirdParty": "Terzo",
  "hosts.address": "Indirizzo",
  "hosts.contactName": "Contatto",
  "hosts.contactPhoneNumber": "Numero di telefono",
  "hosts.email": "E-mail",
  "hosts.comment": "Commento",

  "apartments.title": "Appartamenti",
  "apartments.code": "Codice",
  "apartments.building": "Costruzione",
  "apartments.type": "Tipo",
  "apartments.doorNumber": "Numero di porta",
  "apartments.beddings": "Biancheria da letto",
  "apartments.host": "Ospite",
  "apartments.owner": "Proprietario",
  "apartments.condo": "Condominio",
  "apartments.comment": "Commento",

  "apartments.unassign": "Annulla assegnazione",
  "apartments.unassign.confirm": "Sei sicuro di voler annullare l'assegnazione di questo appartamento a questo proprietario?",

  "apartments.error.alreadyOwned": "Questo appartamento è già assegnato ad un altro proprietario",
  "apartments.error.alreadyOwnedBySelf": "Questo appartamento è già assegnato a questo proprietario",

  "articles.title": "Articoli",
  "articles.code": "Codice",

  "articles.label": "Etichetta",
  "articles.type": "Genere",
  "articles.season": "Stagione",
  "articles.seasonStart": "Inizio stagione",
  "articles.seasonEnd": "Fine di stagione",
  "articles.validityDuration": "Periodo di validità",
  "articles.validityDurationType": "Unità di validità",
  "articles.unitPriceInclVAT": "Tutte le tasse incluse nel prezzo",
  "articles.VAT": "I.V.A.",
  "articles.accountingCode": "Codice dell'account",
  "articles.active": "risorsa web",
  "articles.limitToBeddingQuota": "Rispettare la quota di sonno",
  "articles.beddingQuota": "Biancheria da letto",
  "articles.isBlanche": "Carta bianca",
  "articles.accountingCategory": "Category",

  "article.type.parking": "Parcheggio",
  "article.type.poolBlanche": "Piscina Bianca",
  "article.type.poolGold": "Piscina Oro",
  "article.type.poolGoldAdditional": "Piscina Oro Agg.",
  "article.type.lostParking": "Parcheggio persa",
  "article.type.lostPool": "Piscina persa",
  "article.type.parkingClone": "Parcheggio Clone",
  "article.type.misc": "Varie",
  "article.accountingCategory.parking": "Parking",
  "article.accountingCategory.pool": "Pool",

  "article.inactive.ownerInvisible": "Questo tipo di articolo non viene mostrato ai proprietari",
  "article.inactive.season": "Questo articolo è allegato a una stagione inattiva e non verrà mostrato ai proprietari",

  "register.title": "Uscrizione",
  "register.lastName": "Nome",
  "register.firstName": "Nome",
  "register.title_gender": "Civiltà",
  "register.address": "Indirizzo (della tua residenza principale)",
  "register.zipCode": "Codice postale",
  "register.city": "Città",
  "register.country": "Paese",
  "register.phoneNumber": "Mobile",
  "register.condoBuildingApartmentCode": "Copro / Costruzione / Appartamento",
  "register.condo": "Copro",
  "register.building": "Costruzione",
  "register.apartmentCode": "Appartamento",
  "register.email": "E-mail",
  "register.password": "Parola d'ordine",
  "register.passwordValidation": "Conferma password",
  "register.submit": "Creare",
  "register.passwordValidationReject": "Le due password inserite non corrispondono",
  "register.mustBeInternational": "Il numero deve iniziare con un prefisso internazionale +XX",
  "register.invalidPhoneNumber": "Il numero di telefono deve essere valido",
  "register.verificationCode": "Codice di verifica",
  "register.pleaseEnterVerificationCode": "Inserisci il codice di verifica che ti è stato inviato via email.",
  "register.condoBuildingApartmentMessage": "(Informazioni trovate sul tuo estratto conto)",

  "register.password.minLength": "La tua password deve contenere almeno ${minLength} caratteri",
  "register.password.lower": "La tua password deve contenere un carattere minuscolo",
  "register.password.upper": "La tua password deve contenere un carattere maiuscolo",
  "register.password.digit": "La tua password deve contenere un carattere numerico",
  "register.password.symbol": "La tua password deve contenere un carattere speciale",

  "register.success.completeNewPassword": "La tua password è stata cambiata con successo. Accedi con la tua nuova password",
  "register.signUpError": "Errore di registrazione",
  "register.code": "Codice",

  "cognito.error.UserNotFoundException": "Utente non trovato",
  "cognito.error.UsernameExistsException": "Questa email è già utilizzata da un altro utente",
  "cognito.error.NotAuthorizedException": "Accesso rifiutato",
  "cognito.error.CodeMismatchException": "Il codice di convalida non è valido",
  "cognito.error.UserNotConfirmedException": "Il tuo indirizzo email non è stato verificato",

  "owner.error.cannotDeleteIfApartments": "Impossibile eliminare un proprietario associato agli appartamenti. Si prega di annullare l'assegnazione di questi appartamenti",

  "ownerRequests.title": "Account in attesa di verifica",
  "ownerRequests.fullName": "Nome e cognome",
  "ownerRequests.lastName": "Cognome",
  "ownerRequests.firstName": "Nome di battesimo",
  "ownerRequests.title_gender": "Titolo",
  "ownerRequests.address": "Indirizzo",
  "ownerRequests.zipCode": "Cap",
  "ownerRequests.city": "Città",
  "ownerRequests.country": "Paese",
  "ownerRequests.phoneNumber": "Numero di telefono",
  "ownerRequests.email": "E-mail",
  "ownerRequests.apartment": "Appartamento",
  "ownerRequests.createDate": "Data di creazione",

  "ownerRequest.validate": "Convalidare",
  "ownerRequest.validateWarning": "Sei sicuro di voler creare questo proprietario? Il proprietario verrà avvisato via e-mail",
  "ownerRequest.validateError": "L'appartamento collegato a questa richiesta è già associato a un proprietario verificato",

  "orders.title": "Ordini",
  "orders.newOrder": "Nuovo ordine",
  "orders.orderNumber": "Numero d'ordine",
  "orders.owner": "Proprietario",
  "orders.orderedCards": "Carte ordinate",
  "orders.deliveredCards": "Carte consegnate",
  "orders.totalPrice": "Prezzo totale tasse incluse",
  "orders.status": "Stato",
  "orders.accountingStatus": "Stato dell'account",
  "orders.fabricationStatus": "Stato di fabbricazione",
  "orders.articleQuantity": "Quantità",
  "orders.createDate": "Data di acquisto",
  "orders.createDate.short": "Data",
  "orders.apartment": "Appartamento",
  "orders.article": "Articolo",
  "orders.season": "Stagione",
  "orders.articleType": "Tipo di articolo",
  "orders.validityStart": "Inizio validità",
  "orders.validityEnd": "Fine validità",
  "orders.comment": "Nota",
  "orders.paymentType": "Tipo di pagamento",
  "orders.isProxy": "Delega",
  "orders.isProxy.form": "Concedo delega a terzi",
  "orders.proxy": "Nome completo del proxy",
  "orders.proxyTemplateDownload": "Scarica il PDF della delega da compilare",
  "orders.proxyFile": "PDF delega compilato",
  "orders.type": "Genere",
  "orders.discount": "Sconto",
  "orders.netPrice": "Prezzo netto",
  "orders.checkoutId": "ID carrello",
  "orders.checkNumber": "N° assegno",
  "orders.isRenewal": "Tessera da rinnovare",
  "orders.isRenewal.short": "Rinnovare",
  "orders.iHaveAClone": "Ho un clone",
  "orders.recipient": "Destinatario",
  "orders.recipientName": "Nome (destinatario)",
  "orders.recipientAddr": "Indirizzo (destinatario)",
  "orders.recipientZipCodeAndCity": "Cap - Città (destinatario)",
  "orders.recipientZipCode": "Cap",
  "orders.recipientCity": "Città",
  "orders.recipientCountry": "Paese (destinatario)",
  "orders.nbGoldCards": "Numero di carte d'oro",
  "orders.renewalCardNo": "Inserisci il numero o il cognome/nome di tutte le carte da rinnovare, separati da una virgola",
  "orders.renewalCardNo.short": "Carte da rinnovare",
  "orders.renewalCardNo.gold": "Inserisci nella tabella a destra il nome delle persone o il numero delle carte oro da rinnovare",

  "orders.regeneratePDF.confirm": "Are you sure you want to regenerate PDF for this order? This will erase current PDF (if any).",
  "orders.regeneratePDF.success": "PDF has been regenerated successfully",
  "orders.regeneratePDF.error": "An error occurred while regenerating the PDF.",

  "orders.type.invoice": "Fattura",
  "orders.type.credit": "Avere",

  "orders.accountingStatus.submitted": "Salvato",
  "orders.accountingStatus.printed": "stampato",
  "orders.accountingStatus.paid": "Pagato",
  "orders.accountingStatus.accounted": "Contato",
  "orders.accountingStatus.failed": "Fallito",

  "orders.fabricationStatus.submitted": "Salvato",
  "orders.fabricationStatus.paid": "Pagato",
  "orders.fabricationStatus.available": "Disponibile",
  "orders.fabricationStatus.delivered": "Livrea",

  "order.paymentType.paypal": "PayPal",
  "order.paymentType.sumup": "Carta di credito (SumUp)",
  "order.paymentType.wire": "Bonifico bancario",
  "order.paymentType.check": "Controllo",
  "order.paymentType.cash": "Specie",
  "order.paymentType.pos": "Terminale di pagamento elettronico",
  "order.paymentType.soge": "Carta di credito",
  "orders.payment.wireInstructions": "Coordinate bancarie",
  "orders.payment.iban": "IBAN",
  "orders.payment.bic": "BIC",
  "orders.payment.checkInstructions": "Controlla dettagli",
  "orders.payment.checkOrder": "Ordine",
  "orders.payment.checkAddress": "Indirizzo",
  "orders.securePayment": "Pagamento sicuro",

  "order.modal.title": "Confermare l'ordine",
  "order.modal.confirm": "Conferma con obbligo di pagamento",
  "order.modal.summary": "Sintesi",
  "orders.confirm.terms.prefix": "Ho letto e accetto il ",
  "orders.confirm.terms.link": "condizioni di vendita",
  "orders.confirm.terms.suffix": "",

  "order.submitSuccess": "Il tuo ordine è stato inviato con successo",

  "order.error.noArticles": "Non ci sono articoli disponibili per questo appartamento/stagione. Si prega di contattare il nostro supporto",

  "orderLines.title": "Linee di comando",

  "orderLines.article": "Articolo",
  "orderLines.unitPrice": "Prezzo unitario",
  "orderLines.quantity": "Quantità",
  "orderLines.VAT": "I.V.A.",
  "orderLines.totalPrice": "Prezzo totale",
  "orderLines.discount": "Sconto",
  "orderLines.netPrice": "Prezzo netto",
  "orderLines.customNetPrice": "Prezzo manuale",

  "card.create": "Aggiunta di una mappa personalizzata",
  "card.upload.warning": "Solo le foto riconoscibili (senza cappello, senza occhiali da sole, ecc.)",

  "cards.title": "Carte",
  "card.lastName": "cognome",
  "card.firstName": "Nome",
  "card.isChild": "Bambino < 6 anni",
  "card.cost": "In aumento",
  "card.photo": "Foto",

  "seasons.title": "Stagioni",
  "seasons.code": "Codice",
  "seasons.dateStart": "Data di inizio",
  "seasons.dateEnd": "Data di fine",
  "seasons.active": "Attivo sul web",

  "confirm.title": "Conferma",
  "confirm.email": "E-mail",
  "confirm.code": "Codificato",
  "confirm.submit": "Confermator",

  "settings.title": "Impostazioni",
  "settings.nextOrderId": "Prossimo numero d'ordine",
  "settings.nextInvoiceId": "Numero fattura successiva",
  "settings.nextThirdPartyId": "Prossimo numero di terze parti",
  "settings.vatRate": "Aliquota IVA",
  "settings.bankAccountClient": "Conto cliente",
  "settings.bankAccountSales": "Conto vendita",
  "settings.bankAccountVat": "Conto IVA",
  "settings.bankAccountBank": "Conto bancario",
  "settings.journalSales": "Giornale delle vendite",
  "settings.journalBank": "Giornale di banca",
  "settings.iban": "IBAN",
  "settings.bic": "BIC",
  "settings.poolIban": "Piscina IBAN",
  "settings.poolBic": "Piscina BIC",
  "settings.parkingIban": "Parcheggio IBAN",
  "settings.parkingBic": "Parcheggio BIC",
  "settings.settingsTab": "Impostazioni / Host",
  "settings.articlesTab": "Articoli / Le stagioni",
  "settings.adminUsersTab": "Utenti amministratori",
  "settings.adminAllUsersTab": "Utenti (tutti)",
  "settings.checkOrder": "Ordine",
  "settings.checkAddress": "Indirizzo",
  "settings.disableOrderCreationOnClientPortal": "Disabilitare la creazione dell'ordine sul portale del cliente",

  "apartmentRequests.title": "Richieste Appartamento",
  "apartmentRequests.requestTitle": "Richiesta di aggiungere un nuovo appartamento",
  "apartmentRequests.fullName": "Proprietario",
  "apartmentRequests.email": "Email proprietario",
  "apartmentRequests.code": "Codificato",
  "apartmentRequests.createDate": "Data di creazione",

  "apartmentRequests.alreadyOwned": "Questo appartamento è già associato al tuo account",
  "apartmentRequests.alreadyRequested": "Questo appartamento è già in attesa di verifica",

  "apartmentRequests.validateWarning": "Sei sicuro di voler collegare questo appartamento a questo proprietario? Il proprietario riceverà una notifica via email",
  "apartmentRequests.validateError": "L'appartamento collegato a questa richiesta è già associato a un proprietario",

  "creditCard.title": "Carta di credito",
  "creditCard.name": "Nome del titolare",
  "creditCard.number": "Numero di carta",
  "creditCard.expiryMonth": "Mese di scadenza",
  "creditCard.expiryYear": "Anno di scadenza",
  "creditCard.cvv": "Codice di sicurezza",

  "creditCard.error.invalidCC": "Numero di carta non valido",
  "creditCard.error.expiredCC": "Carta scaduta",
  "creditCard.error.invalidCVC": "Codice di sicurezza non valido",

  "checkoutResult.pending": "Pagamento in attesa di conferma",

  "checkoutResult.notFound.title": "Cassa non trovata",
  "checkoutResult.notFound.subTitle": "Non siamo riusciti a recuperare le informazioni di pagamento del tuo ordine. Per ulteriori informazioni, contatta il nostro supporto",

  "checkoutResult.notCompleted.title": "Il pagamento è stato annullato o non è riuscito",
  "checkoutResult.notCompleted.subTitle": "Il tuo ordine non ti è stato addebitato. Riprova o contatta il nostro supporto per ulteriori informazioni",

  "checkoutResult.success.title": "Pagamento riuscito",
  "checkoutResult.success.subTitle": "Il tuo pagamento è stato ricevuto correttamente e il tuo ordine verrà elaborato a breve",

  "invoices.title": "Fatture",
  "invoices.createDate": "Crea data",
  "invoices.invoicedName": "Nome",
  "invoices.amount": "Quantità",
  "invoices.invoiceNumber": "Numero di fattura",
  "invoices.status": "Stato",
  "invoices.type": "Tipo",

  "payments.title": "Pagamenti",
  "payments.type": "Tipo",
  "payments.paymentDate": "Data di pagamento",
  "payments.payerName": "Nome",
  "payments.label": "Etichetta",
  "payments.amount": "Quantità",
  "payments.invoiceNumber": "Numero di fattura",
  "payments.isAccounted": "Contabilità",
  "payments.updateOrderStatus": "Questo pagamento paga l'ordine N°${orderNumber}",

  "payments.warn.unexpectedOrderStatus": "L'ordine ${orderNumber} non è più in sospeso, il suo stato attuale è \"${orderStatus}\"",
  "payments.warn.unexpectedPaymentAmount": "L'ordine ${orderNumber} ha un importo totale di ${orderAmount}, ma il pagamento corrente è di ${paymentAmount}",
  "payments.warn.unexpectedInvoiceOrderNumber": "L'ordine associato alla fattura è diverso dall'ordine associato al pagamento",

  "payments.error.amountMustBePositiveAndGreaterThanZero": "L'importo del pagamento deve essere rigorosamente superiore a 0,00",

  "cardsAwaitingProduction.title": "Carte in attesa di produzione",
  "cardsAwaitingDelivery.title": "Carte in attesa di consegna",

  "cards.startDate": "Data",
  "cards.order": "Ordine",
  "cards.name": "Nome",
  "cards.apartment": "Appartamento",
  "cards.article": "Articolo",
  "cards.count": "Conteggio carte ordinate",
  "cards.fabricatedCount": "Conteggio delle carte fabbricato",
  "cards.deliveredCount": "Conteggio delle carte consegnate",
  "cards.remainder": "Resto",
  "cards.amount": "Quantità",
  "cards.photo": "Foto",

  "cards.bulkDeliverRenewals": "Consegna tutte le carte da rinnovare",

  "stats.title": "Statistiche",
  "stats.cognitoStats": "Statistiche utente",
  "stats.cognito.total": "Utenti registrati",
  "stats.cognito.pending": "Utenti che hanno cambiato la propria password",
  "stats.salesStats": "Statistiche di vendita",
  "stats.offSeason": "Fuori stagione?",
  "stats.card.goldSeasonalExport": "Esportazione stagionale dell'oro consegnato",

  "entries.title": "Voci",
  "entries.export": "Esportazione voci",
  "entries.exportStartDate": "Data di inizio della fattura",
  "entries.exportEndDate": "Data fine fattura",

  "cardsGold.title": "Carte d'oro",

  "vehicles": "Veicoli",
  "vehicles.title": "Veicoli",
  "vehicles.createDate": "Data di creazione",
  "vehicles.updateDate": "Data di modifica",
  "vehicles.date": "Data",
  "vehicles.active": "Attivo",
  "vehicles.marked": "Segnato",
  "vehicles.type": "Tipo",
  "vehicles.type.owner": "Proprietario",
  "vehicles.type.occupant": "Inquilino",
  "vehicles.ownerId": "Proprietario",
  "vehicles.apartmentId": "Appartamento",
  "vehicles.brandName": "Marca",
  "vehicles.model": "Modello",
  "vehicles.licensePlateNo": "Targa",
  "vehicles.licensePlateNo.short": "Targa",
  "vehicles.occupantName": "Nome dell'inquilino",
  "vehicles.phoneNumber": "Telefono",
  "vehicles.dates": "Date",
  "vehicles.startDate": "Data d'inizio",
  "vehicles.endDate": "Data di fine",
  "vehicles.error.already_three_vehicles_for_this_owner": "Ci sono già 3 veicoli attivi per questo proprietario.",
  "vehicles.error.already_one_vehicle_for_this_period": "Esiste già un veicolo per questo periodo.",
  "vehicles.newOccupant": "Veicolo nuovo (noleggio)",
  "vehicles.newVehicle": "Nuovo veicolo"
}
