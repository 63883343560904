import {useRequest} from 'ahooks';
import {Button, Result, Spin} from 'antd';
import {FC, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {useTranslation} from '../translation';
import {DEFAULT_API_NAME, useApi} from '../util/Auth';
import {OrderAccountingStatus, OrderRecord} from './table/Order';

const singularRoute = '/order';
const pluralRoute = '/orders';

interface CheckoutNotFoundResultProps {
  checkoutId?: string;
  order?: OrderRecord;
}
const CheckoutNotFoundResult: FC<CheckoutNotFoundResultProps> = ({
  checkoutId,
  order,
}) => {
  const {t} = useTranslation();
  return (
    <Result
      status="404"
      title={t('checkoutResult.notFound.title')}
      subTitle={
        <div>
          <p>{t('checkoutResult.notFound.subTitle')}</p>
          {order ? <p>order.id = {order.id}</p> : null}
          {checkoutId ? <p>checkoutId = {checkoutId}</p> : null}
        </div>
      }
      extra={
        <Link to="/">
          <Button type="primary">{t('general.goBackHome')}</Button>
        </Link>
      }
    />
  );
};

const CheckoutNotCompletedResult: FC = () => {
  const {t} = useTranslation();
  return (
    <Result
      status="500"
      title={t('checkoutResult.notCompleted.title')}
      subTitle={t('checkoutResult.notCompleted.subTitle')}
      extra={
        <Link to="/">
          <Button type="primary">{t('general.goBackHome')}</Button>
        </Link>
      }
    />
  );
};

const CheckoutSuccessfulResult: FC = () => {
  const {t} = useTranslation();
  return (
    <Result
      status="success"
      title={t('checkoutResult.success.title')}
      subTitle={t('checkoutResult.success.subTitle')}
      extra={
        <Link to="/">
          <Button type="primary">{t('general.goBackHome')}</Button>
        </Link>
      }
    />
  );
};

export const SumupSecureCheckoutSuccess: FC = () => {
  const api = useApi();

  const [searchParams] = useSearchParams();
  const checkout_id = searchParams.get('checkout_id');

  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const fetchOrder = async (): Promise<OrderRecord | undefined> => {
    if (!api || !checkout_id) return;
    const order = ((
      await api.get(
        DEFAULT_API_NAME,
        `${pluralRoute}/me?f_checkoutId=${checkout_id}`,
        {},
      )
    )?.entities ?? [])[0];

    setIsSuccess(
      order && order.accountingStatus !== OrderAccountingStatus.SUBMITTED
        ? true
        : null,
    );

    return order;
  };
  const {data: order, loading: orderLoading} = useRequest(fetchOrder, {
    refreshDeps: [api, checkout_id],
  });

  const confirmOrder = async () => {
    if (!api || !order) return;
    const res = await api.post(
      DEFAULT_API_NAME,
      `${singularRoute}/${order.id}/confirmPayment`,
      {},
    );

    console.log(res);

    await api.post(
      DEFAULT_API_NAME,
      `${singularRoute}/${order.id}/generateInvoicePDF`,
      {},
    );
  };

  const {loading: confirmLoading, run: runConfirm} = useRequest(confirmOrder, {
    refreshDeps: [api, order],
    manual: true,
    onSuccess: () => setIsSuccess(true),
    onError: () => setIsSuccess(false),
  });

  if (!checkout_id) {
    return <CheckoutNotFoundResult />;
  }

  if (orderLoading || confirmLoading) {
    return <Spin size="large" />;
  }

  if (!order || order.checkoutId !== checkout_id) {
    return <CheckoutNotFoundResult checkoutId={checkout_id} order={order} />;
  }

  if (
    isSuccess === null &&
    order &&
    order.accountingStatus === OrderAccountingStatus.SUBMITTED &&
    !confirmLoading
  ) {
    runConfirm();
  }

  if (isSuccess === false) return <CheckoutNotCompletedResult />;
  if (isSuccess === true) return <CheckoutSuccessfulResult />;

  return <Spin size="large" />;
};
