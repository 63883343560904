import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {formatCurrency, useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {ValidityDurationType} from '../../util/date';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {SeasonRecord} from './Season';
import {
  booleanFilters,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';

const singularRoute = '/article';
const pluralRoute = '/articles';

export enum ArticleType {
  PARKING = 1,
  POOL_BLANCHE,
  POOL_GOLD,
  POOL_GOLD_ADDITIONAL,
  LOST_PARKING,
  LOST_POOL,
  PARKING_CLONE,
  MISC,
}

export const ArticleTypeLabel: {[type in ArticleType]: string} = {
  [ArticleType.PARKING]: 'article.type.parking',
  [ArticleType.POOL_BLANCHE]: 'article.type.poolBlanche',
  [ArticleType.POOL_GOLD]: 'article.type.poolGold',
  [ArticleType.POOL_GOLD_ADDITIONAL]: 'article.type.poolGoldAdditional',
  [ArticleType.LOST_PARKING]: 'article.type.lostParking',
  [ArticleType.LOST_POOL]: 'article.type.lostPool',
  [ArticleType.PARKING_CLONE]: 'article.type.parkingClone',
  [ArticleType.MISC]: 'article.type.misc',
};

export const OwnerVisibleArticleTypes: ArticleType[] = [
  ArticleType.PARKING,
  ArticleType.POOL_BLANCHE,
  ArticleType.POOL_GOLD,
  ArticleType.POOL_GOLD_ADDITIONAL,
];

export const OwnerSelectableArticleTypes: ArticleType[] = [
  ArticleType.PARKING,
  ArticleType.POOL_BLANCHE,
  ArticleType.POOL_GOLD,
];

export enum ArticleAccountingCategory {
  PARKING = 1,
  POOL,
}

export const ArticleAccountingCategoryLabel: {
  [type in ArticleAccountingCategory]: string;
} = {
  [ArticleAccountingCategory.PARKING]: 'article.accountingCategory.parking',
  [ArticleAccountingCategory.POOL]: 'article.accountingCategory.pool',
};

export const ValidityDurationTypeLabel: {
  [type in ValidityDurationType]: string;
} = {
  [ValidityDurationType.DAYS]: 'general.duration.days',
  [ValidityDurationType.WEEKS]: 'general.duration.weeks',
  [ValidityDurationType.MONTHS]: 'general.duration.months',
  [ValidityDurationType.YEARS]: 'general.duration.years',
};

export interface CommonArticleFormValues {
  code: string;
  label: string;
  type: ArticleType;
  unitPriceInclVAT: number | undefined;
  VAT: number;
  accountingCode: string;
  active: boolean;
  accountingCategory: ArticleAccountingCategory;
}

export const ArticleTypeRequireArticleSelection: ArticleType[] = [
  ArticleType.POOL_BLANCHE,
];

export const ArticleTypeRequireNamedCards: ArticleType[] = [
  ArticleType.POOL_GOLD,
  ArticleType.POOL_GOLD_ADDITIONAL,
];

export const ArticleTypeAllowsProxy: ArticleType[] = [
  ArticleType.POOL_BLANCHE,
  ArticleType.POOL_GOLD,
  ArticleType.POOL_GOLD_ADDITIONAL,
];

export const ArticleTypeUnitPriceOptional: ArticleType[] = [
  ArticleType.POOL_GOLD,
];

export const ArticleTypeRequiresCardFabrication: ArticleType[] = [
  ArticleType.PARKING,
  ArticleType.POOL_BLANCHE,
  ArticleType.POOL_GOLD,
  ArticleType.POOL_GOLD_ADDITIONAL,
  ArticleType.LOST_PARKING,
  ArticleType.LOST_POOL,
  ArticleType.PARKING_CLONE,
];

export const ArticleTypeRequiresValidityInterval: ArticleType[] = [
  ArticleType.PARKING,
  ArticleType.POOL_BLANCHE,
  ArticleType.POOL_GOLD,
  ArticleType.POOL_GOLD_ADDITIONAL,
  ArticleType.LOST_PARKING,
  ArticleType.LOST_POOL,
  ArticleType.PARKING_CLONE,
];

export interface SeasonalArticleFormValues extends CommonArticleFormValues {
  seasonId: string;
}
export const SeasonalArticleType: ArticleType[] = [
  ArticleType.POOL_GOLD,
  ArticleType.POOL_GOLD_ADDITIONAL,
  ArticleType.POOL_BLANCHE,
  ArticleType.LOST_POOL,
];
export const isArticleValuesSeasonal = (
  article: CommonArticleFormValues,
): article is SeasonalArticleFormValues =>
  SeasonalArticleType.includes(article.type);

export interface QuotaArticleFormValues extends CommonArticleFormValues {
  beddingQuota: number;
}
export const QuotaArticleType: ArticleType[] = [ArticleType.POOL_BLANCHE];
export const isArticleValuesQuota = (
  article: CommonArticleFormValues,
): article is QuotaArticleFormValues => QuotaArticleType.includes(article.type);

export interface PeriodicArticleFormValues extends CommonArticleFormValues {
  validityDuration: number;
  validityDurationType: ValidityDurationType;
}
export const PeriodicArticleType: ArticleType[] = [ArticleType.PARKING];
export const isArticleValuesPeriodic = (
  article: CommonArticleFormValues,
): article is PeriodicArticleFormValues =>
  PeriodicArticleType.includes(article.type);

export interface ArticleRecord extends CommonArticleFormValues {
  id: string;
  createDate: number;
  updateDate: number;
}
export interface SeasonalArticleRecord extends ArticleRecord {
  seasonId: string;
  season: SeasonRecord;
}

export const isArticleRecordSeasonal = (
  article: ArticleRecord,
): article is SeasonalArticleRecord =>
  SeasonalArticleType.includes(article.type);

const articleInitialValues: Partial<CommonArticleFormValues> = {VAT: 0};

export const ArticleForm: FC<TableFormProps<CommonArticleFormValues>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t} = useTranslation();
  const api = useApi();

  useMemo(() => {
    form.setFieldsValue({
      ...articleInitialValues,
      ...initialValues,
      // next line fixes a problem when user loads up an Article that has
      // no "accountingCategory" value (in that case, the form keeps
      // the previous value.)
      accountingCategory: initialValues.accountingCategory ?? undefined,
    });
  }, [form, initialValues]);

  const [seasons, setSeasons] = useState<SeasonRecord[]>();
  const [seasonsLoading, setSeasonsLoading] = useState(false);

  const getSeasons = useCallback(async () => {
    if (!api) return;

    setSeasonsLoading(true);
    const {entities} = await api.get(DEFAULT_API_NAME, '/seasons', {});
    setSeasonsLoading(false);
    setSeasons(entities);
  }, [api]);

  useEffect(() => {
    getSeasons();
  }, [getSeasons]);

  const typeValue = Form.useWatch('type', form);
  const seasonIdValue = Form.useWatch('seasonId', form);

  const season: SeasonRecord | undefined = useMemo(() => {
    if (!seasonIdValue || !seasons) return;
    const season = seasons.find(({id}) => id === seasonIdValue);

    return season;
  }, [seasonIdValue, seasons]);

  const disabledReason =
    typeValue && !OwnerVisibleArticleTypes.includes(typeValue)
      ? 'article.inactive.ownerInvisible'
      : season && !season.active
      ? 'article.inactive.season'
      : '';

  const unitPriceRequired = !ArticleTypeUnitPriceOptional.includes(typeValue);

  return (
    <Form<CommonArticleFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...articleInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="code"
            label={t('articles.code')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="label"
            label={t('articles.label')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label={t('articles.type')}
            rules={[{required: true}]}
          >
            <Select<ArticleType>
              options={Object.entries(ArticleTypeLabel).map(
                ([type, label]) => ({
                  value: Number(type) as ArticleType,
                  label: t(label),
                }),
              )}
            />
          </Form.Item>

          {SeasonalArticleType.includes(typeValue) ? (
            <Form.Item
              name="seasonId"
              label={t('articles.season')}
              rules={[{required: true}]}
            >
              <Select
                options={seasons?.map(({id, code}) => ({
                  value: id,
                  label: code,
                }))}
                loading={seasonsLoading}
                allowClear
              />
            </Form.Item>
          ) : PeriodicArticleType.includes(typeValue) ? (
            <>
              <Form.Item
                name="validityDuration"
                label={t('articles.validityDuration')}
                rules={[{required: true}]}
              >
                <InputNumber name="validityDuration" />
              </Form.Item>
              <Form.Item
                name="validityDurationType"
                label={t('articles.validityDurationType')}
                rules={[{required: true}]}
              >
                <Select<ValidityDurationType>
                  options={Object.entries(ValidityDurationTypeLabel).map(
                    ([type, label]) => ({
                      value: Number(type) as ValidityDurationType,
                      label: t(label),
                    }),
                  )}
                />
              </Form.Item>
            </>
          ) : null}
        </Col>
        <Col span={12}>
          <Form.Item
            name="unitPriceInclVAT"
            label={t('articles.unitPriceInclVAT')}
            rules={[{required: unitPriceRequired}]}
          >
            <InputNumber addonAfter="€" />
          </Form.Item>

          <Form.Item
            name="VAT"
            label={t('articles.VAT')}
            rules={[{required: unitPriceRequired}]}
          >
            <InputNumber addonAfter="%" />
          </Form.Item>

          <Form.Item
            name="accountingCode"
            label={t('articles.accountingCode')}
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="accountingCategory"
            label={t('articles.accountingCategory')}
            rules={[{required: true}]}
          >
            <Select<ArticleAccountingCategory>
              options={Object.entries(ArticleAccountingCategoryLabel).map(
                ([cat, label]) => ({
                  value: Number(cat) as ArticleAccountingCategory,
                  label: t(label),
                }),
              )}
            />
          </Form.Item>

          {QuotaArticleType.includes(typeValue) ? (
            <Form.Item
              name="beddingQuota"
              label={t('articles.beddingQuota')}
              rules={[{required: true}]}
            >
              <InputNumber />
            </Form.Item>
          ) : null}

          <Form.Item
            name="active"
            label={
              <Space>
                {t('articles.active')}{' '}
                {disabledReason ? (
                  <Tooltip title={t(disabledReason)}>
                    <WarningOutlined color="#faad14" />
                  </Tooltip>
                ) : null}
              </Space>
            }
            valuePropName="checked"
            className="force-horizontal"
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

const parseArticleValues = ({
  VAT = 0,
  ...other
}: CommonArticleFormValues): CommonArticleFormValues => ({VAT, ...other});

export const Article: FC<{style?: React.CSSProperties}> = (props) => {
  const api = useApi();
  const {t, lang} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<ArticleRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const [seasons, setSeasons] = useState<SeasonRecord[]>();

  useEffect(() => {
    if (!api) return;

    (async () => {
      const {entities} = await api.get(DEFAULT_API_NAME, '/seasons', {});
      setSeasons(entities);
    })();
  }, [api]);

  const articleColumns: TableColumnsProp<ArticleRecord> = useStateFn(
    () => [
      {
        title: t('articles.code'),
        key: 'code',
        dataIndex: 'code',
        searchInput: true,
        filterName: 'codeSearch',
        sorter: true,
      },
      {
        title: t('articles.label'),
        key: 'label',
        dataIndex: 'label',
        searchInput: true,
        sorter: true,
      },
      {
        title: t('articles.type'),
        key: 'type',
        dataIndex: 'type',
        render: (type: ArticleType) => t(ArticleTypeLabel[type]),
        filters: Object.entries(ArticleTypeLabel).map(([type, label]) => ({
          value: Number(type) as ArticleType,
          text: t(label),
        })),
        // sorter: true,
      },
      {
        title: t('articles.unitPriceInclVAT'),
        key: 'unitPriceInclVAT',
        dataIndex: 'unitPriceInclVAT',
        align: 'right',
        render: (unitPriceInclVAT) =>
          formatCurrency(unitPriceInclVAT ?? 0, lang),
        sorter: true,
      },
      {
        title: t('articles.season'),
        key: 'seasonId',
        dataIndex: 'season',
        render: (_, article) =>
          isArticleRecordSeasonal(article) ? article.season?.code : null,
        filters: seasons
          ? seasons.map(({id, code}) => ({
              text: code,
              value: id,
            }))
          : undefined,
        // sorter: true,
      },
      {
        title: t('articles.active'),
        key: 'active',
        dataIndex: 'active',
        render: (active, article) => {
          let disabledReason = '';
          if (!OwnerVisibleArticleTypes.includes(article.type)) {
            disabledReason = 'article.inactive.ownerInvisible';
          } else if (
            isArticleRecordSeasonal(article) &&
            !article.season?.active
          ) {
            disabledReason = 'article.inactive.season';
          }

          if (disabledReason) {
            return (
              <Tooltip title={t(disabledReason)}>
                <MinusCircleTwoTone twoToneColor="#aaa" />
              </Tooltip>
            );
          }

          return active ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#a30e10" />
          );
        },
        filters: booleanFilters(t),
        sorter: true,
      },
    ],
    [t, lang, seasons],
  );

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: ArticleForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  return (
    <ContentDiv
      title={total ? `${t('articles.title')}: ${total}` : t('articles.title')}
      titleRightComponent={
        <Button
          type="primary"
          onClick={() => setCreateModalOpen(true)}
          icon={<PlusOutlined />}
        />
      }
      style={props.style}
    >
      <TableList<ArticleRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={articleColumns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        onTotalChange={setTotal}
      />
      <TableCreateModal<ArticleRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
        parseValues={parseArticleValues}
      />
      <TableUpdateModal<ArticleRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={parseArticleValues}
      />
    </ContentDiv>
  );
};
