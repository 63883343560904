import {
  Alert,
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {FC, useCallback, useMemo, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import slugify from 'slugify';
import {v4 as uuid} from 'uuid';
import {loadSoGeCheckout} from '../../soge';
import {
  completeSumUpCheckout,
  generateSecureSumUpForm,
  isSecureCheckout,
} from '../../sumup';
import {
  formatCurrency,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {
  DayjsDateRangeToTimestampRange,
  TimestampToDayjsDate,
} from '../../util/date';
import {getBlogBaseUrl} from '../../util/links';
import {CheckInstructions} from '../CheckInstructions';
import {CreditCardForm, CreditCardFormValues} from '../CreditCardForm';
import {ContentDiv} from '../layout/ContentDiv';
import {ApartmentRecord} from '../table/Apartment';
import {
  ArticleRecord,
  ArticleType,
  ArticleTypeRequireNamedCards,
  ArticleTypeRequiresCardFabrication,
  ArticleTypeRequiresValidityInterval,
} from '../table/Article';
import {
  OrderForm,
  OrderFormValues,
  OrderLineFormValues,
  OrderRecord,
  PaymentType,
} from '../table/Order';
import {OrderLineRecord} from '../table/OrderLine';
import {OwnerRecord} from '../table/Owner';
import {SeasonRecord} from '../table/Season';
import {flattenObject, getValidateMessages, isAxiosError} from '../table/Table';
import {WireTransferInstructions} from '../WireTransferInstructions';
import {CardFormValues, OwnerOrderCards} from './OwnerOrderCards';

const singularRoute = '/order';
// const pluralRoute = '/orders';

const backUrl = '/owner/orders';

const parseOrderFormValues = ({
  validityStart: _validityStart,
  validityEnd: _validityEnd,
  ...values
}: OrderFormValues): OrderFormValues => {
  if (
    !ArticleTypeRequiresValidityInterval.includes(values.articleType) ||
    !_validityStart ||
    !_validityEnd
  ) {
    return values;
  }

  const [validityStart, validityEnd] = DayjsDateRangeToTimestampRange(
    _validityStart,
    _validityEnd,
  );

  return {
    validityStart,
    validityEnd,
    ...values,
  };
};

export const getOrderLineSummaryColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): ColumnsType<OrderLineFormValues> => [
  {
    title: t('orderLines.article'),
    key: 'articleId',
    dataIndex: 'articleId',
    render: (_, {article}) => article?.label,
    className: 'align-title-center',
  },
  {
    title: t('orderLines.unitPrice'),
    key: 'unitPriceInclVAT',
    dataIndex: 'unitPriceInclVAT',
    render: (_, {article: {unitPriceInclVAT = 0}}) =>
      formatCurrency(unitPriceInclVAT, lang),
    align: 'right',
    className: 'align-title-center',
  },
  {
    title: t('orderLines.quantity'),
    key: 'quantity',
    dataIndex: 'quantity',
    align: 'right',
    className: 'align-title-center',
  },
  {
    title: t('orderLines.netPrice'),
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    render: (_, {article: {unitPriceInclVAT = 0}, quantity = 1}) =>
      formatCurrency(unitPriceInclVAT * quantity, lang),
    align: 'right',
    className: 'align-title-center',
  },
];

export const getOrderLineSummaryColumnsWithDiscount = (
  t: TranslateFn,
  lang: LANGUAGE,
): ColumnsType<OrderLineFormValues> => [
  {
    title: t('orderLines.article'),
    key: 'articleId',
    dataIndex: 'articleId',
    render: (_, {article}) => article?.label,
  },
  {
    title: t('orderLines.unitPrice'),
    key: 'unitPriceInclVAT',
    dataIndex: 'unitPriceInclVAT',
    render: (_, {article: {unitPriceInclVAT = 0}}) =>
      formatCurrency(unitPriceInclVAT, lang),
    align: 'right',
  },
  {
    title: t('orderLines.quantity'),
    key: 'quantity',
    dataIndex: 'quantity',
    align: 'right',
  },
  {
    title: t('orderLines.totalPrice'),
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    render: (_, {article: {unitPriceInclVAT = 0}, quantity = 1}) =>
      formatCurrency(unitPriceInclVAT * quantity, lang),
    align: 'right',
  },
  {
    title: t('orderLines.discount'),
    key: 'discount',
    dataIndex: 'discount',
    render: (discount = 0) => `${discount * 100}%`,
    align: 'right',
  },
  {
    title: t('orderLines.netPrice'),
    key: 'netPrice',
    dataIndex: 'netPrice',
    render: (
      _,
      {article: {unitPriceInclVAT = 0}, quantity = 1, discount = 0},
    ) => {
      const totalPrice = unitPriceInclVAT * quantity;
      return formatCurrency(totalPrice - totalPrice * discount, lang);
    },
    align: 'right',
  },
];

export interface OrderConfirmFormValues {
  terms: boolean;
}

export const OwnerOrder: FC = () => {
  const api = useApi();
  const {id: orderId} = useParams();
  const {t, lang} = useTranslation();
  const navigate = useNavigate();

  const [order, setOrder] = useState<OrderRecord>();
  const [orderFetchError, setOrderFetchError] = useState('');
  const [orderLoading, setOrderLoading] = useState(!!order);

  const [orderSubmitError, setOrderSubmitError] = useState('');

  const [orderForm] = Form.useForm<OrderFormValues>();
  const [orderConfirmForm] = Form.useForm<OrderConfirmFormValues>();
  const [creditCardForm] = Form.useForm<CreditCardFormValues>();

  const termsValue = Form.useWatch('terms', orderConfirmForm);

  const [orderFormValues, setOrderFormValues] = useState<
    OrderFormValues | undefined
  >();
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);

  const [modalSummaryOpen, setModalSummaryOpen] = useState(false);

  const [season, setSeason] = useState<SeasonRecord>();
  const [apartment, setApartment] = useState<ApartmentRecord>();
  const [article, setArticle] = useState<ArticleRecord>();
  const [additionalArticle, setAdditionalArticle] = useState<ArticleRecord>();
  const [articleType, setArticleType] = useState<ArticleType>();
  const [orderLines, setOrderLines] = useState<OrderLineFormValues[]>();
  const [cards, setCards] = useState<CardFormValues[]>();
  const [isRenewal, setIsRenewal] = useState<boolean>();
  const [, setPaymentType] = useState<PaymentType>();

  const [secureCheckoutOrder, setSecureCheckoutOrder] = useState<OrderRecord>();
  const [isSecureCheckoutPending, setIsSecureCheckoutPending] = useState(false);

  const [modalSoGeOpen, setModalSoGeOpen] = useState(false);
  const sogeDiv = useRef<HTMLDivElement>(null);
  const [sogeDivId, setSogeDivId] = useState(uuid());

  const confirmPayment = async (order = secureCheckoutOrder) => {
    console.log({order, isSecureCheckoutPending});

    if (!api || !order) return;
    const res = await api.post(
      DEFAULT_API_NAME,
      `${singularRoute}/${order.id}/confirmPayment`,
      {},
    );

    console.log(res);

    // cancelConfirmPaymentPolling();
    setIsSecureCheckoutPending(false);

    await api.post(
      DEFAULT_API_NAME,
      `${singularRoute}/${order.id}/generateInvoicePDF`,
      {},
    );

    setSogeDivId(uuid());
    message.success(t('order.submitSuccess'));
    navigate('/owner/orders');
  };

  /* const {run: runConfirmPaymentPolling, cancel: cancelConfirmPaymentPolling} =
    useRequest(confirmPayment, {
      refreshDeps: [api, order, isSecureCheckoutPending],
      pollingInterval: 3000,
      pollingWhenHidden: false,
      manual: true,
      // debounceLeading: true,
      // debounceWait: 3000,
    }); */

  const netPrice = useMemo(
    () =>
      (orderLines ?? []).reduce(
        (
          sum,
          {article: {unitPriceInclVAT = 0}, quantity = 1, discount = 0},
        ) => {
          const totalPrice = quantity * unitPriceInclVAT;
          return sum + (totalPrice - totalPrice * discount);
        },
        0,
      ),
    [orderLines],
  );

  const initializeSoGeCheckout = useCallback(
    async (order: OrderRecord) => {
      console.log(!api, !articleType, !sogeDiv.current);
      if (!api || !articleType || !sogeDiv.current) return;

      setOrderSubmitError('');
      try {
        const checkout = await api.post(
          DEFAULT_API_NAME,
          `${singularRoute}/${order.id}/createCheckout`,
          {},
        );

        console.log(checkout);

        await loadSoGeCheckout({
          articleType,
          form: sogeDiv.current,
          formToken: checkout.formToken,
          onSubmit: (paymentData) => {
            console.log({paymentData});
            // setModalSoGeOpen(false);

            setSogeDivId(uuid());
            message.success(t('order.submitSuccess'));
            navigate('/owner/orders');
          },
        });
      } catch (err) {
        let message = t('general.uploadError');
        if (isAxiosError(err) && err.response?.data) {
          message += `:\n${err.response?.data}`;
        }
        setOrderSubmitError(message);
      }
    },
    [api, articleType, navigate, t],
  );

  const fetchOrder = useCallback(async () => {
    if (!api || !orderId || orderFetchError || orderLoading) return;

    setOrder(undefined);
    setOrderLoading(true);

    try {
      const order = (await api.get(
        DEFAULT_API_NAME,
        [singularRoute, orderId].join('/'),
        {},
      )) as OrderRecord;

      setOrder(flattenObject(order));
      return order;
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOrderFetchError(message);
    } finally {
      setOrderLoading(false);
    }
  }, [api, orderFetchError, orderId, orderLoading, t]);

  useMemo(() => {
    if (!api || !orderId || order?.id === orderId || orderLoading) return;

    (async () => fetchOrder())();
  }, [api, orderId, order?.id, orderLoading, fetchOrder]);

  const handleOrderSubmit = async () => {
    await orderForm.validateFields();

    const formValues = parseOrderFormValues(orderForm.getFieldsValue());
    setOrderFormValues(formValues);

    orderConfirmForm.resetFields();
    setModalSummaryOpen(true);
  };

  const handleOrderConfirm = async () => {
    console.log({api, orderFormValues, orderLines});
    if (!api || !orderFormValues || !orderLines?.length || !articleType) return;

    if (orderFormValues.paymentType === PaymentType.SUMUP)
      await creditCardForm.validateFields();

    setUpdateOrderLoading(true);
    setOrderSubmitError('');

    const {proxyDocuments = [], ...orderValues} = orderFormValues;
    const [proxyFile] = proxyDocuments;

    try {
      const owner: OwnerRecord = await api.get(
        DEFAULT_API_NAME,
        `/owner/me`,
        {},
      );

      const proxyFileName = proxyFile?.name
        ? slugify(proxyFile.name)
        : undefined;

      const {order, proxyFileSignedUrl} = (await api.put(
        DEFAULT_API_NAME,
        `${singularRoute}/me`,
        {
          body: {
            ...orderValues,
            ...(proxyFile
              ? {
                  proxyFile: {
                    name: proxyFileName,
                    type: proxyFile.type,
                  },
                }
              : {}),
          },
        },
      )) as {order: OrderRecord; proxyFileSignedUrl?: string};

      if (proxyFile && proxyFileSignedUrl) {
        const headers: HeadersInit = {};
        if (proxyFile.type) {
          headers['Content-Type'] = proxyFile.type;
        }
        if (proxyFileName) {
          headers[
            'Content-Disposition'
          ] = `attachment; filename="${proxyFileName}"`;
        }

        await fetch(proxyFileSignedUrl, {
          method: 'PUT',
          body: proxyFile.originFileObj,
          headers,
        });
      }

      for (const orderLine of orderLines) {
        const {article, discount = 0, quantity = 1} = orderLine;
        const totalPrice = (article.unitPriceInclVAT ?? 0) * quantity;
        const orderLineRecord = (await api.put(
          DEFAULT_API_NAME,
          `/orderLine/me`,
          {
            body: {
              orderId: order.id,
              articleId: article.id,
              quantity,

              // Will be verified by the server
              discount,
              unitPrice: article.unitPriceInclVAT,
              VAT: article.VAT ?? 0,
              totalPrice,
              netPrice: totalPrice - totalPrice * discount,
            },
          },
        )) as OrderLineRecord;
        orderLine.id = orderLineRecord.id;
      }

      for (const {id, article, cards} of orderLines) {
        if (!id || !ArticleTypeRequiresCardFabrication.includes(article.type))
          continue;
        if (!cards) {
          await api.put(DEFAULT_API_NAME, `/card/me`, {
            body: {
              lastName: owner.user.lastName,
              firstName: owner.user.firstName,
              startDate: order.validityStart,

              orderId: order.id,
              orderNumber: order.orderNumber,
              apartmentId: orderValues.apartmentId,
              orderLineId: id,
              articleType: article.type,
            },
          });

          if (articleType === ArticleType.PARKING) break;
          continue;
        }

        for (const {
          article,
          firstName,
          lastName,
          isChild,
          photos = [],
        } of cards) {
          let photoFile: {name: string; type?: string} | undefined = undefined;
          const [photo] = photos;
          if (photo) {
            photoFile = {
              name: slugify(photo.name),
              type: photo.type,
            };
          }

          const {photoFileSignedUrl} = await api.put(
            DEFAULT_API_NAME,
            `/card/me`,
            {
              body: {
                firstName,
                lastName,
                startDate: order.validityStart,
                isChild,
                photoFile,

                orderId: order.id,
                orderNumber: order.orderNumber,
                apartmentId: orderValues.apartmentId,
                orderLineId: id,
                articleType: article.type,
              },
            },
          );

          if (photoFile && photoFileSignedUrl) {
            const headers: HeadersInit = {};
            if (photoFile.type) {
              headers['Content-Type'] = photoFile.type;
            }
            if (photoFile.name) {
              headers[
                'Content-Disposition'
              ] = `attachment; filename="${photoFile.name}"`;
            }

            await fetch(photoFileSignedUrl, {
              method: 'PUT',
              body: photo.originFileObj,
              headers,
            });
          }
        }
      }

      if (!netPrice) {
        await api.post(
          DEFAULT_API_NAME,
          `${singularRoute}/${order.id}/confirmFreeOrder`,
          {},
        );

        await api.post(
          DEFAULT_API_NAME,
          `${singularRoute}/${order.id}/generateInvoicePDF`,
          {},
        );
      } else if (order.paymentType === PaymentType.SUMUP) {
        const checkout = await api.post(
          DEFAULT_API_NAME,
          `${singularRoute}/${order.id}/createCheckout`,
          {},
        );

        console.log(checkout);

        const complete = await completeSumUpCheckout(
          checkout.id,
          creditCardForm.getFieldsValue(),
        );
        console.log(complete);

        if (!complete) {
          message.error('Checkout error');
          return;
        }

        if (isSecureCheckout(complete)) {
          const {form, iframe} = generateSecureSumUpForm(complete);
          if (iframe) {
            document.body.appendChild(iframe);
          }

          document.body.appendChild(form);
          form.submit();

          setIsSecureCheckoutPending(true);
          setSecureCheckoutOrder(order);
          // runConfirmPaymentPolling();
          return;
        }

        if (!isSecureCheckout(complete) && complete?.status === 'PAID') {
          await confirmPayment(order);
        }
      } else if (order.paymentType === PaymentType.SOGE) {
        setModalSummaryOpen(false);
        setModalSoGeOpen(true);

        initializeSoGeCheckout(order);

        // prevents redirect
        return;
      }

      setSogeDivId(uuid());
      message.success(t('order.submitSuccess'));
      navigate('/owner/orders');
    } catch (err) {
      console.error(err);
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOrderSubmitError(message);
      return;
    } finally {
      setUpdateOrderLoading(false);
    }
  };

  if (orderLoading) {
    return <Spin size="large" />;
  }

  if (orderFetchError) {
    return (
      <div>
        <Row>
          <Alert message={orderFetchError} />
        </Row>
        <Row>
          <Link to={backUrl}>
            <Button>{t('general.cancel')}</Button>
          </Link>
        </Row>
      </div>
    );
  }

  return (
    <ContentDiv>
      <AppTitle level={3} style={{marginTop: 0}}>
        {t('orders.title')}
      </AppTitle>
      <Row gutter={20} justify="space-between">
        <Col span={12}>
          <OrderForm
            form={orderForm}
            initialValues={order}
            orderLines={orderLines}
            onFinish={handleOrderSubmit}
            onSeasonChange={setSeason}
            onApartmentChange={setApartment}
            onArticleChange={setArticle}
            onAdditionalArticleChange={setAdditionalArticle}
            onArticleTypeChange={setArticleType}
            onOrderLinesChange={setOrderLines}
            onIsRenewalChange={setIsRenewal}
            onPaymentTypeChange={setPaymentType}
          />
        </Col>
        <Col span={12}>
          {articleType &&
          ArticleTypeRequireNamedCards.includes(articleType) &&
          season ? (
            <OwnerOrderCards
              season={season}
              apartment={apartment}
              article={article}
              isRenewal={isRenewal}
              additionalArticle={additionalArticle}
              onOrderLinesChange={setOrderLines}
              onCardsChange={setCards}
            />
          ) : null}
        </Col>
      </Row>
      <Row gutter={20} justify="space-between">
        <Col span={12}>
          <Row gutter={20} justify="space-between">
            <Col>
              <Space>
                <Link to={backUrl}>
                  <Button>{t('general.cancel')}</Button>
                </Link>
                <Button
                  type="primary"
                  onClick={handleOrderSubmit}
                  loading={updateOrderLoading}
                >
                  {t('general.submit')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        centered
        forceRender
        title={t('order.modal.title')}
        open={modalSummaryOpen}
        onOk={handleOrderConfirm}
        onCancel={() => setModalSummaryOpen(false)}
        okButtonProps={{loading: updateOrderLoading, disabled: !termsValue}}
        okText={netPrice ? t('order.modal.confirm') : t('confirm.submit')}
        width={600}
      >
        <AppTitle level={3}>{t('order.modal.summary')}</AppTitle>
        {orderFormValues?.paymentType === PaymentType.WIRE_TRANSFER ? (
          <WireTransferInstructions articleType={articleType} />
        ) : null}
        {orderFormValues?.paymentType === PaymentType.CHECK ? (
          <CheckInstructions />
        ) : null}
        {orderFormValues?.paymentType === PaymentType.SUMUP ? (
          <CreditCardForm form={creditCardForm} />
        ) : null}
        {orderFormValues?.validityStart && orderFormValues?.validityEnd ? (
          <>
            <AppTitle level={3}>{t('articles.validityDuration')}</AppTitle>
            <Descriptions bordered layout="vertical" size="small">
              <Descriptions.Item
                label={<Row justify="center">{t('orders.validityStart')}</Row>}
              >
                {TimestampToDayjsDate(orderFormValues?.validityStart).format(
                  'L',
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={<Row justify="center">{t('orders.validityEnd')}</Row>}
              >
                {TimestampToDayjsDate(
                  orderFormValues?.effectiveValidityEnd ??
                    orderFormValues?.validityEnd,
                ).format('L')}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : null}
        <Form<OrderConfirmFormValues>
          form={orderConfirmForm}
          layout="inline"
          onSubmitCapture={handleOrderConfirm}
          onFinish={handleOrderConfirm}
          validateMessages={getValidateMessages(t)}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <AppTitle level={4}>{t('orderLines.title')}</AppTitle>
            </Col>
            <Col span={24}>
              <Table<OrderLineFormValues>
                className="centered-thead"
                size="small"
                bordered
                dataSource={orderLines ?? []}
                columns={(articleType === ArticleType.POOL_GOLD &&
                  cards?.find(({discount}) => !!discount)
                  ? getOrderLineSummaryColumnsWithDiscount
                  : getOrderLineSummaryColumns)(t, lang)}
                pagination={false}
              />
            </Col>
            <Col span={24}>
              <p style={{float: 'right'}}>
                {t('orders.netPrice')} : {formatCurrency(netPrice, lang)}
              </p>
            </Col>
          </Row>
          <Form.Item
            name="terms"
            label={
              <b>
                {t('orders.confirm.terms.prefix')}
                <a
                  href={
                    getBlogBaseUrl() +
                    '/documents-legaux/conditions-generales-de-vente/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('orders.confirm.terms.link')}
                </a>
                {t('orders.confirm.terms.suffix')}
              </b>
            }
            rules={[{required: true}]}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
        {orderSubmitError ? (
          <Alert
            message={orderSubmitError}
            type="error"
            style={{whiteSpace: 'pre-line'}}
          />
        ) : null}
      </Modal>

      <Modal
        centered
        forceRender
        title={t('order.modal.title')}
        open={modalSoGeOpen}
        closable={false}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
        width={350}
      >
        <div ref={sogeDiv} id={sogeDivId}>
          <div
            className="kr-smart-form"
            kr-card-form-expanded="kr-card-form-expanded"
          />
        </div>

        {orderSubmitError ? (
          <Alert
            message={orderSubmitError}
            type="error"
            style={{whiteSpace: 'pre-line'}}
          />
        ) : null}
      </Modal>

      <Modal
        centered
        forceRender
        title={t('checkoutResult.pending')}
        open={isSecureCheckoutPending}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        closable={false}
        width={600}
      >
        <Spin style={{width: '100%'}} size="large" />
      </Modal>
    </ContentDiv>
  );
};
