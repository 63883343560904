import {FileOutlined} from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import {Dayjs} from 'dayjs';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import {FC, useMemo, useState} from 'react';
import {
  formatCurrency,
  getDateFormat,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {TimestampToDayjsDate} from '../../util/date';
import {ContentDiv} from '../layout/ContentDiv';
import {useStateFn} from '../useStateFn';
import {
  openInvoiceFileInNewTab,
  OrderAccountingStatus,
  OrderAccountingStatusLabel,
  OrderRecord,
  OrderType,
  OrderTypeLabel,
} from './Order';
import {
  getDefaultSorter,
  TableColumnsProp,
  TableCreateModal,
  TableFormProps,
  TableList,
  TableUpdateModal,
} from './Table';
import {useGetOwner} from './util';

const singularRoute = '/invoice';
const pluralRoute = '/invoices';

export interface InvoiceFormValues {
  invoicedName: string;
  invoiceNumber: number;
  amount: number;
  status: OrderAccountingStatus;
  type: OrderType;
  orderId?: string;
  recipientName?: string;
}

export interface InvoiceRecord extends InvoiceFormValues {
  id: string;
  order?: OrderRecord;
  // TODO
  // invoice?: InvoiceRecord;
  createDate: number | Dayjs;
  updateDate: number;
}

const invoiceInitialValues: Partial<InvoiceFormValues> = {};

const MaxWidthDatePicker = styled(DatePicker)`
  width: 100%;
`;

const MaxWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const InvoiceForm: FC<TableFormProps<InvoiceRecord>> = ({
  form,
  onFinish,
  initialValues = {},
}) => {
  const {t, lang} = useTranslation();

  const ownerId = useMemo(() => initialValues?.order?.ownerId, [initialValues]);
  const ownerData = useGetOwner(ownerId);
  const shouldDisplayRecipientFields =
    ownerData?.data?.displayRecipientFields ?? false;

  useMemo(() => {
    const {createDate, ...other} = {
      ...invoiceInitialValues,
      ...initialValues,
    } as InvoiceRecord;

    form.setFieldsValue({
      ...other,
      createDate: createDate ? TimestampToDayjsDate(createDate) : undefined,
    });
  }, [form, initialValues]);

  return (
    <Form<InvoiceFormValues>
      form={form}
      layout="vertical"
      onSubmitCapture={onFinish}
      onFinish={onFinish}
      initialValues={{...invoiceInitialValues, ...initialValues}}
      autoComplete="off"
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="createDate"
            label={t('invoices.createDate')}
            rules={[{required: true}]}
          >
            <MaxWidthDatePicker format={getDateFormat(lang)} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="invoiceNumber"
            label={t('invoices.invoiceNumber')}
            rules={[{required: true}]}
          >
            <MaxWidthInputNumber disabled />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="invoicedName"
        label={t('invoices.invoicedName')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="recipientName"
        label={t('orders.recipientName')}
        rules={[{required: shouldDisplayRecipientFields}]}
        hidden={!shouldDisplayRecipientFields}
      >
        <Input />
      </Form.Item>
      <Form.Item name="amount" label={t('invoices.amount')}>
        <InputNumber addonAfter="€" />
      </Form.Item>
      <Form.Item
        name="status"
        label={t('invoices.status')}
        rules={[{required: true}]}
      >
        <Select
          options={Object.entries(OrderAccountingStatusLabel).map(
            ([status, label]) => ({
              value: Number(status),
              label: t(label),
            }),
          )}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="type"
        label={t('invoices.type')}
        rules={[{required: true}]}
      >
        <Select
          disabled
          options={Object.entries(OrderTypeLabel).map(([type, label]) => ({
            value: Number(type),
            label: t(label),
          }))}
          allowClear
        />
      </Form.Item>

      <Form.Item name="orderId" hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item hidden={true}>
        <Button htmlType="submit">{t('general.submit')}</Button>
      </Form.Item>
    </Form>
  );
};

export const getInvoiceColumns = (
  t: TranslateFn,
  lang: LANGUAGE,
): TableColumnsProp<InvoiceRecord> => [
  {
    title: t('invoices.createDate'),
    key: 'createDate',
    dataIndex: 'createDate',
    render: (createDate) => TimestampToDayjsDate(createDate).format('L'),
    sorter: true,
  },
  {
    title: t('invoices.invoicedName'),
    key: 'invoicedName',
    dataIndex: 'invoicedName',
    sorter: true,
    searchInput: true,
  },
  {
    title: t('orders.recipient'),
    key: 'recipientName',
    dataIndex: 'recipientName',
    sorter: true,
    searchInput: true,
  },
  {
    title: t('invoices.amount'),
    key: 'amount',
    dataIndex: 'amount',
    align: 'right',
    render: (amount) => formatCurrency(amount, lang),
    sorter: true,
    searchInput: true,
  },
  {
    title: t('invoices.invoiceNumber'),
    key: 'invoiceNumber',
    dataIndex: 'invoiceNumber',
    align: 'right',
    sorter: true,
    searchInput: true,
  },
  {
    title: t('invoices.status'),
    key: 'status',
    dataIndex: 'status',
    render: (status: OrderAccountingStatus) =>
      t(OrderAccountingStatusLabel[status]),
    sorter: true,
    filters: Object.entries(OrderAccountingStatusLabel).map(
      ([type, label]) => ({
        text: t(label),
        value: Number(type),
      }),
    ),
  },
];

const parseInvoiceValues = (form: InvoiceRecord): InvoiceFormValues => ({
  ...form,
});

export const Invoices: FC = () => {
  const api = useApi();
  const {t, lang} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editEntity, setEditEntity] = useState<InvoiceRecord | undefined>();
  const [refreshDate, setRefreshDate] = useState<number>();
  const [total, setTotal] = useState<number>();

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const handleInvoiceFileClick = async (invoice: InvoiceRecord) => {
    if (!api || !invoice.orderId) return;
    setInvoiceLoading(true);
    try {
      openInvoiceFileInNewTab(api, invoice.orderId);
    } finally {
      setInvoiceLoading(false);
    }
  };

  const defaultModalProps = {
    singularRoute,
    pluralRoute,
    FormComponent: InvoiceForm,
    closable: false,
    maskClosable: false,
    cancelText: t('general.cancel'),
  };

  const columns = useStateFn(() => getInvoiceColumns(t, lang), [t, lang]);
  const defaultSorter = useStateFn(
    () => getDefaultSorter(columns, 'createDate'),
    [columns],
  );

  const [exportLoading, setExportLoading] = useState(false);
  const handleExportClick = async () => {
    if (!api) return;
    setExportLoading(true);
    try {
      const {csv} = (await api.get(
        DEFAULT_API_NAME,
        `${pluralRoute}/export`,
        {},
      )) as {csv: string};

      const fileName = `export-${new Date().toISOString().replace(/:/g, '-')}`;

      const zip = new JSZip();
      zip.file(`${fileName}.csv`, csv);

      const content = await zip.generateAsync({type: 'blob'});
      saveAs(content, `${fileName}.zip`);
    } finally {
      setExportLoading(false);
    }
  };

  const showExport = true;

  return (
    <ContentDiv>
      <Row justify="space-between" align="middle">
        <Col>
          <AppTitle level={3} style={{marginTop: 0}}>
            {total ? `${t('invoices.title')}: ${total}` : t('invoices.title')}
          </AppTitle>
        </Col>
        <Col style={{margin: 'auto 0'}}>
          <Space>
            <Button
              loading={exportLoading}
              onClick={handleExportClick}
              type="primary"
              style={{display: showExport ? '' : 'none'}}
            >
              {t('general.export')}
            </Button>
          </Space>
        </Col>
      </Row>

      <TableList<InvoiceRecord>
        singularRoute={singularRoute}
        pluralRoute={pluralRoute}
        columns={columns}
        refreshDate={refreshDate}
        setEditEntity={setEditEntity}
        defaultSorter={defaultSorter}
        actionButtonProps={{
          delete: (invoice) =>
            invoice.status !== OrderAccountingStatus.SUBMITTED
              ? {disabled: true}
              : {},
        }}
        customActions={[
          (invoice) => (
            <Button
              key="invoice"
              icon={<FileOutlined />}
              type="default"
              title={t('orders.type.invoice')}
              disabled={invoice.status === OrderAccountingStatus.SUBMITTED}
              loading={invoiceLoading}
              onClick={() => handleInvoiceFileClick(invoice)}
            />
          ),
        ]}
        onTotalChange={setTotal}
      />
      <TableCreateModal<InvoiceRecord>
        {...defaultModalProps}
        open={createModalOpen}
        title={t('general.addTitle')}
        okText={t('general.add')}
        onCancel={() => setCreateModalOpen(false)}
        onOk={() => {
          setCreateModalOpen(false);
          setRefreshDate(Date.now());
        }}
        parseValues={parseInvoiceValues}
      />
      <TableUpdateModal<InvoiceRecord>
        {...defaultModalProps}
        entity={editEntity}
        open={!!editEntity}
        title={t('general.updateTitle')}
        okText={t('general.update')}
        onCancel={() => setEditEntity(undefined)}
        width={700}
        onOk={() => {
          setEditEntity(undefined);
          setRefreshDate(Date.now());
        }}
        parseValues={parseInvoiceValues}
      />
    </ContentDiv>
  );
};
