import {FC} from 'react';

export const AppLayout: FC<{children?: React.ReactNode}> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className="app-layout"
    >
      {props.children}
    </div>
  );
};
