import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {useRequest} from 'ahooks';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import {FC, useCallback, useMemo, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {TranslateFn, useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {
  AssignApartmentForm,
  AssignApartmentFormValues,
} from '../AssignApartmentForm';
import {ContentDiv} from '../layout/ContentDiv';
import {
  ApartmentRecord,
  ApartmentType,
  ApartmentTypeLabel,
  parseApartmentFormValues,
} from '../table/Apartment';
import {OwnerForm, OwnerFormValues, OwnerRecord} from '../table/Owner';
import {flattenObject, isAxiosError, TableColumnsProp} from '../table/Table';
import {OwnerVehicles} from './OwnerVehicles';

const singularRoute = '/owner';
// const pluralRoute = '/owners';

const routes = {
  apartment: {
    singular: '/apartment',
    plural: '/apartments',
  },
  user: {
    singular: '/user',
    plural: '/users',
  },
};

export const getApartmentColumns = (
  t: TranslateFn,
): TableColumnsProp<ApartmentRecord> => [
  {
    title: t('apartments.host'),
    key: 'hostId',
    dataIndex: 'hostId',
    render: (hostId, {host}) => host?.name ?? hostId,
  },
  {
    title: t('apartments.condo'),
    key: 'condo',
    dataIndex: 'condo',
  },
  {
    title: t('apartments.building'),
    key: 'building',
    dataIndex: 'building',
  },
  {
    title: t('apartments.code'),
    key: 'code',
    dataIndex: 'code',
  },
  {
    title: t('apartments.type'),
    key: 'type',
    dataIndex: 'type',
    render: (type: ApartmentType) => ApartmentTypeLabel[type],
  },
  {
    title: t('apartments.doorNumber'),
    key: 'doorNumber',
    dataIndex: 'doorNumber',
  },
  {
    title: t('apartments.beddings'),
    key: 'beddings',
    dataIndex: 'beddings',
  },
];

interface UpdateEmailFormValues {
  previousEmail: string;
  email: string;
}

export const parseOwnerFormValues = (form: OwnerFormValues) => ({
  name: `${form['user.lastName']} ${form['user.firstName']}`,
  address: form.address,
  zipCode: form.zipCode,
  city: form.city,
  country: form.country,
  phoneNumber: form.phoneNumber,
  userId: form.userId,
  displayRecipientFields: form.displayRecipientFields,
});

export const Owner: FC = () => {
  const api = useApi();
  const {id: ownerId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [owner, setOwner] = useState<OwnerRecord>();
  const [ownerError, setOwnerError] = useState('');
  const [ownerLoading, setOwnerLoading] = useState(!!owner);

  const [ownerForm] = Form.useForm<OwnerFormValues>();

  const [updateOwnerLoading, setUpdateOwnerLoading] = useState(false);
  const [deleteOwnerLoading, setDeleteOwnerLoading] = useState(false);

  const [apartments, setAppartments] = useState<ApartmentRecord[]>();
  const [apartmentsLoading, setAppartmentsLoading] = useState(!!apartments);

  const [assignApartmentForm] = Form.useForm<AssignApartmentFormValues>();
  const [assignApartment, setAssignApartment] = useState<ApartmentRecord>();
  const [showAssignApartmentModal, setShowAssignApartmentModal] =
    useState(false);
  const [assignApartmentLoading, setAssignApartmentLoading] = useState(false);
  const [assignError, setAssignError] = useState('');

  const [unassignApartmentLoading, setUnassignApartmentLoading] =
    useState(false);

  const [updateEmailForm] = Form.useForm<UpdateEmailFormValues>();
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const [updateEmailLoading, setUpdateEmailLoading] = useState(false);
  const [updateEmailError, setUpdateEmailError] = useState('');

  const fetchOwner = useCallback(async () => {
    if (!api || !ownerId || ownerError || ownerLoading) return;

    setOwner(undefined);
    setOwnerLoading(true);
    setAssignApartment(undefined);
    setShowAssignApartmentModal(false);
    assignApartmentForm.resetFields();

    try {
      const owner = (await api.get(
        DEFAULT_API_NAME,
        [singularRoute, ownerId].join('/'),
        {},
      )) as OwnerRecord;

      setOwner(flattenObject(owner));
      return owner;
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOwnerError(message);
    } finally {
      setOwnerLoading(false);
    }
  }, [api, assignApartmentForm, ownerError, ownerId, ownerLoading, t]);

  const fetchAppartments = useCallback(async () => {
    if (!api || !ownerId) return;

    setAppartmentsLoading(true);
    setAppartments(undefined);

    try {
      const apartments = ((
        await api.get(DEFAULT_API_NAME, [routes.apartment.plural].join('/'), {
          queryStringParameters: {
            limit: 50,
            f_ownerId: ownerId,
          },
        })
      )?.entities ?? []) as ApartmentRecord[];

      setAppartments(apartments.map(flattenObject));
      return apartments;
    } finally {
      setAppartmentsLoading(false);
    }
  }, [api, ownerId]);

  const fetchCognitoStatus = async (): Promise<string | undefined> => {
    if (!api || !owner) return;
    return await api.get(
      DEFAULT_API_NAME,
      `/user/cognitoStatus/${owner.userId}`,
      {},
    );
  };
  const {data: cognitoStatus, loading: cognitoStatusLoading} = useRequest(
    fetchCognitoStatus,
    {
      refreshDeps: [api, owner],
    },
  );

  const fetchResetPasswordAndSendEmail = async (): Promise<
    string | undefined
  > => {
    if (!api || !owner) return;
    return await api.post(
      DEFAULT_API_NAME,
      `/user/resetPasswordAndSendEmail/${owner.userId}`,
      {},
    );
  };
  const {loading: userResetLoading, run: handleUserReset} = useRequest(
    fetchResetPasswordAndSendEmail,
    {
      refreshDeps: [api, owner],
      manual: true,
    },
  );

  useMemo(() => {
    if (!api || !ownerId || owner?.id === ownerId || ownerLoading) return;

    (async () => {
      const owner = await fetchOwner();
      if (!owner) return;
      await fetchAppartments();
    })();
  }, [api, ownerId, owner?.id, ownerLoading, fetchOwner, fetchAppartments]);

  const handleOwnerSubmit = async () => {
    if (!api || !owner) return;
    await ownerForm.validateFields();
    const formValues = ownerForm.getFieldsValue();
    const values = parseOwnerFormValues(formValues);

    setUpdateOwnerLoading(true);
    try {
      await api.post(DEFAULT_API_NAME, `${singularRoute}/${owner.id}`, {
        body: values,
      });

      if (
        owner.user.firstName !== formValues['user.firstName'] ||
        owner.user.lastName !== formValues['user.lastName'] ||
        owner.user.title !== formValues['user.title'] ||
        owner.user.disabled !== formValues['user.disabled']
      ) {
        await api.post(
          DEFAULT_API_NAME,
          `${routes.user.singular}/${formValues.userId}`,
          {
            body: {
              ...owner.user,
              firstName: formValues['user.firstName'],
              lastName: formValues['user.lastName'],
              title: formValues['user.title'],
              disabled: formValues['user.disabled'],
            },
          },
        );
      }
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOwnerError(message);
      return;
    } finally {
      setUpdateOwnerLoading(false);
    }

    return fetchOwner();
  };

  const handleOwnerDelete = async () => {
    if (!api || !owner) return;

    setDeleteOwnerLoading(true);
    try {
      await api.del(DEFAULT_API_NAME, `${singularRoute}/${owner.id}`, {});
      await api.del(
        DEFAULT_API_NAME,
        `${routes.user.singular}/${owner.userId}`,
        {},
      );
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setOwnerError(message);
      return;
    } finally {
      setDeleteOwnerLoading(false);
    }

    navigate('/admin/owners');
  };

  const handleAssignApartmentModalClose = () => {
    assignApartmentForm.resetFields();
    setAssignApartment(undefined);
    setAssignError('');
    setShowAssignApartmentModal(false);
  };

  const handleAssignApartment = async () => {
    if (!api || !owner || !assignApartment || assignApartment.ownerId) return;

    await assignApartmentForm.validateFields();

    const assignApartmentFormValues = assignApartmentForm.getFieldsValue();

    setAssignApartmentLoading(true);
    try {
      await api.post(
        DEFAULT_API_NAME,
        `${routes.apartment.singular}/${assignApartment.id}`,
        {
          body: {
            ...parseApartmentFormValues(assignApartment),
            ownerId: ownerId,
            hostId: assignApartmentFormValues.hostId,
          },
        },
      );

      handleAssignApartmentModalClose();
      await fetchAppartments();
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setAssignError(message);
    } finally {
      setAssignApartmentLoading(false);
    }
  };

  const handleUnassignApartment = async (apartment: ApartmentRecord) => {
    if (
      !api ||
      !owner ||
      !apartment?.ownerId ||
      apartment?.ownerId !== owner.id
    )
      return;

    setUnassignApartmentLoading(true);
    try {
      await api.post(
        DEFAULT_API_NAME,
        `${routes.apartment.singular}/${apartment.id}`,
        {
          body: {
            ...parseApartmentFormValues(apartment),
            ownerId: undefined,
          },
        },
      );

      await fetchAppartments();
    } finally {
      setUnassignApartmentLoading(false);
    }
  };

  const handleUpdateEmail = async () => {
    if (!api || !owner) return;
    updateEmailForm.validateFields();

    const {email} = updateEmailForm.getFieldsValue();

    setUpdateEmailError('');
    try {
      setUpdateEmailLoading(true);

      await api.post(DEFAULT_API_NAME, `/user/updateEmail/${owner.user.id}`, {
        body: {email},
      });

      await fetchOwner();
      handleUpdateEmailModalClose();
      updateEmailForm.setFieldValue('previousEmail', email);
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setUpdateEmailError(message);
    } finally {
      setUpdateEmailLoading(false);
    }
  };

  const handleUpdateEmailModalClose = () => {
    updateEmailForm.resetFields();
    setUpdateEmailError('');
    setShowUpdateEmailModal(false);
  };

  if (!ownerId) {
    return <div>Missing id parameter in URL</div>;
  }

  if (ownerLoading) {
    return <Spin size="large" />;
  }

  if (ownerError) {
    return (
      <div>
        <Row>
          <Alert message={ownerError} />
        </Row>
        <Row>
          <Link to="/admin/owners">
            <Button>{t('general.cancel')}</Button>
          </Link>
        </Row>
      </div>
    );
  }

  return (
    <ContentDiv>
      <Row gutter={20}>
        <Col span={10}>
          <AppTitle level={3} style={{marginTop: 0}}>
            {t('owners.title')}
          </AppTitle>
          <OwnerForm
            form={ownerForm}
            initialValues={owner}
            onFinish={handleOwnerSubmit}
            onEmailChangeClick={() => setShowUpdateEmailModal(true)}
          />
          <Row gutter={20} justify="space-between">
            <Col>
              <p>
                <Space>
                  <a
                    href="https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UserType.html#CognitoUserPools-Type-UserType-UserStatus"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <QuestionCircleOutlined />
                  </a>
                  <span>
                    {t('users.cognitoStatus')}:{' '}
                    {cognitoStatusLoading ? (
                      <Spin />
                    ) : (
                      <code>{cognitoStatus}</code>
                    )}
                  </span>
                </Space>
              </p>
            </Col>
          </Row>
          <Row style={{margin: '20px 0'}}>
            <Popconfirm
              title={t('users.resetPasswordAndSendEmail')}
              okText={t('general.yes')}
              cancelText={t('general.no')}
              onConfirm={handleUserReset}
            >
              <Button type="primary" danger={true} loading={userResetLoading}>
                {t('users.resetPasswordAndSendEmail')}
              </Button>
            </Popconfirm>
          </Row>
          <Row gutter={20} justify="space-between">
            <Col>
              <Tooltip
                title={
                  !apartments || apartments?.length > 0
                    ? t('owner.error.cannotDeleteIfApartments')
                    : ''
                }
              >
                <Popconfirm
                  title={t('general.delete')}
                  okText={t('general.yes')}
                  cancelText={t('general.no')}
                  onConfirm={handleOwnerDelete}
                  disabled={!apartments || apartments?.length > 0}
                >
                  <Button
                    type="primary"
                    danger={true}
                    loading={deleteOwnerLoading}
                    disabled={!apartments || apartments?.length > 0}
                  >
                    {t('general.delete')}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Col>
            <Col>
              <Space>
                <Link to="/admin/owners">
                  <Button>{t('general.cancel')}</Button>
                </Link>
                <Button
                  type="primary"
                  onClick={handleOwnerSubmit}
                  loading={updateOwnerLoading}
                >
                  {t('general.submit')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Row justify="space-between" align="middle">
            <Col>
              <AppTitle level={3} style={{marginTop: 0}}>
                {t('apartments.title')}
              </AppTitle>
            </Col>
            <Col style={{margin: 'auto 0'}}>
              <Button
                type="primary"
                onClick={() => setShowAssignApartmentModal(true)}
                icon={<PlusOutlined />}
              />
            </Col>
          </Row>

          <Table<ApartmentRecord>
            loading={apartmentsLoading}
            dataSource={apartments}
            rowKey="id"
            pagination={false}
            size="small"
            bordered
            columns={[
              ...getApartmentColumns(t),
              {
                dataIndex: 'actions',
                key: 'actions',
                render: (_, entity) => (
                  <Space>
                    <Popconfirm
                      title={t('apartments.unassign.confirm')}
                      okText={t('general.yes')}
                      cancelText={t('general.no')}
                      onConfirm={() => handleUnassignApartment(entity)}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        title={t('apartments.unassign')}
                        type="primary"
                        danger={true}
                        loading={unassignApartmentLoading}
                      />
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
          />

          <OwnerVehicles ownerId={ownerId} />
        </Col>
      </Row>

      <Modal
        centered
        forceRender
        open={showAssignApartmentModal}
        onOk={handleAssignApartment}
        onCancel={handleAssignApartmentModalClose}
        okButtonProps={{
          loading: assignApartmentLoading,
          disabled: !!assignApartment?.ownerId,
        }}
        title={t('apartmentRequests.title')}
      >
        <AssignApartmentForm
          form={assignApartmentForm}
          onFinish={handleAssignApartment}
          onApartmentChange={setAssignApartment}
          ownerId={ownerId}
        />

        {assignError ? (
          <Alert
            message={assignError}
            type="error"
            style={{whiteSpace: 'pre-line'}}
          />
        ) : null}
      </Modal>

      <Modal
        centered
        forceRender
        open={showUpdateEmailModal}
        onOk={handleUpdateEmail}
        onCancel={handleUpdateEmailModalClose}
        title={t('users.updateEmail')}
        okButtonProps={{loading: updateEmailLoading}}
        width={600}
      >
        <Form<UpdateEmailFormValues>
          form={updateEmailForm}
          name="update-email"
          className="update-email-form"
          initialValues={{previousEmail: owner?.['user.email']}}
          onFinish={handleUpdateEmail}
        >
          <Form.Item
            name="previousEmail"
            label={t('users.previousEmail')}
            labelCol={{span: 6}}
          >
            <Input type="email" disabled />
          </Form.Item>

          <Form.Item
            name="email"
            label={t('register.email')}
            labelCol={{span: 6}}
            required
          >
            <Input type="email" />
          </Form.Item>

          {updateEmailError ? (
            <Alert
              message={updateEmailError}
              type="error"
              style={{whiteSpace: 'pre-line'}}
            />
          ) : (
            <Alert
              type="warning"
              message={t('users.warning.updateEmail')}
              style={{whiteSpace: 'pre-wrap'}}
            />
          )}

          <Form.Item hidden={true}>
            <Button htmlType="submit">{t('general.submit')}</Button>
          </Form.Item>
        </Form>
      </Modal>
    </ContentDiv>
  );
};
