import {useRequest} from 'ahooks';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  message,
  Row,
  Space,
  Spin,
} from 'antd';
import {APIClass} from 'aws-amplify';
import {FC, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {getDateFormat, useTranslation} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {
  DayjsDateRangeToTimestampRange,
  TimestampToDayjsDate,
} from '../../util/date';
import {ApartmentCodeSelect} from '../input/ApartmentCodeSelect';
import {OwnerSelect2} from '../input/OwnerSelect2';
import {getValidPhoneRule} from '../input/PhoneInput';
import {VehicleBrandNameAutoComplete} from '../input/VehicleBrandNameAutoComplete';
import {VehicleTypeSelect} from '../input/VehicleTypeSelect';
import {ContentDiv} from '../layout/ContentDiv';
import {flattenObject, isAxiosError} from '../table/Table';
import {
  canEditVehicle,
  VehicleFormValues,
  VehicleRecord,
  VehicleType,
} from '../table/Vehicles';

const singularRoute = '/vehicle';
// const pluralRoute = '/vehicles';

const backUrl = '/admin/vehicles';

const AdminVehicleForm: FC<
  FormProps<VehicleFormValues> & {
    creating?: boolean;
    /** Whether we're in admin mode or owner mode. */
    admin?: boolean;
  }
> = (props) => {
  const {admin = false, creating = false, ...formProps} = props;
  const {t, lang} = useTranslation();

  const [form] = Form.useForm(props.form);

  const type = Form.useWatch('type', props.form);
  // const isOwner = type === VehicleType.OWNER;
  const isOccupant = type === VehicleType.OCCUPANT;

  const ownerId = Form.useWatch('ownerId', props.form);

  return (
    <Form<VehicleFormValues>
      labelCol={{span: 10}}
      wrapperCol={{span: 24 - 10}}
      labelWrap
      onValuesChange={(changed) => {
        if ('type' in changed) {
          form.setFieldsValue({
            ownerId: undefined,
            apartmentId: undefined,
          });
        }
      }}
      {...formProps}
      form={form}
    >
      <Form.Item
        label={t('vehicles.ownerId')}
        name="ownerId"
        style={admin ? {} : {display: 'none'}}
        rules={[{required: true}]}
      >
        <OwnerSelect2 allowClear />
      </Form.Item>

      <Form.Item
        label={t('vehicles.type')}
        name="type"
        rules={[{required: true}]}
        style={admin ? {} : {display: 'none'}}
      >
        <VehicleTypeSelect disabled={!creating} />
      </Form.Item>

      <Form.Item
        label={t('vehicles.active')}
        name="active"
        valuePropName="checked"
      >
        <Checkbox disabled={creating} />
      </Form.Item>
      <Form.Item
        label={t('vehicles.apartmentId')}
        name="apartmentId"
        style={isOccupant ? {} : {display: 'none'}}
        rules={[isOccupant ? {required: true} : {}]}
      >
        <ApartmentCodeSelect ownerId={ownerId} allowClear />
      </Form.Item>

      <Form.Item label={t('vehicles.brandName')} name="brandName">
        <VehicleBrandNameAutoComplete />
      </Form.Item>
      <Form.Item label={t('vehicles.model')} name="model">
        <Input />
      </Form.Item>
      <Form.Item
        label={t('vehicles.licensePlateNo')}
        name="licensePlateNo"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('vehicles.occupantName')}
        name="occupantName"
        rules={[isOccupant ? {required: true} : {}]}
        style={isOccupant ? {} : {display: 'none'}}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('vehicles.phoneNumber')}
        name="phoneNumber"
        rules={[
          getValidPhoneRule({
            t,
            allowEmpty: true,
          }),
        ]}
        style={isOccupant ? {} : {display: 'none'}}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('vehicles.dates')}
        name="dates"
        rules={[isOccupant ? {required: true} : {}]}
        style={isOccupant ? {} : {display: 'none'}}
      >
        <DatePicker.RangePicker format={getDateFormat(lang)} />
      </Form.Item>
      <Form.Item
        label={t('vehicles.marked')}
        name="marked"
        valuePropName="checked"
        // hidden
      >
        <Checkbox />
      </Form.Item>
    </Form>
  );
};

export const useGetVehicle = (vehicleId: string | undefined) => {
  const api = useApi();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState<VehicleRecord>();
  const [error, setError] = useState('');

  const fetchVehicle = async () => {
    if (!api || !vehicleId || error || loading) {
      return;
    }

    setVehicle(undefined);
    setLoading(true);

    try {
      const vehicle = (await api.get(
        DEFAULT_API_NAME,
        [singularRoute, vehicleId].join('/'),
        {},
      )) as VehicleRecord;

      setVehicle(flattenObject(vehicle));
      return vehicle;
    } catch (err) {
      let message = t('general.uploadError');
      if (isAxiosError(err) && err.response?.data) {
        message += `:\n${err.response?.data}`;
      }
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  // @TODO use data & loading from here?
  useRequest(fetchVehicle, {
    refreshDeps: [api, vehicleId],
  });

  return {vehicle, loading, error};
};

const removeSpaces = (input: string): string => input.replace(/\s+/g, '');

export const createVehicle = async (
  api: APIClass | null,
  values: VehicleFormValues,
) => {
  if (!api) {
    return;
  }

  // const isOwner = values.type === VehicleType.OWNER;
  const isOccupant = values.type === VehicleType.OCCUPANT;

  const [startDate, endDate] = isOccupant
    ? DayjsDateRangeToTimestampRange(values.dates[0], values.dates[1])
    : [];

  await api.put(DEFAULT_API_NAME, '/vehicle', {
    body: {
      active: values.active,
      type: values.type,
      ownerId: values.ownerId,
      apartmentId: isOccupant ? values.apartmentId : undefined,
      brandName: values.brandName,
      model: values.model,
      licensePlateNo: removeSpaces(values.licensePlateNo),
      occupantName: isOccupant ? values.occupantName.trim().toUpperCase() : '',
      phoneNumber:
        isOccupant && !!values.phoneNumber
          ? removeSpaces(values.phoneNumber)
          : '',
      startDate: isOccupant ? startDate : '',
      endDate: isOccupant ? endDate : '',
      marked: false,
    },
  });
};

export const updateVehicle = async (
  api: APIClass | null,
  vehicleId: string,
  values: VehicleFormValues,
) => {
  if (!api) {
    return;
  }

  // const isOwner = values.type === VehicleType.OWNER;
  const isOccupant = values.type === VehicleType.OCCUPANT;

  const [startDate, endDate] = isOccupant
    ? DayjsDateRangeToTimestampRange(values.dates[0], values.dates[1])
    : [];

  await api.post(DEFAULT_API_NAME, `/vehicle/${vehicleId}`, {
    body: {
      active: values.active,
      type: values.type,
      ownerId: values.ownerId,
      apartmentId: isOccupant ? values.apartmentId : undefined,
      brandName: values.brandName,
      model: values.model,
      licensePlateNo: removeSpaces(values.licensePlateNo),
      occupantName: isOccupant ? values.occupantName.trim().toUpperCase() : '',
      phoneNumber:
        isOccupant && !!values.phoneNumber
          ? removeSpaces(values.phoneNumber)
          : '',
      startDate: isOccupant ? startDate : '',
      endDate: isOccupant ? endDate : '',
      marked: values.marked,
    },
  });
};

export const AdminVehicleUpdate: FC = (props) => {
  const api = useApi();
  const {id: vehicleId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm<VehicleFormValues>();
  const [loading, setLoading] = useState(false);

  const {
    vehicle,
    loading: vehicleLoading,
    error: vehicleFetchError,
  } = useGetVehicle(vehicleId);

  if (vehicleLoading) {
    return <Spin size="large" />;
  }

  if (vehicleFetchError) {
    return (
      <div>
        <Row>
          <Alert message={vehicleFetchError} />
        </Row>
        <Row>
          <Link to={backUrl}>
            <Button>{t('general.cancel')}</Button>
          </Link>
        </Row>
      </div>
    );
  }

  if (!vehicle) {
    return <Spin size="large" />;
  }

  const canEdit = canEditVehicle(vehicle);

  return (
    <ContentDiv>
      <AppTitle level={3} style={{marginTop: 0}}>
        {t('vehicles.title')}
      </AppTitle>
      <Row gutter={20} justify="space-between">
        <Col span={12}>
          <AdminVehicleForm
            admin
            creating={false}
            form={form}
            initialValues={{
              ...vehicle,
              dates:
                vehicle?.startDate && vehicle?.endDate
                  ? [
                      TimestampToDayjsDate(vehicle.startDate),
                      TimestampToDayjsDate(vehicle.endDate),
                    ]
                  : undefined,
            }}
            onFinish={async (values) => {
              if (loading) {
                return;
              }
              if (!canEdit) {
                return;
              }
              setLoading(true);

              // when license plate changes, we want to reset "marked" flag
              // so that admin can see the update
              if (values.licensePlateNo !== vehicle.licensePlateNo) {
                values.marked = false;
              }

              try {
                await updateVehicle(api, vehicleId ?? '-1', values);
              } catch (err: unknown) {
                if (isAxiosError(err) && err.response?.data) {
                  const data = err.response?.data as string;
                  message.warning(t(data));
                  // if (err.response.status === 400) {
                  // }
                }
                setLoading(false);
                return;
              }
              setLoading(false);
              navigate('/admin/vehicles');
            }}
          />
        </Col>
      </Row>
      <Row gutter={20} justify="space-between">
        <Col>
          <Space>
            <Link to={backUrl}>
              <Button>{t('general.cancel')}</Button>
            </Link>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
              disabled={!canEdit}
            >
              {t('general.submit')}
            </Button>
          </Space>
        </Col>
      </Row>
    </ContentDiv>
  );
};

export const AdminVehicleCreate: FC = (props) => {
  const api = useApi();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm<VehicleFormValues>();
  const [loading, setLoading] = useState(false);

  return (
    <ContentDiv>
      <AppTitle level={3} style={{marginTop: 0}}>
        {t('vehicles.title')}
      </AppTitle>
      <Row gutter={20} justify="space-between">
        <Col span={12}>
          <AdminVehicleForm
            admin
            creating={true}
            form={form}
            initialValues={{
              type: VehicleType.OWNER,
              active: true,
            }}
            onFinish={async (values) => {
              if (loading) {
                return;
              }
              setLoading(true);

              try {
                await createVehicle(api, values);
              } catch (err: unknown) {
                if (isAxiosError(err) && err.response?.data) {
                  const data = err.response?.data as string;
                  message.warning(t(data));
                  // if (err.response.status === 400) {
                  // }
                }
                setLoading(false);
                return;
              }
              setLoading(false);
              navigate('/admin/vehicles');
            }}
          />
        </Col>
      </Row>
      <Row gutter={20} justify="space-between">
        <Col>
          <Space>
            <Link to={backUrl}>
              <Button>{t('general.cancel')}</Button>
            </Link>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              {t('general.submit')}
            </Button>
          </Space>
        </Col>
      </Row>
    </ContentDiv>
  );
};
