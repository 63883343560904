import {Checkbox, DatePicker, Form, message, Modal} from 'antd';
import {APIClass} from 'aws-amplify';
import dayjs from 'dayjs';
import {FC, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {FormSubmitButton} from '../FormSubmitButton';

export interface APIResponse {
  nbModified: number;
}

const vehiclesMarkBulk = async (
  api: APIClass | null,
  beforeDate: string,
  marked: boolean,
): Promise<APIResponse | undefined> => {
  if (!api) return;

  const res = await api.post(DEFAULT_API_NAME, '/vehicles/mark', {
    body: {beforeDate, marked},
  });

  if (!res) {
    throw new Error('Erreur');
  }

  if (!!res.error) {
    throw new Error(res.error);
  }

  return res;
};

export const SuperAdminVehicleBulkMark: FC = (props) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState<APIResponse>();

  return (
    <div>
      <Form
        layout="inline"
        initialValues={{
          marked: true,
          beforeDate: dayjs().utc().subtract(2, 'days'),
        }}
        onFinish={async (values) => {
          if (loading) {
            return;
          }

          Modal.confirm({
            title: 'Pointer les véhicules ?',
            content: (
              <p>
                Tous les véhicules avant la date{' '}
                <span style={{whiteSpace: 'nowrap'}}>
                  {dayjs(values.beforeDate).toISOString()}
                </span>{' '}
                seront modifiés ({String(values.marked)}).
              </p>
            ),
            onOk: async () => {
              setError('');
              setLoading(true);
              try {
                const info = await vehiclesMarkBulk(
                  api,
                  values.beforeDate,
                  values.marked,
                );
                setInfo(info);
              } catch (err: any) {
                message.warning(err.message);
                setInfo(undefined);
                setError(err.message);
              }
              setLoading(false);
            },
          });
        }}
      >
        <Form.Item
          label="Avant date"
          name="beforeDate"
          tooltip="Tous les véhicules modifiés AVANT cette date seront modifiés"
          rules={[{required: true}]}
        >
          <DatePicker showTime />
        </Form.Item>
        <Form.Item label="Pointé" name="marked" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <FormSubmitButton loading={loading}>Lancer</FormSubmitButton>
        </Form.Item>
      </Form>

      {error}

      {info ? (
        <ul>
          <li>Nb lignes impactées: {info.nbModified}</li>
        </ul>
      ) : undefined}
    </div>
  );
};
