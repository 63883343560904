import {Typography} from 'antd';
import {TitleProps} from 'antd/es/typography/Title';
import {FC} from 'react';
import {TITLE_FONT_FAMILY} from '../design';

const {Title} = Typography;

export const AppTitle: FC<TitleProps & {icon?: JSX.Element}> = ({
  children,
  icon,
  ...props
}) => (
  <Title
    {...props}
    style={{...(props.style ?? {}), fontFamily: `'${TITLE_FONT_FAMILY}'`}}
  >
    {icon ? <span style={{marginRight: '1rem'}}>{icon}</span> : undefined}
    {children}
  </Title>
);
