import {Form, Input, message} from 'antd';
import {APIClass} from 'aws-amplify';
import {FC, useState} from 'react';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {FormSubmitButton} from '../FormSubmitButton';

interface CognitoInfo {
  Username: string | undefined;
  Enabled: boolean;
  UserAttributes: {Name: string | undefined; Value: string}[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
}

/** Returns Cognito info from an email adress */
const fetchInfoCognitoUser = async (
  api: APIClass | null,
  email: string,
): Promise<CognitoInfo | undefined> => {
  if (!api) return;

  const res = await api.get(DEFAULT_API_NAME, `/user/cognitoInfo/${email}`, {});

  if (!res) {
    throw new Error("Impossible de récupérer les infos de l'utilisateur");
  }

  if (!!res.error) {
    throw new Error(res.error);
  }

  return res;
};

export const SuperAdminCognitoInfo: FC = (props) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState<CognitoInfo>();

  return (
    <div>
      <Form
        layout="inline"
        onFinish={async (values) => {
          if (loading || !values.email) {
            return;
          }

          setError('');
          setLoading(true);
          try {
            const info = await fetchInfoCognitoUser(api, values.email);
            setInfo(info);
          } catch (err: any) {
            message.warning(err.message);
            setInfo(undefined);
            setError(err.message);
          }
          setLoading(false);
        }}
      >
        <Form.Item
          label="Email Cognito"
          name="email"
          rules={[{required: true}]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item>
          <FormSubmitButton loading={loading}>
            Récupérer infos Cognito
          </FormSubmitButton>
        </Form.Item>
      </Form>

      {error}

      {info ? (
        <ul>
          <li>Username: {info.Username}</li>
          <li>Enabled: {info.Enabled}</li>
          <li>UserCreateDate: {info.UserCreateDate}</li>
          <li>UserLastModifiedDate: {info.UserLastModifiedDate}</li>
          <li>UserStatus: {info.UserStatus}</li>
          {info.UserAttributes.map((attr) => (
            <li key={attr.Name}>
              UserAttribute "{attr.Name}": {attr.Value}
            </li>
          ))}
        </ul>
      ) : undefined}
    </div>
  );
};
