import {CreditCardFormValues} from './component/CreditCardForm';

const endpoint = 'https://api.sumup.com';

export interface CheckoutResult {
  amount: number;
  checkout_reference: string;
  currency: 'EUR';
  date: string;
  description: string;
  id: string;
  merchant_code: string;
  merchant_name: string;
  pay_to_email: string;
  purpose: 'CHECKOUT';
  redirect_url: string;
  status: 'PAID';
  transaction_code: string;
  transaction_id: string;
  transactions: {
    amount: number;
    currency: 'EUR';
    entry_mode: 'CUSTOMER_ENTRY';
    id: string;
    installments_count: number;
    internal_id: number;
    merchant_code: string;
    payment_type: 'ECOM';
    status: 'SUCCESSFUL';
    timestamp: string;
    tip_amount: number;
    transaction_code: string;
    vat_amount: number;
  }[];
}

export type SecureCheckoutNextStepMechanism = 'iframe' | 'browser';
export interface SecureCheckoutNextStep {
  next_step: {
    mechanism:
      | SecureCheckoutNextStepMechanism
      | SecureCheckoutNextStepMechanism[];
    method: 'POST' | 'GET';
    payload: object;
    url: string;
    redirect_url: string;
  };
}

export const isSecureCheckout = (
  checkout: CheckoutResult | SecureCheckoutNextStep,
): checkout is SecureCheckoutNextStep =>
  !!(checkout as SecureCheckoutNextStep).next_step;

export const completeSumUpCheckout = async (
  checkoutId: string,
  card: CreditCardFormValues,
) => {
  const req = {
    payment_type: 'card',
    card: {
      name: card.name,
      number: card.number.replace(/\s+/g, ''),
      expiry_month: card.expiryMonth,
      expiry_year: card.expiryYear,
      cvv: card.cvv,
    },
  };
  const res = await fetch(`${endpoint}/v0.1/checkouts/${checkoutId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(req),
  });
  if (!res.ok) return null;

  return res.json() as Promise<CheckoutResult | SecureCheckoutNextStep>;
};

export const generateSecureSumUpForm = ({
  next_step,
}: SecureCheckoutNextStep) => {
  const form = document.createElement('form');

  const mechanism = Array.isArray(next_step.mechanism)
    ? next_step.mechanism
    : [next_step.mechanism];

  // prefer browser mechanism
  const selectedMechanism = mechanism.includes('browser')
    ? 'browser'
    : 'iframe';

  form.action = next_step.url;
  form.method = next_step.method;

  let iframe;
  if (selectedMechanism === 'browser') {
    // we could open the 3DS page in another window, but it's pointless
    // to show a spinner on the current page, the bank will redirect the user
    // to the application once they've confirmed/failed the 3DS process
    // form.target = '_blank';
  } else {
    iframe = document.createElement('iframe');
    iframe.className = 'full-size';
    iframe.name = 'threeDSMethodIframe';

    form.target = 'threeDSMethodIframe';
  }

  for (const [name, value] of Object.entries(next_step.payload)) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;

    form.appendChild(input);
  }

  return {form, iframe};
};
