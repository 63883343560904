import {FC} from 'react';
import {Button, ButtonProps} from 'antd';
import {useTranslation} from '../translation';

export const FormResetButton: FC<ButtonProps> = (props) => {
  const {t} = useTranslation();
  return (
    <Button htmlType="reset" {...props}>
      {t('general.cancel')}
    </Button>
  );
};
