import {useRequest} from 'ahooks';
import {APIClass} from 'aws-amplify';
import {OwnerRecord} from '../component/table/Owner';
import {DEFAULT_API_NAME, useApi, useConnectedUser} from '../util/Auth';
import {DefaultOptionType, FilterFunc} from 'rc-select/es/Select';

const fetchOwnerMe = async (
  api: APIClass | null,
): Promise<OwnerRecord | undefined> => {
  if (!api) return;
  const owner: OwnerRecord = await api.get(DEFAULT_API_NAME, `/owner/me`, {});
  return owner;
};

/** Fetches the owner of the connected user. */
export const useOwnerMe = () => {
  const api = useApi();
  const user = useConnectedUser();

  const {data, loading, error} = useRequest(() => fetchOwnerMe(api), {
    refreshDeps: [api, user],
  });

  return {owner: data, loading, error};
};

/** basic search on labels to use with Select.filterOption */
export const filterOptionByLabel: FilterFunc<DefaultOptionType> = (
  inputValue,
  option,
): boolean =>
  (
    (typeof option?.label === 'string'
      ? option.label
      : option?.key?.toString()) ?? ''
  )
    .trim()
    .toLowerCase()
    .indexOf((inputValue ?? '').trim().toLowerCase()) !== -1;
