import {FC} from 'react';
import {COLORS, MAX_WIDTH, TITLE_FONT_FAMILY} from '../../design';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserLock} from '@fortawesome/free-solid-svg-icons';
import {LanguageSelect, useTranslation} from '../../translation';
import {Link, NavLink} from 'react-router-dom';
import logo from './logo.svg';
import {Button, Space} from 'antd';
import {getAppBaseUrl, getBlogBaseUrl} from '../../util/links';
import {AuthUser, UserRole, useUser, useUserRole} from '../../util/Auth';
import {LogoutButton} from '../LogoutButton';

interface MenuItem {
  label: string;
  to: string;
  external?: boolean;
  disabled?: boolean;
}

const getHeaderMenuItems = (
  user: AuthUser | null,
  role: UserRole | null,
): MenuItem[] => {
  const publicItems: MenuItem[] = [
    {
      label: 'header.theVillage',
      to: getBlogBaseUrl() + '/le-village/',
      external: true,
    },
    {
      label: 'header.activities',
      to: getBlogBaseUrl() + '/activites/',
      external: true,
    },
    {
      label: 'header.tourism',
      to: getBlogBaseUrl() + '/tourisme/',
      external: true,
    },
    {
      label: 'header.news',
      to: getBlogBaseUrl() + '/actualites/',
      external: true,
    },
    {
      label: 'header.contact',
      to: getBlogBaseUrl() + '/contact/',
      external: true,
    },
  ];

  if (!user || !role) {
    return publicItems;
  }

  if (role === UserRole.UNVERIFIED) {
    return publicItems;
  } else if (role === UserRole.OWNER) {
    return [
      {
        label: 'header.owner.documents',
        to: getBlogBaseUrl() + '/espace-proprietaire/documents/',
        external: true,
      },
      {
        label: 'header.owner.info',
        to: getBlogBaseUrl() + '/espace-proprietaire/informations/',
        external: true,
      },
      {
        label: 'header.owner.news',
        to: getBlogBaseUrl() + '/actualites/',
        external: true,
      },
      {
        label: 'header.owner.orderTracking',
        to: '/owner/orders',
      },
      {label: 'header.owner.myVehicles', to: '/owner/vehicles'},
      {label: 'header.owner.myOccupants', to: '/owner/occupants'},
      {label: 'header.owner.myAccount', to: '/owner/profile'},
    ];
  } else if (role === UserRole.ADMIN) {
    return [
      {label: 'header.admin.settings', to: '/admin/settings'},
      {label: 'header.admin.apartments', to: '/admin/apartments'},
      {label: 'header.admin.owners', to: '/admin/owners'},
      {label: 'header.admin.orders', to: '/admin/orders'},
      {label: 'header.admin.invoices', to: '/admin/invoices'},
      {label: 'header.admin.payments', to: '/admin/payments'},
      {label: 'header.admin.entries', to: '/admin/accountingEntries'},
      {label: 'header.admin.stats', to: '/admin/stats'},
    ];
  }

  return [];
};

const AppHeaderMenuItem: FC<{menuItem: MenuItem}> = (props) => {
  const {menuItem} = props;
  const {t} = useTranslation();

  if (menuItem.disabled) {
    return (
      <div>
        <span className="header-menu-item disabled">{t(menuItem.label)}</span>
      </div>
    );
  }

  if (menuItem.external) {
    return (
      <div>
        <a href={menuItem.to} className="header-menu-item">
          {t(menuItem.label)}
        </a>
      </div>
    );
  }

  return (
    <div>
      <NavLink to={menuItem.to} className="header-menu-item">
        {t(menuItem.label)}
      </NavLink>
    </div>
  );
};

const getLogoLink = (
  user: AuthUser | null,
  role: UserRole | null,
): {url: string; external: boolean} => {
  if (user && role === UserRole.OWNER) {
    return {external: true, url: getBlogBaseUrl() + '/espace-proprietaire/'};
  }

  return {external: false, url: '/'};
};

export const AppHeader: FC<{
  /** enables the "big" header */
  big?: boolean;
  /** Only for connected users */
  displayLogoutButton?: boolean;
}> = (props) => {
  const {big = false, displayLogoutButton} = props;
  const {t} = useTranslation();

  const user = useUser();
  const role = useUserRole();
  const menuItems = getHeaderMenuItems(user, role);
  const {external, url} = getLogoLink(user, role);

  const img = (
    <img
      src={logo}
      alt="Logo"
      style={{width: big ? 260 : 130, height: 'auto'}}
    />
  );

  return (
    <div>
      <div
        style={{
          maxWidth: MAX_WIDTH,
          margin: '0 auto',
          display: 'flex',
          alignItems: 'stretch',
          minHeight: big ? 180 : 70,
        }}
      >
        <div
          style={{
            flex: '0 1 340px',
            backgroundColor: COLORS.LOGO_BG,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {external ? <a href={url}>{img}</a> : <Link to={url}>{img}</Link>}
        </div>
        <div style={{flex: '1 1 auto', marginRight: 25}}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{flex: '1 1 40px'}}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <a href={getAppBaseUrl()}>
                  <Button type="primary" style={{marginTop: 4, height: 34}}>
                    <Space>
                      <FontAwesomeIcon icon={faUserLock} />
                      <span
                        style={{
                          fontFamily: TITLE_FONT_FAMILY,
                          fontSize: '12px',
                        }}
                      >
                        {t('general.ownersArea')}
                      </span>
                    </Space>
                  </Button>
                </a>

                <LanguageSelect style={{marginLeft: 12, marginTop: 4}} />
              </div>
            </div>
            <div style={{flex: '1 1 30px'}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  columnGap: 24,
                  rowGap: 6,
                  alignItems: 'center',
                  height: '100%',
                  flexWrap: 'wrap',
                  alignContent: 'flex-end',
                }}
              >
                {menuItems.map((item, key) => (
                  <AppHeaderMenuItem menuItem={item} key={key} />
                ))}
                {displayLogoutButton && user ? <LogoutButton /> : undefined}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
