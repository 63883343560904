import {
  CheckCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {red} from '@ant-design/colors';
import {useRequest} from 'ahooks';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Upload,
  UploadFile,
} from 'antd';
import Table, {ColumnsType} from 'antd/es/table';
import {FC, useMemo, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {
  formatCurrency,
  LANGUAGE,
  TranslateFn,
  useTranslation,
} from '../../translation';
import {AppTitle} from '../../util/AppTitle';
import {DEFAULT_API_NAME, useApi} from '../../util/Auth';
import {ApartmentRecord} from '../table/Apartment';
import {ArticleRecord} from '../table/Article';
import {OrderLineFormValues} from '../table/Order';
import {OwnerRecord} from '../table/Owner';
import {SeasonRecord} from '../table/Season';

export interface CardFormValues {
  tempId: string;
  lastName: string;
  firstName: string;
  isChild: boolean;
  photos?: UploadFile[];
  article: ArticleRecord;
  unitPrice?: number;
  discount?: number;
}

interface SeasonRemainingFreeOr {
  total: number;
  remaining: number;
}

export const getCardTotalCost = ({
  unitPrice = 0,
  discount = 0,
}: CardFormValues): number => unitPrice - unitPrice * discount;

const getOwnerOrderCardsColumns = ({
  t,
  lang,
}: {
  t: TranslateFn;
  lang: LANGUAGE;
}): ColumnsType<CardFormValues> => [
  {
    title: t('card.lastName'),
    key: 'lastName',
    dataIndex: 'lastName',
  },
  {
    title: t('card.firstName'),
    key: 'firstName',
    dataIndex: 'firstName',
  },
  {
    title: t('card.isChild'),
    key: 'isChild',
    dataIndex: 'isChild',
    render: (isChild) =>
      isChild ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null,
  },
  {
    title: t('card.cost'),
    key: 'cost',
    dataIndex: 'cost',
    render: (_, card) => {
      const cost = getCardTotalCost(card);
      return cost ? formatCurrency(cost, lang) : '';
    },
  },
];

interface CardFormProps {
  form: FormInstance<CardFormValues>;
  isRenewal?: boolean;
  editCard?: CardFormValues;
  onFinish?: () => void;
}

const photoMimeTypes = ['image/jpeg', 'image/jpg', 'image/png'];

const CardForm: FC<CardFormProps> = ({form, isRenewal, editCard, onFinish}) => {
  const {t} = useTranslation();

  useMemo(() => {
    form.setFieldsValue({...editCard});
  }, [form, editCard]);

  return (
    <Form<CardFormValues>
      form={form}
      name="card"
      className="card-form"
      onFinish={onFinish}
      initialValues={{tempId: uuid()}}
      labelCol={{span: 9}}
      wrapperCol={{span: 24 - 9}}
      labelWrap
    >
      <Form.Item
        name="lastName"
        label={t('card.lastName')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="firstName"
        label={t('card.firstName')}
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="isChild"
        label={t('card.isChild')}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name="photos"
        label={t('card.photo')}
        valuePropName="fileList"
        rules={[{required: !isRenewal}]}
        getValueFromEvent={(event: {
          file: UploadFile;
          fileList: UploadFile[];
        }) => event?.fileList}
      >
        <Upload
          listType="picture-card"
          accept={photoMimeTypes.join(',')}
          action={async () => ''}
          customRequest={({onSuccess}) =>
            setTimeout(() => {
              onSuccess?.('ok');
            }, 0)
          }
          maxCount={1}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
            showDownloadIcon: false,
          }}
          beforeUpload={(file) => {
            if (!photoMimeTypes.includes(file.type)) {
              message.error(t('general.invalidUploadFileType'));
              return false;
            }
            return true;
          }}
        >
          {t('general.upload')}
        </Upload>
      </Form.Item>
      <Row>
        <Col span={9}></Col>
        <Col span={15}>
          <div
            style={{
              marginTop: -24,
              color: red[5],
            }}
          >
            {t('card.upload.warning')}
          </div>
        </Col>
      </Row>

      <Form.Item name="tempId" label="tempId" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item hidden>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export interface OwnerOrderCardsProps {
  owner?: OwnerRecord;
  season?: SeasonRecord;
  apartment?: ApartmentRecord;
  article?: ArticleRecord;
  additionalArticle?: ArticleRecord;
  isRenewal?: boolean;
  onOrderLinesChange?: (orderLines?: OrderLineFormValues[]) => void;
  onCardsChange?: (cards?: CardFormValues[]) => void;
}

export const OwnerOrderCards: FC<OwnerOrderCardsProps> = ({
  owner,
  season,
  apartment,
  article,
  isRenewal,
  additionalArticle,
  onOrderLinesChange,
  onCardsChange,
}) => {
  const api = useApi();
  const {t, lang} = useTranslation();

  const [cards, setCards] = useState<CardFormValues[]>([]);
  const [editCard, setEditCard] = useState<CardFormValues | undefined>();

  const [cardForm] = Form.useForm<CardFormValues>();

  const fetchSeasonRemainingFreeOr = async (): Promise<
    SeasonRemainingFreeOr | undefined
  > => {
    if (!api || !season || !apartment) return;
    return api.get(
      DEFAULT_API_NAME,
      owner
        ? `/orders/getSeasonRemainingFreeOr/${owner.id}/${season.id}/${apartment.id}`
        : `/orders/me/getSeasonRemainingFreeOr/${season.id}/${apartment.id}`,
      {},
    );
  };

  const {data: seasonRemainingFreeOr} = useRequest(fetchSeasonRemainingFreeOr, {
    refreshDeps: [api, season, apartment, owner],
  });

  const getOrderLinesFromCards = (
    cards: CardFormValues[],
  ): OrderLineFormValues[] => {
    const articleMap: {
      [key: string]: {
        article: ArticleRecord;
        discount: number;
        quantity: number;
        cards: CardFormValues[];
      };
    } = {};
    for (const card of cards) {
      const {article, discount = 0} = card;

      const key = `${article.id}_${discount}`;
      if (!articleMap[key])
        articleMap[key] = {article, discount, quantity: 0, cards: []};

      articleMap[key].quantity++;
      articleMap[key].cards.push(card);
    }

    return Object.values(articleMap).map(
      ({article, quantity, discount, cards}) => ({
        article,
        quantity,
        discount,
        cards,
      }),
    );
  };

  const updateCardTypeAndDiscount = (
    cards: CardFormValues[],
  ): CardFormValues[] => {
    if (!additionalArticle || !article) throw new Error('never');

    let currentRemainingFreeOr = seasonRemainingFreeOr?.remaining ?? 0;

    for (const card of cards) {
      const isAdditionalCard = currentRemainingFreeOr < 1;
      const isFreeAdditionalCard = isAdditionalCard && !!card.isChild;

      card.article = isAdditionalCard ? additionalArticle : article;

      card.unitPrice = isAdditionalCard
        ? card.article.unitPriceInclVAT
        : // FIXME should always be 0?
          card.article.unitPriceInclVAT;
      card.discount = isFreeAdditionalCard ? 1 : 0;

      if (!card.isChild) --currentRemainingFreeOr;
    }

    return [...cards];
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreate = async () => {
    if (!article || !additionalArticle) return;
    await cardForm.validateFields();

    const card = cardForm.getFieldsValue();

    const _cards = updateCardTypeAndDiscount([...cards, card]);
    setCards(_cards);
    onCardsChange?.(_cards);
    onOrderLinesChange?.(getOrderLinesFromCards(_cards));
    handleCreateModalClose();
  };

  const handleCreateModalClose = () => {
    cardForm.resetFields();
    setShowCreateModal(false);
  };

  const handleEdit = async () => {
    if (!article || !additionalArticle) return;

    await cardForm.validateFields();

    const card = cardForm.getFieldsValue();
    const previousCard = cards.find(({tempId}) => tempId === card.tempId);
    if (previousCard) {
      cards[cards.indexOf(previousCard)] = card;
    } else {
      console.warn(
        "NEVER: Couldn't find matching tempIds, pushing the edited card at the end",
      );
      cards.push(card);
    }

    const _cards = updateCardTypeAndDiscount(cards);
    setCards(_cards);
    onCardsChange?.(_cards);
    onOrderLinesChange?.(getOrderLinesFromCards(_cards));
    handleEditModalClose();
  };

  const handleEditModalClose = () => {
    cardForm.resetFields();
    setEditCard(undefined);
  };

  const handleDelete = (card: CardFormValues) => {
    if (!article || !additionalArticle) return;

    const _cards = updateCardTypeAndDiscount(
      cards.filter((_card) => _card !== card),
    );
    setCards(_cards);
    onCardsChange?.(_cards);
    onOrderLinesChange?.(getOrderLinesFromCards(_cards));
  };

  if (!article || !additionalArticle) {
    return <Alert type="error" message={t('order.error.noArticles')} />;
  }

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <AppTitle level={4} style={{marginTop: 0}}>
            {t('cards.title')}
          </AppTitle>
        </Col>
        <Col style={{margin: 'auto 0'}}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateModal(true)}
          />
        </Col>
      </Row>
      <Table<CardFormValues>
        dataSource={cards}
        pagination={false}
        bordered
        columns={[
          ...getOwnerOrderCardsColumns({t, lang}),
          {
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            fixed: 'right',
            render: (_, card) => (
              <Space>
                <Button
                  icon={<EditOutlined />}
                  type="primary"
                  onClick={() => setEditCard(card)}
                />

                <Popconfirm
                  title={t('general.delete')}
                  okText={t('general.yes')}
                  cancelText={t('general.no')}
                  onConfirm={() => handleDelete(card)}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    danger={true}
                  />
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />
      <Modal
        centered
        forceRender
        open={showCreateModal}
        onOk={handleCreate}
        onCancel={handleCreateModalClose}
        cancelText={t('general.cancel')}
        title={t('card.create')}
      >
        <CardForm
          form={cardForm}
          onFinish={handleCreate}
          isRenewal={isRenewal}
        />
      </Modal>
      <Modal
        centered
        forceRender
        open={!!editCard}
        onOk={handleEdit}
        onCancel={handleEditModalClose}
        cancelText={t('general.cancel')}
        title={t('card.create')}
      >
        <CardForm
          form={cardForm}
          onFinish={handleEdit}
          editCard={editCard}
          isRenewal={isRenewal}
        />
      </Modal>
    </>
  );
};
